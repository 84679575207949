import React from "react";
import "./index.css";
import {
  getDeadLineData,
  getDeadLineDataByLeadManager,
  getDeadLineDataByLeadRemote
} from "../../../../api/routes/extensions";
import { BAD, SUCCESS } from "../../../../helpers/response-service";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../../../components/kit/Buttons/IconButton";
import { TbArrowLeft } from "react-icons/tb";
import { MULTIPLY_VIEWS } from "../../../../constants/multiply-views";
import { VIEW_TYPES } from "../../../../constants/types";
import Page404 from "../../../error/Page404";
import SkeletonView from "../../../../components/kit/Loaders/SkeletonView";
import { useLocation, useNavigate } from "react-router-dom";
import { multiplyObjects_AddData, multiplyObjects_InitData } from "../../../../store/reducers/multiplyObjectsReducer";
import SchemaObjectsFilterModal from "../../../../components/kit/Modals/SchemaObjectsFilterModal";

const Extension_DeadLinePage = (props) => {
  const { schema } = props;

  const { pathname } = useLocation()

  const [loading, setLoading] = React.useState(false);
  const stageExtensionData = useSelector((state) => state.extensions.__stageExtensionConfig.data);

  const createStoreAction = useDispatch();
  const navigate = useNavigate();
  const objects = useSelector((state) => state.multiplyObjects.__groupedObjects);
  const [filter, setFilter] = React.useState(null);

  React.useEffect(() => {
    getDeadlineData();
  }, []);

  const getDeadlineData = (filter_data, paginationConfig) => {
    return new Promise((resolve, reject) => {
      const payload = {
        query: {
          schema_id: schema.id,
          page: paginationConfig && "page" in paginationConfig ? paginationConfig.page : 0,
          // limit: paginationConfig && "limit" in paginationConfig ? paginationConfig.limit : 20,
          limit: 12
        },
        data: filter_data ? filter_data : {},
      };
      payload.query = new URLSearchParams(payload.query).toString();
      !paginationConfig && setLoading(true);
      (pathname.includes('lead-manager') ? getDeadLineDataByLeadManager(payload) : pathname.includes('lead-remote') ? getDeadLineDataByLeadRemote(payload) : getDeadLineData(payload))
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              if (paginationConfig) {
                const currentGroup = result.data.grouped_objects.find((group) => {
                  const isTarget = group.grouped_by.choice_id === paginationConfig.choice_id;
                  if (isTarget && group.objects.length < paginationConfig.limit) {
                    group.grouped_by.isLastPage = true;
                  }
                  return isTarget;
                });
                if (currentGroup.objects.length > 0) {
                  appendDataFromPagination({ grouped_objects: [currentGroup], object_structure: result.data.object_structure });
                }
              } else {
                createStoreAction(
                  multiplyObjects_InitData({
                    data: result.data,
                    isOpenStages: true,
                    stageExtensionData,
                  })
                );
              }
              resolve(result);
              break;
            case BAD:
              reject(result.data);
              break;
            default:
              reject(result.data);
          }
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          !paginationConfig && setLoading(false);
        });
    });
  };

  const getObjects = (filter) => {
    setFilter(filter);
    getDeadlineData(filter);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleDeadLine = (info, revertAction) => {};

  const handlePagination = (paginationConfig) => {
    return new Promise((resolve, reject) => {
      getDeadlineData(filter, paginationConfig)
        .then((result) => {})
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const appendDataFromPagination = (currentGroup) => {
    createStoreAction(multiplyObjects_AddData(currentGroup));
  };

  return (
    <div {...{ className: "Extension_ApprovePage flex-column MultiplyView" }}>
      <div {...{ className: "Extension_ApprovePage_header flex-row justify-between" }}>
        <div {...{ className: "flex-row" }}>
          <IconButton
            {...{
              onClick: () => goBack(),
              color: "#333",
              icon: <TbArrowLeft />,
              size: 24,
              style: { background: "var(--card-background)", height: 60, width: 60 },
              width: 55,
              height: 55,
            }}
          />
          <div {...{ className: "Extension_ApprovePageTitle" }}>
            <span>{pathname.includes('lead-manager') ? "uHome - Менеджеры" : pathname.includes('lead-remote') ? "uHome - Встречи" : "Срок выполнения"}</span>
          </div>
        </div>
        <SchemaObjectsFilterModal
          {...{
            ungrouped: true,
            style: { marginRight: 6 },
            getObjects,
            schema_id: schema.id,
          }}
        />
      </div>
      <div {...{ className: "MultiplyView_dataContainer" }}>
        {!loading ? (
          objects ? (
            MULTIPLY_VIEWS[VIEW_TYPES.BILLBOARD].component({
              drag: false,
              data: objects.grouped_objects,
              action: handleDeadLine,
              multiply_view_id: null,
              schemaFields: null,
              onListEnd: handlePagination,
              schema_id: schema.id,
              schema_key: schema.key,
              extensions: false,
            })
          ) : (
            <Page404 />
          )
        ) : (
          <SkeletonView {...{ view: VIEW_TYPES.BILLBOARD }} />
        )}
      </div>
    </div>
  );
};

export default Extension_DeadLinePage;
