export const FinishStageTypesConfig = {
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
} as const;

export type FinishStageTypes = keyof typeof FinishStageTypesConfig;

export interface IMessageExtension {
  external_id: number;
  name: string;
  webhook_url: string;
  phone: string;
  hidden: boolean;
  description: string;
  exist_in_crm: boolean;
}

export interface IStageExtension {
  need_approve: boolean;
  use_auto_movement: boolean;
  choice_id: number;
  finish_stage_type: FinishStageTypes | null;
  require_complete_comment: boolean;
  id: number;
  field_id: number;
  meta_name: string;
  order: number;
  name: string;
  icon: string;
  color: string;
}

export interface IPactUser {
  user_id: number;
  pact_company_id: number;
  pact_company_name: string;
  created_at: string;
  exist: boolean;
}
