import "dayjs/locale/ru";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

dayjs.locale("ru");
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(relativeTime, {
	thresholds: [{ l: "dd", r: 364 * 100, d: "day" }],
});
