import { api } from "../index.jsx";
import * as auth from "../entry-points.jsx";
import { Api, ApiEndpoint } from "../api-service";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

const signIn = (payload) => loginEndpoint.request(auth.SIGN_IN, payload, "post");

const signUp = (payload) => loginEndpoint.request(auth.SIGN_UP, payload, "post");

const logOut = () => loginEndpoint.request(auth.LOGOUT, null, "post");

const verify = () => loginEndpoint.request(auth.VERIFY, null, "get");

const push = (payload) => loginEndpoint.request(auth.GET_PUSH(payload.token), payload, "post");

export { signIn, signUp, logOut, verify, push };
