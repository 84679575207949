import DeadLineExtension_ElementView from "./DeadLineExtension_ElementView";
// import DeadLineExtension_SingleView from "./DeadLineExtension_SingleView";
import DeadLineExtension_FieldView from "./DeadLineExtension_FieldView";

export const DEADLINE_EXTENSION = {
  MULTIPLE_VIEW: {
    component: () => <div></div>,
  },
  SINGLE_VIEW: {
    component: () => null,
    // <DeadLineExtension_SingleView {...props}/>
  },
  ELEMENT_VIEW: {
    component: (props) => <DeadLineExtension_ElementView {...props} />,
  },
  FIELD_VIEW: {
    component: (props) => <DeadLineExtension_FieldView {...props} />,
  },
};
