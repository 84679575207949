import { EditWidgetsEnum, ViewWidgetsEnum } from "./types";
import { TypesEnum } from "./TypesEnum";

export interface IWidgetConfig {
  name: string;
  id: number;
  view?: ViewWidgetsEnum;
  edit?: EditWidgetsEnum;
}

export interface ITypeConfig extends IWidgetConfig {
  type: TypesEnum;
  widgetsConfig?: IWidgetConfig[];
}

export const TypesConfig: ITypeConfig[] = [
  {
    type: TypesEnum.STRING,
    name: "Текстовое поле",
    id: 1,
    view: ViewWidgetsEnum.DEFAULT,
    edit: EditWidgetsEnum.INPUT,
  },
  {
    type: TypesEnum.STRING,
    name: "Большое текстовое поле",
    id: 2,
    view: ViewWidgetsEnum.DEFAULT,
    edit: EditWidgetsEnum.TEXTAREA,
  },
  {
    type: TypesEnum.STRING,
    name: "Выпадающий список",
    id: 3,
    view: ViewWidgetsEnum.DEFAULT,
    edit: EditWidgetsEnum.DROPDOWN,
  },
  {
    type: TypesEnum.NUMBER,
    name: "Числа",
    id: 4,
    widgetsConfig: [
      {
        name: "Текстовое поле",
        id: 5,
        view: ViewWidgetsEnum.DEFAULT,
        edit: EditWidgetsEnum.INPUT,
      },
      {
        name: "Выпадающий список",
        id: 6,
        view: ViewWidgetsEnum.DEFAULT,
        edit: EditWidgetsEnum.DROPDOWN,
      },
    ],
  },
  {
    type: TypesEnum.PHONE,
    name: "Телефон",
    id: 7,
    view: ViewWidgetsEnum.PHONE,
    edit: EditWidgetsEnum.PHONE,
  },
  // {
  // 	typeName: "Деньги",
  // 	type: TypesEnum.PRICE,
  // 	flat: false,
  // 	name: "Текстовое поле",
  // 	id: 7,
  // 	view: ViewWidgetsEnum.PRICE,
  // 	edit: EditWidgetsEnum.PRICE,
  // },
  // {
  // 	typeName: "Деньги",
  // 	type: TypesEnum.PRICE,
  // 	flat: false,
  // 	name: "Выпадающий список",
  // 	id: 8,
  // 	view: ViewWidgetsEnum.PRICE,
  // 	edit: EditWidgetsEnum.DROPDOWN,
  // },
  {
    type: TypesEnum.LINK,
    name: "Ссылка",
    id: 10,
    view: ViewWidgetsEnum.LINK,
    edit: EditWidgetsEnum.LINK,
  },
  {
    type: TypesEnum.DATETIME,
    name: "Время",
    id: 11,
    widgetsConfig: [
      {
        name: "Время",
        id: 12,
        view: ViewWidgetsEnum.TIME,
        edit: EditWidgetsEnum.TIME,
      },
      {
        name: "Дата",
        id: 13,
        view: ViewWidgetsEnum.DATE,
        edit: EditWidgetsEnum.DATE,
      },
      {
        name: "Дата и вермя",
        id: 14,
        view: ViewWidgetsEnum.DATETIME,
        edit: EditWidgetsEnum.DATETIME,
      },
    ],
  },

  {
    type: TypesEnum.EMAIL,
    name: "Электронная почта",
    id: 15,
    view: ViewWidgetsEnum.DEFAULT,
    edit: EditWidgetsEnum.EMAIL,
  },
];

export const getTypeWidgets = (type: TypesEnum): ITypeConfig[] => {
  const result: ITypeConfig[] = [];
  TypesConfig.forEach((config) => {
    if (config.type === type) {
      result.push(config);
    }
  });
  return result;
};

export const findConfig = (type: TypesEnum, edit: EditWidgetsEnum, view: ViewWidgetsEnum) => {
  return TypesConfig.find((config) => config.type === type && config.view === view && config.edit === edit);
};
