import React from "react";
import { isValue, validateValuesByType } from "../../../../helpers/values-validator";
import EditDropDown from "../../../elements/Widgets/Edit/EditDropDown";
import { updateObject } from "../../../../api/routes/objects_core";

const StageExtension_FieldView = (props) => {
  const { data, object_id } = props;

  const [values, setValues] = React.useState(data.values);

  if (!isValue(data.values)) {
    return null;
  }

  const updateObjectStage = (value) => {
    setValues(value);
    const payload = {
      query: object_id,
      data: [
        {
          field_id: data.id,
          values: validateValuesByType(data.type, value),
        },
      ],
    };
    updateObject(payload).catch((e) => console.log(e));
  };

  return (
    <div>
      <EditDropDown
        {...{
          color: "var(--primary-color)",
          onSubmit: updateObjectStage,
          values,
          variants: data.choice,
        }}
      />
    </div>
  );
};

export default StageExtension_FieldView;
