import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";

import ModalPopup from "../ModalPopup";
import SelectDefault from "../../Selects/SelectDefault";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";

import styles from "./index.module.css";
import Bottom from "../Bottom";
import { isValue, validateValuesByType } from "../../../../helpers/values-validator";
import { moveObjectBetweenSchemas } from "../../../../api/routes/objects_core";
import DefaultInput from "../../Inputs/DefaultInput";
import DefaultSwitcherSingle from "../../Switchers/DefaultSwitcherSingle/DefaultSwitcherSingle";
import { useParams } from "react-router-dom";
import { SUCCESS } from "../../../../helpers/response-service";
import { successToast } from "../../Toast/toast";
import { deleteAllSelectedObjects } from "../../../../store/reducers/selectedObjectsSlice";

const ModalSelectAction = (props) => {
  const { isOpen, getObjects, handleClose } = props;

  const { schema_key } = useParams();
  const dispatch = useDispatch();

  const schemas = useSelector((state) => state.app.schemas).filter((item) => item.meta_name === "__lead__");
  const users = useSelector((state) => state.app.campaign_users);
  const userFilters = useSelector((state) => state.multiplyObjects.userFilters);
  const schemaObjectsFilter = useSelector((state) => state.multiplyObjects.__schema_objects_filter);
  const selectedObjectsSlice = useSelector((state) => state.selectedObjectsSlice.pull);
  const groupedByFieldsActive = useSelector((state) => state.multiplyObjects.groupedByFieldsActive);

  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    schemaFrom: false,
    schemaTo: false,
    user: false,
    stageFrom: false,
    stageTo: false,
  });

  const [requestError, setRequestError] = useState("");

  const [formState, setFormState] = useState({
    schemaFrom: schemas.filter((item) => item.id === +schema_key),
    schemaTo: [],
    user: [],
    stageFrom: [],
    stageTo: [],
    limit: Object.keys(selectedObjectsSlice).length || 10,
    excludeDuplicate: false,
  });

  const currentSchemaChoice = useMemo(
    () => (formState.schemaFrom ? formState.schemaFrom[0].fields.filter((item) => item.meta_name === "__stage__")[0].choice : []),
    []
  );

  const handleChangeFormState = (fieldKey, value) => {
    if (requestError) {
      setRequestError("");
    }

    setFormErrors((prevState) => ({ ...prevState, [fieldKey]: false }));
    setFormState((prevState) => ({
      ...prevState,
      [fieldKey]: value,
    }));
  };

  const handleChangeLimit = (value) => {
    if (requestError) {
      setRequestError("");
    }

    setFormState((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleSwitch = (checked) => {
    if (requestError) {
      setRequestError("");
    }

    setFormState((prevState) => ({
      ...prevState,
      excludeDuplicate: checked,
    }));
  };

  const filteredStageTo = useMemo(() => {
    if (formState.schemaTo.length) {
      const findStageBySchema = formState.schemaTo[0].fields.find((item) => item.meta_name === "__stage__");
      return findStageBySchema ? findStageBySchema.choice : [];
    }
    return [];
  }, [formState.schemaTo]);

  const getFieldValues = (type, values) => {
    const data = [];
    values.forEach((value) => {
      if (typeof value === "string" || typeof value === "boolean") {
        data.push(validateValuesByType(type, [value])[0]);
      } else if (typeof value === "object") {
        data.push(value.name);
      }
    });
    return data;
  };

  const getFilterFieldForRequest = (field) => {
    return {
      field_id: field.id,
      type: field.type,
      operand: field.operand,
      values: getFieldValues(field.type, field.selected),
    };
  };

  const filterFieldsCollection = () => {
    const array = [];
    schemaObjectsFilter.map((schema) => {
      schema.field_filters.map((fieldsArray) => {
        fieldsArray.map((field) => {
          if (isValue(field.selected)) {
            array.push(getFilterFieldForRequest(field));
          }
        });
      });
    });

    const hasCreatedAt = userFilters?.created_from || userFilters?.created_to;
    const created_at = {
      created_from: userFilters?.created_from || undefined,
      created_to: userFilters?.created_to || undefined,
    };

    return {
      is_desc: userFilters?.is_desc,
      simple_filters: array.length > 0 ? array : undefined,
      creators_ids: userFilters?.creators_ids?.map(({ id }) => id),
      owners_ids: userFilters?.owners_ids?.map(({ id }) => id),
      created_at: hasCreatedAt ? [created_at] : undefined,
    };
  };

  const handleSubmit = () => {
    if (
      !formState.schemaFrom.length ||
      !formState.schemaTo.length ||
      !formState.user.length ||
      !formState.stageFrom.length ||
      !formState.stageTo.length
    ) {
      const errors = Object.keys(formErrors).reduce(
        (acc, key) => ({
          ...acc,
          [key]: !formState[key].length,
        }),
        {}
      );
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);

    const filtersPayload = filterFieldsCollection();

    const payload = {
      query: {
        from_schema_id: formState.schemaFrom[0].id,
        to_schema_id: formState.schemaTo[0].id,
        to_user_id: formState.user[0].id,
        from_stage_id: formState.stageFrom[0].id,
        to_stage_id: formState.stageTo[0].id,
        limit: formState.limit,
        exclude_duplicate: formState.excludeDuplicate,
      },
      data: {
        ...(Object.keys(selectedObjectsSlice).length ? { ids: Object.keys(selectedObjectsSlice).map((item) => +item) } : filtersPayload),
      },
    };

    payload.query = new URLSearchParams(payload.query).toString();

    moveObjectBetweenSchemas(payload)
      .then(({ kind, data }) => {
        if (kind === SUCCESS) {
          successToast("Объекты успешно отправлены на перенос");
          handleClose();
          getObjects(groupedByFieldsActive, filtersPayload);
          dispatch(deleteAllSelectedObjects());
        } else {
          setRequestError(data.error.detail);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      stageTo: [],
    }));
  }, [formState.schemaTo]);

  return (
    <ClickOutsideBoundaryTemplate
      overlayVisible
      isOpen={isOpen}
      onClose={() => handleClose()}
      center>
      <ModalPopup
        onClose={handleClose}
        titleText="Массовый перенос сделок"
        bottom={() => (
          <Bottom
            buttonLabel={"Перенести"}
            onSubmit={handleSubmit}
            onClose={() => handleClose()}
            loading={isLoading}
          />
        )}
        onClick={() => handleClose()}>
        <div className={styles.FieldsWrapper}>
          <SelectDefault
            search
            disabled
            amount={1}
            field_key="name"
            placeholder="Из воронки"
            data={[]}
            values={formState.schemaFrom}
            error={formErrors.schemaFrom}
          />
          <SelectDefault
            search
            amount={1}
            field_key="name"
            placeholder="В воронку"
            data={schemas.filter((item) => item.id !== +schema_key)}
            values={formState.schemaTo}
            onChange={(value) => handleChangeFormState("schemaTo", value)}
            error={formErrors.schemaTo}
          />
          <SelectDefault
            search
            amount={1}
            field_key="name"
            placeholder="Передать пользователю"
            data={users}
            values={formState.user}
            onChange={(value) => handleChangeFormState("user", value)}
            error={formErrors.user}
          />
          <SelectDefault
            search
            amount={1}
            field_key="name"
            placeholder="Из стадии"
            data={currentSchemaChoice}
            values={formState.stageFrom}
            onChange={(value) => handleChangeFormState("stageFrom", value)}
            error={formErrors.stageFrom}
          />
          <SelectDefault
            search
            amount={1}
            field_key="name"
            disabled={!formState.schemaTo.length}
            placeholder="В стадию"
            data={filteredStageTo}
            values={formState.stageTo}
            onChange={(value) => handleChangeFormState("stageTo", value)}
            error={formErrors.stageTo}
          />
          <DefaultInput
            type="number"
            placeholder="Количество"
            disabled={!!Object.keys(selectedObjectsSlice).length}
            value={formState.limit}
            onChange={handleChangeLimit}
          />
          <div className={styles.SwitcherWrapper}>
            <span className={styles.SwitcherLabel}>Исключить дубликаты</span>
            <DefaultSwitcherSingle
              checked={formState.excludeDuplicate}
              onChange={handleSwitch}
            />
          </div>
          <span className={styles.WarningBlockText}>
            {!!Object.keys(selectedObjectsSlice).length
              ? "Перенос будет происходить по выбранным из списка объектам"
              : "Перенос будет происходить на основе примененного фильтра"}
          </span>
          {!!requestError && <span className={styles.ErrorText}>{requestError}</span>}
        </div>
      </ModalPopup>
    </ClickOutsideBoundaryTemplate>
  );
};

export default ModalSelectAction;
