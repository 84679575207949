import * as permission from "../entry-points";
import { ApiEndpoint } from "../api-service";
import { api } from "../index";
import { BasePermission, GetPermission } from "../interfaces/permissions";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

//@ts-ignore
const getPermissions: GetPermission = (role_id) => loginEndpoint.request(permission.GET_PERMISSIONS(role_id), null, "get");
const getCreatePermission: BasePermission = (payload) =>
  loginEndpoint.request(permission.BASE_PERMISSION(payload.role_id), payload.permissions, "post");
const updatePermission: BasePermission = (payload) => loginEndpoint.request(permission.BASE_PERMISSION(payload.role_id), payload.permissions, "put");

export { getPermissions, getCreatePermission, updatePermission };
