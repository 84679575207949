import React, { useEffect } from "react";
import "./index.css";
import useTimer from "../../../../hooks/useTimer";
import { motion } from "framer-motion";
import * as JsSIP from "jssip";
import { getTelephonyConfig } from "../../../../api/routes/extensions";
import { BAD, SUCCESS } from "../../../../helpers/response-service";
import { CALL_STATUSES } from "../call-statuses";
import Spinner from "../../../kit/Loaders/Spinner";
import IconManager from "../../../kit/Icons/IconManager";
import { COLORS } from "../../../../constants/types";

const incomeCallSound = "https://bigsoundbank.com/UPLOAD/mp3/0375.mp3";

const LISTENERS = {
  NEW_RTC_SESSION: "newRTCSession",
  INCOMING: "incoming",
  ACCEPTED: "accepted",
  CONFIRMED: "confirmed",
  ENDED: "ended",
  FAILED: "failed",
  PEER_CONNECTION: "peerconnection",
  ADD_STREAM: "addstream",
};

const INCOME_CALL_MEDIA_CONSTRAINTS_OPTIONS = {
  mediaConstraints: {
    audio: true,
    video: false,
  },
};

const IncomingCallContainer = ({ isActive }) => {
  if (!isActive) {
    return null;
  }

  const [isInit, setIsInit] = React.useState(false);
  const incomeCallContainerRef = React.useRef(null);
  const [currentStatus, setCurrentStatus] = React.useState(CALL_STATUSES.INCOME());
  const [isTimerStart, timerString, startTimer, stopTimer] = useTimer();

  const callSessionRef = React.useRef(null);

  const remoteIncomeAudioElement = React.useRef(null);
  const incomingCallSoundElement = React.useRef(null);

  useEffect(() => {
    if (currentStatus && currentStatus.timer) {
      startTimer();
    } else if (isTimerStart) {
      stopTimer();
    }
  }, [currentStatus]);

  useEffect(() => {
    if (isActive) {
      initSocket();
    }
  }, []);

  const getSipConfig = () => {
    return new Promise((resolve, reject) => {
      getTelephonyConfig().then((result) => {
        switch (result.kind) {
          case SUCCESS:
            resolve(result.data);
            break;
          case BAD:
            reject(result.data);
        }
      });
    });
  };

  const initSocket = () => {
    getSipConfig()
      .then((result) => {
        // initUA(result);
      })
      .catch((error) => {
        console.log("ERROR getSipConfig", error);
      });
  };

  const initUA = (sipConfig) => {
    // console.log(sipConfig);

    const config = {
      sip: "1014@webrtc4.getret.ru",
      sip_password: "ci2ci234lf34lfci234",
      sip_socket_url: "wss://webrtc4.getret.ru",
    };

    window.INCOME_SIP_SESSION = new JsSIP.UA({
      uri: config.sip,
      password: config.sip_password,
      sockets: [new JsSIP.WebSocketInterface(config.sip_socket_url + "/ws")],
    });
    window.INCOME_SIP_SESSION.start();
    window.INCOME_SIP_SESSION.on(LISTENERS.NEW_RTC_SESSION, newRTCSessionCallBack);
  };

  const newRTCSessionCallBack = (event) => {
    if (callSessionRef.current) {
      resetCall();
    }
    callSessionRef.current = event.session;

    if (callSessionRef.current.direction === LISTENERS.INCOMING) {
      setIsInit(true);
      playIncomeCallSound();
      setCurrentStatus(CALL_STATUSES.INCOME());

      callSessionRef.current.on(LISTENERS.ACCEPTED, (e) => {
        console.log("CALL", e);
        incomingCallSoundElement.current.pause();
        setCurrentStatus(CALL_STATUSES.CALL);
      });

      callSessionRef.current.on(LISTENERS.CONFIRMED, (e) => {
        const localStream = callSessionRef.current.connection.getLocalStreams()[0];
        let dtmfSender = callSessionRef.current.connection.createDTMFSender(localStream.getAudioTracks()[0]);
        callSessionRef.current.sendDTMF = function (tone) {
          dtmfSender.insertDTMF(tone);
        };
      });

      callSessionRef.current.on(LISTENERS.ENDED, (e) => {
        resetCall();
      });

      callSessionRef.current.on(LISTENERS.FAILED, (e) => {
        resetCall();
      });

      callSessionRef.current.on(LISTENERS.PEER_CONNECTION, (e) => {
        const peerconnection = e.peerconnection;
        peerconnection.onaddstream = function (e) {
          remoteIncomeAudioElement.current.srcObject = e.stream;
          remoteIncomeAudioElement.current.play();
        };

        let remoteStream = new MediaStream();
        peerconnection.getReceivers().forEach(function (receiver) {
          remoteStream.addTrack(receiver.track);
        });
      });
    } else {
      callSessionRef.current.connection.addEventListener(LISTENERS.ADD_STREAM, (e) => {
        stopIncomeCallSound();
        remoteIncomeAudioElement.current.srcObject = e.stream;
      });
    }
  };

  const playIncomeCallSound = () => {
    incomingCallSoundElement.current.muted = false;
    try {
      incomingCallSoundElement.current.play();
    } catch (e) {}
  };

  const stopIncomeCallSound = () => {
    incomingCallSoundElement.current.muted = true;
    try {
      incomingCallSoundElement.current.pause();
    } catch (e) {}
  };

  const resetCall = () => {
    stopIncomeCallSound();
    setCurrentStatus(CALL_STATUSES.ENDED);
    setTimeout(() => {
      setIsInit(false);
      callSessionRef.current = null;
    }, 2000);
  };

  const declineCall = () => {
    if (callSessionRef && callSessionRef.current) {
      callSessionRef.current.terminate();
    }
    resetCall();
  };

  const answerCall = () => {
    if (callSessionRef && callSessionRef.current) {
      callSessionRef.current.answer(INCOME_CALL_MEDIA_CONSTRAINTS_OPTIONS);
    }
  };

  return (
    <motion.div
      {...{
        ref: incomeCallContainerRef,
        className: `IncomeCallClient__container`,
        style: { background: currentStatus.status_background },
        variants: CALL_CLIENT_OPEN_VARIANTS,
        animate: isInit ? "opened" : "closed",
        initial: "closed",
      }}>
      <div
        {...{
          className: "CallClient__container__statusColorLine",
          style: { background: currentStatus.status_color },
        }}></div>
      <audio {...{ id: "call-client__remote-audio", style: { visibility: "hidden" } }}></audio>
      <div {...{ className: "CallClient__containerData" }}>
        <div {...{ className: "flex-row align-center" }}>
          <div
            {...{
              style: { color: "#aaa" },
              className: "CallClient__containerDataTimerName",
            }}>
            {currentStatus.status_timer_name}
          </div>
          {currentStatus.loading && (
            <div>
              <Spinner
                {...{
                  style: { marginLeft: 10, marginTop: 1 },
                  color: "#aaa",
                  loading: currentStatus.loading,
                  size: 15,
                }}
              />
            </div>
          )}
        </div>
        <div
          {...{
            className: "CallClient__containerDataStatusText flex-row align-center",
          }}>
          <div
            {...{
              className: "CallClient__containerDataStatusCircle",
              style: { background: currentStatus.status_color },
            }}></div>
          <div>{currentStatus.status_text}</div>
        </div>
      </div>
      <div {...{ className: "CallClient__containerControls flex-row align-center" }}>
        {currentStatus.timer && <div {...{ className: "CallClient__containerControlsTimer" }}>{timerString}</div>}
        {currentStatus.declineButtonActive && (
          <CircleCallButton
            {...{
              style: { marginRight: 6 },
              onClick: () => declineCall(),
              icon: "phone-off",
            }}
          />
        )}
        {currentStatus.callButtonActive && (
          <CircleCallButton
            {...{
              onClick: () => answerCall(),
              icon: "phone",
              color: COLORS.GREEN100,
            }}
          />
        )}
      </div>
      <audio {...{ ref: remoteIncomeAudioElement, style: { visibility: "hidden" } }}></audio>
      <audio {...{ ref: incomingCallSoundElement, src: incomeCallSound, loop: true, style: { visibility: "hidden" } }}></audio>
    </motion.div>
  );
};

IncomingCallContainer.defaultProps = {
  isActive: false,
};

export default IncomingCallContainer;

const CircleCallButton = ({ color, icon, onClick, style }) => {
  return (
    <div
      {...{
        className: "CallClient__containerControlsCircleButton",
        style: {
          ...style,
          backgroundColor: color,
        },
        onClick,
      }}>
      <IconManager
        {...{
          icon,
          size: 14,
          color: "#fff",
        }}
      />
    </div>
  );
};

const CALL_CLIENT_OPEN_VARIANTS = {
  opened: {
    zIndex: 99999,
    y: 0,
    opacity: 1,
    transition: {
      bounces: false,
    },
  },
  closed: {
    zIndex: 0,
    y: 250,
    opacity: 0,
    transition: {
      bounces: false,
    },
  },
};
