import React from "react";
import FilterField from "../../kit/Modals/SchemaObjectsFilterModal/FilterFieldsIteration/FilterField";
import styles from "./index.module.css";
import { EditWidgetsEnum } from "../../../constants/types";
import cn from "classnames";

const FormLargeElements = ({ fields, onSubmit, className }) => {
  const handleSubmitField = (values, field_index, field_subIndex) => {
    onSubmit(values, field_index, field_subIndex);
  };

  const MultipleFieldsWrapper = (props) => {
    const { fields, field_index } = props;

    return (
      <div {...{ className: cn(styles.grid, className) }}>
        {fields.map((field, i) => (
          <div key={i}>
            <FilterField
              {...{
                field: { ...field, name: `${i === 0 ? "От" : "До"} ${field.name.toLocaleLowerCase()}` },
                className,
                onSubmit: (values) => handleSubmitField(values, field_index, i),
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.grid}>
      {fields &&
        fields.map((fieldsArray, index) => {
          if (fieldsArray.length === 0 || fieldsArray[0]?.edit_widget === EditWidgetsEnum.TAG) return null;
          return (
            <div
              {...{ key: index }}
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {fieldsArray.length > 1 ? (
                <MultipleFieldsWrapper
                  {...{
                    fields: fieldsArray,
                    field_index: index,
                  }}
                />
              ) : (
                <FilterField
                  {...{
                    field: fieldsArray[0],
                    className,
                    onSubmit: (values) => handleSubmitField(values, index, 0),
                  }}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default FormLargeElements;
