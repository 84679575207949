import React from "react";
import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { getObjectFilters } from "../../../../api/routes/objects_core";
import { SUCCESS } from "../../../../helpers/response-service";
import { connect } from "react-redux";
import {
  multiplyObjects_ClearData,
  multiplyObjects_resetFilterFieldValues,
  multiplyObjects_setCurrentObjectFilters,
  multiplyObjects_setFilterFieldValue,
  multiplyObjects_setFiltersArray,
  multiplyObjectsActions,
} from "../../../../store/reducers/multiplyObjectsReducer";
import IconManager from "../../Icons/IconManager";
import DefaultButton from "../../Buttons/DefaultButton";
import FilterFieldsIteration from "./FilterFieldsIteration";
import { isValue, validateValuesByType } from "../../../../helpers/values-validator";
import SelectDefault from "../../Selects/SelectDefault";
import SelectDatePlaceholder from "../../Selects/SelectDatePlaceholder";
import { IconArrowsSort, IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import cn from "classnames";

class SchemaObjectsFilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      maxHeight: "auto",
    };
    this.dropDownRef = null;
    this.ungrouped = this.props.ungrouped;
    this.schema_id = this.props.schema_id;
    this.getObjects = this.props.getObjects;
    this.grouped_by = this.props.groupedByFieldsActive;
    this.modal = null;
    this.filterFieldsCollection = this.filterFieldsCollection.bind(this);
  }

  componentDidMount() {
    if (this.modal) {
      this.setState({ maxHeight: document.body.getBoundingClientRect().bottom - this.modal.getBoundingClientRect().top - 100 });
    }
    if (this.schema_id) {
      this.getFiltersArray();
    }
  }

  componentWillUnmount() {
    this.setState({ maxHeight: "auto" });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  getFiltersArray = () => {
    const payload = {
      query: {
        schema_id: this.schema_id,
      },
      data: null,
    };
    payload.query = new URLSearchParams(payload.query).toString();
    this.setState({ loading: true });
    getObjectFilters(payload)
      .then((result) => {
        switch (result.kind) {
          case SUCCESS:
            this.props.setFiltersArray(result.data);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getFilterFieldForRequest = (field) => ({
    field_id: field.id,
    type: field.type,
    operand: field.operand,
    values: this.getFieldValues(field.type, field.selected),
  });

  getFieldValues = (type, values) => {
    const data = [];
    values.forEach((value) => {
      if (typeof value === "string" || typeof value === "boolean") {
        data.push(validateValuesByType(type, [value])[0]);
      } else if (typeof value === "object") {
        data.push(value.name);
      }
    });
    return data;
  };

  filterFieldsCollection = () => {
    const array = [];
    this.props.__schema_objects_filter.map((schema) => {
      schema.field_filters.map((fieldsArray) => {
        fieldsArray.map((field) => {
          if (isValue(field.selected)) {
            array.push(this.getFilterFieldForRequest(field));
          }
        });
      });
    });

    const hasCreatedAt = this.props.userFilters?.created_from || this.props.userFilters?.created_to;
    const created_at = {
      created_from: this.props.userFilters?.created_from || undefined,
      created_to: this.props.userFilters?.created_to || undefined,
    };

    return {
      is_desc: !this.props?.userFilters?.is_desc,
      simple_filters: array.length > 0 ? array : undefined,
      creators_ids: this.props.userFilters?.creators_ids?.map((item) => item.id),
      owners_ids: this.props.userFilters?.owners_ids?.map((item) => item.id),
      created_at: hasCreatedAt ? [created_at] : undefined,
    };
  };

  handleConfirmFilter = () => {
    const payload = this.filterFieldsCollection();

    this.props.setCurrentFilter(payload);
    this.props.clearData();

    if (this.ungrouped) {
      this.getObjects(payload);
    } else {
      if (!this.props.groupedByFieldsActive) {
        this.dropDownRef.handleContainer();
        return;
      }
      this.getObjects(this.props.groupedByFieldsActive, payload);
    }
    this.dropDownRef.handleContainer();
  };

  header = () => {
    return (
      <div
        {...{
          className: "SchemaObjectsFilterModal_headerIcon",
          ref: (el) => (this.modal = el),
          style: this.props.style,
        }}>
        <IconManager
          {...{
            icon: "adjustments",
            size: 16,
            color: "var(--text-fifth)",
          }}
        />
      </div>
    );
  };

  handleCloseFilter = () => {
    this.dropDownRef.handleContainer();
  };

  resetFilterFieldsValues = () => {
    this.props.resetFilterFieldValues();
  };

  onFilterFieldChange = (payload) => {
    this.props.setFilterFieldValue(payload);
  };

  onUserFilterChange = (key, values) => {
    this.props.setUserFilters({ key, values });
  };

  render() {
    return (
      <ClickOutsideBoundaryTemplate
        {...{
          ref: (elem) => (this.dropDownRef = elem),
          header: this.header,
          toLeft: true,
        }}>
        <div
          {...{
            // ref: (el) => (this.modal = el),
            className: "SchemaObjectsFilterModal flex-column",
            style: {
              maxHeight: this.state.maxHeight,
            },
          }}>
          {/*TITLE*/}
          <div {...{ className: "SchemaObjectsFilterModal_header flex-row align-center justify-between" }}>
            <div {...{ className: "SchemaObjectsFilterModal_headerTitle flex-row align-center" }}>Фильтр</div>
            <div
              {...{
                className: "SchemaObjectsFilterModal_headerClose",
                onClick: () => this.handleCloseFilter(),
              }}>
              <IconManager
                {...{
                  icon: "x",
                  size: 16,
                  color: "var(--text-tenth)",
                }}
              />
            </div>
          </div>
          <div
            {...{
              className: "SchemaObjectsFilterModal_body flex-col default-scrollbar",
              style: {
                width: 600,
              },
            }}>
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", flexDirection: "row", width: "100%", gap: 14 }}>
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  onClick={() => this.onUserFilterChange("is_desc", !this.props?.userFilters?.is_desc)}
                  className={cn(
                    "SchemaObjectsFilterModal_sortItem",
                    !!this.props?.userFilters?.is_desc && "SchemaObjectsFilterModal_sortItemActive"
                  )}>
                  <div className={"SchemaObjectsFilterModal_sortItemText"}>Дата обновления</div>
                  {!this.props?.userFilters?.is_desc ? (
                    <IconSortAscending
                      size={16}
                      color={"#555"}
                    />
                  ) : (
                    <IconSortDescending
                      size={16}
                      color={"#fff"}
                    />
                  )}
                </div>
              </div>
              <div className={"SchemaObjectsFilterModal_hardInputsWidth"}>
                <SelectDefault
                  search={true}
                  placeholder="Ответственный"
                  data={this.props.users?.map((user) => ({ id: user?.id, name: user?.name, by: "OWNER" })) || []}
                  field_key="name"
                  onChange={(value) => this.onUserFilterChange("owners_ids", value)}
                  values={this.props?.userFilters?.owners_ids || []}
                />
              </div>
              <div className={"SchemaObjectsFilterModal_hardInputsWidth"}>
                <SelectDefault
                  search={true}
                  placeholder="Создатель"
                  data={this.props.users?.map((user) => ({ id: user?.id, name: user?.name, by: "CREATOR" })) || []}
                  field_key="name"
                  onChange={(value) => this.onUserFilterChange("creators_ids", value)}
                  values={this.props?.userFilters?.creators_ids || []}
                />
              </div>
              <div className={"SchemaObjectsFilterModal_hardInputsWidth"}>
                <SelectDatePlaceholder
                  time={true}
                  date={true}
                  placeholder="Дата создания от"
                  onChange={(value) => this.onUserFilterChange("created_from", value.isoDateTime)}
                  value={isValue(this.props?.userFilters?.created_from) ? this.props?.userFilters?.created_from : null}
                />
              </div>
              <div className={"SchemaObjectsFilterModal_hardInputsWidth"}>
                <SelectDatePlaceholder
                  time={true}
                  date={true}
                  placeholder="Дата создания до"
                  onChange={(value) => this.onUserFilterChange("created_to", value.isoDateTime)}
                  value={isValue(this.props?.userFilters?.created_to) ? this.props?.userFilters?.created_to : null}
                />
              </div>
            </div>

            {/*<div className="flex margin-bottom-14 justify-center">*/}
            {/*  <div className="w-50">*/}
            {/*    <SelectDefault style={{width: '50%'}}*/}
            {/*      search*/}
            {/*      placeholder="Ответственный"*/}
            {/*      data={this.props.users?.map((user) => ({ id: user?.id, name: user?.name, by: "OWNER" })) || []}*/}
            {/*      field_key="name"*/}
            {/*      onChange={(value) => this.onUserFilterChange("owners_ids", value)}*/}
            {/*      values={this.props?.userFilters?.owners_ids || []}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className="w-50 margin-left-14">*/}
            {/*    <SelectDefault*/}
            {/*      search*/}
            {/*      placeholder="Создатель"*/}
            {/*      data={this.props.users?.map((user) => ({ id: user?.id, name: user?.name, by: "CREATOR" })) || []}*/}
            {/*      field_key="name"*/}
            {/*      onChange={(value) => this.onUserFilterChange("creators_ids", value)}*/}
            {/*      values={this.props?.userFilters?.creators_ids || []}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="flex margin-bottom-14 justify-center">*/}
            {/*  <div className="w-50">*/}
            {/*    <SelectDatePlaceholder*/}
            {/*      time*/}
            {/*      date*/}
            {/*      placeholder="Дата создания от"*/}
            {/*      onChange={(value) => this.onUserFilterChange("created_from", value.isoDateTime)}*/}
            {/*      value={isValue(this.props?.userFilters?.created_from) ? this.props?.userFilters?.created_from : null}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className="w-50 margin-left-14">*/}
            {/*    <SelectDatePlaceholder*/}
            {/*      time*/}
            {/*      date*/}
            {/*      placeholder="Дата создания до"*/}
            {/*      onChange={(value) => this.onUserFilterChange("created_to", value.isoDateTime)}*/}
            {/*      value={isValue(this.props?.userFilters?.created_to) ? this.props?.userFilters?.created_to : null}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="SchemaObjectsFilterModal_bodyColumnBreakLine margin-bottom-14" />
            <div className="flex-row">
              {!this.state.loading && this.props.__schema_objects_filter && (
                <FilterFieldsIteration
                  {...{
                    data: this.props.__schema_objects_filter,
                    onSubmit: this.onFilterFieldChange,
                  }}
                />
              )}
            </div>
          </div>
          {/*BOTTOM BUTTONS*/}
          <div {...{ className: "SchemaObjectsFilterModal_bottom flex-column" }}>
            <div {...{ className: "SchemaObjectsFilterModal_bottomButtons flex-row justify-end" }}>
              <DefaultButton
                {...{
                  border: true,
                  width: 150,
                  onClick: () => this.resetFilterFieldsValues(),
                  style: {
                    marginRight: 14,
                    border: "1px solid transparent",
                  },
                  text: "Сбросить фильтр",
                }}
              />
              <DefaultButton
                {...{
                  width: 200,
                  onClick: this.handleConfirmFilter,
                  text: "Применить фильтр",
                }}
              />
            </div>
          </div>
        </div>
      </ClickOutsideBoundaryTemplate>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFilterFieldValue: (payload) => dispatch(multiplyObjects_setFilterFieldValue(payload)),
    resetFilterFieldValues: (payload) => dispatch(multiplyObjects_resetFilterFieldValues(payload)),
    setFiltersArray: (payload) => dispatch(multiplyObjects_setFiltersArray(payload)),
    setCurrentFilter: (payload) => dispatch(multiplyObjects_setCurrentObjectFilters(payload)),
    setUserFilters: (payload) => dispatch(multiplyObjectsActions.setUserFilters(payload)),
    clearData: (payload) => dispatch(multiplyObjects_ClearData(payload)),
  };
};

const mapStateToProps = (state) => ({
  __schema_objects_filter: state.multiplyObjects.__schema_objects_filter,
  userFilters: state.multiplyObjects.userFilters,
  users: state.app.campaign_users,
  groupedByFieldsActive: state.multiplyObjects.groupedByFieldsActive,
});

export default connect(mapStateToProps, mapDispatchToProps)(SchemaObjectsFilterModal);
