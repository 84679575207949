import React from "react";
import styles from "./index.module.css";
import cn from "classnames";

const ChildrenModal = (props) => {
  const { style, children, headerBoundings, center, close } = props;

  const [position, setPosition] = React.useState(style);
  const [isInited, setIsInited] = React.useState(false);

  const parentRef = React.useRef(null);

  const observerRef = React.useRef(null);

  // const handleClickOutside = (event) => {
  //   if (parentRef && !parentRef.current.contains(event.target)) {
  //     close();
  //   }
  // };

  React.useEffect(() => {
    if (!headerBoundings || center) {
      setPosition({
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      });
      return;
    }
    observerRef.current = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const rect = parentRef.current.getBoundingClientRect();
        const openPosition = getOpenPositions(rect);
        const verticalOffset = headerBoundings.height + 5;
        const horizontalOffset = headerBoundings.width;
        const allowBoundings = {
          left: style.left,
          right: window.innerWidth - (style.left + horizontalOffset),
          top: style.top,
          bottom: window.innerHeight - (style.top - verticalOffset - 5),
        };
        const config = {
          ...(openPosition.isToRight && { left: allowBoundings.left }),
          ...(!openPosition.isToRight && { right: allowBoundings.right }),
          ...(openPosition.isToBottom && { top: allowBoundings.top }),
          ...(!openPosition.isToBottom && { bottom: allowBoundings.bottom }),
        };
        setPosition(config);
        setIsInited(true);
      }
    });
    observerRef.current.observe(parentRef.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [parentRef.current]);

  React.useEffect(() => {
    if (isInited) {
      observerRef.current.disconnect();
    }
  }, [isInited]);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     document.body.addEventListener("click", handleClickOutside);
  //   }, 1);
  //
  //   return () => {
  //     document.body.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const getOpenPositions = (childrenRect) => {
    return {
      isToBottom: childrenRect.y + childrenRect.height < window.innerHeight,
      isToRight: childrenRect.x + childrenRect.width < window.innerWidth,
    };
  };
  // {...{ className: cn(styles.ChildrenModalWrapper) }}
  return (
    <div
      {...{
        className: cn(styles.showAnimation),
        ref: parentRef,
        style: {
          position: "fixed",
          zIndex: 999999999999,
          backgroundColor: "#fff",
          borderRadius: 5,
          boxShadow: "0 5px 15px rgba(10, 21, 61, 0.15)",
          overflow: "hidden",
          ...position,
        },
      }}>
      {children}
      <div
        style={{ zIndex: -1, position: "fixed", height: "100vh", width: "100vw", left: 0, top: 0 }}
        onClick={(e) => {
          e.preventDefault();
          close?.();
        }}
      />
    </div>
  );
};

export default ChildrenModal;
