export const SUCCESS = "SUCCESS";
export const INVALID = "INVALID";
export const BAD = "BAD";

export enum KindEnum {
	SUCCESS = "SUCCESS",
	INVALID = "INVALID",
	BAD = "BAD",
}

const responseService = <T>(kind: KindEnum, data: T) => ({ kind, data });

export { responseService };
