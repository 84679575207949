import React, { useEffect } from "react";
import "./DefaultSwitcherSingle.css";
import { motion } from "framer-motion";
import { SWITCHER_SWITCH_ANIMATION } from "../../../../constants/framer-motion-animations";
import { useToggle } from "usehooks-ts";

interface DefaultSwitcherSingleProps {
  checked?: boolean;
  onChange: (isOn: boolean) => void;
}

const DefaultSwitcherSingle = ({ onChange, checked }: DefaultSwitcherSingleProps) => {
  const [isOn, toggleIsOn, setIsOn] = useToggle(checked ?? false);

  useEffect(() => {
    onChange?.(isOn);
  }, [isOn]);

  useEffect(() => {
    if (checked !== undefined) {
      setIsOn(checked);
    }
  }, [checked]);

  const handleClick = (isOn: boolean) => {
    toggleIsOn();
  };

  return (
    <div
      {...{
        className: `DefaultSwitcherSingle`,
      }}>
      <div
        {...{
          className: `DefaultSwitcherSingleContainer`,
          dataison: isOn.toString(),
          onClick: () => handleClick(!isOn),
        }}>
        <div {...{ className: `DefaultSwitcherSingle_dot` }}></div>
        <motion.div
          {...{
            className: "handle",
            layout: true,
            transition: SWITCHER_SWITCH_ANIMATION,
            animate: {
              transition: { duration: 0.3 },
              backgroundColor: isOn ? "var(--green1)" : "var(--red)",
            },
          }}
        />
        <div {...{ className: `DefaultSwitcherSingle_dot` }}></div>
      </div>
    </div>
  );
};

export default DefaultSwitcherSingle;
