import React from "react";
import { useAuth } from "../../providers/AuthProvider";

const PermissionLayout = (props) => {
  const { user } = useAuth();

  const { children, include } = props;

  const validateUserRole = () => {
    return !!(user && include && include.includes(user.role.type));
  };

  if (validateUserRole()) {
    return children;
  } else return null;
};

export default PermissionLayout;
