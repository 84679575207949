import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { getAllUsers } from "../../api/routes/role";
import { BAD, SUCCESS } from "../../helpers/response-service";
import { useDispatch } from "react-redux";
import { app_SetCamapignUsers } from "../../store/actions/appActions";

export const ProtectedLayout = () => {
  const { token, verifyAction, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const createStoreAction = useDispatch();
  const [isInit, setIsInit] = useState(false);

  // Verify user action
  useEffect(() => {
    verifyAction()
      .then(() => {
        // All is okay
        getCampaignUsers();
      })
      .catch((e) => {
        // console.log(e)
      })
      .finally(() => {
        setIsInit(true);
      });
  }, []);

  // Wait for verify action for start routing
  useEffect(() => {
    if (isInit) {
      if (!token && !user) {
        !location.pathname.includes("auth") && navigate("/auth/", { replace: true });
      } else {
        if (location.pathname.includes("auth")) {
          navigate("/path_redirect", { replace: true });
        }
      }
    }
  }, [token, user, isInit, location.pathname]);

  const getCampaignUsers = () => {
    getAllUsers().then((result) => {
      switch (result.kind) {
        case SUCCESS:
          createStoreAction(app_SetCamapignUsers(result.data));
      }
    });
  };

  if (isInit) {
    return (
      <div {...{ className: "MainLayout" }}>
        <Outlet />
      </div>
    );
  } else return null;
};
