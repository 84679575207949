import React, { useState } from "react";
import { setSelectedObjectId } from "../../../../store/reducers/selectedObjectsSlice";
import DefaultCheckbox from "../../../kit/CheckBoxes/DefaultCheckbox";
import { useDispatch, useSelector } from "react-redux";

const DraggableElement = (props) => {
  const { multiSelect, drag, item, elementContent, cardStyle, cardWidth, cardHeight, onMouseDown, navigateToObject } = props;

  const dispatch = useDispatch();

  const isSelected = useSelector((state) => state.selectedObjectsSlice.pull[item.id]);

  const itemRef = React.useRef(null);
  React.useEffect(() => {
    Object.assign(itemRef.current, {
      crm_drag_item_id: item.id,
    });
  }, []);

  const onClick = () => {
    navigateToObject(item.id);
  };

  const onSelect = (id) => {
    const payload = {
      [id]: !isSelected,
    };
    dispatch(setSelectedObjectId(payload));
  };

  // console.log("DraggableElement", item);
  return (
    <div
      {...{
        ref: itemRef,
        id: item.id,
        style: {
          ...cardStyle,
          width: cardWidth ? cardWidth : false,
          height: cardHeight ? cardHeight : false,
        },
        className: `boardElement droppable ${drag ? "cursorGrab disabled-user-select" : "cursorPointer"}`,
        ...(drag && { onMouseDown }),
        onDragStart: () => {
          return false;
        },
      }}>
      {!drag && multiSelect && (
        <div {...{ className: "SelectElementCheckbox" }}>
          <DefaultCheckbox
            onChange={() => {
              onSelect(item.id);
            }}
            checked={isSelected}
          />
        </div>
      )}
      <div
        {...{
          ...(!drag && { onClick }),
          className: `boardElementContent ${drag ? "disabled-point-events" : ""}`,
        }}>
        {elementContent({ data: item, draggable: drag })}
      </div>
    </div>
  );
};

DraggableElement.defaultProps = {
  drag: false,
};

export default DraggableElement;
