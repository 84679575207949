import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
import { CONTENT_LAYOUT_ANIMATION } from "../../../constants/framer-motion-animations";

const Page404 = () => {

	return (
		<motion.div
			{...{
				...CONTENT_LAYOUT_ANIMATION,
				className: "MissingPageWrapper flex-column justify-center align-center",
			}}
		>
			<div {...{ className: "MissingPageWrapper_statusCode" }}>404</div>
			<div {...{ className: "MissingPageWrapper_statusText" }}>
				К сожалению, мы ничего не нашли по вашему запросу. <Link {...{to: '/'}}>Вернуться на главную</Link>
			</div>
		</motion.div>
	);
};

export default Page404;
