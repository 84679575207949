import React from "react";
import styles from "./index.module.css";
import PortalModal from "./PortalModal";
import cn from "classnames";

const defaultPos = {
  left: "100px",
  top: "100px",
};

const centerPos = {
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

export class ClickOutsideBoundaryTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.headerContainer = null;
    // this.childrenContainer = null;
    this.templateContainer = null;
    this.state = {
      headerBoundaries: {
        width: 0,
        height: 0,
      },
      openPosition: POSITIONS,
      isOpen: this.props.isOpen ?? false,
      rendered: false,
      position: this.props.center ? centerPos : defaultPos,
    };
    this.handleContainer = this.handleContainer.bind(this);
  }
  // childrenWithProps = React.Children.map(this.props.children, (child) => {
  //   if (React.isValidElement(child)) {
  //     return React.cloneElement(child, { handleContainer: this.handleContainer });
  //   }
  //   return child;
  // });

  handleContainer = () => {
    this.onHeaderClick();
  };

  onHeaderClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.props.onChange?.(!this.props.isOpen);

    let position = null;
    if (this.props.isOpen === undefined && !this.props.center) {
      this.props.onChange?.(!this.state.isOpen);
      const { x, y, height } = this.headerContainer.getBoundingClientRect();
      position = {
        left: x,
        top: y + height + 5,
      };
    }
    this.setState({
      isOpen: !this.state.isOpen,
      ...(position && { position }),
    });
  };

  render() {
    return (
      <div
        {...{
          style: this.props.style,
          ref: (elem) => (this.templateContainer = elem),
          className: cn(styles.template, this.props.className, this.props.disabled && "cursor-default no-pointer-events"),
        }}>
        {/*HEADER START*/}
        {this.props.header && (
          <div
            {...{
              style: this.props.headerStyle,
              onClick: this.onHeaderClick,
              ref: (elem) => (this.headerContainer = elem),
              className: cn(styles.templateHeader),
            }}>
            {typeof this.props.header === "function" ? this.props.header() : this.props.header}
          </div>
        )}
        {/*HEADER END*/}
        {/*CHILDREN START*/}
        {(this.props.isOpen ?? this.state.isOpen) && !this.props.disabled && (
          <PortalModal
            {...{
              important: {
                toLeft: this.props.toLeft,
                toTop: this.props.toTop,
              },
              center: this.props.center,
              overlayVisible: this.props.overlayVisible,
              config: this.state.position,
              // close: () => this.setState({ isOpen: false }),
              close: this.onHeaderClick,
              headerBoundings: this.headerContainer?.getBoundingClientRect(),
            }}>
            {/*<div*/}
            {/*  {*/}
            {/*    ...{*/}
            {/*      // style: { ...this.props.childrenStyle },*/}
            {/*      // ref: (elem) => (this.childrenContainer = elem),*/}
            {/*      // ...SHOW_DROPDOWN_ANIMATION(this.state.isOpen),*/}
            {/*      // className: "ClickOutsideBoundaryTemplateChildren",*/}
            {/*    }*/}
            {/*  }>*/}
            {this.props.children ? this.props.children : <div {...{ className: styles.empty }}>Не найдено</div>}
            {/*</div>*/}
          </PortalModal>
        )}
        {/*CHILDREN END*/}
      </div>
    );
  }
}

ClickOutsideBoundaryTemplate.defaultProps = {
  containerMargin: 5,
  handleScrollableDivs: true,
};

const POSITIONS = {
  left: null,
  right: null,
  top: null,
  bottom: null,
  display: "none",
  zIndex: 0,
  onChange: null,
};
