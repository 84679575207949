import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../../templates/ClickOutsideBoundaryTemplate";
import { phoneFormatter } from "@/helpers/phone-formatter.js";
import * as _ from "lodash"
import React from "react";

const WhatsAppPhonesSelect = (props) => {
  const { error, style, data, value, setValue } = props;

  const ref = React.useRef(null);

  // console.log("PHONES", data);

  const header = (value, error) => {
    return (
      <div {...{ className: `WhatsAppPhonesSelect_header ${error ? "WhatsAppPhonesSelect_error" : ""}` }}>
        {value ? phoneFormatter(value) : "Выбрать контакт"}
      </div>
    );
  };

  const selectItem = (item) => {
    if (!value || (value && item !== value)) {
      setValue(item);
    } else {
      setValue(null);
    }
    ref.current.handleContainer();
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        style,
        ref,
        toTop: true,
        header: () => header(value, error),
      }}>
      <div {...{ className: `WhatsAppPhonesSelect` }}>
        {data &&
          data.map((item) => {
            return (
              <div
                {...{
                  key: _.uniqueId(),
                  onClick: () => selectItem(item),
                  className: `WhatsAppPhonesSelect_element ${value && item === value && "WhatsAppPhonesSelect_elementActive"}`,
                }}>
                {phoneFormatter(item)}
              </div>
            );
          })}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

WhatsAppPhonesSelect.defaultProps = {
  error: false,
};

export default WhatsAppPhonesSelect;
