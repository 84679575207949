import { useSelector } from "react-redux";
import SingleObjectNestedGroups from "../SingleObjectNestedGroup";
import { useParams } from "react-router-dom";
import * as _ from "lodash"

const SingleObjectRelationObjects = (props) => {
  const { schemas, isCreateMode, objectSchemaId } = props;

  const { object_id } = useParams();

  const __singleObjectRelationObjectsSchemaData = useSelector((state) => state.singleObject.__singleObjectRelationObjectsSchemaData);

  if (!isCreateMode && schemas && __singleObjectRelationObjectsSchemaData) {
    return Object.keys(schemas).map((schema_key) => {
      const relationSchemaObjects = __singleObjectRelationObjectsSchemaData[schema_key];
      return (
        <SingleObjectNestedGroups
          {...{
            key: _.uniqueId("relation_object_schema_key"),
            parent_id: object_id,
            objectSchemaId,
            nested_object: relationSchemaObjects,
          }}
        />
      );
    });
  } else return null;
};

export default SingleObjectRelationObjects;
