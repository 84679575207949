import React from "react";
import "./index.css";
import ConfigIcon from "../../../../kit/Icons/ConfigIcon";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import WhatsAppPhonesSelect from "../WhatsAppPhonesSelect";
import { createWaMessage } from "../../../../../api/routes/extensions";
import Spinner from "../../../../kit/Loaders/Spinner";
import { setWhatsAppExtensionPhone } from "../../../../../store/reducers/extensionsReducer";
import EditInput from "../../../../elements/Widgets/Edit/EditInput";

const INITIAL_STATE = {
  text: {
    value: "",
    error: false,
  },
};

const PHONE_WA_TYPES = ["__pact_phone_wa__", "__teletype_phone_wa__"];

const CreateWhatsAppMessage = () => {
  const [state, setState] = React.useState<typeof INITIAL_STATE>(INITIAL_STATE);
  const [phoneError, setPhoneError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<any>(null);

  const __singleObjectRelationObjects = useSelector((state: any) => state.singleObject.__singleObjectRelationObjects);
  const __singleObjectExtensions = useSelector((state: any) => state.singleObject.__singleObjectExtensions);
  const phone = useSelector((state: any) => state.extensions.__whatsAppExtension.phone);
  const createStoreAction = useDispatch();

  const filterObjectsByMessageExtension = (data: Array<any>) => {
    let phonesArray: Array<any> = [];

    if (data?.length > 0) {
      data?.forEach((object) => {
        const MESSAGE_EXTENSION = object?.extensions?.MESSAGE;
        if (MESSAGE_EXTENSION) {
          const phones = getPhones(MESSAGE_EXTENSION);
          if (phones && phones.length > 0) {
            phonesArray = [...phonesArray, ...phones];
          }
        }
      });
    }

    const singleObjectPhones = getSingleObjectMessageExtensionPhones();
    if (singleObjectPhones) {
      phonesArray = phonesArray.concat(singleObjectPhones);
    }

    return Array.from(new Set(phonesArray));
  };

  const getSingleObjectMessageExtensionPhones = () => {
    const extension = __singleObjectExtensions.MESSAGE;
    return extension ? getPhones(extension) : null;
  };

  const getPhones = (value: any) => {
    let objectPhonesCollection: any = [];
    PHONE_WA_TYPES.map((phone_wa_type: any) => {
      if (!value[phone_wa_type]) {
        return;
      }
      const values = value[phone_wa_type][0]?.values;
      if (Array.isArray(values) && values?.length > 0) {
        objectPhonesCollection = [...objectPhonesCollection, ...values];
      }
    });
    return objectPhonesCollection;
  };

  const handleSendWaMessage = () => {
    let validate = true;

    Object.keys(state).map((key: any) => {
      const field = (state as any)[key];
      if (!field.value) {
        validate = false;
        onChangeField(key, { error: true });
      }
    });

    if (!phone) {
      validate = false;
      setPhoneError(true);
    }

    if (validate) {
      setLoading(true);
      const payload = {
        data: {
          phone,
          text: state.text.value,
          attachment_url: null,
          attachment_type: null,
        },
      };
      createWaMessage(payload)
        .catch((e) => console.log(e))
        .finally(() => {
          reset();
        });
    } else return null;
  };

  const reset = () => {
    setLoading(false);
    inputRef.current.clearInput();
    onChangeField("text", { value: "", error: false });
  };

  type OnChangeFieldSecondParam = {
    value?: any;
    error?: boolean;
  };
  const onChangeField = (key: string, { value = undefined, error = undefined }: OnChangeFieldSecondParam) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...(value !== undefined && { value }),
        ...(error !== undefined && { error }),
      },
    }));
  };

  const setPhone = (phone: any) => {
    createStoreAction(setWhatsAppExtensionPhone(phone));
  };

  return (
    <div
      {...{
        className: "CreateWhatsAppMessage flex-row align-start justify-between",
      }}>
      <div {...{ className: "CreateWhatsAppMessage_textContainer" }}>
        <WhatsAppPhonesSelect
          {...{
            style: { marginBottom: 1, marginTop: 3 },
            data: filterObjectsByMessageExtension(__singleObjectRelationObjects),
            error: phoneError,
            value: phone,
            setValue: (value: any) => {
              setPhoneError(false);
              setPhone(value);
            },
          }}
        />
        <EditInput
          {...{
            ref: inputRef,
            inputStyle: { minWidth: 300, width: "100%" },
            style: { width: "100%" },
            label: null,
            icon: null,
            fontSize: 15,
            loading: false,
            error: state?.text?.error,
            value: state?.text?.value,
            placeholder: "Текст WhatsApp сообщения",
            readOnly: false,
            onSubmit: (value: any) => onChangeField("text", { value, error: false }),
          }}
        />
      </div>
      <div {...{ className: "CreateWhatsAppMessage_sendButton" }}>
        {loading ? (
          <Spinner
            {...{
              size: 20,
              loading: loading,
            }}
          />
        ) : (
          <ConfigIcon
            {...{
              onClick: handleSendWaMessage,
              className: "CreateWhatsAppMessage_sendButton_icon",
              size: 22,
            }}>
            <HiOutlinePaperAirplane />
          </ConfigIcon>
        )}
      </div>
    </div>
  );
};

export default CreateWhatsAppMessage;
