import { IconCheck } from "@tabler/icons-react";
import { valuesIn } from "lodash";
import React, { useState, useRef, useEffect, CSSProperties, useMemo } from "react";
import { isValue } from "../../../../helpers/values-validator";
import styles from "./index.module.css";
import SearchInput from "../../Inputs/SearchInput";
import DefaultCheckbox from "../../CheckBoxes/DefaultCheckbox";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { IoIosArrowDown } from "react-icons/io";
import cn from "classnames";

type Value<T, VKey extends string> = T extends { [key in VKey]: string } & { id: number | string } ? T : never;

interface SelectDefaultProps<T, VKey extends string> {
  field_key: VKey;
  data: Value<T, VKey>[] | null;
  values: Value<T, VKey>[] | null;
  placeholder?: string;
  onChange?: (values: Value<T, VKey>[]) => void;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  amount?: number;
  notEmpty?: boolean;
  onSubmit?: (values: Value<T, VKey>[]) => void;
  style?: CSSProperties;
  search?: boolean;
  arrow?: boolean;
  small?: boolean;
}

const SelectDefault = <T, VKey extends string>({
  data,
  field_key,
  arrow,
  values,
  search,
  error,
  errorText,
  onChange,
  small,
  disabled,
  amount = 0,
  notEmpty,
  onSubmit,
  style,
  placeholder,
}: SelectDefaultProps<T, VKey>) => {
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValues, setSelectedValues] = useState<Value<T, VKey>[]>(values || []);
  const ref = useRef<any>(null);

  const handleSelectValue = (id: number) => {
    const selectedValue = data?.find((value) => value.id === id);
    const isRemove = !!selectedValues.find((value) => value.id === id);
    const isAdd = !isRemove;

    if ((selectedValues.length === 1 && isRemove && notEmpty) || !selectedValue) {
      return;
    }

    if (amount === 1 && isAdd) {
      ref.current?.handleContainer();
      onChange?.([selectedValue]);
      return setSelectedValues([selectedValue]);
    }
    if (amount === 1 && isRemove) {
      ref.current?.handleContainer();
      onChange?.([]);
      return setSelectedValues([]);
    }

    const filteredValues = selectedValues.filter((value) => value.id !== id);

    if (isRemove) {
      onChange?.([...filteredValues]);
      setSelectedValues([...filteredValues]);
      return;
    }
    if ((selectedValues.length < amount || amount === 0) && isAdd) {
      onChange?.([...filteredValues, selectedValue]);
      setSelectedValues([...filteredValues, selectedValue]);
    }
  };

  useEffect(() => {
    if (!values) {
      return;
    }
    setSelectedValues(values);
  }, [values]);

  const header = () => {
    return (
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={cn("flex", styles.header, error && styles.error, small && styles.small, disabled && "no-pointer-events cursor-default opacity-40")}
        style={style}>
        <span className={cn(small ? "font-size-14" : "font-size-15", "flex overflow-hidden block")}>
          {selectedValues.length > 0 && selectedValues.map((selectedValue) => selectedValue[field_key]).join(", ")}
          {/* {selectedValues.length > 0 &&
            selectedValues.map((selectedValue, i, arr) => (
              <span
                key={selectedValue.id}
                className={cn(styles.value, arr.length - 1 === i && "margin-right-5")}>
                {selectedValue[field_key]}
                {arr.length - 1 !== i ? "," : ""}
              </span>
            ))} */}
          <span className={cn(styles.placeholder, small && styles.small, (focused || selectedValues.length > 0) && styles.focus)}>
            {placeholder || "Выбрать"}
          </span>
        </span>
        {arrow ? (
          <div
            className="margin-left-auto transition-default"
            style={{ transform: `rotate(${focused ? 180 : 0}deg)` }}>
            <IoIosArrowDown />
          </div>
        ) : null}
        {errorText && <div className={styles.errorText}>{errorText}</div>}
      </div>
    );
  };

  const items = useMemo(() => {
    if (!search) {
      return data || [];
    }
    return data?.filter((item) => item[field_key].toLowerCase().includes(query.toLowerCase())) || [];
  }, [query, data]);

  return (
    <ClickOutsideBoundaryTemplate
      ref={ref}
      header={() => header()}
      onChange={!disabled ? (value: boolean) => setFocused(value) : undefined}
      style={{ width: "100%", userSelect: "none" }}
      disabled={disabled}>
      <div className={cn("padding-17 flex-column default-scrollbar", styles.dropdown)}>
        {search && (
          <SearchInput
            className="margin-bottom-14 no-shrink"
            small
            value={query}
            onChange={setQuery}
            // focused={focused}
            // onChange: (value) => onChangeSearchValue(value),
          />
        )}
        {items.length > 0 ? (
          items.map((item, index) => {
            const isSelected = selectedValues.find((selectedValue) => selectedValue.id === item.id);
            return (
              <div
                key={index}
                className={cn(
                  "flex align-center text-dotted-end pointer",
                  styles.item,
                  small && styles.small,
                  isSelected && styles.active,
                  styles.dropdownItem
                  // (!!values && values.includes(item)) || (values.length === maxRows && maxRows > 1 && "disabled"),
                )}
                onClick={() => handleSelectValue(+item.id)}>
                {amount !== 1 && (
                  <DefaultCheckbox
                    size={26}
                    checked={isSelected}
                    className="margin-right-14"
                  />
                )}
                <span>{item[field_key]}</span>
                {amount === 1 && isSelected && (
                  <div className={styles.activeIcon}>
                    <IconCheck />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className={cn("flex align-center text-dotted-end cursor-default", styles.item, small && styles.small)}>Не найдено</div>
        )}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

export default SelectDefault;
