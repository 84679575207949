import WhatsAppExtension_FieldView from "./WhatsAppExtension_FieldView";
import WhatsAppExtension_ElementView from "./WhatsAppExtension_ElementView";


export const WHATSAPP_EXTENSION = {
	MULTIPLE_VIEW: {
		component: () => null
	},
	SINGLE_VIEW: {
		component: () => null
	},
	ELEMENT_VIEW: {
		component: (props) => <WhatsAppExtension_ElementView {...props} />
	},
	FIELD_VIEW: {
		component: (props) => <WhatsAppExtension_FieldView {...props} />
	}
};
