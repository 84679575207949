export const getUrl = () => {
	return new Promise((resolve, reject) => {
		const text = window.location.href;
		navigator.clipboard.writeText(text).then(
			function () {
				resolve()
			},
			function (err) {
				reject()
			}
		);
	})
};
