import React from "react";
import "./index.css";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";

class EditCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTrue: this.props.value,
    };
  }

  handleSubmit = (value) => {
    this.setState({ isTrue: value });
    this.props.onChange && this.props.onChange(value);
    this.props.onSubmit && this.props.onSubmit(value);
  };

  render() {
    const { label, fontSize, icon, readOnly } = this.props;

    return (
      <EditWidgetIconLabelTemplate
        {...{
          label,
          fontSize,
          icon,
        }}>
        <div
          {...{
            style: { opacity: readOnly ? 0.5 : 1, cursor: readOnly ? "default" : "pointer" },
            className: this.state.isTrue ? "EditCheckboxActive" : "EditCheckbox",
            onClick: () => (readOnly ? null : this.handleSubmit(!this.state.isTrue)),
          }}>
          <div {...{ className: "EditCheckbox_inner" }}></div>
        </div>
      </EditWidgetIconLabelTemplate>
    );
  }
}

export default EditCheckbox;
