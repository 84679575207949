import React, { useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconManager from "../../../kit/Icons/IconManager";
import styles from "./index.module.css";
import cn from "classnames";
import ChildrenElements from "../ChildrenElements";

const MenuElement = ({ icon, path, children, meta_name, getLocation, onClick, condition_path }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isTimeout = useRef(false);

  const childrenPathNamesArray = React.useMemo(() => {
    return children
      ? children.map((el) => {
          return getLocation(el.condition_path);
        })
      : getLocation && condition_path
      ? [getLocation(condition_path)]
      : [null];
  }, [children]);

  const throttledNavigate = useCallback((path, timeout, active) => {
    if (!active) {
      navigate(path);
      return;
    }
    if (!isTimeout.current) {
      navigate(path);
      isTimeout.current = true;
      setTimeout(() => {
        isTimeout.current = false;
      }, timeout);
    }
  }, []);

  const isActive = () => {
    return childrenPathNamesArray.includes(getLocation(location.pathname));
  };

  return (
    <div
      {...{
        className: cn(styles.MenuElement, getLocation && isActive() && styles.MenuElementActive, !children && "cursor-pointer"),
        onClick: !children && path ? () => throttledNavigate(path, 1000, isActive()) : onClick,
      }}>
      <div {...{ className: cn(styles.MenuElementBackDrop) }}></div>
      {children && (
        <div {...{ className: cn(styles.SideMenuChildrenElements) }}>
          <ChildrenElements {...{ children, title: meta_name, navigate: throttledNavigate, getLocation }} />
        </div>
      )}
      <IconManager {...{ icon: icon, size: 15 }} />
    </div>
  );
};

export default MenuElement;

MenuElement.defaultProps = {
  active: false,
};
