import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import { EditGroupDto } from "../../api/interfaces/views";
import { IStageExtension } from "../../types/extension";
import { ISchemaField } from "../../types/fields";
import { ISchema } from "../../types/schema";
import { ISingleViewGroup } from "../../types/views";

// const DEFAULT_SCHEMA_ID = 1;

export interface IChoiceItem {
  name: string;
  id: number;
  color: string;
  order: number;
}

export type SchemasMap = Record<string, Omit<ISchema, "fields">>;
export type GroupsMap = Record<string, ISingleViewGroup>;
export type Choice = IChoiceItem | IStageExtension;

// type Field = FieldPayload & { choice: Choice[]; id?: number };

export interface ISettingState {
  selectedSchemaId: number | null;
  shouldRefetch: boolean;
  schemasMap: SchemasMap | null;
  groupsMap: GroupsMap | null;
  mainGroup: ISchemaField[] | null;
  selectedGroupId: number | null;
  selectedField: ISchemaField | null;
  stages: IStageExtension[];
}

const initialState: ISettingState = {
  selectedField: null,
  selectedSchemaId: null,
  groupsMap: null,
  mainGroup: null,
  selectedGroupId: null,
  schemasMap: null,
  shouldRefetch: false,
  stages: [],
};

const isInGroupsMap = (key: number, groupsMap: GroupsMap | null): groupsMap is GroupsMap => !!groupsMap && key in groupsMap;

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    refetch: (state) => {
      state.shouldRefetch = !state.shouldRefetch;
    },
    clearState: (state) => {
      state.selectedField = null;
      state.groupsMap = null;
      state.mainGroup = null;
      state.selectedGroupId = null;
    },
    setSchemasMap: (state, action: PayloadAction<SchemasMap>) => {
      state.schemasMap = action.payload;
    },
    setSelectedSchemaId: (state, action: PayloadAction<number>) => {
      state.selectedSchemaId = action.payload;
    },
    setMainGroup: (state, action: PayloadAction<ISchemaField[]>) => {
      state.mainGroup = action.payload;
    },
    setGroups: (state, action: PayloadAction<GroupsMap>) => {
      state.groupsMap = action.payload;
    },
    setSelectedGroupId: (state, action: PayloadAction<number>) => {
      state.selectedGroupId = action.payload;
    },
    editGroupName: (state, { payload }: PayloadAction<EditGroupDto>) => {
      if (!isInGroupsMap(payload.id, state.groupsMap) || !payload.name) {
        return;
      }
      state.groupsMap[payload.id].name = payload.name;
    },
    addGroup: (state, { payload }: PayloadAction<{ group: ISingleViewGroup }>) => {
      if (state.groupsMap) {
        state.groupsMap[payload.group.id.toString()] = payload.group;
      }
    },
    removeGroup: (state, { payload }: PayloadAction<{ group_id: number }>) => {
      if (!isInGroupsMap(payload.group_id, state.groupsMap)) {
        return;
      }
      state.groupsMap = omit(state.groupsMap, [payload.group_id.toString()]);
    },
    addField: (state, { payload }: PayloadAction<{ field: ISchemaField; group_id: number }>) => {
      if (!isInGroupsMap(payload.group_id, state.groupsMap)) {
        return;
      }
      state.groupsMap[payload.group_id]?.fields?.push(payload.field);
    },
    removeField: (state, { payload }: PayloadAction<{ field: ISchemaField; group_id: number }>) => {
      if (!isInGroupsMap(payload.group_id, state.groupsMap)) {
        return;
      }
      const group = state.groupsMap[payload.group_id];
      if (group) {
        group.fields = group.fields.filter((field) => field.id !== payload.field.id);
      }
    },
    editField: (state, { payload }: PayloadAction<{ field: ISchemaField; group_id: number }>) => {
      if (!isInGroupsMap(payload.group_id, state.groupsMap)) {
        return;
      }
      const group = state.groupsMap[payload.group_id];
      if (group) {
        group.fields = group.fields.map((oldField) => (oldField.id === payload.field.id ? payload.field : oldField));
      }
    },
    setStages: (state, action: PayloadAction<IStageExtension[]>) => {
      state.stages = action.payload;
    },
    handleReorderFields: (state, { payload }: PayloadAction<{ fields: ISchemaField[]; group_id: number }>) => {
      if (!isInGroupsMap(payload.group_id, state.groupsMap)) {
        return;
      }
      const group = state.groupsMap[payload.group_id];
      if (group) {
        group.fields = payload.fields;
      }
    },
    setSelectedField: (state, action: PayloadAction<ISchemaField | null>) => {
      state.selectedField = action.payload;
    },
  },
});

export const getSchema = (schemaMap: SchemasMap | null, id: number | null) => {
  const parsedId = id?.toString();
  if (schemaMap && parsedId && parsedId in schemaMap) {
    return schemaMap[parsedId];
  }
  return null;
};

export const settingActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
