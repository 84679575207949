import { API_RESPONSE_CODES, NOTIFICATION_TYPES } from "../constants/types";
import store from "../store/store";
import { setNotification } from "../store/actions/appActions";

const returnDetail = (detail) => {
	if(typeof detail === 'string') {
		return detail
	}
	return null
}

export const errorResponseService = (error) => {
	if (error.response.status === API_RESPONSE_CODES.VALIDATION_ERROR) {
		store.dispatch(
			setNotification({
				type: NOTIFICATION_TYPES.WARNING,
				text: returnDetail(error.response.data.detail) || "Data validation error, reload page and try again: 422",
			})
		);
	}
	if (error.response.status === API_RESPONSE_CODES.SERVER_ERROR) {
		store.dispatch(
			setNotification({
				type: NOTIFICATION_TYPES.ERROR,
				text: returnDetail(error.response.data.detail) || "Server Internal Error: 500",
			})
		);
	}
	if (error.response.status === API_RESPONSE_CODES.FORBIDDEN) {
		store.dispatch(
			setNotification({
				type: NOTIFICATION_TYPES.ERROR,
				text: returnDetail(error.response.data.detail) || "You have not permission: 403",
			})
		);
	}
	if (error.response.status === API_RESPONSE_CODES.CLIENT_ERROR) {
		store.dispatch(
			setNotification({
				type: NOTIFICATION_TYPES.ERROR,
				text: returnDetail(error.response.data.detail) || "Client error, reload page and try again: 400",
			})
		);
	}
	if (error.response.status === API_RESPONSE_CODES.UNDEFINED_ERROR) {
		store.dispatch(
			setNotification({
				type: NOTIFICATION_TYPES.ERROR,
				text: "CORS Politics Error, reload page and try again: CORS BLOCK",
			})
		);
	}
	if (error.response.status === API_RESPONSE_CODES.AUTH_ERROR) {
		localStorage.clear();
	}
}
