import { singleObjectTypes } from "../types/singleObjectTypes";

export const singleObject_setLoading = (payload) => {
	return {
		type: singleObjectTypes.SET_LOADING,
		payload,
	};
};

export const singleObject_resetSingleObject = (payload) => {
	return {
		type: singleObjectTypes.RESET_SINGLE_OBJECT,
		payload,
	};
};

export const singleObject_Init = (payload) => {
	return {
		type: singleObjectTypes.SET_SINGLE_OBJECT,
		payload,
	};
};

export const singleObject_UpdateObjectGroupField = (payload) => {
	return {
		type: singleObjectTypes.UPDATE_GROUP_FIELD,
		payload,
	};
};

export const singleObject_UpdateObjectMainElementField = (payload) => {
	return {
		type: singleObjectTypes.UPDATE_MAIN_ELEMENT_FIELD,
		payload,
	};
};

export const singleObject_SetHistory = (payload) => {
	return {
		type: singleObjectTypes.SET_SINGLE_OBJECT_HISTORY,
		payload,
	};
};

export const singleObject_setRelationObjects = (payload) => {
	return {
		type: singleObjectTypes.SET_SINGLE_OBJECT_RELATION_OBJECTS,
		payload
	}
}

export const singleObject_setOwnersIdsCreateMode = (payload) => {
	return {
		type: singleObjectTypes.HANDLE_OBJECT_OWNERS_IDS_CREATE_MODE,
		payload
	}
}
