import { FieldsMetaNames, SchemasMetaNames } from "../constants/MetaNamesEnum";
import { EditWidgetsEnum, ViewWidgetsEnum } from "../constants/types";
import { TypesEnum } from "../constants/TypesEnum";
import { IChoiceItem } from "../store/reducers/settingsSlice";

export const StatTypesConfig = {
  DATETIME: "DATETIME",
  DAY_HOUR: "DAY_HOUR",
  HOUR_MINUTE_SECOND: "HOUR_MINUTE_SECOND",
  DATE: "DATE",
  NUMBER: "NUMBER",
  STRING: "STRING",
  AUDIO: "AUDIO",
  OBJECT_LINK: "OBJECT_LINK",
};

export interface FilterChoice {
  id: number;
  name: string;
  meta_name: SchemasMetaNames;
}

export interface IFilter {
  field_id: number;
  name: string;
  type: TypesEnum;
  edit_widget: EditWidgetsEnum;
  view_widget: ViewWidgetsEnum;
  choice: FilterChoice[];
}

export type StatTypes = keyof typeof StatTypesConfig;

export interface IStatsMeta {
  name: string;
  path: string;
  filter_meta_name: FieldsMetaNames[] | null;
}

export interface IStats {
  fields: [
    {
      name: string;
      type: StatTypes;
    }
  ];
  values: (number | string)[][];
}
