import React from "react";
import "./index.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MainElementSkeleton = ({ style }) => {
	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<div className={"MainElementSkeleton"} style={style}>
				<Skeleton {...{ style: { width: "80%", height: 20, borderRadius: 4 } }} />
				<Skeleton
					{...{
						style: { width: 140, height: 20, marginTop: 7, borderRadius: 4 },
					}}
				/>
				<Skeleton
					{...{
						style: { width: 140, height: 40, marginTop: 7, borderRadius: 4 },
					}}
				/>
				<Skeleton
					{...{
						style: {
							width: 200,
							height: 40,
							marginTop: 7,
							marginBottom: 7,
							borderRadius: 4,
						},
					}}
				/>
			</div>
		</SkeletonTheme>
	);
};

export default MainElementSkeleton;
