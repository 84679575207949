import React from "react";
import ReactDOM from "react-dom";
import ChildrenModal from "./Children";
import styles from "./index.module.css";
import cn from "classnames";

const portalRoot = document.getElementById("portal-root");

class PortalModal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  setElement = (element) => {
    if (this.props.overlayVisible) {
      element.classList.add(cn(styles.overlay));
    }
    Object.assign(element.style, {
      width: "100vw",
      height: "100vh",
      top: "0px",
      left: "0px",
      bottom: "0px",
      right: "0px",
      zIndex: 99999,
      position: "fixed",
      display: "block",
    });
    element.addEventListener("click", this.elOnClick);
  };

  resetElement = (element) => {
    element.classList.remove(cn(styles.overlay));
    Object.assign(element.style, {
      width: "100vw",
      height: "100vh",
      top: "0px",
      left: "0px",
      bottom: "0px",
      right: "0px",
      zIndex: 99999,
      position: "fixed",
      display: "none",
    });
    element.removeEventListener("click", this.elOnClick);
  };

  elOnClick = (e) => {
    if (e.target === portalRoot) {
      this.props.close();
    }
    // this.props.close();
  };

  componentDidMount() {
    if (portalRoot.children.length === 0) {
      this.setElement(portalRoot);
    }
    portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.el.removeEventListener("click", this.elOnClick);
    portalRoot.removeChild(this.el);
    if (portalRoot.children.length === 0) {
      this.resetElement(portalRoot);
    }
  }

  render() {
    return ReactDOM.createPortal(
      <ChildrenModal
        {...{
          close: this.props.close,
          // important: this.props.important,
          center: this.props.center,
          headerBoundings: this.props.headerBoundings,
          style: this.props.config,
          children: this.props.children,
        }}
      />,
      this.el
    );
  }
}

export default PortalModal;
