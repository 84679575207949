import {ClickOutsideBoundaryTemplate} from "../../../../components/templates/ClickOutsideBoundaryTemplate";
import styles from "./index.module.css";
import {DefaultPlayer} from "../../../../components/elements/DefaultPlayer/DefaultPlayer";
import React from "react";
import {IconPhone} from "@tabler/icons-react";

const AudioPlayer = ({src}: any) => {
  return (
    <ClickOutsideBoundaryTemplate
      header={
        <div className={styles.Container}>
          <span>Прослушать</span>
          <IconPhone size={14}/>
        </div>
      }>
      <div className={styles.Modal}>
        <DefaultPlayer audioSrc={src} />
      </div>
    </ClickOutsideBoundaryTemplate>
  );
}

export default AudioPlayer
