import React, { useState, useRef } from "react";
import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { isValue } from "../../../../helpers/values-validator";
import DateCalendar from "../../DateTimes/DateCalendar";
import { getShortRuDate, getShortRuDateHoursMinutes } from "../../../../helpers/date-formatter";

/**
 * @param {
 *     {
 *      data: array,
 *      values: array,
 *      placeholder: string,
 *      error: boolean,
 *      errorText: string,
 *      background: string,
 *      style: object,
 *      disabled: boolean,
 *      maxRows: int,
 *      search: boolean
 *     }
 * } props
 * @returns {JSX.Element}
 */

const SelectDatePlaceholder = (props) => {
  const { time, date, value, placeholder, onChange, error, errorText, onSubmit, style } = props;
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);

  /**
   * @param {string} value
   */
  const handleSelectValue = (value) => {
    handleValues(value);
  };

  const handleValues = (values) => {
    onSubmit && onSubmit(values);
    onChange && onChange(values);
    ref.current.handleContainer();
  };

  const getValueView = (value) => {
    let str = "";
    if (value && new Date(value).getHours) {
      str = (date ? getShortRuDate(value) + (time ? " - " : "") : "") + (time ? getShortRuDateHoursMinutes(value) : "");
    }
    return str;
  };

  const header = () => {
    return (
      <div
        {...{
          className: `SelectDefaultPlaceholderContainer pointer ${error && "SelectDefaultPlaceholderError"} ${
            focused || isValue(value) ? "SelectDefaultPlaceholderContainerActive" : ""
          }`,
        }}>
        <label
          {...{
            className: `SelectDefaultPlaceholder_label ${error && "SelectDefaultPlaceholderError_text"}`,
          }}>
          {!!placeholder ? placeholder : "Выбрать"}
        </label>
        <span {...{ className: "text-dotted-end font-size-16" }}>{isValue(value) ? getValueView(value) : null}</span>
        {errorText && <div {...{ className: "SelectDefaultPlaceholderContainerErrorText" }}>{errorText}</div>}
      </div>
    );
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        style: { ...style, width: style && style.width ? style.width : "100%" },
        headerStyle: { width: "100%" },
        childrenStyle: { background: "transparent" },
        ref,
        onChange: (isOpen) => setFocused(isOpen),
        header,
      }}>
      <DateCalendar
        {...{
          value,
          time,
          date,
          onCancel: () => ref.current.handleContainer(),
          onSubmit: (value) => handleSelectValue(value),
        }}
      />
    </ClickOutsideBoundaryTemplate>
  );
};

export default SelectDatePlaceholder;
