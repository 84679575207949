import React, { ComponentProps, CSSProperties, HTMLAttributes, ReactNode, RefObject } from "react";
import "./index.css";
import ConfigIcon from "../../Icons/ConfigIcon";

/**
 * @param {
 *     {
 *      icon: component,
 *      size: int,
 *      onClick: function,
 *      width: int,
 *      style: object,
 *      height: int,
 *      border: boolean,
 *      color: string
 *     }
 * } props
 * @returns {JSX.Element}
 */

interface IconButtonProps {
  icon: ReactNode;
  size?: number;
  onClick?: () => void;
  width?: number;
  height?: number;
  style?: CSSProperties;
  border?: boolean;
  color?: string;
  ref?: RefObject<any>;
  disabled?: boolean;
  type?: ComponentProps<"button">["type"];
  className?: string;
}

const IconButton = (props: IconButtonProps) => {
  const { icon, size, onClick, width, style, border, color, className, height, ref, disabled, type = "button" } = props;

  return (
    <button
      {...{
        ref,
        type,
        disabled,
        className: `IconButton flex-row align-center justify-center transition-default pointer ${border && "IconButtonBorder"} ${className}`,
        onClick: onClick,
        style: { ...style, width, height },
      }}>
      <div {...{ className: "transition-default" }}>
        <ConfigIcon {...{ color, size }}>{icon}</ConfigIcon>
      </div>
    </button>
  );
};

export default IconButton;
