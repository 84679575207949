import { STAGE_EXTENSION } from "./StageExtension";
import { DEADLINE_EXTENSION } from "./DeadLineExtension";
import { CALL_EXTENSION } from "./CallExtension";
import { WHATSAPP_EXTENSION } from "./MessageExtensions/WhatsApp";

export const EXTENSIONS = {
	STAGE: STAGE_EXTENSION,
	DEADLINE: DEADLINE_EXTENSION, // TODO set dates
	TELEPHONY: CALL_EXTENSION,
	MESSAGE: WHATSAPP_EXTENSION
}

export const ELEMENT_VIEW_STYLE_CONFIG = {
	notification: {
		position: 'absolute',
		width: 6,
		height: 6,
		borderRadius: '50%',
		top: 0,
		right: 0
	},
	icon: {
		position: 'relative'
	}
}
