import cn from "classnames";
import { isValue } from "../../../../helpers/values-validator";
import { IStageExtension } from "../../../../types/extension";
import styles from "./index.module.css";

interface GroupProps {
  title: string;
  value: any;
  variants: IStageExtension[];
  field_key: keyof IStageExtension;
  handleClick: (stage: IStageExtension) => void;
  className?: string;
}

export const Group = ({ variants, field_key, handleClick, value, title, className }: GroupProps) => {
  return (
    <div>
      <div className={className}>{title}</div>
      {variants?.map((item, key) => (
        <div
          {...{
            key,
            className: cn(styles.variantsItem, isValue(value) && value[0] === item[field_key] && styles.variantsItemActive, "flex-row align-center"),
            onClick: () => handleClick(item),
          }}>
          <span {...{ className: "text-dotted-end" }}>{item[field_key]}</span>
        </div>
      ))}
    </div>
  );
};
