import { createSlice } from "@reduxjs/toolkit";

const openStages = {
  name: "Открытые",
  icon: "lock-open",
  key: "opened",
};

const initialState = {
  __acceptedViews: null,
  acceptedViewsActive: null,

  __groupedByFields: null,
  groupedByFieldsActive: null,

  __groupedObjects: null,
  __groupedObjects_counts: null,

  currentSchemaFields: null,

  __schema_objects_filter: null,
  currentObjectsFilter: null,

  userFilters: null,

  stageExtensionActiveStatusesType: { ...openStages },
};

export const multiplyObjectsSlice = createSlice({
  name: "multiplyObjectsReducer",
  initialState,
  reducers: {
    multiplyObjects_InitData: (state, action) => {
      const maskData = action.payload.stageExtensionData;
      if (maskData) {
        state.__groupedObjects = filterStagesByStageExtension(action.payload.data, action.payload.stageExtensionData, action.payload.isOpenStages);
      } else
        state.__groupedObjects = filterStagesByStageExtension(action.payload.data, action.payload.stageExtensionData, action.payload.isOpenStages);
      state.__groupedObjects_counts = getCounts(action.payload.data);
    },
    multiplyObjects_ChangeStatus: (state, action) => {
      const tmpObjects = [];
      const filteredObjects = state.__groupedObjects.grouped_objects.map((grouped_object) => {
        return {
          ...grouped_object,
          objects: grouped_object.objects.filter((object) => {
            const idx = action.payload.object_ids.findIndex((obj) => obj === object.id.toString());
            if (idx >= 0) {
              tmpObjects.push(object);
            }
            return !action.payload.object_ids.includes(object.id.toString());
          }),
        };
      });
      state.__groupedObjects = {
        ...state.__groupedObjects,
        grouped_objects: filteredObjects.map((obj) => {
          if (obj.grouped_by.value === action.payload.status) {
            obj.objects = [...tmpObjects, ...obj.objects];
          }
          return obj;
        }),
      };
    },
    multiplyObjects_ClearData: (state, action) => {
      state.__groupedObjects = null;
      state.__groupedObjects_counts = null;
    },
    multiplyObjects_AddData: (state, action) => {
      const group = state.__groupedObjects.grouped_objects.find(
        (group) => group.grouped_by.choice_id === action.payload.grouped_objects[0].grouped_by.choice_id
      );
      if (!group) {
        return;
      }
      const data = createNewInitObject(action.payload.grouped_objects, action.payload.object_structure);
      group.grouped_by = data[0].grouped_by;
      group.objects = group.objects.concat(data[0].objects);
      // state.__groupedObjects_counts[group.grouped_by.choice_id].count = group.objects.length;
    },
    multiplyObjects_SetObjectsAcceptViews: (state, action) => {
      state.__acceptedViews = action.payload;
    },
    multiplyObjects_SetActiveAcceptView: (state, action) => {
      state.acceptedViewsActive = action.payload;
      state.__groupedByFields = action.payload.views;
    },
    multiplyObjects_SetActiveGroupedBy: (state, action) => {
      state.groupedByFieldsActive = action.payload;
    },
    multiplyObjects_SetCurrentSchemaFields: (state, action) => {
      state.currentSchemaFields = action.payload;
    },
    multiplyObjects_addColumnNewObject: (state, action) => {
      const index = state.__groupedObjects.grouped_objects.findIndex((column) => column.grouped_by.choice_id === action.payload.choice_id);
      state.__groupedObjects.grouped_objects[index].objects.unshift(action.payload.data);
    },
    multiplyObjects_setFiltersArray: (state, action) => {
      action.payload.forEach((schemaFilter) =>
        schemaFilter.field_filters.forEach((fieldFilter) => {
          const operand = fieldFilter[0]?.operand;
          if (operand && operand === "LT") {
            fieldFilter.reverse();
          }
        })
      );
      state.__schema_objects_filter = action.payload;
    },
    multiplyObjects_setFilterFieldValue: (state, action) => {
      state.__schema_objects_filter[action.payload.schema_key].field_filters[action.payload.field_index][action.payload.field_subIndex].selected =
        action.payload.values;
    },
    multiplyObjects_setCurrentObjectFilters: (state, action) => {
      state.currentObjectsFilter = action.payload;
    },
    multiplyObjects_resetFilterFieldValues: (state) => {
      state.__schema_objects_filter.map((schema, schema_key) => {
        schema.field_filters.map((field, field_index) => {
          // // TODO CHECK
          state.__schema_objects_filter[schema_key].field_filters[field_index].map((currentField, field_subIndex) => {
            state.__schema_objects_filter[schema_key].field_filters[field_index][field_subIndex].selected = null;
          });
        });
      });
      state.userFilters = null;
    },
    multiplyObjects_resetMultiplyViewData: (state) => {
      Object.keys(state).forEach((property) => {
        state[property] = null;
      });
    },
    multiplyObjects_handleGroupCountsDraggedObject: (state, action) => {
      const section_from_id = action.payload.from_section.choice_id;
      const section_to_id = action.payload.to_section.choice_id;
      state.__groupedObjects_counts[section_from_id].count--;
      state.__groupedObjects_counts[section_to_id].count++;
    },
    multiplyObjects_setActiveStatusesType: (state, action) => {
      state.stageExtensionActiveStatusesType = action.payload;
    },
    setUserFilters: (state, action) => {
      if (!state.userFilters) {
        state.userFilters = { [action.payload.key]: action.payload.values };
      } else {
        state.userFilters[action.payload.key] = action.payload.values;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  multiplyObjects_InitData,
  multiplyObjects_SetObjectsAcceptViews,
  multiplyObjects_SetActiveAcceptView,
  multiplyObjects_SetActiveGroupedBy,
  multiplyObjects_deleteObject,
  multiplyObjects_SetCurrentSchemaFields,
  multiplyObjects_addColumnNewObject,
  multiplyObjects_setFiltersArray,
  multiplyObjects_setFilterFieldValue,
  multiplyObjects_resetFilterFieldValues,
  multiplyObjects_resetMultiplyViewData,
  multiplyObjects_handleGroupCountsDraggedObject,
  multiplyObjects_ClearData,
  multiplyObjects_AddData,
  multiplyObjects_setCurrentObjectFilters,
  multiplyObjects_setActiveStatusesType,
  multiplyObjects_ChangeStatus,
  ...multiplyObjectsActions
} = multiplyObjectsSlice.actions;

export default multiplyObjectsSlice.reducer;

const getCounts = (data) => {
  const counts = {};
  if (data && data.grouped_objects) {
    data.grouped_objects.forEach((group) => {
      const { choice_id, count_objects } = group.grouped_by;
      counts[choice_id] = { count: count_objects };
    });
  }
  return counts;
};

const createNewInitObject = (grouped_objects, object_structure) => {
  return grouped_objects.map((items) => ({
    ...items,
    objects: items.objects.map((object) => ({
      id: object.id,
      schema_id: null,
      fields: Object.entries(object.fields_values).map(([key, value]) => ({ [key]: { ...object_structure.fields[key], values: value || [] } })),
      extensions: Object.fromEntries(
        Object.entries(object.extensions_values).map(([key, value]) => [key, Object.fromEntries(Object.entries(value).map(([k, v]) => [k, [v]]))])
      ),
      users: { owners: object.users },
    })),
  }));
};

const filterStagesByStageExtension = (data, stageExtensionData, isOpenStages) => {
  const newData = createNewInitObject(data.grouped_objects, data.object_structure);

  const isShowByChoiceId = (grouped_by) => {
    let hasFinishStageType = false;
    if(Array.isArray(stageExtensionData)) {
      stageExtensionData.forEach((stage) => {
        if (grouped_by.choice_id === stage.choice_id) {
          hasFinishStageType = !!stage.finish_stage_type;
        }
      });
    }
    return hasFinishStageType;
  };

  // const object
  let condition = (item) => !isShowByChoiceId(item.grouped_by);
  if (!isOpenStages) {
    condition = (item) => isShowByChoiceId(item.grouped_by);
  }
  return {
    ...data,
    grouped_objects: newData.filter((item) => condition(item)),
  };
};
