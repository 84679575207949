import React, { ComponentProps, CSSProperties, ReactNode, useRef } from "react";
import Spinner from "../../Loaders/Spinner";
import { motion } from "framer-motion";
import { SHOW_SPINNER_ANIMATION } from "../../../../constants/framer-motion-animations";
import cn from "classnames";
import { useHover } from "usehooks-ts";
import styles from "./index.module.css";

interface DefaultButtonProps {
  text?: string | ReactNode;
  onClick?: () => void;
  height?: number;
  width?: number;
  style?: CSSProperties;
  border?: boolean;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
  colorHover?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  className?: string;
  fitContent?: boolean;
  type?: ComponentProps<"button">["type"];
}

const DefaultButton = (props: DefaultButtonProps) => {
  const {
    text,
    onClick,
    height,
    width,
    style,
    border,
    loading,
    color,
    disabled,
    colorHover,
    onMouseOver,
    onMouseOut,
    className,
    fitContent,
    type = "button",
  } = props;

  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <button
      ref={hoverRef}
      type={type}
      className={cn(
        className,
        "flex-row align-center justify-center transition-default",
        styles.button,
        disabled ? "disabled cursor-default" : "pointer",
        border && styles.border,
        fitContent && styles.fit
      )}
      disabled={disabled}
      onClick={loading ? undefined : onClick}
      style={{ ...style, width: width, height: height }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOver}>
      <div
        className={cn("transition-default", styles.buttonText)}
        style={{ color: isHover && colorHover ? colorHover : color }}>
        {text}
      </div>
      <motion.div
        style={{ marginLeft: loading ? 10 : 0 }}
        {...SHOW_SPINNER_ANIMATION(loading)}>
        <Spinner
          loading={loading}
          size={20}
        />
      </motion.div>
    </button>
  );
};

export default DefaultButton;
