export enum TypesEnum {
	STRING = "STRING",
	NUMBER = "NUMBER",
	BOOLEAN = "BOOLEAN",
	DATETIME = "DATETIME",
	INTERVAL = "INTERVAL",
	LINK = "LINK",
	HTML = "HTML",
	GEO = "GEO",
	IMG = "IMG",
	AUD = "AUD",
	VID = "VID",
	DOC = "DOC",
	PHONE = "PHONE",
	EMAIL = "EMAIL",
	PRICE = "PRICE",
	COMPUTED = "COMPUTED",
	BUTTON = "BUTTON",
	TRIGGER = "TRIGGER",
}
