import React from "react";
import MessageExtensionsRouter from "../../../extensions/MessageExtensions";
import { useDispatch, useSelector } from "react-redux";
import { setCreateMessageActiveExtension } from "../../../../store/reducers/extensionsReducer";

const CreateMessageContainer = (props) => {
  const { object_id, onChange, isSendFile } = props;

  const __createMessageExtensions = useSelector((state) => state.extensions.__createMessageExtensions);
  const createStoreAction = useDispatch();

  const setActiveExtension = (extension_key) => {
    createStoreAction(setCreateMessageActiveExtension(extension_key));
  };

  return (
    <div>
      <div {...{ className: "LogsContainer_create" }}>
        <div {...{ className: "LogsContainer_createTabs flex-row" }}>
          {MessageExtensionsRouter.accepted_extensions.map((extension_key, extension_index) => {
            const menu = MessageExtensionsRouter.extensions[extension_key].menu;
            return (
              <div
                {...{
                  key: extension_index,
                  onClick: () => setActiveExtension(extension_key),
                  className: "LogsContainer_createTabs_item",
                  style: extension_key === __createMessageExtensions.activeExtension ? menu.style : null,
                }}>
                {menu.name}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {__createMessageExtensions.activeExtension &&
          MessageExtensionsRouter.extensions[__createMessageExtensions.activeExtension].create_widget({
            object_id,
            onChange,
            isSendFile,
          })}
      </div>
    </div>
  );
};

export default CreateMessageContainer;
