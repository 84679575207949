// @ts-nocheck
import { ApiEndpoint } from "../api-service";
import { api } from "../index";
import * as role from "../entry-points";
import { CreateRole, CreateUser, SetRole, UpdateRole } from "../interfaces/role";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

// - ROLE
const getAllUsers = () => loginEndpoint.request(role.GET_ALL_USERS, null, "get");
const getAllRoles = () => loginEndpoint.request(role.GET_ALL_ROLES, null, "get");
const getRole = (payload) => loginEndpoint.request(role.GET_ROLE(payload.query), null, "get");
const createRole: CreateRole = (payload) => loginEndpoint.request(role.CREATE_ROLE, payload, "post");
const updateRole: UpdateRole = (payload) => loginEndpoint.request(role.UPDATE_ROLE(payload.role_id), payload.data, "patch");
const deleteRole = (payload) => loginEndpoint.request(role.DELETE_ROLE(payload.query.role_id, payload.query.new_role_id), null, "delete");
const getSetRoleQuery = (payload: SetRole) => {
  const query = new URLSearchParams(payload()).toString();
  return `?${query}`;
};

const setRole: SetRole = (payload) => loginEndpoint.request(role.SET_ROLE(getSetRoleQuery(payload)), null, "post");
// - USER WITH ROLE
const createUser: CreateUser = (payload) => loginEndpoint.request(role.CREATE_USER, payload, "post");
const removeUser = (payload) => loginEndpoint.request(role.REMOVE_USER(payload.query.user_id, payload.query.new_user_id), null, "delete");

// - OWNERS

const addOwnerToSchema = (payload) => loginEndpoint.request(role.ADD_OWNER_TO_SCHEMA(payload.query.action), payload.data, "put");

export {
  // - ROLE
  getAllUsers,
  getAllRoles,
  getRole,
  createRole,
  updateRole,
  deleteRole,
  // - USER WITH ROLE
  setRole,
  createUser,
  removeUser,
  // - OWNER
  addOwnerToSchema,
};
