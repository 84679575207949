import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState } from "react";

import ModalPopup from "../ModalPopup";
import SelectDefault from "../../Selects/SelectDefault";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";

import styles from "./index.module.css";
import Bottom from "../Bottom";
import { isValue, validateValuesByType } from "../../../../helpers/values-validator";
import { updateObjectsOwnersFully } from "../../../../api/routes/objects_core";
import DefaultInput from "../../Inputs/DefaultInput";
import DefaultSwitcherSingle from "../../Switchers/DefaultSwitcherSingle/DefaultSwitcherSingle";
import { useParams } from "react-router-dom";
import { SUCCESS } from "../../../../helpers/response-service";
import { successToast } from "../../Toast/toast";
import { deleteAllSelectedObjects } from "../../../../store/reducers/selectedObjectsSlice";

const ModalChangeResponsiblePerson = (props) => {
  const { isOpen, getObjects, handleClose } = props;

  const { schema_key } = useParams();
  const dispatch = useDispatch();

  const schemas = useSelector((state) => state.app.schemas).filter((item) => item.meta_name === "__lead__");
  const users = useSelector((state) => state.app.campaign_users);
  const userFilters = useSelector((state) => state.multiplyObjects.userFilters);
  const owners = useSelector((state) => state.multiplyObjects?.userFilters?.owners_ids);
  const schemaObjectsFilter = useSelector((state) => state.multiplyObjects.__schema_objects_filter);
  const selectedObjectsSlice = useSelector((state) => state.selectedObjectsSlice.pull);
  const groupedByFieldsActive = useSelector((state) => state.multiplyObjects.groupedByFieldsActive);

  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    stage: false,
    currentOwners: false,
    newOwners: false,
  });

  const [requestError, setRequestError] = useState("");

  const [formState, setFormState] = useState({
    schema: schemas.filter((item) => item.id === +schema_key),
    stage: [],
    currentOwners: owners || [],
    newOwners: [],
    limit: Object.keys(selectedObjectsSlice).length || 10,
    excludeDuplicate: false,
  });

  const currentSchemaChoice = useMemo(
    () => (formState.schema ? formState.schema[0].fields.filter((item) => item.meta_name === "__stage__")[0].choice : []),
    []
  );

  const handleChangeFormState = (fieldKey, value) => {
    if (requestError) {
      setRequestError("");
    }

    setFormErrors((prevState) => ({ ...prevState, [fieldKey]: false }));
    setFormState((prevState) => ({
      ...prevState,
      [fieldKey]: value,
    }));
  };

  const handleChangeLimit = (value) => {
    if (requestError) {
      setRequestError("");
    }

    setFormState((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const handleSwitch = (checked) => {
    if (requestError) {
      setRequestError("");
    }

    setFormState((prevState) => ({
      ...prevState,
      excludeDuplicate: checked,
    }));
  };

  const getFieldValues = (type, values) => {
    const data = [];
    values.forEach((value) => {
      if (typeof value === "string" || typeof value === "boolean") {
        data.push(validateValuesByType(type, [value])[0]);
      } else if (typeof value === "object") {
        data.push(value.name);
      }
    });
    return data;
  };

  const getFilterFieldForRequest = (field) => {
    return {
      field_id: field.id,
      type: field.type,
      operand: field.operand,
      values: getFieldValues(field.type, field.selected),
    };
  };

  const filterFieldsCollection = () => {
    const array = [];
    schemaObjectsFilter.map((schema) => {
      schema.field_filters.map((fieldsArray) => {
        fieldsArray.map((field) => {
          if (isValue(field.selected)) {
            array.push(getFilterFieldForRequest(field));
          }
        });
      });
    });

    const hasCreatedAt = userFilters?.created_from || userFilters?.created_to;
    const created_at = {
      created_from: userFilters?.created_from || undefined,
      created_to: userFilters?.created_to || undefined,
    };

    return {
      is_desc: userFilters?.is_desc,
      simple_filters: array.length > 0 ? array : undefined,
      creators_ids: userFilters?.creators_ids?.map(({ id }) => id),
      owners_ids: formState.currentOwners.map(({ id }) => id),
      created_at: hasCreatedAt ? [created_at] : undefined,
    };
  };

  const handleSubmit = () => {
    if (!formState.newOwners.length || !formState.currentOwners.length) {
      const errors = Object.keys(formErrors).reduce(
        (acc, key) => ({
          ...acc,
          [key]: !formState[key].length,
        }),
        {}
      );
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);

    const filtersPayload = filterFieldsCollection();

    const payload = {
      query: {
        schema_id: formState.schema[0].id,
        choice_id: formState.stage[0].id,
        limit: formState.limit,
        exclude_duplicate: formState.excludeDuplicate,
      },
      data: filtersPayload,
    };

    payload.query = new URLSearchParams(payload.query);
    formState.newOwners.map(({ id }) => payload.query.append("user_ids", id));
    payload.query = payload.query.toString();

    updateObjectsOwnersFully(payload)
      .then(({ kind, data }) => {
        if (kind === SUCCESS) {
          successToast("Смена ответственного осуществлена успешно");
          handleClose();
          getObjects(groupedByFieldsActive, filtersPayload);
          dispatch(deleteAllSelectedObjects());
        } else {
          setRequestError(data.error.detail);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ClickOutsideBoundaryTemplate
      overlayVisible
      isOpen={isOpen}
      onClose={() => handleClose()}
      center>
      <ModalPopup
        onClose={handleClose}
        titleText="Смена ответственного"
        bottom={() => (
          <Bottom
            buttonLabel={"Перенести"}
            onSubmit={handleSubmit}
            onClose={() => handleClose()}
            loading={isLoading}
          />
        )}
        onClick={() => handleClose()}>
        <div className={styles.FieldsWrapper}>
          <SelectDefault
            search
            disabled
            amount={1}
            field_key="name"
            placeholder="Текущая воронка"
            data={[]}
            values={formState.schema}
            error={formErrors.schema}
          />
          <SelectDefault
            search
            amount={1}
            field_key="name"
            placeholder="Текущая стадия"
            data={currentSchemaChoice}
            values={formState.stage}
            onChange={(value) => handleChangeFormState("stage", value)}
            error={formErrors.stage}
          />
          <SelectDefault
            search
            field_key="name"
            placeholder="Текущий ответственный"
            data={users}
            values={formState.currentOwners}
            onChange={(value) => handleChangeFormState("currentOwners", value)}
            error={formErrors.currentOwners}
          />
          <SelectDefault
            search
            field_key="name"
            placeholder="Новый ответственный"
            data={users}
            values={formState.newOwners}
            onChange={(value) => handleChangeFormState("newOwners", value)}
            error={formErrors.newOwners}
          />
          <DefaultInput
            type="number"
            placeholder="Количество"
            disabled={!!Object.keys(selectedObjectsSlice).length}
            value={formState.limit}
            onChange={handleChangeLimit}
          />
          <div className={styles.SwitcherWrapper}>
            <span className={styles.SwitcherLabel}>Исключить дубликаты</span>
            <DefaultSwitcherSingle
              checked={formState.excludeDuplicate}
              onChange={handleSwitch}
            />
          </div>
          <span className={styles.WarningBlockText}>
            {!!Object.keys(selectedObjectsSlice).length
              ? "Перенос будет происходить по выбранным из списка объектам"
              : "Перенос будет происходить на основе примененного фильтра"}
          </span>
          {!!requestError && <span className={styles.ErrorText}>{requestError}</span>}
        </div>
      </ModalPopup>
    </ClickOutsideBoundaryTemplate>
  );
};

export default ModalChangeResponsiblePerson;
