import React from "react";
import IconManager from "../../../../kit/Icons/IconManager";
import styles from "./index.module.css";

const CircleCallButton = ({ color, icon, onClick, style }) => {
  return (
    <div
      {...{
        className: styles.CircleCallButton,
        style: {
          ...style,
          backgroundColor: color,
        },
        onClick,
      }}>
      <IconManager
        {...{
          icon,
          size: 14,
          color: "#fff",
        }}
      />
    </div>
  );
};

export default CircleCallButton;
