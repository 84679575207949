import IconManager from "../../../kit/Icons/IconManager";
import { ELEMENT_VIEW_STYLE_CONFIG } from "../../index";
import { COLORS } from "@/constants/types.tsx";
import { isValue } from "@/helpers/values-validator.js";
import * as _ from "lodash"

// DEADLINE
const DeadLineExtension_ElementView = (props) => {
  const { __deadline_from__, __deadline_to__, __deadline_status__ } = props;

  const STATUSES = {
    TOMORROW: {
      priorityLevel: 3,
      color: COLORS.YELLOW600,
    },
    TODAY: {
      priorityLevel: 2,
      color: COLORS.GREEN100,
    },
    OVERDUE: {
      priorityLevel: 1,
      color: COLORS.RED100,
    },
    OK: {
      priorityLevel: 4,
      color: COLORS.BLACK600,
    }
  };

  const getStatusByPriority = () => {
    if (!__deadline_status__ || !_.isArray(__deadline_status__)) {
      return null;
    }
    let array = [];
    __deadline_status__.forEach((item) => {
      const value = isValue(item.values) ? item.values[0] : null;
      value && array.push(value);
    });
    array = Array.from(new Set(array.filter((item) => Object.keys(STATUSES).includes(item))));

    if (!isValue(array)) {
      return false;
    }
    let sortedArray = [];
    array.forEach((status) => sortedArray.push(STATUSES[status]));
    sortedArray.filter((a, b) => a.priorityLevel - b.priorityLevel);
    return sortedArray[0];
  };

  const status = getStatusByPriority();

  if (!status) {
    return null;
  }

  return (
    <div {...{ style: { ...ELEMENT_VIEW_STYLE_CONFIG.icon, className: "margin-right-6" } }}>
      <IconManager
        {...{
          icon: "clock-hour-4",
          size: 18,
          color: "#DBDBEB",
        }}
      />
      <div
        {...{
          style: { ...ELEMENT_VIEW_STYLE_CONFIG.notification, backgroundColor: status.color },
        }}></div>
    </div>
  );
};

export default DeadLineExtension_ElementView;
