import React from "react";
import { VIEW_TYPES } from "./types";
import KanbanTemplate from "../components/templates/KanbanTemplate";
import RowsTemplate from "../components/templates/RowsTemplate";
import TableTemplate from "../components/templates/TableTemplate";

export const MULTIPLY_VIEWS = {
  [VIEW_TYPES.BILLBOARD]: {
    component: (params) => <KanbanTemplate {...{ ...params, multiSelect: false }} />,
  },
  [VIEW_TYPES.LIST]: {
    component: (params) => <RowsTemplate {...{ ...params, drag: false, multiSelect: true }} />,
  },
  [VIEW_TYPES.TABLE]: {
    component: (params) => <TableTemplate {...{ ...params, drag: false, multiSelect: true }} />,
  },
};
