import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  __stageExtensionConfig: {
    data: null,
    isActive: false,
    stages: {
      SUCCESS: {
        key: "SUCCESS",
        hasItem: false,
        name: "Успешно",
        color: "green",
        data: [],
      },
      FAILURE: {
        key: "FAILURE",
        hasItem: false,
        name: "Провалено",
        color: "red",
        data: [],
      },
    },
    finishStageText: null,
  },
  __deadlineExtensionConfig: {
    data: null,
  },
  __callExtension: null,
  __whatsAppExtension: {
    isActive: true,
    conversations: null,
    phone: null,
  },
  __createMessageExtensions: {
    activeExtension: "comment",
  },
  __commentExtension: {
    data: null,
  },
};

export const extensionsReducerSlice = createSlice({
  name: "extensionsReducerSlice",
  initialState,
  reducers: {
    setMultiplyViewStageExtension: (state, action) => {
      state.__stageExtensionConfig.data = action.payload;
      Object.keys(state.__stageExtensionConfig.stages).forEach((finishStage) => {
        state.__stageExtensionConfig.stages[finishStage].data = action.payload.filter((item) => item.finish_stage_type === finishStage);
      });
    },
    setMultiplyViewStageIsActive: (state, action) => {
      state.__stageExtensionConfig.isActive = action.payload;
    },
    setMultiplyViewActiveStageIsActive: (state, action) => {
      state.__stageExtensionConfig.stages[action.payload.type].hasItem = action.payload.value;
    },
    setMultiplyViewDeadLineExtension: (state, action) => {
      state.__deadlineExtensionConfig.data = action.payload;
    },
    setCallExtension: (state, action) => {
      state.__callExtension = action.payload;
    },
    setWhatsAppExtensionConversations: (state, action) => {
      state.__whatsAppExtension.conversations = action.payload;
    },
    setWhatsAppExtensionPhone: (state, action) => {
      state.__whatsAppExtension.phone = action.payload;
    },
    setCreateMessageActiveExtension: (state, action) => {
      state.__createMessageExtensions.activeExtension = action.payload;
    },
    setCommentExtension: (state, action) => {
      state.__commentExtension.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMultiplyViewActiveStageIsActive,
  setMultiplyViewStageExtension,
  setMultiplyViewStageIsActive,
  setMultiplyViewDeadLineExtension,
  setCallExtension,
  setCommentExtension,
  setWhatsAppExtensionConversations,
  setCreateMessageActiveExtension,
  setWhatsAppExtensionPhone,
} = extensionsReducerSlice.actions;

export default extensionsReducerSlice.reducer;
