import React from "react";
import EditDateTime from "../../../elements/Widgets/Edit/EditDateTime";
import { isValue } from "../../../../helpers/values-validator";
import { updateObject } from "../../../../api/routes/objects_core";
import EditWidgetIconLabelTemplate from "../../../templates/EditWidgetIconLabelTemplate";

const DeadLineExtension_FieldView = (props) => {
  const { data, object_id } = props;

  const [value, setValue] = React.useState(isValue(data.values) ? data.values[0] : null);

  const updateFieldValue = (value) => {
    const date = value.value ? value.isoDateTime : null;
    setValue(date);
    const payload = {
      query: object_id,
      data: [
        {
          field_id: data.id,
          values: [date]
        }
      ]
    };
    updateObject(payload).catch((e) => console.log(e));
  };

  return (
    <EditWidgetIconLabelTemplate
      {...{
        label: data.name,
        fontSize: 15,
        icon: data.icon
      }}>
      <EditDateTime
        {...{
          fontSize: 13,
          color: "#999",
          value,
          onSubmit: updateFieldValue
        }}
      />
    </EditWidgetIconLabelTemplate>
  );
};

export default DeadLineExtension_FieldView;
