import React, { ChangeEvent, forwardRef, KeyboardEvent, useState } from "react";
import "./index.css";
import { phoneFormatter } from "../../../../helpers/phone-formatter";

interface PhoneInputPlaceholderProps {
	value: string;
	disabled: boolean;
	placeholder: string;
	error: boolean;
	errorText: string;
	onChange: (value: string) => void;
	type: string;
	onSubmit: (value: string) => void;
	style: object;
}

const PhoneInputPlaceholder = forwardRef<HTMLInputElement, Partial<PhoneInputPlaceholderProps>>((props, parentRef) => {
	const { disabled, value, placeholder, error, errorText, onChange, type, onSubmit, style } = props;

	const [focused, setFocused] = useState(false);
	const ref = React.useRef<HTMLInputElement>(null)
	const [data, setData] = React.useState(value)

	const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		const value = getFormattedValue(e.target.value)
		setData(value)
		onChange && onChange(value)
	}

	const onSubmitField = () => {
		const value = getFormattedValue(ref.current?.value || "")
		onSubmit && onSubmit(value)
	}

	React.useEffect(() => {
		const propsValue = getFormattedValue(value || "")
		const localValue = getFormattedValue(data || "")
		if(localValue !== propsValue) {
			setData(propsValue)
		}
	}, [value])

	const getFormattedValue = (value: string) => {
		return phoneFormatter(value)
	}

	const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			ref.current?.blur()
		}
	}

	const onBlur = () => {
		setFocused(false)
		onSubmitField()
	}

	return (
		<div
			{...{
				className: `DefaultInputPlaceholderContainer flex-row ${error && "DefaultInputPlaceholderError"} ${focused || data ? "DefaultInputPlaceholderContainerActive" : ""}`,
				style: { ...style, opacity: disabled ? 0.7 : 1 },
			}}
		>
			<label
				{...{
					className: `DefaultInputPlaceholder_label ${error && "DefaultInputPlaceholderError_text"}`,
				}}
			>
				{!!placeholder ? placeholder : "Введите данные"}
			</label>
			<input
				{...{
					className: `DefaultInputPlaceholder_input flex align-center ${error && "DefaultInputPlaceholderError_text"}`,
					value: data,
					ref,
					disabled,
					onKeyPress,
					type: type ? type : "text",
					onChange: (e) => onChangeValue(e),
					onFocus: () => setFocused(true),
					onBlur
				}}
			/>
			{errorText && <div {...{ className: "DefaultInputPlaceholderContainerErrorText" }}>{errorText}</div>}
		</div>
	);
});

export default PhoneInputPlaceholder;
