import React, {useEffect, useMemo} from "react";
import { getObjectLogs, getObjectLogsTemp } from "@/api/routes/objects_core.tsx";
import { BAD, SUCCESS } from "@/helpers/response-service.ts";
import "./index.css";
import LogsSkeleton from "../../kit/Loaders/LogsSkeleton";
import HistoryLog from "../Logs/HistoryLog";
import { isValue } from "@/helpers/values-validator.js";
import CreateMessageContainer from "./CreateMessageContainer";
import { useIntersectionObserver } from "usehooks-ts";
import {useDispatch, useSelector} from "react-redux";
import { setCommentExtension } from "@/store/reducers/extensionsReducer.jsx";
import LogsFilterContainer from "./LogsFilterContainer";
import ModalDelete from "../../kit/Modals/ModalDelete/ModalDelete";
import { removeObjectComment } from "@/api/routes/extensions.tsx";
import * as _ from "lodash"

const INITIAL_LIMIT = 100;
const LOAD_MORE_LIMIT = 200;
const PULLING_INTERVAL = 4600

const LogsContainer = (props) => {
  const { object_id } = props;

  const isWindowFocused = useSelector((state) => state.window.isFocused)
  const [pull, setPull] = React.useState([]);
  const [filteredPull, setFilteredPull] = React.useState([])

  const [selectedFilters, setSelectedFilters] = React.useState([
    { id: 0, type: "COMMENT", name: "Комментарии", icon: "quote" },
    { id: 3, type: "TELEPHONY", name: "Звонки", icon: "phone-outgoing" },
    { id: 4, type: "MESSAGE", name: "Сообщения", icon: "message" },
  ]);

  const [firstLoaded, setFirstLoaded] = React.useState(false);
  const isObserverActive = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const isLastPage = React.useRef(false);
  const bodyRef = React.useRef(null);

  const containerRef = React.useRef(null);
  const logsRef = React.useRef({
    lastAtTopRef: null,
    lastAtEndRef: null,
    filterDataRef: null,
    intervalRef: null,
  });

  const visibleElementRef = React.useRef(null);
  const entry = useIntersectionObserver(visibleElementRef, { threshold: 1 });
  const isVisible = !!entry?.isIntersecting;

  const createStoreAction = useDispatch();

  const containerHeightRef = React.useRef({
    prevHeight: 0,
    resizedHeight: 0,
  });

  useEffect(() => {
    if (isLastPage.current) {
      return;
    }
    if (isVisible && onListEnd) {
      onListEnd();
    }
  }, [isVisible, visibleElementRef]);

  const handleSelectedFilter = (selected) => {
    setSelectedFilters((prev) => {
      const isIncludes = !!prev.find((item) => item.id === selected.id);
      if (isIncludes) {
        return prev.filter((item) => item.id !== selected.id);
      }
      return [...prev, selected];
    });
  };

  const selectedFilterTypes = useMemo(() => {
    return selectedFilters.map((item) => item.type)
  }, [selectedFilters])

  useEffect(() => {
    setFilteredPull(pull.filter((log) => selectedFilterTypes.length === 0 ? true : selectedFilterTypes.includes(log.type)))
  }, [selectedFilterTypes, pull]);

  const onListEnd = () => {
    if (firstLoaded) {
      getLastLogs(logsRef.current.lastAtTopRef);
    }
  };

  const getHistoryLogsPayload = () => {
    return {
      query: new URLSearchParams({
        object_id,
        page: 0,
        limit: INITIAL_LIMIT,
        ...(logsRef.current.lastAtEndRef && { last_at: logsRef.current.lastAtEndRef }),
      }).toString(),
      data: logsRef.current.filterDataRef ? logsRef.current.filterDataRef : null,
    };
  };

  const getLogsRequest = (payload) => {
    getObjectLogs(payload)
        .then((result) => {
          if (result.kind === SUCCESS) {
            if (isValue(result.data)) {
              logsRef.current.lastAtEndRef = getHistoryLogTime(result.data.at(0));
              isLastPage.current = result.data.length < LOAD_MORE_LIMIT;
              setPull((prev) => [...prev, ...result.data]);
            }
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
  };

  const getLastLogs = (last_at) => {
    containerHeightRef.current.prevHeight = containerRef.current.scrollHeight;
    const payload = {
      query: new URLSearchParams({
        object_id,
        page: 0,
        limit: LOAD_MORE_LIMIT,
        ...(last_at && { last_at }),
      }).toString(),
      data: logsRef.current.filterDataRef ? logsRef.current.filterDataRef : null,
    };
    getObjectLogsTemp(payload)
        .then((result) => {
          if (result.kind === SUCCESS) {
            isLastPage.current = result.data.length < LOAD_MORE_LIMIT;
            const logs = result.data.reverse();
            if (isValue(logs)) {
              logsRef.current.lastAtTopRef = getHistoryLogTime(result.data.at(0));
              if (last_at) {
                setPull((prev) => [...logs, ...prev]);
              } else {
                logsRef.current.lastAtEndRef = getHistoryLogTime(result.data.at(-1));
                setPull(logs);
              }
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  React.useEffect(() => {
    if (!loading && isWindowFocused) {
      initInterval();
    }
    return () => {
      resetInterval();
    }
  }, [loading, isWindowFocused]);

  const initInterval = () => {
    logsRef.current.intervalRef = setInterval(() => {
      getLogsRequest(getHistoryLogsPayload());
    }, PULLING_INTERVAL);
  };

  const resetInterval = () => {
    clearInterval(logsRef?.current?.intervalRef);
  };

  const callBack = (entries) => {
    for (let e of entries) {
      containerHeightRef.current.resizedHeight = containerRef.current ? e.contentBoxSize[0].blockSize : 1;
      const scrollHeight = containerHeightRef.current.resizedHeight - containerHeightRef.current.prevHeight;

      let chat = e.target.parentNode;
      chat.scrollTop = chat.scrollHeight - chat.clientHeight;
      scrollToBottom(!isObserverActive.current ? scrollHeight : null, bodyRef.current);
    }

    if (containerRef.current?.childNodes?.length > 2) {
      isObserverActive.current = false;
      if (!firstLoaded) {
        setFirstLoaded(true);
      }
    }
  };

  React.useEffect(() => {
    isObserverActive.current = true;
    let observer = new ResizeObserver(callBack);
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
      observer = null;
    };
  }, []);

  const onEdit = (data) => {
    createStoreAction(setCommentExtension(data));
  };

  const onDelete = async (e) => {
    if (!selectedItem) {
      return;
    }
    const res = await removeObjectComment({ query: selectedItem.id });
    if (res.kind === BAD) {
      return;
    }

    const deletedEmptyObject = generateDeletedLogObject()
    if (res.kind === SUCCESS) {
      setPull((prev) => {
        return prev.map((pr) => {
          if (pr.id === selectedItem.id) {
            return { created_at: pr.created_at, id: pr.id, object_id: pr.object_id, user: pr.user, data: deletedEmptyObject };
          }
          return pr;
        });
      });
      setSelectedItem(null);
    }
  };

  const onChangeLog = (log, text) => {
    const nextLog = pull.map((item) => {
      if (item.id === log.id) {
        return {
          ...item,
          data: {
            ...item.data,
            body: {
              ...item.data.body,
              text,
            },
          },
        };
      } else {
        return item;
      }
    });
    setPull(nextLog);
  };

  const getHistoryLogTime = (item) => {
    return item ? item.created_at : null;
  };

  const scrollToBottom = (height, element) => {
    if (height && element && height > 0) {
      element.scrollTop = height;
      return;
    }

    const top = element.scrollHeight;
    const scrollConfig = {
      top: top ? top : 0,
      behavior: "smooth",
    };
    element && element.scrollTo && element.scrollTo(scrollConfig);
  };

  const isLogInDayWithPrevious = (time, index) => {
    const prevLogTime = new Date(getHistoryLogTime(filteredPull[index - 1]));
    const logTime = new Date(time);
    return prevLogTime.getUTCDate() === logTime.getUTCDate() && prevLogTime.getUTCMonth() === logTime.getUTCMonth() && prevLogTime.getUTCFullYear() === logTime.getUTCFullYear();
  };

  useEffect(() => {
    getLastLogs();
    return () => clearInterval(logsRef.current.intervalRef);
  }, []);

  return (
      <div className="LogsContainer flex-column">
        <div className="LogsContainer_header flex-row justify-between align-center">
          <div className="LogsContainer_headerName">История</div>
          <LogsFilterContainer
              selected={selectedFilters}
              onSelected={handleSelectedFilter}
          />
        </div>
        <div
            ref={bodyRef}
            className="LogsContainer_body">
          {!loading ? (
              pull && _.isArray(pull) ? (
                  <div className="LogsContainer_bodyInner">
                    <div
                        ref={containerRef}
                        className="LogsContainer_body_logs">
                      <div
                          ref={visibleElementRef}
                          style={{ width: "100%", height: 20, position: "absolute" }}
                      />
                      {
                        filteredPull.map((item, index) => {
                          return <HistoryLog
                              {...{
                                ...(item.data.icon.name === "quote" && {
                                  onEditClick: () => onEdit(item),
                                  onDeleteClick: () => setSelectedItem(item),
                                }),
                                showDate: index === 0 ? true : !isLogInDayWithPrevious(getHistoryLogTime(item), index),
                                key: item.id,
                                data: item.data,
                                date: item.created_at,
                                user: item.user,
                              }}
                          />
                        })
                      }
                    </div>
                  </div>
              ) : null
          ) : (
              <LogsSkeleton />
          )}
        </div>
        <ModalDelete
            open={Boolean(selectedItem)}
            onOpenChange={(isOpen) => !isOpen && setSelectedItem(null)}
            title="Вы действительно хотите удалить комментарий"
            description="Вся информация хранимая в комментарии удалится без возможности возврата данных."
            submitText="удалить"
            handleSubmit={onDelete}
        />
        <CreateMessageContainer {...{ object_id, onChange: onChangeLog, isSendFile: true }} />
      </div>
  );
};

const generateDeletedLogObject = () => {
  return {
    attachments: {
      audio: null,
      document: null,
      img: null,
      location: null,
      video: null,
    },
    body: {
      comment: null,
      tag: null,
      text: null,
    },
    card: {
      backgroundColor: "#fff",
      color: "#333",
    },
    icon: {
      backgroundColor: "#333",
      color: "#fff",
      name: "x",
      position: "LEFT",
    },
    title: {
      comment: null,
      tag: null,
      text: "Комментарий удален",
    },
  };
}

export default LogsContainer;
