import { NOTIFICATION_TYPES } from "./types";

export const FAILED_RELATE_OBJECT_TO_PARENT = {
  type: NOTIFICATION_TYPES.ERROR,
  text: "Ошибка привязки нового объекта к данному родителю",
};
export const FAILED_CREATE_FAST_OBJECT = {
  type: NOTIFICATION_TYPES.ERROR,
  text: "Ошибка созданиия нового объекта",
};

export const MULTIPLY_VIEW_EXTENSIONS_EXIST = {
  type: NOTIFICATION_TYPES.INFO,
  text: "У этого типа схемы есть возможность работы со стадиями",
};
export const FINISH_STAGE_SUCCESS = {
  type: NOTIFICATION_TYPES.SUCCESS,
  text: "Объект успешно перенесен в финишную стадию",
};
export const FINISH_STAGE_BAD = {
  type: NOTIFICATION_TYPES.ERROR,
  text: "Произошла ошибка при перенесении объекта в финишную стадию",
};
export const COPY_LINK = {
  type: NOTIFICATION_TYPES.SUCCESS,
  text: "Ссылка успешно скопирована",
};

export const WHATSAPP_PHONE_EMPTY = {
  type: NOTIFICATION_TYPES.WARNING,
  text: "Необходимо указать номер, к которому привязан WhatsApp",
};

export const GET_OBJECT_TO_RELATE__ERROR = {
  type: NOTIFICATION_TYPES.WARNING,
  text: "Ошибка получения созданного объекта",
};
