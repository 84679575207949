import cn from "classnames";
import React, { ReactNode } from "react";
import "./index.css";

interface DefaultHeaderProps {
  value: string;
  children?: ReactNode;
  className?: string;
}

const DefaultHeader = (props: DefaultHeaderProps) => {
  const { value, children, className } = props;
  return (
    <div className={cn("DefaultHeader w-100 margin-vertical-14 border-radius-6", className)}>
      <div className="DefaultHeaderContainer w-100 padding-17 flex align-center justify-between">
        <span className="font-size-20"> {value}</span>
        {children && (
          <div className="DefaultHeaderContainer_controls flex">
            {/*<SelectAction {...{*/}
            {/*    style: {...{width: 36, height: 36,}},*/}
            {/*    width: 18,*/}
            {/*    height: 18,*/}
            {/*    data: dataHeader,*/}
            {/*    icon: <TbDotsVertical/>,*/}
            {/*}}/>*/}
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultHeader;

const dataHeader = {
  items: [
    {
      id: 1,
      name: "Редактировть",
      action: () => console.log("Переименовать"),
    },
  ],
};
