import React from "react";
import { ucFirst } from "../../../../helpers/uppercaseFirst";
import "./index.css";

/**
 * @param {
 *     {
 *      style: object,
 *      text: string,
 *     }
 * } props
 * @returns {JSX.Element}
 */
const TagStage = (props) => {
	const { style, text } = props;

	return (
		<div
			{...{
				className: `TagStage font-size-15 flex nowrap align-center border-radius-6`,
				style: {
					...style,
					color: !!style && !!style.color ? style.color : "var(--text-sixth)",
				},
			}}
		>
			<div
				{...{
					style: {
						...style,
						opacity: 0.1,
						background: !!style && !!style.color ? style.color : "var(--background-body)",
						position: "absolute",
					},
					className: "TagStage_background w-100 border-radius-6",
				}}
			></div>
			<span {...{ className: `text-dotted-end padding-4-10 font-size-13` }}> {!!text && ucFirst(text)} </span>
		</div>
	);
};

export default TagStage;
