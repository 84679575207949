import { toast } from "react-toastify";
const toastsTypes = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  UPDATED: "UPDATED",
  DEADLINE: "DEADLINE",
};

const options = {
  [toastsTypes.SUCCESS]: { type: "success", theme: "colored", position: "bottom-left" },
  [toastsTypes.ERROR]: { type: "error", theme: "colored", position: "bottom-left" },
  [toastsTypes.UPDATED]: { type: "info", theme: "colored", position: "bottom-left" },

  [toastsTypes.WARNING]: { type: "warning", theme: "colored", position: "bottom-left" },
};

export const useNotification = () => {
  const errorNotification = (text) => toast(text, options[toastsTypes.ERROR]);

  const updateNotification = (text) => toast(text, options[toastsTypes.UPDATED]);
  const succeedNotification = (text) => toast(text, options[toastsTypes.SUCCESS]);
  const warringNotification = (text) => toast(text, options[toastsTypes.WARNING]);

  return { errorNotification, warringNotification, succeedNotification, updateNotification };
};
