import * as _ from "lodash"
import "./index.css";
import { connect } from "react-redux";
import { getGroupedObjectsTest, getSchemaFields, getViews, updateObject } from "@/api/routes/objects_core.tsx";
import { BAD, SUCCESS } from "@/helpers/response-service.ts";
import { setX } from "@/store/reducers/positionSlice.jsx";
import {
  multiplyObjects_InitData,
  multiplyObjects_SetActiveAcceptView,
  multiplyObjects_SetActiveGroupedBy,
  multiplyObjects_SetObjectsAcceptViews,
  multiplyObjects_SetCurrentSchemaFields,
  multiplyObjects_resetMultiplyViewData,
  multiplyObjects_handleGroupCountsDraggedObject,
  multiplyObjects_AddData,
} from "@/store/reducers/multiplyObjectsReducer.jsx";
import { VIEW_TYPES } from "@/constants/types.tsx";
import { findFirstViewByTemplate } from "@/helpers/multiply-view-helper.js";
import MultiplyViewHeader from "../../../components/elements/MultiplyView/MultiplyViewHeader";
import GroupedObjects from "../../../components/elements/MultiplyView/GroupedObjects";
import withRouter from "../../../router/with-router";
import { getDeadLineData, getMultipleViewExtensionsConfig } from "@/api/routes/extensions.tsx";
import { setNotification } from "@/store/actions/appActions.jsx";
import { setMultiplyViewDeadLineExtension, setMultiplyViewStageExtension } from "@/store/reducers/extensionsReducer.jsx";
import { isValue } from "@/helpers/values-validator.js";

import { IconPencil, IconUserMinus, IconUserPlus } from "@tabler/icons-react";
import ModalMultipleView from "../../../components/kit/Modals/ModalMultipleView";
import ModalMultipleViewSelectObjects from "../../../components/kit/Modals/ModalMultipleView/ModalMultipleViewSelectObjects";
import { RoleConfig } from "@/types/role.ts";
import React from "react";

const IS_OPEN_STAGES_CONFIG = [
  {
    name: "Открытые",
    icon: "lock-open",
    isOpenStages: true,
  },
  {
    name: "Закрытые",
    icon: "lock",
    isOpenStages: false,
  },
];

class MultiplyView extends React.Component {
  // 1. - Get accept views
  // 2. - Get first groupedBy value
  // 3. - Get grouped objects by previous value (view_id)
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewsLoading: true,
      isOpenStages: IS_OPEN_STAGES_CONFIG[0],
      isAddOwner: false,
      isRemoveOwner: false,
      isChangeStatus: false,
    };
    this.view = null;
    this.location = this.props.location.pathname;
    this.schema_key = this.props.params.schema_key;
    this.schema_id = this.returnSchemaId(this.props.schemas);
    // this.create_path = this.location + '/new'
    // this.approve_path = this.location + '/approve'
    this.setFilterRef = this.setFilterRef.bind(this);
  }

  optionsOfSelectedObjects = [
    {
      icon: (
        <IconUserPlus
          size={20}
          color={"var(--text-nineth)"}
        />
      ),
      name: "Добавить ответственного",
      action: () => this.setState({ isAddOwner: !this.state.isAddOwner }),
    },
    {
      icon: (
        <IconUserMinus
          size={20}
          color={"var(--text-nineth)"}
        />
      ),
      name: "Убрать ответственного",
      action: () => this.setState({ isRemoveOwner: !this.state.isRemoveOwner }),
    },
    {
      icon: (
        <IconPencil
          size={20}
          color={"var(--text-nineth)"}
        />
      ),
      name: "Сменить статус",
      action: () => this.setState({ isChangeStatus: !this.state.isChangeStatus }),
    },
  ];

  returnSchemaId = (schemas) => {
    if (schemas && _.isArray(schemas)) {
      const schema = schemas.filter((route) => route.key === this.schema_key)[0];
      if (schema && schema.id) {
        return schema.id;
      }
    }
    return null;
  };

  setFilterRef = (elem) => {
    this.filterRef = elem;
  };

  setMultiplyViewActiveViewTemplateGroupedBy = async (value) => {
    this.props.setActiveGroupedBy(value);
    this.setState({ loading: true });
    await this.getExtensionsConfigData();
    await this.getMultiplyViewGroupedObjects(value, this.props.currentFilter || null);
  };

  setMultiplyViewActiveViewTemplate = (value) => {
    this.view = value;
    this.props.setActiveAcceptView(value);
    this.setMultiplyViewActiveViewTemplateGroupedBy(value.views[0]).finally()
  };

  setIsOpenStages = (value) => {
    this.setState({
      isOpenStages: value,
      loading: true,
    });
    this.getMultiplyViewGroupedObjects(this.props.groupedBy, this.props.currentFilter, null, value);
  };

  abortController = new AbortController();

  generateMultiplyViewGroupedObjectsPayload = (isInit = false, groupedBy, filter, paginationConfig) => {
    let limit = 14;
    if (this.props.currentUserRole.type === RoleConfig.ADMIN && this.view.template !== VIEW_TYPES.BILLBOARD) {
      limit = 14;
    }

    return {
      query: {
        view_id: groupedBy.view_id,
        schema_id: this.schema_id,
        grouped_by: groupedBy.grouped_by_field.id,
        page: !isInit && paginationConfig && "page" in paginationConfig ? paginationConfig.page : 0,
        limit,
        // limit: !isInit && paginationConfig && "limit" in paginationConfig ? paginationConfig.limit : OBJECTS_LIMIT,
      },
      data: filter ? filter : {},
    };
  };

  getMultiplyViewGroupedObjectsDeepReload = (groupedBy, filter) => {
    this.setState({
      loading: true,
    });

    const payload = this.generateMultiplyViewGroupedObjectsPayload(true, groupedBy, filter);
    payload.query = new URLSearchParams(payload.query).toString();

    return getGroupedObjectsTest(payload, this.abortController.signal)
      .then((result) => {
        switch (result.kind) {
          case SUCCESS:
            this.props.initData({
              data: result.data,
              isOpenStages: this.state.isOpenStages.isOpenStages,
              stageExtensionData: this.props.stagesExtensionData,
            });
            break;
          default:
            break;
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getMultiplyViewGroupedObjects = (groupedBy, filter, paginationConfig, isOpenStages = undefined) => {
    const payload = this.generateMultiplyViewGroupedObjectsPayload(false, groupedBy, filter, paginationConfig);
    payload.query = new URLSearchParams(payload.query).toString();
    // console.log({
    //   payload,
    //   paginationConfig,
    // })
    return getGroupedObjectsTest(payload, this.abortController.signal)
      .then((result) => {
        switch (result.kind) {
          case SUCCESS:
            if (paginationConfig) {
              const currentGroup = result.data.grouped_objects.find((group) => {
                const isTarget = group.grouped_by.choice_id === paginationConfig.choice_id;
                if (isTarget && group.objects.length < paginationConfig.limit) {
                  group.grouped_by.isLastPage = true;
                }
                return isTarget;
              });
              if (currentGroup.objects.length > 0) {
                this.props.addData({ object_structure: result.data.object_structure, grouped_objects: [currentGroup] });
              }
            } else {
              // TODO add { isOpenStages: true || false }
              this.props.initData({
                data: result.data,
                isOpenStages: isOpenStages !== undefined ? isOpenStages.isOpenStages : this.state.isOpenStages.isOpenStages,
                stageExtensionData: this.props.stagesExtensionData,
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getCurrentSchemaFields = () => {
    const payload = {
      query: { schema_id: this.schema_id },
      data: null,
    };
    payload.query = new URLSearchParams(payload.query).toString();
    getSchemaFields(payload).then((result) => {
      switch (result.kind) {
        case SUCCESS:
          this.props.setCurrentSchemaFields(Object.assign({}, result.data));
          break;
      }
    });
  };

  getAcceptedViewTemplates = () =>
    new Promise((resolve, reject) => {
      const payload = {
        query: { schema_id: this.schema_id },
        data: null,
      };
      payload.query = new URLSearchParams(payload.query).toString();
      getViews(payload).then((result) => {
        switch (result.kind) {
          case SUCCESS:
            if (result.data && result.data.length > 0) {
              this.props.setObjectsAcceptViews(result.data);
              const viewTemplate = findFirstViewByTemplate(result.data, VIEW_TYPES.BILLBOARD);
              resolve(viewTemplate);
            } else reject(result.data);
            break;
          case BAD:
            reject(result.data);
            break;
        }
      });
    });

  getExtensionsConfigData = () => {
    return new Promise((resolve, reject) => {
      const payload = {
        query: { schema_id: this.schema_id },
        data: null,
      };
      payload.query = new URLSearchParams(payload.query).toString();
      getMultipleViewExtensionsConfig(payload).then((result) => {
        switch (result.kind) {
          case SUCCESS:
            // this.props.setNotification(MULTIPLY_VIEW_EXTENSIONS_EXIST)
            const isFilledArray = isValue(result.data);
            const payload = {
              kind: isFilledArray ? SUCCESS : BAD,
              data: isFilledArray ? result.data : null,
            };
            this.props.setExtensionsConfig(payload.data);
            isFilledArray ? resolve(payload) : reject(payload);
            break;
          case BAD:
            reject({
              kind: BAD,
              data: null,
            });
            break;
        }
      });
    });
  };

  updateObjectField = async ({ id, field_id, value, info, reverseAction }) => {
    if (id && field_id && value) {
      const storePayload = {
        from_section: info.from_section,
        to_section: info.to_section,
        drag_item_id: info.drag_item_id,
      };
      this.props.setCounts(storePayload);
      const payload = {
        query: id,
        data: [
          {
            field_id,
            values: [value.toString()],
          },
        ],
      };

      const resetAction = () => {
        const reverseActionStorePayload = {
          from_section: info.to_section,
          to_section: info.from_section,
          drag_item_id: info.drag_item_id,
        };
        reverseAction();
        this.props.setCounts(reverseActionStorePayload);
      };

      updateObject(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              break;
            case BAD:
              resetAction();
          }
        })
        .catch((e) => {
          resetAction();
        });
    }
  };

  getDeadlineData = () => {
    const payload = {
      query: {
        schema_id: this.schema_id,
        page: 0,
        limit: 100,
      },
    };
    payload.query = new URLSearchParams(payload.query).toString();
    getDeadLineData(payload).then((result) => {
      switch (result.kind) {
        case SUCCESS:
          this.props.setDeadLineExtension(result.data);
      }
    });
  };

  createUpdateObjectFieldAction = (info, reverseAction) => {
    const payload = {
      id: info.drag_item_id,
      field_id: info.to_section.field_id,
      value: info.to_section.value,
    };
    let valid = true;
    Object.keys(payload).forEach((key) => (!!payload[key] ? (valid = !!payload[key]) : false));
    valid &&
      info.from_section.choice_id !== info.to_section.choice_id &&
      this.updateObjectField({
        ...payload,
        info,
        reverseAction,
      });
  };

  componentDidMount() {
    this.getMultipleViewData();
  }

  getMultipleViewData = () => {
    if (this.schema_id) {
      this.setState({
        loading: true,
        viewsLoading: true,
      });
      this.getAcceptedViewTemplates()
        .then((result) => {
          this.setState({
            viewsLoading: false,
          });
          this.setMultiplyViewActiveViewTemplate(result);
        })
        .catch((e) => {
          this.setState({
            loading: false,
            viewsLoading: false,
          });
        });
      setTimeout(() => {
        // schema config
        this.getCurrentSchemaFields();
        this.getDeadlineData();
      }, 1000);
    }
  };

  closeModalsMultipleView = () => {
    this.setState({ isAddOwner: false });
    this.setState({ isRemoveOwner: false });
    this.setState({ isChangeStatus: false });
  };

  componentWillUnmount() {
    this.abortController.abort();
    this.props.resetMultiplyViewData();
  }

  setPx = (data) => {
    this.props.setX(data);
  };

  render() {
    return (
      <div
        {...{
          className: "MultiplyView flex-column",
        }}>
        <MultiplyViewHeader
          {...{
            setIsOpenStages: this.setIsOpenStages,
            isOpenStages: this.state.isOpenStages,
            isOpenStagesConfig: IS_OPEN_STAGES_CONFIG,
            viewsLoading: this.state.viewsLoading,
            schema_id: this.schema_id,
            root_path: this.location,
            getObjects: this.getMultiplyViewGroupedObjectsDeepReload,
            onGroupedByChange: this.setMultiplyViewActiveViewTemplateGroupedBy,
            onViewTemplateChange: this.setMultiplyViewActiveViewTemplate,
          }}
        />
        <div
          onScroll={(e) => this.setPx(e.target.scrollLeft)}
          {...{ className: "MultiplyView_dataContainer" }}>
          <GroupedObjects
            {...{
              isDragActive: this.state.isOpenStages.isOpenStages,
              onListEnd: (data) => this.getMultiplyViewGroupedObjects(this.props?.groupedBy, this.props.currentFilter, data),
              action: this.createUpdateObjectFieldAction,
              loading: this.state.loading,
              schema_id: this.schema_id,
              schema_key: this.schema_key,
            }}
          />
        </div>
        {/*DEV  \/\/\/\/\/\/\/\/ */}
        <ModalMultipleViewSelectObjects
          view_id={this.props.view_id}
          optionsOfSelectedObjects={this.optionsOfSelectedObjects}
        />
        <ModalMultipleView
          schema_id={this.schema_id}
          isRemoveOwner={this.state.isRemoveOwner}
          isAddOwner={this.state.isAddOwner}
          isChangeStatus={this.state.isChangeStatus}
          handleClose={() => this.closeModalsMultipleView()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setX: (payload) => dispatch(setX(payload)),
    initData: (payload) => dispatch(multiplyObjects_InitData(payload)),
    addData: (payload) => dispatch(multiplyObjects_AddData(payload)),
    setActiveGroupedBy: (payload) => dispatch(multiplyObjects_SetActiveGroupedBy(payload)),
    setActiveAcceptView: (payload) => dispatch(multiplyObjects_SetActiveAcceptView(payload)),
    setObjectsAcceptViews: (payload) => dispatch(multiplyObjects_SetObjectsAcceptViews(payload)),
    setCurrentSchemaFields: (payload) => dispatch(multiplyObjects_SetCurrentSchemaFields(payload)),
    resetMultiplyViewData: () => dispatch(multiplyObjects_resetMultiplyViewData()),
    setNotification: (payload) => dispatch(setNotification(payload)),
    setExtensionsConfig: (payload) => dispatch(setMultiplyViewStageExtension(payload)),
    setCounts: (payload) => dispatch(multiplyObjects_handleGroupCountsDraggedObject(payload)),
    setDeadLineExtension: (payload) => dispatch(setMultiplyViewDeadLineExtension(payload)),
  };
};

const mapStateToProps = (state) => ({
  view_id: state.multiplyObjects.groupedByFieldsActive?.view_id,
  acceptedViewsActive: state.multiplyObjects.acceptedViewsActive,
  groupedBy: state.multiplyObjects.groupedByFieldsActive,
  schemas: state.app.schemas,
  currentUserRole: state.user.user.role,
  currentFilter: state.multiplyObjects.currentObjectsFilter,
  stagesExtensionData: state.extensions.__stageExtensionConfig.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MultiplyView));
