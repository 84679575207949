import React from "react";
import "./index.css";
import ViewTabs from "../../../kit/Tabs/ViewTabs";
import IconButton from "../../../kit/Buttons/IconButton";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { TbClock } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import SchemaObjectsFilterModal from "../../../kit/Modals/SchemaObjectsFilterModal";
import SelectOpenClosedStages from "../../../kit/Selects/SelectOpenClosedStages";
import PermissionLayout from "../../../../layouts/PermissionLayout";
import { ROLE_TYPES_ENUM } from "../../../../constants/types";
import SelectSchemaFromGroup from "../../SelectSchemaFromGroup";
import SelectAction from "../../SelectAction";
import { RoleConfig } from "../../../../types/role";
import { IoPeople } from "react-icons/io5";
import { FaHandshake } from "react-icons/fa";



const MultiplyViewHeader = (props) => {
  const {
    setIsOpenStages,
    isOpenStages,
    isOpenStagesConfig,
    schema_id,
    root_path,
    getObjects,
    viewsLoading,
    onGroupedByChange,
    onViewTemplateChange,
  } = props;

  const navigate = useNavigate();
  const createStoreAction = useDispatch();

  const __acceptedViews = useSelector((state) => state.multiplyObjects.__acceptedViews);
  const acceptedViewsActive = useSelector((state) => state.multiplyObjects.acceptedViewsActive);
  const groupedByFieldsActive = useSelector((state) => state.multiplyObjects.groupedByFieldsActive);
  const stageExtension = useSelector((state) => state.extensions.__stageExtensionConfig.data);
  const deadlineExtension = useSelector((state) => state.extensions.__deadlineExtensionConfig.data);
  const userType = useSelector((state) => state.user.user.role.type);

  return (
    <div {...{ className: "MultiplyView_controlsContainer" }}>
      <div
        {...{
          className: "MultiplyView_controlsContainer_inner flex-row align-center justify-between",
        }}>
        <div {...{ className: "flex-row" }}>
          {/*DESKS*/}
          <SelectSchemaFromGroup {...{ schema_id }} />
          <ViewTabs
            {...{
              loading: viewsLoading,
              disabled: false,
              value: acceptedViewsActive,
              data: __acceptedViews,
              controls: false,
              loaderConfig: {
                icons: true,
                height: 55,
              },
              onChange: (value) => !!onViewTemplateChange && onViewTemplateChange(value),
            }}
          />
          {userType === RoleConfig.ADMIN && <SelectAction getObjects={getObjects} />}
          {/*{stageExtension && (*/}
          {/*  <PermissionLayout {...{ include: [ROLE_TYPES_ENUM.ADMIN, ROLE_TYPES_ENUM.OWNER] }}>*/}
          {/*    <IconButton*/}
          {/*    	{...{*/}
          {/*    		onClick: () => navigate(root_path + "/approve"),*/}
          {/*    		color: "gray",*/}
          {/*    		icon: <TbAlertCircle />,*/}
          {/*    		size: 24,*/}
          {/*    		style: { background: "var(--card-background)", height: 60, marginLeft: 6 },*/}
          {/*    		width: 60,*/}
          {/*    		height: 60,*/}
          {/*    	}}*/}
          {/*    />*/}
          {/*  </PermissionLayout>*/}
          {/*)}*/}
          {deadlineExtension && (
            <PermissionLayout {...{ include: [ROLE_TYPES_ENUM.ADMIN, ROLE_TYPES_ENUM.OWNER, ROLE_TYPES_ENUM.REGULAR] }}>
              <IconButton
                {...{
                  onClick: () => navigate(root_path + "/deadlines"),
                  color: "gray",
                  icon: <TbClock />,
                  size: 24,
                  style: { background: "var(--card-background)", height: 60, marginLeft: 6 },
                  width: 55,
                  height: 55,
                }}
              />
            </PermissionLayout>
          )}
          {deadlineExtension && (
            <PermissionLayout {...{ include: [ROLE_TYPES_ENUM.ADMIN, ROLE_TYPES_ENUM.OWNER, ROLE_TYPES_ENUM.REGULAR] }}>
              <IconButton
                {...{
                  onClick: () => navigate(root_path + "/deadlines/lead-manager"),
                  color: "gray",
                  icon: <IoPeople />,
                  size: 24,
                  style: { background: "var(--card-background)", height: 60, marginLeft: 6 },
                  width: 55,
                  height: 55,
                }}
              />
            </PermissionLayout>
          )}
          {deadlineExtension && (
            <PermissionLayout {...{ include: [ROLE_TYPES_ENUM.ADMIN, ROLE_TYPES_ENUM.OWNER, ROLE_TYPES_ENUM.REGULAR] }}>
              <IconButton
                {...{
                  onClick: () => navigate(root_path + "/deadlines/lead-remote"),
                  color: "gray",
                  icon: <FaHandshake />,
                  size: 24,
                  style: { background: "var(--card-background)", height: 60, marginLeft: 6 },
                  width: 55,
                  height: 55,
                }}
              />
            </PermissionLayout>
          )}
        </div>
        <div {...{ className: "flex align-center" }}>
          {/*<div {...{ className: "margin-right-14" }}>*/}
          {/*  <SelectByItem*/}
          {/*    {...{*/}
          {/*      toLeft: true,*/}
          {/*      disabled: !(__acceptedViews && __acceptedViews.views && __acceptedViews.views[1]),*/}
          {/*      field_key: "name",*/}
          {/*      placeholder: "Группа по",*/}
          {/*      data: __acceptedViews ? __acceptedViews.views : null,*/}
          {/*      value: groupedByFieldsActive,*/}
          {/*      setValue: (value) => !!onGroupedByChange && onGroupedByChange(value),*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*SORT*/}
          <SelectOpenClosedStages
            {...{
              data: isOpenStagesConfig,
              onChange: setIsOpenStages,
              value: isOpenStages,
            }}
          />
          <SchemaObjectsFilterModal
            {...{
              style: { marginRight: 6, marginLeft: 6 },
              getObjects,
              schema_id,
            }}
          />
          {/*ICON BUTTON*/}
          {/*<SelectIconAction*/}
          {/*  {...{*/}
          {/*    style: { background: "var(--card-background)" },*/}
          {/*    headerSize: 60,*/}
          {/*    className: "margin-right-6",*/}
          {/*    data: selectActionData,*/}
          {/*    icon: <BiDotsVerticalRounded />,*/}
          {/*  }}*/}
          {/*/>*/}
          <IconButton
            {...{
              onClick: () => navigate(root_path + "/new"),
              color: "#FFF",
              size: 24,
              width: 55,
              height: 55,
              icon: <BiPlus />,
              style: { background: "var(--primary-color)", height: 60 },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiplyViewHeader;
