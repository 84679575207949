import React, {useState, useEffect, useRef} from 'react';

const useTimer = () => {

	const [isTimerStart, setIsStart] = useState(false)
	const [seconds, setSeconds] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [hours, setHours] = useState(0);
	const [timerString, setTimer] = useState(null)
	const timerInterval = useRef(null)

	useEffect(() => {
		if (isTimerStart) {
			timerInterval.current = setInterval(() => {
				if (seconds >= 0 && seconds < 59) {
					setSeconds(seconds + 1);
				}
				if (seconds === 59) {
					if (minutes >= 0 && minutes < 59) {
						setMinutes(minutes + 1);
					}
					if (minutes === 59) {
						setMinutes(0);
						setHours(hours + 1);
					}
					setSeconds(0);
				}
			}, 1000);
			return () => {
				clearInterval(timerInterval.current);
			};
		} else {
			resetTimer()
		}
	}, [isTimerStart, seconds, minutes]);

	useEffect(() => {
		getTimerTime()
	}, [seconds, minutes, hours])

	const startTimer = () => {
		setIsStart(true)
	}

	const stopTimer = () => {
		setIsStart(false)
	}

	const resetTimer = () => {
		setIsStart(false)
		if(timerInterval.current) {
			clearInterval(timerInterval.current);
		}
		setSeconds(0)
		setMinutes(0)
		setHours(0)
	}

	const getTimerTime = () => {
		const hoursString = hours !== 0 ? `${hours}:` : ''
		const minutesString = minutes !== 0 ? `${minutes < 10 ? '0' + minutes : minutes}:` : '00:'
		const secondsString = seconds !== 0 ? `${seconds < 10 ? '0' + seconds : seconds}` : '00'
		const time = hoursString + minutesString + secondsString
		setTimer(time)
	}

	return [isTimerStart, timerString, startTimer, stopTimer];

}

export default useTimer
