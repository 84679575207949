import styles from "./index.module.css";
import cn from "classnames";
import { useAppDispatch } from "../../../../../hooks/useRedux";
import { imageViewerActions } from "../../../../../store/reducers/imageViewerSlice";

type propTypes = {
  src: string;
};

const LogAttachmentsImagesItem = ({ src }: propTypes) => {
  const dispatch = useAppDispatch();
  const openViewer = () => {
    dispatch(imageViewerActions.openImageViewer(src));
  };

  return (
    <div
      onClick={openViewer}
      className={cn(styles.HistoryLog_AttachmentsImagesItem)}>
      <img
        className={cn(styles.HistoryLog_AttachmentsImagesItem)}
        src={src}
        alt={"WhatsApp Image"}
      />
    </div>
  );
};

export default LogAttachmentsImagesItem;
