import React, { memo, ReactNode } from "react";
import styles from "./index.module.css";
import { TbDotsVertical } from "react-icons/tb";
import SelectIconAction from "../../../kit/Selects/SelectIconAction";
import cn from "classnames";

interface TableHeaderMiniProps {
  value?: string;
  actionsData?: {
    name: string;
    action: () => void;
  }[];
  controls?: ReactNode;
}

const TableHeaderMini = memo((props: TableHeaderMiniProps) => {
  const { value, actionsData, controls } = props;
  return (
    <div {...{ className: cn("w-100 margin-bottom-14", styles.TableHeaderMini) }}>
      <div
        {...{
          className: "w-100 flex align-center justify-between",
        }}>
        <span>{value}</span>
        <div className="flex justify-between align-center">
          {controls && <div>{controls}</div>}
          {actionsData && (
            <SelectIconAction
              {...{
                style: { ...{ width: 36, height: 36 } },
                width: 18,
                height: 18,
                data: actionsData || [],
                icon: <TbDotsVertical />,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default TableHeaderMini;
