import React, { ReactNode } from "react";
import "./index.css";
import { IoClose } from "react-icons/io5";
import { useMaxHeight } from "../../../../hooks/useMaxHeight";

interface ModalPopupProps {
  titleText: string;
  titleDescription?: string;
  children: ReactNode;
  bottom: () => ReactNode;
  onClose?: () => void;
  width?: number;
}

const ModalPopup = (props: ModalPopupProps) => {
  const { titleText, titleDescription, children, bottom, onClose, width = 450 } = props;
  const maxHeight = useMaxHeight(40);
  return (
    <div
      className="ModalPopup border-radius-6 padding-17"
      style={{ maxHeight, width }}>
      <div className="ModalPopupContainer w-100 flex-column overflow-hidden">
        <div className="ModalPopupContainer_title w-100 flex-column">
          <div
            className="ModalPopupContainer_titleClose"
            onClick={onClose}>
            <IoClose
              size={24}
              className="pointer"
              color="var(--main-text-dark)"
            />
          </div>
          {titleText && (
            <div
              className="w-100"
              style={{ marginTop: -3, marginBottom: 13 }}>
              <div
                className="ModalPopupContainer_titleText flex align-center"
                style={{ height: 28 }}>
                {titleText}
              </div>
            </div>
          )}
          {titleDescription && (
            <div
              className="ModalPopupContainer_titleDescription font-size-15"
              style={{ whiteSpace: "pre-line" }}>
              {titleDescription}
            </div>
          )}
        </div>
        {children ? <div className="ModalPopupContainer_content no-scrollbar">{children}</div> : null}
        {bottom && <div className="ModalPopupContainer_bottom w-100 ">{bottom()}</div>}
      </div>
    </div>
  );
};

export default ModalPopup;
