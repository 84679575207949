import React from "react";
import "./index.css";
import RowCardFieldsIteration from "../TableRow/RowCardFieldsIteration";

const CardRow = (props) => {
  const { data, max } = props;

  return (
    <div {...{ className: "CardRowTemplate flex-row align-center" }}>
      <div {...{ className: "CardRowTemplate_dragIdContainer flex-row" }}>{data.id}</div>
      <RowCardFieldsIteration {...{ fields: data.fields, max }} />
    </div>
  );
};

export default CardRow;
