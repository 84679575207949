import { userTypes } from "../types/userTypes";

const initialState = {
	user: null,
};

export const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case userTypes.INIT_USER:
			return { ...state, user: payload ? payload : null };
		default:
			return state;
	}
};
