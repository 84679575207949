import React, { ReactNode, useRef } from "react";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import ModalPopup from "../ModalPopup";
import Bottom from "../Bottom";
import "./ModalDelete.css";

interface ModalSettingDelete {
  children?: ReactNode;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  loading?: boolean;
  disabled?: boolean;
  title: string;
  description: string;
  submitText: string;
  handleSubmit: () => void;
}

const ModalDelete = ({ handleSubmit, children, loading, disabled, submitText, title, description, open, onOpenChange }: ModalSettingDelete) => {
  return (
    <ClickOutsideBoundaryTemplate
      isOpen={open}
      onChange={onOpenChange}
      overlayVisible
      center>
      <ModalPopup
        onClose={() => onOpenChange(false)}
        titleText={title}
        titleDescription={description}
        bottom={() => (
          <Bottom
            error={true}
            disabled={disabled}
            loading={loading}
            buttonLabel={submitText}
            onSubmit={handleSubmit}
            onClose={() => onOpenChange(false)}
          />
        )}>
        {children}
      </ModalPopup>
    </ClickOutsideBoundaryTemplate>
  );
};

export default ModalDelete;
