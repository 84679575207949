import { useEffect, useRef, useState } from "react";
import { CgLayoutGridSmall } from "react-icons/cg";

interface UseAudioPlayerConfig {
  volumeStep: number;
}

interface IAudioState {
  loading: boolean;
  volume: number;
  duration: number;
  currentTime: number;
  canPlay: boolean;
  canPlayThrough: boolean;
  isPlaying: boolean;
  speed: number;
}

interface IAudioActions {
  pause: () => void;
  play: () => Promise<void>;
  setCurrentTime: (currentTime: number) => void;
  increaseVolume: () => void;
  decreaseVolume: () => void;
  setVolume: (value: number) => void;
  setSpeed: (speed: number) => void;
  reset: () => void;
}

type AudioPlayerReturn = [IAudioState, IAudioActions];

const DEFAULT_STEP = 0.05;

export const useAudioPlayer = (src: string | null, config: UseAudioPlayerConfig): AudioPlayerReturn => {
  const [loading, setLoading] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [canPlayThrough, setCanPlayThrough] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [currentTime, _setCurrentTime] = useState(0);
  const [volume, _setVolume] = useState(0.5);
  const audioInstance = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!src) {
      return;
    }

    setLoading(true);
    audioInstance.current = new Audio(src);
    const canPlayCallback = () => {
      setLoading(false);
      setCanPlay(true);
    };
    const canPlayThroughCallback = () => setCanPlayThrough(true);
    const handleTimeUpdate = () => _setCurrentTime(audioInstance.current?.currentTime || 0);
    const handleError = () => setError(true);
    const handleEnd = () => setIsPlaying(false);

    audioInstance.current.addEventListener("canplay", canPlayCallback);
    audioInstance.current.addEventListener("canplaythrough", canPlayThroughCallback);
    audioInstance.current.addEventListener("timeupdate", handleTimeUpdate);
    audioInstance.current.addEventListener("ended", handleEnd);
    audioInstance.current.addEventListener("error", handleError);

    return () => {
      pause();
      audioInstance.current?.removeEventListener("canplay", canPlayCallback);
      audioInstance.current?.removeEventListener("canplaythrough", canPlayThroughCallback);
      audioInstance.current?.removeEventListener("timeupdate", handleTimeUpdate);
      audioInstance.current?.removeEventListener("ended", handleEnd);
      audioInstance.current?.removeEventListener("error", handleError);
    };
  }, [src]);

  const pause = (): void => {
    if (!audioInstance.current) {
      return;
    }
    setIsPlaying(false);
    audioInstance.current.pause();
  };

  const play = async (): Promise<void> => {
    if (!audioInstance.current) {
      return;
    }
    setIsPlaying(true);
    return audioInstance.current.play();
  };

  const setCurrentTime = (currentTime: number): void => {
    if (!audioInstance.current) {
      return;
    }
    audioInstance.current.currentTime = currentTime;
    _setCurrentTime(currentTime);
  };

  const increaseVolume = (): void => {
    const step = config.volumeStep ?? DEFAULT_STEP;
    if (!audioInstance.current || volume + step > 1) {
      return;
    }
    _setVolume((state) => state + config.volumeStep ?? DEFAULT_STEP);
    audioInstance.current.volume += config.volumeStep ?? DEFAULT_STEP;
  };

  const decreaseVolume = (): void => {
    const step = config.volumeStep ?? DEFAULT_STEP;
    if (!audioInstance.current || volume - step < 0) {
      return;
    }
    _setVolume((state) => state - config.volumeStep ?? DEFAULT_STEP);
    audioInstance.current.volume -= config.volumeStep ?? DEFAULT_STEP;
  };

  const setVolume = (value: number): void => {
    if (!audioInstance.current) {
      return;
    }
    if (value < 0 || value > 1) {
      throw new Error("incorrect volume value");
    }
    _setVolume(value);
    audioInstance.current.volume = value;
  };

  const setSpeed = (speed: number) => {
    if (!audioInstance.current) {
      return;
    }
    audioInstance.current.playbackRate = speed;
  };

  const reset = (): void => {
    if (!audioInstance.current) {
      return;
    }
    audioInstance.current.currentTime = 0;
  };

  return [
    {
      loading,
      canPlayThrough,
      isPlaying,
      canPlay,
      currentTime,
      volume,
      speed: audioInstance.current?.playbackRate || 1,
      duration: audioInstance.current?.duration || 0,
    },
    { pause, play, increaseVolume, decreaseVolume, setVolume, setCurrentTime, reset, setSpeed },
  ];
};
