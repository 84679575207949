import cn from "classnames";
import dayjs from "dayjs";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import TableHeaderMini from "../../../components/elements/Tables/TableHeaderMini";
import SkeletonView from "../../../components/kit/Loaders/SkeletonView";
import { VIEW_TYPES } from "../../../constants/types";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { IFilterPayload, ISortPayload, reportsActions } from "../../../store/reducers/reportsSlice";
import { FiltersModal } from "./FiltersModal";
import LeftMenu from "../../../components/elements/LeftMenu";
import ListTemplate from "../../../components/templates/ListTemplate";
import DefaultHeader from "../../../components/elements/Headers/DefaultHeader";
import { getStatistic, getStatisticMeta, GetStatisticPayload } from "../../../api/routes/statistic";
import { formatPrice } from "../../../helpers/price-formatter";
import { SUCCESS } from "../../../helpers/response-service";
import { IStats, IStatsMeta, StatTypes, StatTypesConfig } from "../../../types/stats";
import styles from "./index.module.css";
import {DealRedirect} from "./DealRedirect";
import {AudioPlayer} from "./AudioPlayer";

const formatValue = (value: string | number, type: StatTypes, onClick?: () => void): ReactNode => {
  let formattedValue: ReactNode = value;

  switch (type) {
    case StatTypesConfig.DATE:
      formattedValue = dayjs(value).format("DD MMM YYYY");
      break;
    case StatTypesConfig.DATETIME:
      formattedValue = dayjs(value).format("DD MMM YYYY HH:mm:ss");
      break;
    case StatTypesConfig.DAY_HOUR:
      formattedValue = dayjs.duration(+value * 1000).format("D д. H ч.");
      break;
    case StatTypesConfig.HOUR_MINUTE_SECOND:
      formattedValue = dayjs.duration(+value * 1000).format("HH:mm:ss");
      break;
    case StatTypesConfig.NUMBER:
      const price = formatPrice(value);
      formattedValue = price ? price : "";
      break;
    case StatTypesConfig.AUDIO:
      formattedValue = typeof value === "string" && value ? <AudioPlayer src={value}/> : "";
      break;
    case StatTypesConfig.OBJECT_LINK:
      if (typeof value !== "string" || !value) {
        formattedValue = "";
        break;
      }
      formattedValue = (
        <DealRedirect url={value}/>
      );
      break;
    default:
      formattedValue = value ? value : "";
  }
  return formattedValue;
};

const LIMIT = 30;

interface FetchStatisticPayload {
  prefix: string;
  page: number;
  limit: number;
  filter_fields?: IFilterPayload[];
  schema_id?: number;
  sort?: ISortPayload;
}

// https://uhome-minio.k8s.caltat.net/call-records/crm/2023/01/30/from-crm-call_1675082621.364748.mp3

const Reports = () => {
  const [tabs, setTabs] = useState<IStatsMeta[] | null>(null);
  const [stats, setStats] = useState<IStats>();

  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  const fetching = useAppSelector((state) => state.reports.fetching);
  const loading = useAppSelector((state) => state.reports.loading);
  const filter_fields = useAppSelector((state) => state.reports.filter_fields);
  const shouldRefetch = useAppSelector((state) => state.reports.shouldRefetch);
  const activeTab = useAppSelector((state) => state.reports.activeTab);
  const selectedSchema = useAppSelector((state) => state.reports.selectedSchema);
  const schemas = useAppSelector((state) => state.reports.schemas);

  const abortController = useRef(new AbortController());

  const dispatch = useAppDispatch();

  const setActiveTab = (path: string) => {
    dispatch(reportsActions.resetFilters());
    abortController.current.abort();
    const activeTab = tabs?.find((tab) => tab.path === path);
    if (!activeTab) {
      return;
    }
    dispatch(reportsActions.setActiveTab({ activeTab }));
  };

  const fetchStatistic = ({ prefix, filter_fields, sort, page, limit, schema_id }: FetchStatisticPayload) => {
    const query = new URLSearchParams({ page: page.toString(), limit: limit.toString() }).toString();
    const payload: GetStatisticPayload = {
      prefix,
      query,
      body: { filter_fields, filter_schema: schema_id ? [{ id: schema_id }] : undefined },
    };
    abortController.current = new AbortController();
    return getStatistic(payload, abortController.current.signal)
      .then((res) => {
        if (res.kind === SUCCESS) {
          if (!res.data.values || res.data.values.length < LIMIT) {
            setIsLastPage(true);
          }
          if (stats && page > 0) {
            setStats({ ...stats, values: stats.values.concat(res.data.values) });
          } else {
            setStats({ ...res.data, values: res.data.values || [] });
          }
        }
      })
      .finally(() => {
        dispatch(reportsActions.setLoading({ loading: false }));
      });
  };

  const handleListEnd = () => {
    if (!activeTab || isLastPage) {
      return;
    }
    dispatch(reportsActions.setLoading({ fetching: true }));
    setPage((page) => page + 1);
    fetchStatistic({
      page: page + 1,
      filter_fields,
      limit: LIMIT,
      prefix: activeTab.path,
      schema_id: selectedSchema?.id,
    });
  };

  useEffect(() => {
    dispatch(reportsActions.setLoading({ loading: true }));
    getStatisticMeta().then((res) => {
      if (res.kind === SUCCESS) {
        setTabs(res.data);
        dispatch(reportsActions.setActiveTab({ activeTab: res.data[0] }));
        return res.data[0].path;
      }
    });
    return () => {
      abortController.current.abort();
    };
  }, []);

  useEffect(() => {
    if (!activeTab || !schemas) {
      return;
    }
    setPage(0);
    setIsLastPage(false);
    dispatch(reportsActions.setLoading({ loading: true }));
    fetchStatistic({ page: 0, filter_fields, limit: LIMIT, prefix: activeTab.path, schema_id: selectedSchema?.id });
  }, [shouldRefetch, activeTab, schemas]);

  const tableData = useMemo(() => {
    if (!stats) {
      return { notEmpty: true, data: [], headers: [] };
    }

    const headers = stats.fields.map((field) => ({ name: field.name }));

    const data = stats?.values
      ? stats.values.map((values) => values.map((value, index) => ({ values: [formatValue(value, stats?.fields[index]?.type)] })))
      : [];

    return { headers, data };
  }, [stats]);

  return (
    <div className={cn(`flex flex-column margin-left-14 margin-right-14`, styles.reports)}>
      <DefaultHeader value="Отчеты" />
      <div className="flex w-100 h-100 overflow-hidden">
        <LeftMenu
          loading={!tabs}
          values={tabs}
          valueActive={activeTab}
          onClick={setActiveTab}
        />
        <div className={cn(styles.content)}>
          <TableHeaderMini
            value={activeTab?.name}
            controls={<FiltersModal />}
          />
          {!loading ? (
            <ListTemplate
              data={tableData.data || []}
              headers={tableData.headers || []}
              onListEnd={handleListEnd}
              isLoading={fetching}
            />
          ) : (
            <SkeletonView view={VIEW_TYPES.TABLE} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
