import React, { useState, useRef } from "react";
import { ucFirst } from "../../../../helpers/uppercaseFirst";
import "./index.css";
import ConfigIcon from "../../Icons/ConfigIcon";
import { MdKeyboardArrowDown } from "react-icons/md";
import IconButton from "../../Buttons/IconButton";
import { AiOutlinePlus } from "react-icons/ai";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";

/**
 * @param {
 *     {
 *      value: object,
 *      data: array,
 *      placeholder: string,
 *      onChange: function,
 *      style: object,
 *      disabled: boolean
 *     }
 * } props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDesk = (props) => {
	const { value, data, onChange, disabled, placeholder, width, style, className, onCreate } = props;

	const ref = useRef(null);
	const [focused, setFocused] = useState(false);

	const handleSelectValue = (element) => {
		onChange(element);
		ref.current.handleContainer();
	};

	const drawValue = () => {
		return value ? ucFirst(value.name) : placeholder ? ucFirst(placeholder) : "Выбрать";
	};

	const header = () => {
		return (
			<div
				{...{
					className: `${className} ${disabled && "disabled"} SelectDesk_value padding-17 w-100 flex justify-between align-center pointer text-dotted-end `,
				}}
			>
				<div className="SelectDesk_valueContainer w-100 flex-row justify-between">
					<span {...{ className: "SelectDesk_choiceValue text-dotted-end" }}>{drawValue()}</span>
					<div
						{...{
							style: { transform: `rotate(${focused ? 180 : 0}deg)` },
							className: "transition-default",
						}}
					>
						<ConfigIcon {...{ color: "#AEAEC7", size: 20 }}>
							<MdKeyboardArrowDown />
						</ConfigIcon>
					</div>
				</div>
			</div>
		);
	};

	return (
		<ClickOutsideBoundaryTemplate
			{...{
				ref,
				onChange: (value) => !disabled && setFocused(value),
				header: () => header(),
				style: { ...style, width: "100%" },
				headerStyle: { width: !!width && width ? width : "100%" },
				childrenStyle: { width: !!width && width ? width : "100%" },
				disabled,
			}}
		>
			<div className="SelectDesk_valuesContainer padding-17 flex-column">
				{!!data &&
					data.map((item, key) => {
						return (
							<div
								{...{
									key,
									className: `SelectDesk_valuesContainer_item text-dotted-end padding-vertical-12 pointer ${!!value && value.name === item.name && "SelectDesk_valuesContainer_itemActive"}`,
									onClick: () => (!!value && value.name === item.name ? null : handleSelectValue(item)),
								}}
							>
								<span>{ucFirst(item.name)}</span>
							</div>
						);
					})}
				{onCreate && <IconButton
					{...{
						color: "#AEAEC7",
						size: 20,
						icon: <AiOutlinePlus />,
						style: { background: "#F9F9FA", height: 40 },
						width: "100%",
					}}
				/>}
			</div>
		</ClickOutsideBoundaryTemplate>
	);
};

SelectDesk.defaultProps = {
	onCreate: null
}

export default SelectDesk;
