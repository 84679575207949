import React, { useEffect, useMemo, useState } from "react";
import styles from "./index.module.css";
import TagStage from "../../../elements/Tags/TagStage";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { isValue } from "../../../../helpers/values-validator";
import { COLORS } from "../../../../constants/types";
import cn from "classnames";
import { FinishStageTypesConfig } from "../../../../types/extension";
import { Group } from "./Group";

const StagesConfig = {
  OPENED: "Открытые",
  SUCCESS: "Успешно",
  FAILURE: "Провалено",
};

const SelectObjectStatus = (props) => {
  const { data, value, onChange, elemRef, onSubmit, onFinishStage, field_key } = props;
  const [stagesMap, setStagesMap] = useState({});

  const groupedData = useMemo(() => {
    return data.reduce((acc, stage) => {
      switch (stage.finish_stage_type) {
        case FinishStageTypesConfig.SUCCESS:
          if (!acc[StagesConfig.SUCCESS]) {
            setStagesMap((prev) => ({ ...prev, [stage.name]: stage }));
            acc[StagesConfig.SUCCESS] = [];
          }
          acc[StagesConfig.SUCCESS].push(stage);
          break;
        case FinishStageTypesConfig.FAILURE:
          setStagesMap((prev) => ({ ...prev, [stage.name]: stage }));
          if (!acc[StagesConfig.FAILURE]) {
            acc[StagesConfig.FAILURE] = [];
          }
          acc[StagesConfig.FAILURE].push(stage);
          break;
        default:
          if (!acc[StagesConfig.OPENED]) {
            acc[StagesConfig.OPENED] = [];
          }
          acc[StagesConfig.OPENED].push(stage);
      }
      return acc;
    }, {});
  }, [data]);

  const filterValueColorByData = (values) => {
    if (data && isValue(values)) {
      const value = values[0];
      return data.filter((item) => item.name === value)[0]?.color ||  "var(--text-tenth)";
    } else {
      return "var(--text-tenth)";
    }
  };

  const [color, setColor] = React.useState(filterValueColorByData(value));

  const ref = elemRef ? elemRef : React.useRef(null);

  const header = (value) => {
    return (
      <div>
        {isValue(value) ? (
          <TagStage {...{ text: value[0], style: { color: COLORS[color] } }} />
        ) : (
          <div {...{ className: styles.placeholder }}>Выберите статус</div>
        )}
      </div>
    );
  };

  const handleChangeValue = (item) => {
    if (isValue(value) && value[0] === item[field_key]) {
      handleValues(null);
    } else handleValues(item[field_key]);
    ref.current.handleContainer();
  };

  const handleValues = (value) => {
    setColor(filterValueColorByData([value]));
    // console.log(stagesMap);
    if (stagesMap[value] && onFinishStage) {
      onFinishStage(stagesMap[value]);
    } else if (onSubmit) {
      onSubmit(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        ref,
        header: () => header(value),
      }}>
      <div {...{ className: cn(styles.variants, "flex-column w-full") }}>
        {Object.entries(groupedData).map(([key, variants], index, array) =>
          variants.length > 0 ? (
            <div key={key}>
              <Group
                className={styles.groupTitle}
                title={key}
                value={value}
                variants={variants}
                field_key={field_key}
                handleClick={handleChangeValue}
              />
              {index !== array.length - 1 && <span className={cn(styles.separator)} />}
            </div>
          ) : null
        )}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

SelectObjectStatus.defaultProps = {
  field_key: "name",
};

export default SelectObjectStatus;
