import React from "react";
import CircleCallButton from "./CircleCallButton";
import styles from "./index.module.css";
import useTimer from "../../../../hooks/useTimer";

const ControlsButtons = React.forwardRef((props, parentRef) => {
  const { isShowDecline, isShowConfirm, onDecline, onConfirm, isShowTimer } = props;
  const [isTimerStart, timerString, startTimer, stopTimer] = useTimer();

  React.useImperativeHandle(parentRef, () => ({
    startTimer: () => {
      if (!isTimerStart) {
        startTimer();
      }
    },
    stopTimer: () => {
      if (isTimerStart) {
        stopTimer();
      }
    },
  }));

  return (
    <div {...{ className: styles.ControlsButtons }}>
      {isShowTimer && <div {...{ className: styles.timerControl }}>{timerString}</div>}
      {isShowConfirm && (
        <CircleCallButton
          {...{
            style: { marginRight: 6 },
            color: "limegreen",
            onClick: onConfirm,
            icon: "phone",
          }}
        />
      )}
      {isShowDecline && (
        <CircleCallButton
          {...{
            style: { marginRight: 6 },
            onClick: onDecline,
            icon: "phone-off",
          }}
        />
      )}
    </div>
  );
});

ControlsButtons.defaultProps = {
  isShowDecline: false,
  isShowConfirm: false,
  onDecline: null,
  onConfirm: null,
};

export default ControlsButtons;
