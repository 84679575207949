export const singleObjectTypes = {
	SET_LOADING: "SET_LOADING",
	SET_SINGLE_OBJECT_HISTORY: "SET_SINGLE_OBJECT_HISTORY",
	RESET_SINGLE_OBJECT: "RESET_SINGLE_OBJECT",
	SET_SINGLE_OBJECT: "SET_SINGLE_OBJECT",
	UPDATE_GROUP_FIELD: "UPDATE_GROUP_FIELD",
	UPDATE_MAIN_ELEMENT_FIELD: "UPDATE_MAIN_ELEMENT_FIELD",
	SET_SINGLE_OBJECT_RELATION_OBJECTS: 'SET_SINGLE_OBJECT_RELATION_OBJECTS',
	HANDLE_OBJECT_OWNERS_IDS_CREATE_MODE: 'HANDLE_OBJECT_OWNERS_IDS_CREATE_MODE'
};
