import React, { useState, useRef, useMemo } from "react";
import { FinishStageTypesConfig, IStageExtension } from "../../../../types/extension";
import { Group } from "./Group";
import styles from "./index.module.css";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import cn from "classnames";

interface SelectDefaultProps {
  data: IStageExtension[];
  selected: IStageExtension | null;
  placeholder?: string;
  onChange: (values: IStageExtension | null) => void;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  showStatuses?: boolean;
}

const StagesConfig = {
  OPENED: "Открытые",
  SUCCESS: "Успешно",
  FAILURE: "Провалено",
};

const SelectDefaultObjectStatus = ({ showStatuses, data, selected, error, errorText, onChange, disabled, placeholder }: SelectDefaultProps) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef<any>(null);

  const handleSelectValue = (stage: IStageExtension) => {
    onChange(stage);
  };

  const groupedData = useMemo(() => {
    return data.reduce((acc, stage) => {
      switch (stage.finish_stage_type) {
        case FinishStageTypesConfig.SUCCESS:
          if (!acc[StagesConfig.SUCCESS]) {
            acc[StagesConfig.SUCCESS] = [];
          }
          acc[StagesConfig.SUCCESS].push(stage);
          break;
        case FinishStageTypesConfig.FAILURE:
          if (!acc[StagesConfig.FAILURE]) {
            acc[StagesConfig.FAILURE] = [];
          }
          acc[StagesConfig.FAILURE].push(stage);
          break;
        default:
          if (!acc[StagesConfig.OPENED]) {
            acc[StagesConfig.OPENED] = [];
          }
          acc[StagesConfig.OPENED].push(stage);
      }
      return acc;
    }, {} as Record<string, IStageExtension[]>);
  }, [data]);

  const header = () => {
    return (
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={cn("flex", styles.header, error && styles.error, disabled && "no-pointer-events cursor-default")}>
        <span className={cn("font-size-15 flex overflow-hidden block")}>
          <span
            key={selected?.id}
            className={cn(styles.value)}>
            {selected?.name}
          </span>
          <span className={cn(styles.placeholder, (focused || selected) && styles.focus)}>{placeholder || "Выбрать"}</span>
        </span>
        {errorText && <div className={styles.errorText}>{errorText}</div>}
      </div>
    );
  };

  return (
    <ClickOutsideBoundaryTemplate
      ref={ref}
      header={() => header()}
      onChange={!disabled ? (value: boolean) => setFocused(value) : undefined}
      style={{ width: "100%", userSelect: "none" }}
      disabled={disabled}>
      <div className={cn("padding-horizontal-17  flex-column default-scrollbar", styles.dropdown)}>
        {Object.entries(groupedData).map(([key, variants], index, array) =>
          variants.length > 0 ? (
            <div key={key}>
              <Group
                className={styles.groupTitle}
                title={key}
                value={selected}
                variants={variants}
                field_key="name"
                handleClick={(status) => {
                  handleSelectValue(status);
                  ref.current.handleContainer();
                }}
              />
              {index !== array.length - 1 && <span className={cn(styles.separator)} />}
            </div>
          ) : null
        )}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

export default SelectDefaultObjectStatus;
