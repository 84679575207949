export const findFirstViewByTemplate = (data, template) => {
  const array = [...data];
  let viewTemplate = array[0];
  if (data) {
    const item = array.filter((item) => item.template === template)[0];
    if (item) {
      viewTemplate = item;
    }
  }
  return viewTemplate;
};
