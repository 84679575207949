import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import SelectObjectStatus from "../../../kit/Selects/SelectObjectStatus";
import SingleObjectPriority from "../../../kit/Selects/SingleObjectPriority";
import SingleObjectUsers from "../../../kit/Selects/SingleObjectUsers";
import SingleObjectComplete from "../../../kit/Selects/SingleObjectComplete";
import SelectTag from "../../../kit/Selects/SelectTag";
import { isFieldValueValid, isValue } from "../../../../helpers/values-validator";
import EditInput from "../../Widgets/Edit/EditInput";
import { LINE_HEIGHT } from "../../../../constants/ui-constants";
import IconManager from "../../../kit/Icons/IconManager";
import { useDispatch, useSelector } from "react-redux";
import { singleObject_setOwnersIdsCreateMode, singleObject_UpdateObjectMainElementField } from "../../../../store/actions/singleObjectActions";
import { finishObjectExtensionStage } from "../../../../api/routes/extensions";
import { BAD, SUCCESS } from "../../../../helpers/response-service";

const SingleObjectMainElement = (props) => {
  const { object_id, handleUpdateSingleObjectField, isCreateMode } = props;
  const campaign_users = useSelector((state) => state.app.campaign_users);

  const __singleObjectMainElement = useSelector((state) => state.singleObject.__singleObjectMainElement);
  const __singleObjectUsers = useSelector((state) => state.singleObject.__singleObjectUsers);
  const __singleObject = useSelector((state) => state.singleObject.__singleObject);
  const navigate = useNavigate();
  const createStoreAction = useDispatch();

  const onSubmitField = (field_key, values) => {
    const field = __singleObjectMainElement[field_key];
    const fieldStoreKeys = {
      field_key,
    };

    const isError = !isFieldValueValid(field.required, field.type, values);
    const payload = {
      ...fieldStoreKeys,
      data: {
        values,
        loading: !isError && !isCreateMode ? true : undefined,
        error: isError,
      },
    };

    createStoreAction(singleObject_UpdateObjectMainElementField(payload));

    if (!payload.data.error && !isCreateMode) {
      // console.log("update");
      handleUpdateSingleObjectField(field, values)
        .then((res) => {
          createStoreAction(
            singleObject_UpdateObjectMainElementField({
              ...fieldStoreKeys,
              data: {
                error: false,
                loading: false,
              },
            })
          );
        })
        .catch((res) => {
          createStoreAction(
            singleObject_UpdateObjectMainElementField({
              ...fieldStoreKeys,
              data: {
                error: true,
                loading: false,
              },
            })
          );
        });
    }
  };

  const handleObjectOwners = (data) => {
    const ids = data ? Object.keys(data).map((str) => Number(str)) : [];
    createStoreAction(singleObject_setOwnersIdsCreateMode(ids));
  };

  const onFinishStage = (field_key, stage) => {
    const field = __singleObjectMainElement[field_key];
    const fieldStoreKeys = {
      field_key,
    };

    const isError = !isFieldValueValid(field.required, field.type, [stage.name]);
    const payload = {
      ...fieldStoreKeys,
      data: {
        values: [stage.name],
        loading: !isError && !isCreateMode ? true : undefined,
        error: isError,
      },
    };

    createStoreAction(singleObject_UpdateObjectMainElementField(payload));

    if (!payload.data.error && !isCreateMode) {
      const finishPayload = {
        query: {
          path: stage.id,
          params: new URLSearchParams({ object_id: __singleObject.id }),
        },
        data: {
          text: stage.name,
        },
      };

      finishObjectExtensionStage(finishPayload).then((res) => {
        switch (res.kind) {
          case SUCCESS:
            createStoreAction(
              singleObject_UpdateObjectMainElementField({
                ...fieldStoreKeys,
                data: {
                  error: false,
                  loading: false,
                },
              })
            );
            break;
          case BAD:
            createStoreAction(
              singleObject_UpdateObjectMainElementField({
                ...fieldStoreKeys,
                data: {
                  error: true,
                  loading: false,
                },
              })
            );
        }
      });
    }
  };

  const navigationGoBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(document.referrer);
    }
  };

  return (
    <div {...{ className: "SingleObjectMainElement flex-column" }}>
      {/*status start*/}
      {__singleObjectMainElement && __singleObjectMainElement.stage && isValue(__singleObjectMainElement.stage.choice) ? (
        <div
          {...{
            className: "SingleObjectMainElement_header flex-row align-center justify-between",
          }}>
          <div {...{ className: "SingleObjectMainElement_headerId" }}>{object_id ? "id " + object_id : ""}</div>
          <div {...{ className: "SingleObjectMainElement_headerRight flex-row " }}>
            <div {...{ className: "SingleObjectMainElement_statusRow" }}>
              <SelectObjectStatus
                {...{
                  field_key: "name",
                  data: __singleObjectMainElement.stage.choice,
                  __singleObjectMainElement: __singleObjectMainElement.stage.choice,
                  value: isValue(__singleObjectMainElement.stage.values) ? __singleObjectMainElement.stage.values : null,
                  onSubmit: (value) => {
                    onSubmitField("stage", [value]);
                  },
                  onFinishStage: (stage) => {
                    onFinishStage("stage", stage);
                  },
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {/*status end*/}

      {/*action and name start*/}
      <div
        {...{
          className: "SingleObjectMainElement_nameActions flex-row align-start justify-between",
        }}>
        <div
          {...{
            style: {
              height: 22 * LINE_HEIGHT,
              width: 22 * LINE_HEIGHT,
            },
            className: "SingleObjectMainElement_goBack",
            onClick: () => navigationGoBack(),
          }}>
          <IconManager
            {...{
              size: 14,
              color: "var(--text-tenth)",
              icon: "chevron-left",
            }}
          />
        </div>
        <div {...{ className: "w-100" }}>
          {__singleObjectMainElement && __singleObjectMainElement.title && (
            <EditInput
              {...{
                icon: __singleObjectMainElement.title.icon,
                object_id: object_id,
                fontSize: 22,
                loading: __singleObjectMainElement.title.loading ? __singleObjectMainElement.title.loading : false,
                error: __singleObjectMainElement.title.error,
                value: isValue(__singleObjectMainElement.title.values) ? __singleObjectMainElement.title.values[0] : "",
                placeholder: __singleObjectMainElement.title.placeholder ? __singleObjectMainElement.title.placeholder : "Название",
                onSubmit: (value) => onSubmitField("title", [value]),
              }}
            />
          )}
        </div>
      </div>
      {/*action and name end*/}

      {/*description start*/}
      {__singleObjectMainElement && __singleObjectMainElement.description && (
        <div {...{ style: { marginTop: 6 } }}>
          <EditInput
            {...{
              icon: __singleObjectMainElement.description.icon,
              fontSize: 15,
              color: "var(--text-sixth)",
              loading: __singleObjectMainElement.description.loading ? __singleObjectMainElement.description.loading : false,
              error: __singleObjectMainElement.description.error,
              value: isValue(__singleObjectMainElement.description.values) ? __singleObjectMainElement.description.values[0] : "",
              placeholder: __singleObjectMainElement.description.placeholder ? __singleObjectMainElement.description.placeholder : "Описание",
              onSubmit: (value) => {
                onSubmitField("description", [value]);
              },
            }}
          />
        </div>
      )}
      {/*description end*/}

      {/*tags start*/}
      {__singleObjectMainElement && __singleObjectMainElement.tags ? (
        <div>
          <SelectTag
            {...{
              onChange: (values) => {
                onSubmitField("tags", values);
              },
              style: { marginTop: 10, marginBottom: 3 },
              values: isValue(__singleObjectMainElement.tags.values) ? __singleObjectMainElement.tags.values : null,
            }}
          />
        </div>
      ) : null}
      {/*tags end*/}

      <div {...{ className: "flex-row align-center flex-wrap" }}>
        {/*priority start*/}
        {__singleObjectMainElement && __singleObjectMainElement.priority && (
          <SingleObjectPriority
            {...{
              onSubmit: (value) => {
                onSubmitField("priority", value);
              },
              data: __singleObjectMainElement.priority.choice,
              value: isValue(__singleObjectMainElement.priority.values) ? __singleObjectMainElement.priority.values[0] : null,
              style: { marginRight: 8, marginTop: 8 },
            }}
          />
        )}
        {/*priority end*/}

        {/*users start*/}
        <SingleObjectUsers
          {...{
            amount: isCreateMode ? 1 : -1,
            onChange: isCreateMode ? handleObjectOwners : null,
            object_id: object_id ? object_id : null,
            object_users: isCreateMode ? null : __singleObjectUsers,
            style: { marginRight: 8, marginTop: 8 },
            campaign_users,
          }}
        />
        {/*users end*/}

        {/*complete_at start*/}
        {__singleObjectMainElement && __singleObjectMainElement.complete_at && (
          <SingleObjectComplete
            {...{
              value: isValue(__singleObjectMainElement.complete_at.values) ? [new Date(__singleObjectMainElement.complete_at.values[0])] : null,
              onSubmit: (value) => {
                onSubmitField("complete_at", value);
              },
              style: { marginRight: 8, marginTop: 8 },
            }}
          />
        )}
        {/*complete_at end*/}
      </div>
    </div>
  );
};

export default SingleObjectMainElement;
