import React from "react";
import "./index.css";
import { getRuFullDate, getShortRuDateHoursMinutes } from "../../../../helpers/date-formatter";
import UserAvatarCircle from "../../User/UserAvatarCircle";
import LogTemplate from "../../../templates/LogTemplate";
import IconManager from "../../../kit/Icons/IconManager";
import Attachments from "../Attachments";
import { urlify } from "../../../../helpers/functions";

const HistoryLog = (props) => {
  const { data, date, user, showDate, onEditClick, onDeleteClick } = props;

  const bodyTextRef = React.useRef(null);

  // React.useEffect(() => {
  //   bodyTextRef.current.innerHTML = data?.body?.text;
  // }, []);

  // const innerText = () => {
  //   bodyTextRef.current.innerHTML = data?.body?.text;
  // };

  const HistoryLogIconContainer = () => {
    return (
      <div
        {...{
          className: "HistoryLogIconContainer",
          style: {
            backgroundColor: data?.icon?.backgroundColor,
          },
        }}>
        <IconManager {...{ size: 14, icon: data?.icon?.name, color: data?.icon?.color }} />
      </div>
    );
  };

  const HistoryLogIconDateContainer = ({ time }) => {
    return (
      <div {...{ className: "HistoryLogIconDateContainer" }}>
        <div>{time ? getRuFullDate(time) : ""}</div>
      </div>
    );
  };

  return (
    <div {...{ className: "LogTemplate_wrapper" }}>
      {showDate && <HistoryLogIconDateContainer {...{ time: date }} />}
      <LogTemplate
        {...{
          leftContainer: data?.icon?.position === "LEFT" ? <HistoryLogIconContainer /> : null,
          rightContainer: data?.icon?.position === "RIGHT" ? <HistoryLogIconContainer /> : null,
        }}>
        <div
          {...{
            className: `HistoryLog ${data?.card?.backgroundColor ? "HistoryLog_card" : ""}`,
            style: {
              ...(data?.card?.backgroundColor && {
                backgroundColor: data?.card?.backgroundColor,
                borderRadius: 6,
              }),
            },
          }}>
          {/* TITLE + USER */}
          <div {...{ className: "HistoryLog_userAndTitle" }}>
            <UserAvatarCircle
              {...{
                name: null,
                image: user?.image,
              }}
            />
            <div {...{ className: "HistoryLog_userAndTitle_title" }}>
              <span>{user?.name}</span>: {data?.title?.text}
              {/*<span className={'text-dotted-end'}>{data?.title?.text}</span>*/}
              {/*<span>{data?.title?.tag}</span>*/}
            </div>
          </div>

          {/* BODY TEXT */}
          <div {...{ className: "HistoryLog_userAndTitle_body HistoryLog_break-word" }}>
            <span
              {...{
                ref: bodyTextRef,
                className: "HistoryLog_userAndTitle_body_text",
                dangerouslySetInnerHTML: { __html: data?.body?.text ? urlify(data?.body?.text) : "" },
              }}></span>
            {/*{data?.body?.text && <span {...{ ref: bodyTextRef, className: "HistoryLog_userAndTitle_body_text" }}>{data?.body?.text}</span>}*/}
            {data?.body?.comment && <span {...{ className: "HistoryLog_userAndTitle_body_comment" }}>{data?.body?.comment}</span>}
            {data?.body?.tag && <span {...{ className: "HistoryLog_userAndTitle_body_tag" }}>{data?.body?.tag}</span>}
          </div>

          {/*ATTACH*/}
          <div {...{}}>
            <Attachments {...{ data: data?.attachments }} />
          </div>

          {onEditClick && (
            <div {...{ className: "HistoryLog_editComment", onClick: onEditClick }}>
              <IconManager {...{ icon: "pencil", size: 13, color: "var(--text-seventh)" }} />
            </div>
          )}
          {onDeleteClick && (
            <div {...{ className: "HistoryLog_deleteComment", onClick: onDeleteClick }}>
              <IconManager {...{ icon: "trash", size: 13, color: "var(--text-seventh)" }} />
            </div>
          )}

          {/* TIME */}
          <div {...{ className: "HistoryLog_userAndTitle_timeHours" }}>
            <span>{getShortRuDateHoursMinutes(new Date(date))}</span>
          </div>
        </div>
      </LogTemplate>
    </div>
  );
};

export default HistoryLog;

HistoryLog.defaultProps = {
  data: null,
  showDate: false,
};
