import React, {useEffect} from "react";
import "./index.css";
import { Outlet } from "react-router-dom";
import SideMenu from "../../components/elements/SideMenu";
import { getSchemas } from "../../api/routes/objects_core";
import { SUCCESS } from "../../helpers/response-service";
import { setSchemasRoutes } from "../../store/actions/appActions";
import { useDispatch } from "react-redux";
import OutgoingCallContainer from "../../components/extensions/CallExtension/OutgoingCall";
import IncomingCallContainer from "../../components/extensions/CallExtension/IncomeCall";
import ImageViewer from "../../components/elements/ImageViewer/ImageViewer";
import {setIsFocused} from "../../store/reducers/windowSlice";

const LoggedLayout = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();

  const getSchemasByCampaign = () => {
    let pull = [];
    let object = {};
    getSchemas().then((result) => {
      switch (result.kind) {
        case SUCCESS:
          result.data.map((schema) => {
            pull.push(getFormattedSchema(schema));
            object = { ...object, ...getSchemaById(schema) };
          });
          const payload = {
            schemas: pull,
            schemas_object: object,
          };
          dispatch(setSchemasRoutes(payload));
          setTimeout(() => {
            setLoading(false);
          });
      }
    });
  };

  const getFormattedSchema = (schema) => {
    return {
      ...schema,
      fields: schema.fields,
      key: schema.path,
      menu: !!schema.meta_name,
    };
  };

  const getSchemaById = (schema) => {
    return {
      [schema.id]: schema.path,
    };
  };

  useEffect(() => {
    dispatch(setIsFocused(windowIsFocused()))
    window.addEventListener('focus', onFocusWindow, true)
    window.addEventListener('blur', onBlurWindow, true)

    return () => {
      window.removeEventListener('focus', onFocusWindow, true)
      window.removeEventListener('blur', onBlurWindow, true)
    }
  }, []);

  function windowIsFocused() {
    return document.hasFocus() || document.getElementById('iframe')?.contentWindow?.document?.hasFocus();
  }

  const onFocusWindow = (event) => {
    // console.log('focus', "isFocused", )
    dispatch(setIsFocused(!!windowIsFocused()))
  }

  const onBlurWindow = (event) => {
    // console.log('blur', "isFocused", )
    dispatch(setIsFocused(!!windowIsFocused()))
  }

  React.useEffect(() => {
    getSchemasByCampaign();
  }, []);

  return (
      <div {...{ className: "flex" }}>
        <SideMenu />
        {!loading && (
            <>
              <div {...{ className: "MainLayout_workWrapper flex" }}>
                <Outlet />
              </div>
              <IncomingCallContainer {...{ isActive: true }} />
              <OutgoingCallContainer />
              <ImageViewer />
            </>
        )}
      </div>
  );
};

export default LoggedLayout;
