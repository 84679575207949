import React, { ChangeEvent, KeyboardEvent, forwardRef, useRef, useState, useEffect } from "react";
import "./index.css";
import { FIELD_TYPES } from "../../../../constants/types";
import { removeLetters } from "../../../../helpers/phone-formatter";
import { formatPrice } from "../../../../helpers/price-formatter";

interface DefaultInputPlaceholderProps {
  value: string;
  disabled: boolean;
  placeholder: string;
  error: boolean;
  errorText: string;
  onChange: (value: string) => void;
  type: string;
  onSubmit: (value: string) => void;
  style: object;
  focused?: boolean;
  onFocus: (focus: boolean) => void;
}

const DefaultInputPlaceholder = forwardRef<HTMLInputElement, Partial<DefaultInputPlaceholderProps>>((props, parentRef) => {
  const { disabled, value, placeholder, error, errorText, onChange, type, onSubmit, style, focused, onFocus } = props;

  const [_focused, setFocused] = useState(() => focused || false);
  const ref = React.useRef<HTMLInputElement>(null);
  const [data, setData] = React.useState(value);

  // const currentFocus = focused ?? _focused;
  //
  // console.log(currentFocus);

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = formatValueByType(e.target.value);
    setData(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    if (value !== data) {
      setData(value);
    }
  }, [value]);

  useEffect(() => {
    onFocus?.(_focused);
  }, [_focused]);

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      ref.current?.blur();
    }
  };

  const formatValueByType = (value: string) => {
    switch (type) {
      case FIELD_TYPES.NUMBER:
        return value ? removeLetters(value) : "";
      case FIELD_TYPES.PRICE:
        return value ? formatPrice(value) : "";
      default:
        return value;
    }
  };

  const onSubmitField = () => {
    onSubmit && onSubmit(ref.current?.value || "");
  };

  const onBlur = () => {
    onSubmitField();
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };
  return (
    <div
      {...{
        className: `DefaultInputPlaceholderContainer flex-row ${error && "DefaultInputPlaceholderError"} ${
          _focused || data ? "DefaultInputPlaceholderContainerActive" : ""
        }`,
        style: { ...style, opacity: disabled ? 0.7 : 1 },
      }}>
      <label
        {...{
          className: `DefaultInputPlaceholder_label ${error && "DefaultInputPlaceholderError_text"}`,
        }}>
        {!!placeholder ? placeholder : "Введите данные"}
      </label>
      <input
        {...{
          className: `DefaultInputPlaceholder_input flex align-center ${error && "DefaultInputPlaceholderError_text"}`,
          value: data,
          ref,
          disabled,
          onKeyPress,
          type: "text",
          onChange: (e) => onChangeValue(e),
          onFocus: handleFocus,
          onBlur,
        }}
      />
      {errorText && <div {...{ className: "DefaultInputPlaceholderContainerErrorText" }}>{errorText}</div>}
    </div>
  );
});

DefaultInputPlaceholder.displayName = "DefaultInputPlaceholder";
export default DefaultInputPlaceholder;
