import { useSelector } from "react-redux";
import SingleObjectFieldsGroup from "../SingleObjectFieldsGroup";
import { useParams } from "react-router-dom";
import * as _ from "lodash"

const SingleObjectFieldsGroupIteration = (props) => {
	const { groups, isCreateMode, handleUpdateSingleObjectField, objectSchemaId } = props;
	const {object_id} = useParams()

	const __singleObjectGroups = useSelector((state) => state.singleObject.__singleObjectGroups);

	if (groups && __singleObjectGroups) {
		return Object.keys(groups).map((group_key, index) => {
			return (
				<SingleObjectFieldsGroup
					{...{
						isCreateMode,
						object_id,
						handleUpdateSingleObjectField,
						data: __singleObjectGroups[group_key],
						store_key: group_key,
            objectSchemaId,
						key: _.uniqueId("__single_object_group_key"),
						...(index === Object.keys(groups).length - 1 && {
							style: lastGroupStyle,
						}),
					}}
				/>
			);
		});
	} else return null;
};

export default SingleObjectFieldsGroupIteration;

const lastGroupStyle = {
	borderBottomRightRadius: 6,
	borderBottomLeftRadius: 6,
	marginBottom: 14,
};
