import CallExtension_FieldView from "./CallExtension_FieldView";

export const 	CALL_EXTENSION = {
	MULTIPLE_VIEW: {
		component: () => null
	},
	SINGLE_VIEW: {
		component: () => null
	},
	ELEMENT_VIEW: {
		component: () => null
	},
	FIELD_VIEW: {
		component: (props) => <CallExtension_FieldView {...props}/>
	}
}
