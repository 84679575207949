import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  x: 0,
  isDrag: false,
};

const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    setX: (state, action) => {
      state.x = (action.payload * 63) / 1083;
    },
  },
});

export const { setX } = positionSlice.actions;
export const positionReducer = positionSlice.reducer;
