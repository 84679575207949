import React from "react";
import "./index.css";
import ConfigIcon from "../../../kit/Icons/ConfigIcon";
import { FaUserAlt } from "react-icons/fa";

const UserAvatarCircle = (props) => {
	const { name, image, onlyName = false, style } = props;
	return (
		<div
			{...{
				className: `UserAvatarCircle flex align-center `,
			}}
		>
			{!onlyName && image && (
				<img
					{...{
						alt: "user image",
						className: `UserAvatarCircle_image margin-right-6`,
						src: image,
						style: {
							...style,
							width: !!style && !!style.width ? style.width : 20,
							height: !!style && !!style.width ? style.width : 20,
						},
					}}
				/>
			)}
			{!onlyName && !image && (
				<div
					{...{
						className: `UserAvatarCircle_noImage`,
						style: {
							...style,
							width: !!style && !!style.width ? style.width : 20,
							height: !!style && !!style.width ? style.width : 20,
						},
					}}
				>
					<ConfigIcon {...{ color: "#C3C3C3", size: 14, style: { marginTop: 4 } }}>
						<FaUserAlt />
					</ConfigIcon>
				</div>
			)}
			{name && <span {...{ className: `UserAvatarCircle_text margin-left-6` }}>{name}</span>}
		</div>
	);
};

export default UserAvatarCircle;
