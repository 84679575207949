import React from "react";
import "./index.css";
import ConfigIcon from "../Icons/ConfigIcon";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const today = new Date();

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      lines: [],
      currentMinutes: 0,
      currentHours: 10,
      selected: {
        day: this.props.count ? this.props.count.getDate() : null,
        month: this.props.count ? this.props.count.getMonth() : null,
        year: this.props.count ? this.props.count.getFullYear() : null,
        hours: this.props.count ? this.props.count.getHours() : null,
        minutes: this.props.count ? this.props.count.getMinutes() : null,
      },
      currentDay: this.props.count ? this.props.count.getDate() : today.getDate(),
      currentMonth: this.props.count ? this.props.count.getMonth() : today.getMonth(),
      currentYear: this.props.count ? this.props.count.getFullYear() : today.getFullYear(),
      totalDate: this.props.count ? this.props.count : today,
    };
    this.handleNextMonth = this.handleNextMonth.bind(this);
    this.handlePrevMonth = this.handlePrevMonth.bind(this);
    this.getDate = this.getDate.bind(this);
  }

  handleNextMonth = (month) => {
    if (month < 11) {
      this.setState({ currentMonth: month + 1, currentDay: 1 });
      this.getDaysInMonth(this.state.currentYear, month + 1);
      this.handleSetTotalDate(this.state.currentYear, month + 1, this.state.currentDay, this.state.currentHours, this.state.currentMinutes);
    } else {
      this.setState({
        currentMonth: 0,
        currentYear: this.state.currentYear + 1,
        currentDay: 1,
      });
      this.getDaysInMonth(this.state.currentYear + 1, 0);
      this.handleSetTotalDate(this.state.currentYear + 1, 0, this.state.currentDay, this.state.currentHours, this.state.currentMinutes);
    }
  };

  handlePrevMonth = (month) => {
    if (month > 0) {
      this.setState({ currentMonth: month - 1, currentDay: 1 });
      this.getDaysInMonth(this.state.currentYear, month - 1);
      this.handleSetTotalDate(this.state.currentYear, month - 1, this.state.currentDay, this.state.currentHours, this.state.currentMinutes);
    } else {
      this.setState({
        currentMonth: 11,
        currentYear: this.state.currentYear - 1,
        currentDay: 1,
      });
      this.getDaysInMonth(this.state.currentYear - 1, 11);
      this.handleSetTotalDate(this.state.currentYear - 1, 11, this.state.currentDay, this.state.currentHours, this.state.currentMinutes);
    }
  };

  handleSetDay = (day) => {
    this.setState({ currentDay: day.getDate() });
    this.handleSetTotalDate(this.state.currentYear, this.state.currentMonth, day.getDate(), this.state.currentHours, this.state.currentMinutes);
  };

  handleSetTotalDate = (year, month, day, hours, minutes) => {
    const date = new Date(year, month, day, hours, minutes);
    this.props.onChange && this.props.onChange(date);
    this.props.onChangeDay && this.props.onChangeDay(date);
    this.setState({
      totalDate: date,
    });
  };

  handleSetTotalDateByTime = (hours, minutes) => {
    const date = new Date(this.state.currentYear, this.state.currentMonth, this.state.currentDay, hours, minutes);
    this.props.onChange && this.props.onChange(date);
    this.props.onChangeTime && this.props.onChangeTime(date);
    this.setState({
      totalDate: date,
    });
  };

  handleSetHours = (value) => {
    let hours = value.replace(/\D/g, "") * 1;
    if (hours < 0) {
      this.setState({ currentHours: 0 });
      this.handleSetTotalDateByTime(0, this.state.currentMinutes);
    } else if (hours > 23) {
      this.setState({ currentHours: 23 });
      this.handleSetTotalDateByTime(23, this.state.currentMinutes);
    } else {
      this.setState({ currentHours: hours });
      this.handleSetTotalDateByTime(hours, this.state.currentMinutes);
    }
  };

  handleSetMinutes = (value) => {
    let minutes = value.replace(/\D/g, "") * 1;
    if (minutes < 0) {
      this.setState({ currentMinutes: 0 });
      this.handleSetTotalDateByTime(this.state.currentHours, 0);
    } else if (minutes > 59) {
      this.setState({ currentMinutes: 59 });
      this.handleSetTotalDateByTime(this.state.currentHours, 59);
    } else {
      this.setState({ currentMinutes: minutes });
      this.handleSetTotalDateByTime(this.state.currentHours, minutes);
    }
  };

  getDaysInMonth = (year_prop, month_prop) => {
    const year = year_prop ? year_prop : this.state.currentYear;
    const month = month_prop !== undefined ? month_prop : this.state.currentMonth;
    let date = new Date(year, month, 1);
    let days = [];
    let lines = [1, 2, 3, 4, 5];
    let start = WEEK_DAYS.filter((item) => date.toLocaleString("ru", { weekday: "short" }) === item.name.toLowerCase());
    let start_week_day = start[0].key;
    let i = 1;
    while (date.getMonth() === month) {
      if (i < start_week_day) {
        days.push(null);
      } else {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      i++;
    }
    if (days.length > 35) {
      lines = [1, 2, 3, 4, 5, 6];
    }
    this.setState({ lines });
    this.setState({ days });
  };

  componentDidMount() {
    this.getDaysInMonth();
  }

  getDate = () => {
    return this.state.totalDate;
  };

  render() {
    return (
      <div
        {...{
          className: "SelectDate_wrapper w-100 flex align-center flex-column",
        }}>
        <div
          {...{
            className: "SelectDate_header w-100 flex justify-between align-center",
          }}>
          <div
            {...{
              className: "SelectDate_headerControl flex align-center justify-start pointer",
              onClick: () => this.handlePrevMonth(this.state.currentMonth),
            }}>
            <ConfigIcon {...{ size: 18 }}>
              <GrFormPrevious />
            </ConfigIcon>
          </div>
          <div {...{ className: "SelectDate_headerCurrentMothYear font-size-14" }}>
            {MONTHS_KEYS[this.state.currentMonth] + " " + this.state.currentYear}
          </div>
          <div
            {...{
              className: "SelectDate_headerControl flex align-center justify-end pointer",
              onClick: () => this.handleNextMonth(this.state.currentMonth),
            }}>
            <ConfigIcon {...{ size: 18 }}>
              <GrFormNext />
            </ConfigIcon>
          </div>
        </div>
        <div
          {...{
            className: "SelectDate_monthBody margin-bottom-10 flex justify-center",
          }}>
          <table {...{ className: "w-100", style: { minHeight: 316 } }}>
            <thead>
              <tr {...{ className: "flex" }}>
                {WEEK_DAYS.map((week_day, key) => (
                  <td {...{ key, className: "font-size-14 flex-center" }}>{week_day.name}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.lines
                ? this.state.lines.map((line, line_index) => {
                    return (
                      <tr
                        {...{
                          key: `${line_index}-line-${line}-${line}`,
                          className: "flex ",
                        }}>
                        {this.state.days
                          ? this.state.days.map((day, day_index) => {
                              if (day_index > 7 * line_index - 1 && day_index < 7 * (line_index + 1)) {
                                return (
                                  <td
                                    {...{
                                      key: `${line_index}-day-${day_index}`,
                                      onClick:
                                        day !== null
                                          ? () => {
                                              this.handleSetDay(day);
                                              this.props.onClickDay && this.props.onClickDay();
                                            }
                                          : null,
                                      className: `${this.state.days[day_index + 1] !== null && "lastEmptyDay"} ${day !== null && "defaultDay"} ${
                                        day === null && "emptyDay"
                                      } ${day !== null && day.getDate() === this.state.currentDay && "activeDay"} flex-center`,
                                    }}>
                                    {day !== null ? day.getDate() : ""}
                                  </td>
                                );
                              }
                            })
                          : null}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        {this.props.time ? (
          <>
            <div
              {...{
                className: "SelectDate_timeContainer flex align-center w-100",
              }}>
              <div
                {...{
                  className: "SelectDate_timeContainer_item border-radius-6 padding-17 w-100 flex align-center justify-between margin-right-10",
                }}>
                <div
                  {...{
                    className: "SelectDate_timeContainer_itemTitle font-size-14",
                  }}>
                  Час
                </div>
                <input
                  {...{
                    className: "SelectDate_timeContainer_itemInput",
                    placeholder: 0,
                    onChange: (e) => this.handleSetHours(e.target.count),
                    value: this.state.currentHours,
                    type: "text",
                  }}
                />
              </div>
              <div
                {...{
                  className: "SelectDate_timeContainer_item border-radius-6 padding-17 w-100 flex align-center justify-between",
                }}>
                <div
                  {...{
                    className: "SelectDate_timeContainer_itemTitle font-size-14",
                  }}>
                  Минут
                </div>
                <input
                  {...{
                    className: "SelectDate_timeContainer_itemInput",
                    placeholder: 0,
                    onChange: (e) => this.handleSetMinutes(e.target.count),
                    value: this.state.currentMinutes,
                    type: "text",
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default Calendar;

export const MONTHS_KEYS = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

export const WEEK_DAYS = [
  {
    name: "Пн",
    key: 1,
  },
  {
    name: "Вт",
    key: 2,
  },
  {
    name: "Ср",
    key: 3,
  },
  {
    name: "Чт",
    key: 4,
  },
  {
    name: "Пт",
    key: 5,
  },
  {
    name: "Сб",
    key: 6,
  },
  {
    name: "Вс",
    key: 7,
  },
];
