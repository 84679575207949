// @ts-nocheck
import { IFilterPayload, ISortPayload } from "../../store/reducers/reportsSlice";
import { IFilter, IStats, IStatsMeta } from "../../types/stats";
import { ApiEndpoint, RESPONSE_TYPE_JSON } from "../api-service";
import * as stat from "../entry-points";
import { api } from "../index";
import { BaseResponse } from "../interfaces/common";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

export interface IFilters {
  sort?: ISortPayload;
  filter_fields?: IFilterPayload[];
  filter_schema?: [{ id: number }];
}

export interface GetStatisticPayload {
  prefix: string;
  query: string;
  body: IFilters | null;
}

const getStatistic: (payload: GetStatisticPayload, signal?: AbortSignal) => BaseResponse<IStats> = (payload, signal) =>
  loginEndpoint.request(stat.GET_STATISTICS(payload), payload.body, "post", undefined, signal);
const getStatisticFilters: () => BaseResponse<IFilter[]> = () => loginEndpoint.request(stat.GET_STATISTIC_FILTERS, null, "get");
const getStatisticMeta: (signal?: AbortSignal) => BaseResponse<IStatsMeta[]> = (signal) =>
  loginEndpoint.request(stat.GET_ALL_STATS, null, "get", undefined, signal);

export { getStatistic, getStatisticMeta, getStatisticFilters };
