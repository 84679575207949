import React from "react";
import DefaultInput from "../../../components/kit/Inputs/DefaultInput";
import DefaultButton from "../../../components/kit/Buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";

// User SignIn Schema that contains 2 fields
const user_auth = {
  name: {
    placeholder: "Имя",
    value: "",
    error: null,
    type: "text",
    required: true,
  },
  email: {
    placeholder: "Почта",
    value: "",
    error: null,
    type: "text",
    required: true,
  },
  password: {
    placeholder: "Пароль",
    value: "",
    error: null,
    type: "password",
    required: true,
  },
  repeat_password: {
    placeholder: "Повторите пароль",
    value: "",
    error: null,
    type: "password",
  },
};

const SignUp = () => {
  const [user, setUser] = React.useState(user_auth);
  const [remember, setRemember] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { registerAction, verifyAction } = useAuth();

  const handleInputChange = (key, value) => {
    handleInputError(key, null);
    setUser((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value },
    }));
  };

  const handleInputError = (key, error) => {
    setUser((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], error },
    }));
  };

  /**
   * @param {object} data
   */
  const setUserCredentials = (data) => {
    localStorage.setItem("access_token", data.payload);
    verifyAction()
      .then(() => {
        navigate("/");
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  const handleSignUpUser = () => {
    let valid = true;
    Object.keys(user).map((key) => {
      if (!user[key].value) {
        handleInputError(key, "Заполните поле");
        valid = false;
      }
    });
    if (user.repeat_password.value !== user.password.value) {
      valid = false;
      handleInputError("repeat_password", "Пароли должны совпадать");
      handleInputError("password", "Пароли должны совпадать");
    }
    if (valid) {
      let body = {};
      Object.keys(user).map((key) => {
        if (user[key].required) {
          body[key] = user[key].value.toString();
        }
      });
      setLoading(true);
      registerAction(body)
        .then((result) => {
          setUserCredentials(result.data);
        })
        .catch((e) => {
          // console.log(e);
          Object.keys(user).map((key) => {
            handleInputError(key, "Данные введены неверно");
          });
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    }
  };

  return (
    <div {...{ className: "AuthFormContainer flex-column" }}>
      <h1>Регистрация</h1>
      {Object.keys(user).map((field) => (
        <div {...{ key: field }}>
          <DefaultInput
            {...{
              style: { marginTop: 25 },
              type: user[field].type,
              value: user[field].value,
              placeholder: user[field].placeholder,
              onChange: (value) => handleInputChange(field, value),
              error: !!user[field].error,
              errorText: user[field].error,
            }}
          />
        </div>
      ))}
      <DefaultButton
        {...{
          style: { marginTop: 30 },
          text: "Зарегистрироваться",
          loading,
          onClick: handleSignUpUser,
        }}
      />
      <div
        {...{
          className: "AuthFormContainer_orAnother flex-row align-center justify-center",
        }}>
        <div></div>
        <div>или</div>
      </div>
      <DefaultButton
        {...{
          onClick: () => navigate("/auth/sign-in"),
          text: "Войти",
          border: true,
        }}
      />
    </div>
  );
};

export default SignUp;
