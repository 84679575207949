import React from 'react';
import './index.css';
import { useNavigate } from "react-router-dom";
import { TbArrowLeft } from "react-icons/tb";
import IconButton from "../../../../components/kit/Buttons/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
	approveObjectStage,
	getApproveObjectsByStages,
	getMultipleViewExtensionsConfig
} from "../../../../api/routes/extensions";
import { BAD, SUCCESS } from "../../../../helpers/response-service";
import SelectDesk from "../../../../components/kit/Selects/SelectDesk";
import { MULTIPLY_VIEWS } from "../../../../constants/multiply-views";
import { VIEW_TYPES } from "../../../../constants/types";
import { multiplyObjects_InitData } from "../../../../store/reducers/multiplyObjectsReducer";
import SkeletonView from "../../../../components/kit/Loaders/SkeletonView";
import { isValue } from "../../../../helpers/values-validator";
import { setMultiplyViewStageExtension } from "../../../../store/reducers/extensionsReducer";
import Page404 from "../../../error/Page404";

const Extension_ApprovePage = (props) => {

	const {
		schema
	} = props

	const navigate = useNavigate()
	const [loading, setLoading] = React.useState(false)
	const storeStages = useSelector(state => state.extensions.__stageExtensionConfig.data)
	const [stages, setStages] = React.useState(null)
	const objects = useSelector(state => state.multiplyObjects.__groupedObjects)
	const [selectedChoice, setSelectedChoice] = React.useState(null)
	const createStoreAction = useDispatch()

		// .filter(item => !!item.need_approve)

	React.useEffect(() => {
		if(storeStages) {
			setFilteredStages(storeStages)
		} else {
			getExtensionsConfigData().then((result) => {
				switch (result.kind) {
					case SUCCESS:
						setFilteredStages(result.data)
						break;
					case BAD:
				}
			})
			// goBack()
		}
	}, [])

	const getNeedApproveStages = (array) => {
		return array.filter(item => !!item.need_approve)
	}

	const setFilteredStages = (data) => {
		const array = getNeedApproveStages(data)
		setStages(array)
		onSelectChoice(array[0])
	}

	const getApproveObjects = (choice_id) => {
		const payload = {
			query: {
				path: choice_id,
				params: {
					page: 0,
					limit: 100
				}
			},
			data: null
		}
		payload.query.params = new URLSearchParams(payload.query.params).toString()
		setLoading(true)
		getApproveObjectsByStages(payload)
			.then(result => {
				switch (result.kind) {
					case SUCCESS:
						createStoreAction(multiplyObjects_InitData({data: result.data}))
						break;
					case BAD:
						createStoreAction(multiplyObjects_InitData({data: null}))
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const getExtensionsConfigData = () => {
		return new Promise((resolve, reject) => {
			const payload = {
				query: { schema_id: schema.id },
				data: null
			};
			payload.query = new URLSearchParams(payload.query).toString();
			getMultipleViewExtensionsConfig(payload).then(result => {
				switch (result.kind) {
					case SUCCESS:
						const isFilledArray = isValue(result.data)
						const payload = {
							kind: isFilledArray ? SUCCESS : BAD,
							data: isFilledArray ? result.data : null
						}
						createStoreAction(setMultiplyViewStageExtension(payload.data))
						isFilledArray ? resolve(payload) : reject(payload)
						break;
					case BAD:
						reject({
							kind: BAD,
							data: null
						})
						break;
				}
			})
		})
	}

	const handleApprove = (info, revertAction) => {
		const approve_type = APPROVE_STATUS_ACTIONS[info.to_section.value]
		if(!approve_type || typeof approve_type === 'undefined') {
			setTimeout(() => {
				revertAction()
			}, 300)
			return;
		}
		const payload = {
			query: {
				object_id: info.drag_item_id,
				approve_type
			},
			data: null
		};
		payload.query = new URLSearchParams(payload.query).toString()
		approveObjectStage(payload)
			.then((result) => {
				switch (result.kind) {
					case SUCCESS:
						break;
					case BAD:
						revertAction()
				}
			})
			.catch((e) => {
				console.log(e)
				revertAction()
			})
	};

	const onSelectChoice = (value) => {
		// console.log('onSelectChoice', value)
		if(!value || !value.choice_id) {
			return;
		}
		setSelectedChoice(value)
		getApproveObjects(value.choice_id)
	}

	const goBack = () => {
		navigate(-1)
	}

	return (
		<div {...{className: "Extension_ApprovePage flex-column MultiplyView"}}>
			<div {...{className: "Extension_ApprovePage_header flex-row justify-center"}}>
				<div {...{className: 'Extension_ApprovePage_headerGoBack'}}>
					<IconButton
						{...{
							onClick: () => goBack(),
							color: "#333",
							icon: <TbArrowLeft />,
							size: 24,
							style: { background: "var(--card-background)", height: 60, width: 60 },
							width: 60,
							height: 60
						}}
					/>
				</div>
				<div {...{className: 'Extension_ApprovePageTitle'}}>
					<span>Модерация</span>
				</div>
				<SelectDesk {...{
					width: 260,
					style: {marginLeft: 6},
					data: stages,
					value: selectedChoice,
					onChange: onSelectChoice
				}}/>
			</div>
			<div {...{className: 'MultiplyView_dataContainer'}}>
				{
					!loading ? isValue(stages) && objects ?
					MULTIPLY_VIEWS[VIEW_TYPES.BILLBOARD].component({
						data: objects.grouped_objects.filter(column => column.grouped_by.value !== NO_APPROVE),
						action: handleApprove,
						multiply_view_id: null,
						schemaFields: null,
						schema_id: schema.id,
						schema_key: schema.key,
						extensions: false
					}) : <Page404/> : <SkeletonView {...{ view: VIEW_TYPES.BILLBOARD }} />
				}
			</div>
		</div>
	)
}

export default Extension_ApprovePage

const APPROVE_STATUS_ACTIONS = {
	CONFIRMED: 'CONFIRM',
	DECLINED: 'DECLINE'
}

const NO_APPROVE = 'NO_APPROVE'
