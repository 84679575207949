import "./index.css";
import * as _ from "lodash"
import {VIEW_WIDGETS_PULL} from "@/constants/widgets.tsx";

const RowCardFieldsIteration = (props) => {
  const { fields, max } = props;

  return fields.map((row) => {
    return (
      <div
        {...{
          key: _.uniqueId("row"),
          className: "RowCardFieldsIteration_table",
        }}>
        {row &&
          Object.values(row).map((field, index) => {
            if (Object.keys(VIEW_WIDGETS_PULL).includes(field.view_widget) && index < max) {
              return (
                <div
                  {...{
                    key: _.uniqueId("field") + field.id,
                    className: "RowCardFieldsIteration_tableCell",
                  }}>
                  {VIEW_WIDGETS_PULL[field.view_widget].component(field)}
                </div>
              );
            } else return null;
          })}
      </div>
    );
  });
};

RowCardFieldsIteration.defaultProps = {
  max: 50,
};

export default RowCardFieldsIteration;
