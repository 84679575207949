import React from "react";
import "./index.css";
import { DefaultPlayer } from "../../../DefaultPlayer/DefaultPlayer";
// import IconManager from "../../../../kit/Icons/IconManager";

const AudioLog = (props) => {
  const { url } = props;

  if (!url || url.includes("dev")) {
    return null;
  }

  return (
    <div {...{ className: "AudioLog" }}>
      <DefaultPlayer
        audioSrc={url}
        preload={false}
      />
    </div>
  );
};

export default AudioLog;
