import dayjs from "dayjs";

// Returns date "DD.MM.YYYY" format
export const getShortRuDate = (date: DateValue) => {
  return dayjs(date).format("DD.MM.YYYY");
};

// Returns date "HH:MM" format
export const getShortRuDateHoursMinutes = (date: DateValue) => {
  return dayjs(date).format("HH:mm");
};

// Returns date "DD.MM.YYYY - DD.MM.YYYY" or "DD.MM - DD.MM" formats
export const getRangeRuDate = (start: DateValue, end: DateValue, year: boolean) => {
  start = dayjs(start).format(year ? "DD:MM:YYYY" : "DD:MM");
  end = dayjs(end).format(year ? "DD:MM:YYYY" : "DD:MM");
  return `${start} - ${end}`;
};

// Returns date "DD.MM.YYYY"
export const getRuTimeFromToday = (value: DateValue) => {
  return dayjs().calendar(value, {
    lastDay: "[Вчера в] HH:mm",
    sameDay: "[Сегодня в] HH:mm",
    nextDay: "[Завтра в] HH:mm",
    lastWeek: "[В следующий] dddd[, в] HH:mm",
    nextWeek: "[В прошлый] dddd[, в] HH:mm",
    sameElse: () => `${dayjs(value).fromNow()} в ${dayjs(value).format("HH:mm")}`,
  });
};

export const getRuFullDateTime = (time: Nullish<DateValue>) => {
  if (!time) {
    return "";
  }
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hours: "numeric",
    minutes: "numeric",
    timezone: "UTC",
  };
  const date = new Date(time);
  return date.toLocaleString("ru", options as any);
};

export const getRuFullDate = (time: DateValue) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    timezone: "UTC",
  };
  const date = new Date(time);
  return date.toLocaleString("ru", options as any);
};
