import React from "react";
import Router from "./router";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/reset.css";
import "./assets/styles/fonts.css";
import "./assets/styles/variables.css";

import "./assets/styles/mixins.css";
import "./assets/styles/app.css";

import { AuthProvider } from "./providers/AuthProvider";

import store from "./store/store";

import "../src/constants/dayjsConfig";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
            <Router />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              limit={8}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
