import { EDIT_WIDGETS_PULL } from "@/constants/widgets.tsx";
import { EXTENSIONS } from "../../../extensions";
import { isValue } from "@/helpers/values-validator.js";
import * as _ from "lodash"
import React from "react";

const SingleObjectEditFieldsIteration = (props) => {
  const { fields, object_id, onSubmit, onChange, handleScrollableDivs, includeExtensions, excludeIds, schemaKey } = props;

  const WIDGET = (field, field_key) =>
    React.useMemo(() => {if(!field.show) return null;
      if (isValue(excludeIds) && excludeIds.includes(field.id)) {
        return null;
      }
      const EDIT_WIDGET = field.edit_widget;
      const extension = field.extensions_type;
      if (Object.keys(EDIT_WIDGETS_PULL(false, null, null)).includes(EDIT_WIDGET)) {
        if (includeExtensions && extension && ["MESSAGE", "TELEPHONY"].includes(extension.toUpperCase())) {
          return (
            <div
              {...{
                key: _.uniqueId("__edit__row__item"),
              }}>
              {EXTENSIONS[extension]?.FIELD_VIEW?.component({
                data: field,
                object_id,
                schemaKey,
              })}
            </div>
          );
        } else
          return (
            <div
              {...{
                className: "flex-column",
                style: { marginTop: 5 },
                key: _.uniqueId("__object__edit__fields"),
              }}>
              {EDIT_WIDGETS_PULL(
                true,
                schemaKey,
                (field, value) => (onChange ? onChange(field_key, field, value) : false),
                (field, value) => (onSubmit ? onSubmit(field_key, field, value) : false),
                handleScrollableDivs
              )[EDIT_WIDGET].component(field)}
            </div>
          );
      } else return null;
    }, [field.values, field.error, field.loading]);

  return (
    <div
      {...{
        className: "flex-column",
      }}>
      {isValue(fields) && Object.keys(fields).map((field_key) => WIDGET(fields[field_key], field_key))}
    </div>
  );
};

export default SingleObjectEditFieldsIteration;

SingleObjectEditFieldsIteration.defaultProps = {
  fields: null,
  onChange: null,
  onSubmit: null,
  handleScrollableDivs: true,
  includeExtensions: true,
};
