import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../../kit/Icons/Svgs/LogoIcon";

const LogoContainer = () => {
  return (
    <div
      {...{
        className: "SideMenu_companyLogo flex align-center justify-center",
      }}>
      <Link {...{ to: "/" }}>
        <LogoIcon />
      </Link>
    </div>
  );
};

export default LogoContainer;
