import { toast } from "react-toastify";

const successToast = (message, options) => {
  toast(message, { type: "success", theme: "colored", position: "bottom-left", ...options });
};

const errorToast = (message, options) => {
  toast(message, { type: "error", theme: "colored", position: "bottom-left", ...options });
};

const infoToast = (message, options) => {
  toast(message, { type: "info", theme: "colored", position: "bottom-left", ...options });
};

const warringToast = (message, options) => {
  toast(message, { type: "warning", theme: "colored", position: "bottom-left", ...options });
};

const defaultPromiseToast = {
  pending: "Подождите...",
  error: "Произошла ошибка",
  success: "Успешно",
};

export const promiseToast = (notify, params, options) => {
  return toast.warning(
    notify,
    { ...defaultPromiseToast, ...params },
    {
      theme: "colored",
      position: "bottom-left",
      ...options,
    }
  );
};

export { infoToast, successToast, errorToast, warringToast };
