import { useRef } from "react";

export const useThrottledFn = (fn: () => void, ms: number = 200) => {
  const timeout = useRef(false);

  return () => {
    if (timeout.current) {
      return;
    }
    fn();
    timeout.current = true;
    setTimeout(() => (timeout.current = false), ms);
  };
};
