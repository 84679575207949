import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserRolePermission } from "../../api/interfaces/role";
import { IPactUser } from "../../types/extension";
import { RoleResponse } from "../../types/role";
import { ISchema } from "../../types/schema";

interface IInitialState {
  shouldRefetch: boolean;
  selectedRoleId: number | null;
  pactUsers: IPactUser[];
  fullUsers: IUserRolePermission[];
  roles: RoleResponse[];
  selectedUserId: number | null;
  schemas: SchemaPayload[] | null;
}

export type SchemaPayload = Omit<ISchema, "fields">;

const initialState: IInitialState = {
  selectedRoleId: null,
  shouldRefetch: false,
  selectedUserId: null,
  pactUsers: [],
  fullUsers: [],
  roles: [],
  schemas: null,
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    refetch: (state) => {
      state.shouldRefetch = !state.shouldRefetch;
    },
    setPactUsers: (state, action: PayloadAction<IPactUser[]>) => {
      state.pactUsers = action.payload;
    },
    setFullUsers: (state, action: PayloadAction<IUserRolePermission[]>) => {
      state.fullUsers = action.payload;
    },
    setSelectedUserId: (state, action: PayloadAction<number | null>) => {
      state.selectedUserId = action.payload;
    },
    setSelectedRoleId: (state, action: PayloadAction<number | null>) => {
      state.selectedRoleId = action.payload;
    },
    setRoles: (state, action: PayloadAction<RoleResponse[]>) => {
      state.roles = action.payload;
    },
    setSchemas: (state, action: PayloadAction<SchemaPayload[]>) => {
      state.schemas = action.payload;
    },
  },
});

export const roleActions = roleSlice.actions;

export const roleReducer = roleSlice.reducer;
