export const structuredCloneByStringify = (object) => {
  if (!object) {
    return null;
  }
  return JSON.parse(JSON.stringify(object));
};

export const urlify = (text) => {
  let urlRegexHTTPS = /(https?:\/\/[^\s]+)/g;
  // let urlRegexHTTP = /(http?:\/\/[^\s]+)/g;
  return text.replace(urlRegexHTTPS, (url) => {
    return '<div class="historyLogLinkWrapper"><a href="' + url + '" target="_blank">' + url + "</a></div>";
  });
};
