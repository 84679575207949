import React from "react";
import "./index.css";
import SingleObjectMainElement from "../SingleObjectMainElement";
import { useParams } from "react-router-dom";
import SingleObjectFieldsGroupIteration from "../SingleObjectFieldsGroupIteration";
import SingleObjectRelationObjects from "../SingleObjectRelationObjects";
import SingleObjectExtensions from "../SingleObjectExtensions";
import SingleObjectCreateObjectButton from "../SingleObjectCreateObjectButton";

const SingleObjectColumn = (props) => {
	const { data, isCreateMode, createObjectRequest, handleUpdateSingleObjectField, objectSchemaId } = props;

	const { object_id } = useParams();

	return (
		<div
			{...{
				className: "SingleObjectColumn flex-column ScrollableAppDivFromClickOutside",
				style: data?.styleConfig,
			}}
		>
			{data.children.__objectMainElement && (
				<SingleObjectMainElement
					{...{
						isCreateMode,
						handleUpdateSingleObjectField,
						object_id,
					}}
				/>
			)}
			{!isCreateMode && data.children.__objectAppExtensions && (
				<SingleObjectExtensions
					{...{
						data: data.children.__objectAppExtensions,
					}}
				/>
			)}
			{data.children.__objectGroups && (
				<SingleObjectFieldsGroupIteration
					{...{
						groups: data.children.__objectGroups,
						isCreateMode,
						handleUpdateSingleObjectField,
            objectSchemaId
					}}
				/>
			)}
			{!isCreateMode && data.children.__objectRelationObjects && (
				<SingleObjectRelationObjects
					{...{
						schemas: data.children.__objectRelationObjects,
						isCreateMode,
						objectSchemaId,
					}}
				/>
			)}
			{isCreateMode && data.children.__objectMainElement && (
				<SingleObjectCreateObjectButton
					{...{
						createObjectRequest,
					}}
				/>
			)}
		</div>
	);
};

export default SingleObjectColumn;
