import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { useSelector } from "react-redux";
import { SUCCESS } from "../../../../helpers/response-service";
import { addObjectOwner, deleteObjectOwner } from "../../../../api/routes/objects_core";
import { isValue } from "../../../../helpers/values-validator";
import HeaderOwnersCircles from "./HeaderOwnersCircles";
import UserVariants from "./UserVariants";
import { addOwnerToSchema } from "../../../../api/routes/role";
import { useEffect, useState } from "react";
import { useNotification } from "../../../../hooks/useNotification";

const defaultColor = "#DBDBEB";

type SingleObjectUsersProps = {
  campaign_users: Array<any>;
  object_users: {
    owners: Array<any>;
  };
  readOnly: boolean;
  object_id: number;
  onChange: (data: any) => void;
  style: any;
  size: number;
  amount: number;
};

type User = {
  id: number;
  name: string;
};

const SingleObjectUsers = (props: SingleObjectUsersProps) => {
  const { campaign_users, style, size, object_users, readOnly, object_id, onChange, amount } = props;
  const __user = useSelector((state: any) => state?.user?.user?.user);

  const currentObjectUsers = {
    owners: campaign_users?.filter((camp) => {
      return object_users?.owners?.includes(camp.id);
    }),
  };
  const [isInit, setIsInit] = useState(false);
  const [disabledSelect, setDisabledSelect] = useState(false);

  const ownersObjectToMap = (owners: Array<any> | null) => {
    let ownersMap = {};
    if (!owners) {
      return ownersMap;
    }
    owners.forEach((user) => {
      ownersMap = {
        ...ownersMap,
        [user.id]: user,
      };
    });
    return ownersMap;
  };
  const users = currentObjectUsers && isValue(currentObjectUsers.owners) ? currentObjectUsers.owners : null;
  const [ownersObject, setOwnersObject] = useState<any>(ownersObjectToMap(users));

  const setOwnersLoading = (loading: boolean, user: User) => {
    setOwnersObject((prev: any) => ({
      ...prev,
      [user.id]: {
        ...prev[user.id],
        loading,
      },
    }));
  };

  const handleAddOwner = (user: User) => {
    object_id ? objectAddOwnerRequest(user) : stateAddOwner(user);
  };

  const handleRemoveOwner = (user: User) => {
    object_id ? objectRemoveOwnerRequest(user) : stateRemoveOwner(user);
  };

  const stateRemoveOwner = (user: User) => {
    setOwnersObject((prev: any) => {
      let users = { ...prev };
      delete users[user.id];
      return users;
    });
  };

  const stateAddOwner = (user: User) => {
    setOwnersObject((prev: any) => ({
      ...prev,
      [user.id]: user,
    }));
  };

  const objectRemoveOwnerRequest = (user: User) => {
    const payload = {
      query: null,
      data: {
        owner_id: user.id,
        object_id,
      },
    };
    setOwnersLoading(true, user);
    deleteObjectOwner(payload)
      .then((result) => {
        switch (result.kind) {
          case SUCCESS:
            stateRemoveOwner(user);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setOwnersLoading(true, user);
      });
  };

  const objectAddOwnerRequest = (user: User) => {
    if (!ownersObject[user.id]) {
      const payload = {
        query: null,
        data: {
          owner_id: user.id,
          object_id,
        },
      };
      addObjectOwner(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              stateAddOwner(user);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // any
        });
    }
  };

  useEffect(() => {
    amount !== -1 && Object.keys(ownersObject).length === amount ? setDisabledSelect(true) : setDisabledSelect(false);
    if (isInit) {
      onChange && onChange(ownersObject);
    }
    setIsInit(true);
  }, [ownersObject]);

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        style,
        header: (
          <HeaderOwnersCircles
            {...{
              size,
              data: ownersObject,
              readOnly,
              color: defaultColor,
            }}
          />
        ),
        disabled: readOnly,
      }}>
      <UserVariants
        {...{
          data: campaign_users,
          selected: ownersObject,
          disabled: disabledSelect,
          onAdd: handleAddOwner,
          onDelete: handleRemoveOwner,
          currentUser: __user,
        }}
      />
    </ClickOutsideBoundaryTemplate>
  );
};

SingleObjectUsers.defaultProps = {
  amount: -1,
  readOnly: false,
  size: 40,
};

export default SingleObjectUsers;
