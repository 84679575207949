import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./index.css";

const SingleObjectFieldGroups = () => {
	return (
		<div className={"SingleObjectFieldGroups"}>
			<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
				<div className={"flex-row align-center justify-between"} style={{ marginBottom: 10, width: "100%" }}>
					<Skeleton {...{ style: { width: 100, height: 20, borderRadius: 4 } }} />
					<Skeleton {...{ style: { width: 100, height: 20, borderRadius: 4 } }} />
				</div>
				<div className={"flex-row align-center justify-between"} style={{ marginBottom: 10, width: "100%" }}>
					<Skeleton {...{ style: { width: 100, height: 20, borderRadius: 4 } }} />
					<Skeleton {...{ style: { width: 100, height: 20, borderRadius: 4 } }} />
				</div>
			</SkeletonTheme>
		</div>
	);
};

export default SingleObjectFieldGroups;
