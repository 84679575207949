import React from "react";
import { IconContext } from "react-icons";

export default function ConfigIcon(props) {
	const { onClick, children, color, size, className, style } = props;

	return (
		<div
			{...{
				...(onClick && {
					onClick: () => {
						if (onClick && typeof onClick === "function") {
							onClick();
						}
					},
				}),
				className: `flex align-center justify-center ${className}`,
				style,
			}}
		>
			<IconContext.Provider
				{...{
					value: {
						color,
						size,
					},
				}}
			>
				{children}
			</IconContext.Provider>
		</div>
	);
}
