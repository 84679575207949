import React from "react";
import "./index.css";
import { motion } from "framer-motion";

/**
 * @param {
 *     refBtn: ReactNode,
 *     center: boolean,
 *     toLeft: boolean,
 *     toTop: boolean
 * }
 * @returns {JSX.Element}
 * */

class DefaultModalClickTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			center: typeof this.props.center === "boolean" ? this.props.center : true,
			refBtn: this.props.refBtn,
			toLeft: typeof this.props.toLeft === "boolean" ? this.props.toLeft : false,
			toTop: typeof this.props.toTop === "boolean" ? this.props.toTop : false,
			position: {
				x: null,
				y: null,
			},
		};
	}

	getOpenPosition = () => {
		const width = window.innerWidth;
		const height = window.innerHeight;
		const widthChild = document.querySelector(".DefaultModalClickTemplate_wrapper").clientWidth;
		const heightChild = document.querySelector(".DefaultModalClickTemplate_wrapper").clientHeight;
		const offset = this.state.refBtn.getBoundingClientRect();

		const y = offset.top + heightChild + offset.height + 5 >= height || this.state.toTop ? offset.top - heightChild - 5 : offset.height + offset.top + 5;
		const x = offset.left + widthChild >= width || this.state.toLeft ? offset.right - widthChild : offset.left;
		this.setState({
			position: {
				x: x,
				y: y,
			},
		});
	};

	componentDidMount() {
		!this.state.center && this.getOpenPosition();
	}

	render() {
		return (
			<div
				{...{
					className: `DefaultModalClickTemplate ${this.state.center && "flex-center"}`,
				}}
			>
				<motion.div
					{...{
						transition: {
							ease: "easeOut",
							duration: 0.2,
						},
						initial: {
							y: "-30%",
						},
						animate: {
							y: "0",
						},
						className: "DefaultModalClickTemplate_wrapper border-radius-6",
						style: {
							left: !this.state.center ? this.state.position.x : null,
							top: !this.state.center ? this.state.position.y : null,
							width: "max-content",
						},
					}}
				>
					{this.props.children}
				</motion.div>
			</div>
		);
	}
}

export default DefaultModalClickTemplate;
