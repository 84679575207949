import React from "react";
import "./index.css";
import { LINE_HEIGHT } from "../../../../../constants/ui-constants";
import IconManager from "../../../../kit/Icons/IconManager";
import { phoneFormatter } from "../../../../../helpers/phone-formatter";
import { formatPrice } from "../../../../../helpers/price-formatter";
import { ViewWidgetsEnum } from "../../../../../constants/types";
import { getShortRuDate, getShortRuDateHoursMinutes } from "../../../../../helpers/date-formatter";

const ViewDefault = (props) => {
  const { iconRight, iconLeft, text, tooltip, fontSize, color, mask } = props;

  const maskValue = (text) => {
    switch (mask) {
      case ViewWidgetsEnum.PHONE:
        return phoneFormatter(text);
      case ViewWidgetsEnum.PRICE:
        return formatPrice(text);
      case ViewWidgetsEnum.DATE:
        return getShortRuDate(text);
      case ViewWidgetsEnum.TIME:
        return getShortRuDateHoursMinutes(text);
      case ViewWidgetsEnum.DATETIME:
        return getShortRuDate(text) + " " + getShortRuDateHoursMinutes(new Date(text));
      default:
        return text;
    }
  };

  if (text) {
    return (
      <div
        {...{
          className: "ViewDefault flex-row align-start",
          title: tooltip,
          height: fontSize * LINE_HEIGHT,
        }}>
        {iconLeft.name && (
          <div
            {...{
              className: "ViewDefault_iconLeft flex-row align-center",
              style: {
                height: fontSize * LINE_HEIGHT,
              },
            }}>
            <IconManager
              {...{
                icon: iconLeft.name,
                color: iconLeft.color,
                size: fontSize - 3,
              }}
            />
          </div>
        )}
        <span
          {...{
            style: {
              fontSize,
              lineHeight: LINE_HEIGHT,
              color,
              className: "ViewDefault_wrap",
            },
          }}>
          {mask ? maskValue(text) : text}
        </span>
        {iconRight.name && (
          <div
            {...{
              className: "ViewDefault_iconRight flex-row align-center",
              style: {
                height: fontSize * LINE_HEIGHT,
              },
            }}>
            <IconManager
              {...{
                icon: iconRight.name,
                color: iconRight.color,
                size: fontSize - 3,
              }}
            />
          </div>
        )}
      </div>
    );
  } else return null;
};

export default ViewDefault;

ViewDefault.defaultProps = {
  iconRight: {
    name: null,
    color: "var(--text-sixth)",
    size: 13,
  },
  iconLeft: {
    name: null,
    color: "var(--text-sixth)",
    size: 13,
  },
  fontSize: 15,
  tooltip: null,
  text: null,
  color: "#6E7689",
};
