import React from "react";
import "./index.css";
import IconManager from "../../kit/Icons/IconManager";
import { LINE_HEIGHT } from "../../../constants/ui-constants";

const EditWidgetIconLabelTemplate = (props) => {
  const { fontSize, icon, label, children, style } = props;

  return (
    <div {...{ className: "EditWidgetIconLabelTemplate" }}>
      {label && (
        <div
          {...{
            className: "EditWidgetIconLabelTemplate_label",
            style: { height: LINE_HEIGHT * fontSize },
          }}>
          {icon && (
            <div
              {...{
                className: "EditWidgetIconLabelTemplate_labelIcon",
                style: { height: LINE_HEIGHT * fontSize },
              }}>
              <IconManager {...{ icon, size: fontSize - 3, color: "var(--text-seventh)" }} />
            </div>
          )}
          <span
            {...{
              className: "text-dotted-end",
              style: { fontSize, lineHeight: LINE_HEIGHT },
            }}>
            {label}
          </span>
        </div>
      )}
      <div {...{ className: "EditWidgetIconLabelTemplate_children" }}>{children}</div>
    </div>
  );
};

export default EditWidgetIconLabelTemplate;

EditWidgetIconLabelTemplate.defaultProps = {
  fontSize: 15,
  icon: null,
  label: null,
};
