import React from "react";
import "./index.css";
import RowCardFieldsIteration from "./RowCardFieldsIteration";

const TableRow = (props) => {
  const { data, max } = props;

  return (
    <div {...{ className: "TableRowTemplate flex-row" }}>
      <div {...{ className: "TableRowTemplate_dragIdContainer flex-row" }}>{data.id}</div>
      <RowCardFieldsIteration {...{ fields: data.fields, max }} />
    </div>
  );
};

export default TableRow;
