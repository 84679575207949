import { IconAdjustments, IconReload } from "@tabler/icons-react";
import React, { memo, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { getStatisticFilters } from "../../../api/routes/statistic";
import DefaultButton from "../../../components/kit/Buttons/DefaultButton";
import IconButton from "../../../components/kit/Buttons/IconButton";
import { ReportsFiltersModal } from "../../../components/kit/Modals/ReportsFiltersModal";
import SelectDefault from "../../../components/kit/Selects/SelectDefault";
import { ClickOutsideBoundaryTemplate } from "../../../components/templates/ClickOutsideBoundaryTemplate";
import { KindEnum } from "../../../helpers/response-service";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { useThrottledFn } from "../../../hooks/useThrottledFn";
import { reportsActions, SCHEMA_FILTER_ID } from "../../../store/reducers/reportsSlice";
import { FilterChoice, IFilter } from "../../../types/stats";

export const FiltersModal = memo(() => {
  const dispatch = useAppDispatch();
  const filtersFields = useAppSelector((state) => state.reports.filter_fields);
  const selectedSchema = useAppSelector((state) => state.reports.selectedSchema);
  const loading = useAppSelector((state) => state.reports.loading);
  const schemas = useAppSelector((state) => state.reports.schemas);

  const ref = useRef<any>(null);

  const [filters, setFilters] = useState<IFilter[]>([]);

  useEffect(() => {
    getStatisticFilters().then((res) => {
      if (res.kind !== KindEnum.SUCCESS) {
        return;
      }
      setFilters(
        res.data.reduce<IFilter[]>((acc, filter) => {
          if (filter.field_id === SCHEMA_FILTER_ID) {
            dispatch(reportsActions.setSchemas(filter.choice));
            dispatch(reportsActions.setSelectedSchema({ schema: filter.choice[0] }));
          }
          acc.push(filter);
          return acc;
        }, [])
      );
    });
  }, []);

  const handleSchemaChange = (schema: FilterChoice[]) => {
    dispatch(reportsActions.setSelectedSchema({ schema: schema[0] }));
    dispatch(reportsActions.setLoading({ loading: true }));
    dispatch(reportsActions.refetch());
  };

  const handleReset = () => {
    if (!filtersFields) {
      return;
    }
    dispatch(reportsActions.setLoading({ loading: true }));
    dispatch(reportsActions.refetch());
    dispatch(reportsActions.resetFilters());
  };

  const throttledReset = useThrottledFn(handleReset, 1000);
  const throttledRefetch = useThrottledFn(() => {
    dispatch(reportsActions.setLoading({ loading: true }));
    dispatch(reportsActions.refetch());
  }, 1000);

  return (
    <>
      <div className="flex align-center">
        <DefaultButton
          disabled={loading || !filters}
          onClick={throttledReset}
          fitContent={true}
          text="сбросить фильтры"
          style={{ backgroundColor: "transparent", marginRight: 14, height: 40 }}
          color="var(--main-text-dark)"
        />
        {schemas ? (
          <SelectDefault
            small
            placeholder="Воронка"
            search
            disabled={loading || !filters}
            data={schemas}
            values={selectedSchema ? [selectedSchema] : []}
            field_key="name"
            onChange={handleSchemaChange}
            amount={1}
            style={{ marginRight: 14, width: 230 }}
          />
        ) : (
          <Skeleton
            className="margin-right-14"
            count={1}
            height={40}
            width={250}
            baseColor="var(--skeleton-loader-second)"
            highlightColor="var(--skeleton-loader-first)"
          />
        )}
        <ClickOutsideBoundaryTemplate
          ref={ref}
          header={
            <IconButton
              width={40}
              height={40}
              icon={
                <IconAdjustments
                  color="var(--gray1)"
                  width={20}
                />
              }
              style={{ background: "var(--card-background)", marginRight: 14, flexShrink: 0 }}
            />
          }>
          <ReportsFiltersModal
            handleClose={ref.current?.handleContainer}
            filters={filters}
          />
        </ClickOutsideBoundaryTemplate>
        <IconButton
          disabled={loading}
          onClick={throttledRefetch}
          width={40}
          height={40}
          icon={
            <IconReload
              color="var(--gray1)"
              width={20}
            />
          }
          style={{ background: "var(--card-background)", flexShrink: 0 }}
        />
      </div>
    </>
  );
});
