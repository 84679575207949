import { phoneFormatter } from "../../../helpers/phone-formatter";

export const CALL_STATUSES = {
  ENDED: {
    status_timer_name: "ЗАВЕРШЕНИЕ",
    loading: false,
    timer: false,
    status_key: "end",
    status_text: "Звонок завершен",
    status_color: "#fec74f",
    status_background: "#FFFFFF",
    callButtonActive: false,
    declineButtonActive: false,
  },
  CALL: {
    status_timer_name: "ЗВОНОК",
    loading: false,
    timer: true,
    status_key: "call",
    status_text: "Разговор",
    status_color: "#22bc3c",
    status_background: "#FFFFFF",
    callButtonActive: false,
    declineButtonActive: true,
  },
  ERROR: {
    status_timer_name: "ЗАВЕРШЕНИЕ",
    loading: false,
    timer: false,
    status_key: "error",
    status_text: "Отключение",
    status_color: "#FE4F4F",
    status_background: "#FFFFFF",
    callButtonActive: false,
    declineButtonActive: false,
  },
  WAITING: {
    status_timer_name: "ОЖИДАНИЕ ОТВЕТА",
    loading: true,
    timer: false,
    status_key: "waiting",
    status_text: "Вызов...",
    status_color: "#cbcbcb",
    status_background: "#FFFFFF",
    callButtonActive: false,
    declineButtonActive: true,
  },
  CONNECTING: {
    status_timer_name: "ПОДКЛЮЧЕНИЕ",
    loading: true,
    timer: false,
    status_key: "connecting",
    status_text: "Подключение к IP-телефонии...",
    status_color: "#cbcbcb",
    status_background: "#FFFFFF",
    callButtonActive: false,
    declineButtonActive: true,
  },
  INCOME: (client_name, client_phone) => ({
    status_timer_name: "ВХОДЯЩИЙ ВЫЗОВ",
    loading: false,
    timer: false,
    status_key: "connecting",
    status_text: client_name && client_phone ? `${client_name}: ${phoneFormatter(client_phone)}` : "Неизвестный абонент",
    status_color: "#22bc3c",
    status_background: "#FFFFFF",
    callButtonActive: true,
    declineButtonActive: true,
  }),
};
