// @ts-nocheck
import { api } from "../index.jsx";
import * as extensions from "../entry-points.jsx";
import { ApiEndpoint } from "../api-service";
import { CreatePact, GetAllCampaigns, GetPactUsers, UpdateStage } from "../interfaces/extensions";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

// INTEGRATIONS ===================================================================
const getExtensionsCampaign = () => loginEndpoint.request(extensions.GET_EXTENSIONS_CAMPAIGN, null, "get");
const getPactCampaign = () => loginEndpoint.request(extensions.GET_PACT_CAMPAIGNS, null, "get");
const addExtensionToCampaign = (payload) => loginEndpoint.request(extensions.ADD_EXTENSIONS_CAMPAIGN(payload.query.extension_id), null, "post");
const updateExtensionsCampaign = (payload) => loginEndpoint.request(extensions.UPDATE_EXTENSIONS_CAMPAIGN(payload.query.extension_id), null, "patch");
const addTelephonyConfig = (payload) => loginEndpoint.request(extensions.ADD_TELEPHONY_CONFIG, payload.data, "post");
const updateTelephonyConfig = (payload) => loginEndpoint.request(extensions.UPDATE_TELEPHONY_CONFIG, payload.data, "patch");
const getTelephonyConfig = () => loginEndpoint.request(extensions.GET_TELEPHONY_CONFIG, null, "get");
const getPactToken = () => loginEndpoint.request(extensions.GET_PACT_TOKEN, null, "get");
const addPactToken = (payload) => loginEndpoint.request(extensions.ADD_PACT_TOKEN(payload.query.pact_token), null, "post");
const setPactCampaigns = (payload) => loginEndpoint.request(extensions.SET_PACT_CAMPAIGNS, payload.data, "post");
const deletePactCampaign = (payload) => loginEndpoint.request(extensions.DELETE_PACT_CAMPAIGN(payload.query.campaign_id), null, "delete");

// STAGES ===================================================================
const getMultipleViewExtensionsConfig = (payload) =>
  loginEndpoint.request(extensions.GET_MULTIPLY_VIEW_EXTENSIONS_CONFIGS(payload.query), null, "get");
const finishObjectExtensionStage = (payload) => loginEndpoint.request(extensions.FINISH_STAGE(payload.query), payload.data || null, "patch");
const updateStage: UpdateStage = (payload) => loginEndpoint.request(extensions.UPDATE_STAGE, payload, "put");
const approveObjectStage = (payload) => loginEndpoint.request(extensions.APPROVE_OBJECT(payload.query), null, "post");
const getApproveObjectsByStages = (payload) => loginEndpoint.request(extensions.GET_APPROVE_OBJECTS(payload.query), null, "get");
const changeObjectStatuses = (payload) => loginEndpoint.request(extensions.CHANGE_OBJECT_STATUSES, payload, "patch");

// MESSAGES ===================================================================
const createPact: CreatePact = (payload) => loginEndpoint.request(extensions.CREATE_PACT(payload), payload.ids, "post");
const getAllCampaigns: GetAllCampaigns = () => loginEndpoint.request(extensions.GET_ALL_CAMPAIGNS);
const getPactUsers: GetPactUsers = () => loginEndpoint.request(extensions.GET_PACT_USERS);

const createWaMessage = (payload) => loginEndpoint.request(extensions.CREATE_WA_MESSAGE, payload.data, "post");

// DEADLINE
const getDeadLineData = (payload) => loginEndpoint.request(extensions.GET_DEADLINE_GROUPED_OBJECTS(payload.query), payload.data, "post");
const getDeadLineDataByLeadManager = (payload) => loginEndpoint.request(extensions.GET_DEADLINE_GROUPED_OBJECTS_BY_LEAD_MANAGER(payload.query), payload.data, "post");
const getDeadLineDataByLeadRemote = (payload) => loginEndpoint.request(extensions.GET_DEADLINE_GROUPED_OBJECTS_BY_LEAD_REMOTE(payload.query), payload.data, "post");

// COMMENT
const createObjectComment = (payload) => loginEndpoint.request(extensions.CREATE_OBJECT_COMMENT(payload.query), payload.data, "post");
const updateObjectComment = (payload) => loginEndpoint.request(extensions.UPDATE_OBJECT_COMMENT(payload.query), payload.data, "put");
const removeObjectComment = (payload) => loginEndpoint.request(extensions.REMOVE_OBJECT_COMMENT(payload.query), null, "put");
const sendFile = (payload) => loginEndpoint.request(extensions.UPLOAD_FILES(payload.query), payload.data, "post");

// TELEPHONY
const preOutgoingCall = (payload) => loginEndpoint.request(extensions.PRE_OUTGOING_CALL(payload.query), null, "post");
const registerOutgoingCall = (payload) => loginEndpoint.request(extensions.REGISTER_OUTGOING_CALL, payload.data, "post");

export {
  // stages
  getPactCampaign,
  getTelephonyConfig,
  updateTelephonyConfig,
  getExtensionsCampaign,
  addExtensionToCampaign,
  updateExtensionsCampaign,
  addTelephonyConfig,
  getPactToken,
  addPactToken,
  setPactCampaigns,
  deletePactCampaign,
  getMultipleViewExtensionsConfig,
  finishObjectExtensionStage,
  approveObjectStage,
  getApproveObjectsByStages,
  updateStage,
  changeObjectStatuses,
  // messages
  getAllCampaigns,
  createPact,
  getPactUsers,
  createWaMessage,
  // deadline
  getDeadLineData,
  getDeadLineDataByLeadManager,
  getDeadLineDataByLeadRemote,
  // comment
  createObjectComment,
  removeObjectComment,
  // telephony
  sendFile,
  preOutgoingCall,
  registerOutgoingCall,
  updateObjectComment,
};
