import React from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { colorGenerator } from "../../../../../helpers/color-generator";
import { getInitials } from "../../../../../helpers/initials-generator";

const UserCircle = (props) => {
  const { user, size, user_key, shadow, active } = props;

  const inner = 4;
  const outer = 0;

  const outerSize = (size, isActive) => ({
    width: isActive ? size - outer : size,
    height: isActive ? size - outer : size,
    borderRadius: isActive ? (size - outer) / 2 : size,
    ...(isActive && { border: `2px solid var(--primary-color)` }),
  });

  const innerSize = (size, isActive) => ({
    width: isActive ? size - inner : size,
    height: isActive ? size - inner : size,
    borderRadius: isActive ? (size - inner) / 2 : size,
    fontSize: (isActive ? size - inner : size) / 2.8,
    ...(isActive && { border: `2px solid var(--card-background)` }),
  });

  return (
    <div
      {...{
        className: cn(styles.UserCircle, shadow && styles.UserCircle_shadow, active && styles.UserCircle_active),
        style: {
          backgroundColor: "var(--card-background)",
          ...outerSize(size, active),
          zIndex: 100 - user_key,
          marginLeft: user_key === 0 ? 0 : -size / 2,
        },
      }}>
      {user?.image ? (
        <>
          <img
            {...{
              src: user?.image,
              style: {
                ...innerSize(size, active),
                objectFit: "cover",
              },
            }}
          />
        </>
      ) : (
        <div
          {...{
            className: cn(styles.UserCircleInner),
            style: {
              ...innerSize(size, active),
              backgroundColor: colorGenerator(user?.name, 100, 80),
              color: "#fff",
            },
          }}>
          {getInitials(user?.name)}
        </div>
      )}
    </div>
  );
};

UserCircle.defaultProps = {
  user_key: 0,
  user: {
    name: "DF",
  },
};

export default UserCircle;
