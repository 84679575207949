import React from "react";
import styles from "./index.module.css";
import Spinner from "../../../kit/Loaders/Spinner";

const CallStatusInformation = (props) => {
  const { loading, title, subtitle, color } = props;

  return (
    <div {...{ className: styles.CallStatusInformation }}>
      <div {...{ className: styles.CallStatusInformation_VerticalColor, style: { backgroundColor: color } }}></div>
      <div {...{ className: styles.CallStatusInformation_Content }}>
        <div {...{ className: styles.CallStatusInformation_Content_Title }}>
          <div {...{ className: styles.CallStatusInformation_Content_TitleText }}>{title}</div>
          {loading && (
            <Spinner
              {...{
                color: "#AAAAAA",
                loading,
                size: 15,
              }}
            />
          )}
        </div>
        <div {...{ className: styles.CallStatusInformation_Content_SubTitle }}>{subtitle}</div>
      </div>
    </div>
  );
};

export default CallStatusInformation;
