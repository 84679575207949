import React from "react";
import "./index.css";
import { getSchemaFields, getUnGroupedObjects } from "../../../../api/routes/objects_core";
import { SUCCESS } from "../../../../helpers/response-service";
import { TbEdit } from "react-icons/tb";
import SelectIconAction from "../../../kit/Selects/SelectIconAction";
import FastObjectCreate from "../../FastObjectCreate";
import ObjectCard from "../../Cards/ObjectCard";
import NestedObjectsSkeleton from "../../../kit/Loaders/NestedObjectsSkeleton";
import { singleObject_setRelationObjects } from "../../../../store/actions/singleObjectActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNotification } from "../../../../store/actions/appActions";
import { GET_OBJECT_TO_RELATE__ERROR } from "../../../../constants/notification-texts";

const SingleObjectNestedGroup = (props) => {
  const { parent_id, nested_object, objectSchemaId } = props;

  const view_type = "CARD";

  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(null);
  const containerRef = React.useRef(null);
  const [schemaFields, setSchemaFields] = React.useState(null);

  const schemaKey = useSelector((state) => state.app.schemas_object_by_id[nested_object.schema_id]);

  const createStoreAction = useDispatch();

  React.useEffect(() => {
    if (nested_object) {
      if (nested_object.schema_id && nested_object.fields === null) {
        setLoading(true);
        const payload = {
          query: {
            schema_id: nested_object.schema_id,
            view_type,
            page: 0,
            limit: 50,
          },
          data: {
            parent_ids: [parent_id * 1],
          },
        };
        payload.query = new URLSearchParams(payload.query).toString();
        getUnGroupedObjects(payload)
          .then((result) => {
            if (result.kind === SUCCESS) {
              createStoreAction(singleObject_setRelationObjects(result.data));
              setData(Object.assign({}, result.data));
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      const schema_payload = {
        query: {
          schema_id: nested_object.schema_id,
        },
        data: null,
      };
      schema_payload.query = new URLSearchParams(schema_payload.query).toString();
      getSchemaFields(schema_payload).then((result) => {
        if (result.kind === SUCCESS) {
          setSchemaFields(Object.assign({}, result.data));
        }
      });
    }
  }, [nested_object]);

  const onCreateNewObject = (object) => {
    if (object && object.id) {
      setData((prev) => ({ [object.id]: object, ...prev }));
    } else {
      createStoreAction(setNotification(GET_OBJECT_TO_RELATE__ERROR));
    }
  };

  const navigate = useNavigate();

  const selectActionData = [
    // {
    // 	id: 2,
    // 	name: "Удалить",
    // 	icon: <TbTrash />,
    // 	action: () => console.log("Удалить"),
    // },
    {
      id: 2,
      name: "Редактировать",
      icon: <TbEdit />,
      action: () => navigate("/settings/structure"),
    },
  ];

  if (nested_object) {
    return (
      <div {...{ className: "SingleObjectNestedGroup flex-column" }}>
        <div
          {...{
            className: "SingleObjectNestedGroup_title",
          }}>
          <div {...{ className: "SingleObjectNestedGroup_titleName" }}>{nested_object.name}</div>
          <div {...{ className: "SingleObjectNestedGroup_titleControls" }}>
            {schemaFields && (
              <FastObjectCreate
                {...{
                  parentObjectId: parent_id,
                  parentObjectSchemaId: objectSchemaId,
                  schemaFields,
                  schema_id: nested_object.schema_id,
                  onCreate: onCreateNewObject,
                  schemaKey,
                  containerRef,
                  includeFields: null,
                }}
              />
            )}
            <SelectIconAction
              {...{
                data: selectActionData,
              }}
            />
          </div>
        </div>
        <div {...{ className: "flex-column" }}>
          {!loading ? (
            data && Object.keys(data) ? (
              Object.keys(data).map((object_key, key) => {
                return (
                  <ObjectCard
                    {...{
                      style: {
                        background: "var(--card-background)",
                        borderRadius: 6,
                        marginTop: 5,
                      },
                      schemaKey,
                      key,
                      editable: true,
                      data: data[object_key],
                      schema_id: nested_object.schema_id,
                    }}
                  />
                );
              })
            ) : null
          ) : (
            <NestedObjectsSkeleton
              {...{
                count: 2,
                style: {
                  background: "var(--card-background)",
                  borderRadius: 6,
                  marginTop: 5,
                  padding: 17,
                },
              }}
            />
          )}
        </div>
      </div>
    );
  } else return null;
};

export default SingleObjectNestedGroup;
