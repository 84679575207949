import React from "react";
import "./index.css";
import TableList from "../../elements/Tables/TableList";
import DragAndDropTemplate from "../DragAndDropNativeTemplate";
import RowCard from "../../elements/Rows/TableRow";
import TableHeader from "../../elements/Tables/TableHeader";

const TableTemplate = (props) => {
  const { schema_key, drag, multiSelect, data, schema_id, view_id, onListEnd, loading, action } = props;
  const MAX_FIELDS_COUNT = 9;
  const formatObjToRender = data[0]?.objects?.[0]?.fields.map((field) => Object.values(field)[0]);
  if(!formatObjToRender) {
      return <div {...{ className: "TableTemplate" }}><div className={"TableTemplateEmpty"}>В текущей воронке нет ни одного объекта</div></div>
  }
  return (
    <div {...{ className: "TableTemplate" }}>
      <div {...{ className: "TableTemplate_header" }}>
        {data &&
          data[0] &&
          data[0].objects &&
          data[0].objects[0] &&
          [formatObjToRender].map((row, key) => <TableHeader {...{ row, key, max: MAX_FIELDS_COUNT }} />)}
      </div>
      <div {...{ className: "TableTemplate_body" }}>
        <DragAndDropTemplate
          {...{
            multiSelect,
            schema_key,
            drag,
            cardWidth: "100%",
            cardStyle: {
              borderRadius: 0,
              marginTop: 1,
            },
            loading,
            schema_id,
            action,
            list: true,
            data,
            onListEnd,
            sectionWrapper: (props) => <TableList {...{ ...props, schema_id, view_id }} />,
            elementContent: (props) => <RowCard {...{ ...props, border: true, max: MAX_FIELDS_COUNT }} />,
          }}
        />
      </div>
    </div>
  );
};

export default TableTemplate;
