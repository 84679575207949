import React from "react";
import "./index.css";
import ColumnList from "../../elements/ColumnList";
import DragAndDropTemplate from "../DragAndDropNativeTemplate";
import ObjectCard from "../../elements/Cards/ObjectCard";
import HorizontalScrollBar from "../../elements/Scrolls/HorizontalScrollBar";

const KanbanTemplate = (props) => {
  const { dragItem, drag, schema_key, multiSelect, data, schemaFields, schema_id, multiply_view_id, extensions, loading, action, onListEnd } = props;
  // const layoutRef = React.useRef(null);

  return (
    <div
      {...{
        className: "KanbanTemplate flex-row",
        style: { width: data.length * 354 },
      }}>
      <DragAndDropTemplate
        {...{
          multiSelect,
          drag,
          schema_key,
          cardWidth: 340,
          cardStyle: {
            marginTop: 3,
          },
          schema_id,
          data,
          action,
          extensions,
          onListEnd,
          loading,
          sectionWrapper: (props) => (
            <ColumnList
              {...{
                ...props,
                schemaFields,
                schema_id,
                view_id: multiply_view_id,
              }}
            />
          ),
          elementContent: (props) => {
            return (
              <ObjectCard
                {...{
                  ...props,
                  actions: false,
                  style: { borderRadius: 6 },
                  editable: false,
                  schemaKey: schema_key,
                  users: true,
                }}
              />
            );
          },
        }}
      />
      <HorizontalScrollBar
        // ref={minimapRef}
        ref={dragItem}
        {...{
          count: data.length,
          elementClass: "KanbanTemplate",
        }}
      />
    </div>
  );
};

KanbanTemplate.defaultProps = {
  drag: true,
};

export default KanbanTemplate;
