import React from "react";
import "./index.css";
import ConfigIcon from "../../Icons/ConfigIcon";
import { BsCheck } from "react-icons/bs";

/**
 * @param {
 *     {
 *      checked: boolean,
 *      disabled: boolean,
 *      size: int,
 *      text: string,
 *      style: string,
 *      onChange: function,
 *      style: object,
 *      className: string,
 *     }
 * } props
 * @returns {JSX.Element}
 */

const DefaultCheckbox = (props) => {
  const { checked, disabled, size, text, style, onChange, className } = props;

  return (
    <div
      className={`DefaultCheckbox ${disabled ? "no-pointer-events cursor-default" : ""}`}
      {...{
        style,
        className,
        onClick: () => (!disabled ? onChange?.() : null),
      }}>
      <label
        {...{
          className: "DefaultCheckbox_label pointer",
          style: {
            ...{ width: !!size ? size : 20, height: !!size ? size : 20 },
          },
        }}>
        <span
          {...{
            type: "checkbox",
            className: "DefaultCheckbox_input",
          }}
        />
        {checked ? (
          <ConfigIcon {...{ className: "def" }}>
            <BsCheck color="var(--main-text-dark)" />
          </ConfigIcon>
        ) : null}
      </label>
      <span
        {...{
          className: "DefaultCheckbox_text",
        }}>
        {text}
      </span>
    </div>
  );
};

export default DefaultCheckbox;
