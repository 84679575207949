import React from "react";
import IconManager from "../../../Icons/IconManager";
import Spinner from "../../../Loaders/Spinner";
import UserCircle from "../UserCircle";
import styles from "./index.module.css";
import cn from "classnames";

const UserVariantsElement = (props) => {
  const { user, isMe, onAdd, onDelete, selected } = props;

  // SingleObjectUsers_containerItemDisabled

  return (
    <div
      {...{
        onClick: selected ? onDelete : onAdd,
        className: cn(styles.UserVariantsElement_containerItem, selected && styles.UserVariantsElement_containerItemActive),
      }}>
      <div {...{ className: "flex-row align-center" }}>
        <UserCircle
          {...{
            active: selected,
            size: 34,
            user,
            user_key: 0,
          }}
        />
        <div {...{ className: cn(styles.UserVariantsElement_containerItemName) }}>
          {user?.name} {isMe ? "(Вы)" : ""}
        </div>
      </div>
      <div {...{ className: cn(styles.UserVariantsElement_containerActions) }}>
        <div {...{ className: cn(styles.UserVariantsElement_containerAdd) }}>
          <IconManager
            {...{
              icon: selected ? "x" : "plus",
              size: 11,
              color: selected ? "var(--text-error)" : "var(--text-seventh)",
            }}
          />
        </div>
        {user.loading && <Spinner {...{ loading: true, size: 15 }} />}
      </div>
    </div>
  );
};

export default UserVariantsElement;
