import "./index.css";
import { WEEK_DAYS } from "../../Calendar";
import IconManager from "../../Icons/IconManager";
import { removeLetters } from "@/helpers/phone-formatter.js";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as _ from "lodash"
import React from "react";

dayjs.extend(utc);
dayjs.extend(timezone);

class DateCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.today = new Date(Date.now());
    this.state = {
      pull: [],
      hours: "10",
      minutes: "0",
      today: {
        date: this.today.getDate(),
        month: this.today.getMonth(),
        year: this.today.getFullYear(),
      },
      selected: null,
      currentMonth: this.today.getMonth(),
      currentDay: this.today.getDate(),
      currentYear: this.today.getFullYear(),
    };
  }

  componentDidMount() {
    const date = this.props.value;
    let value = null;
    if (date) {
      if (date.getDate) {
        value = date;
      } else if (new Date(date).getDate) {
        value = new Date(date);
      }
    }
    let month = null;
    let year = null;
    let hours = "12";
    let minutes = "0";
    if (value) {
      month = value.getMonth();
      year = value.getFullYear();
      hours = value.getHours();
      minutes = value.getUTCMinutes();
      this.setSelectedDate(value, month, year, hours, minutes);
    }
    this.pullDays(year, month);
  }

  setSelectedDate = (value, month, year, hours, minutes) => {
    this.setState({
      selected: value,
      currentMonth: month,
      currentYear: year,
      hours: hours.toString(),
      minutes: minutes.toString(),
    });
  };

  pullDays = (year_prop = undefined, month_prop = undefined) => {
    const year = ![undefined, null].includes(year_prop) ? year_prop : this.state.currentYear;
    const month = ![undefined, null].includes(month_prop) ? month_prop : this.state.currentMonth;
    let date = new Date(year, month, 1);
    let days = [];
    let start = WEEK_DAYS.filter((item) => date.toLocaleString("ru", { weekday: "short" }) === item.name.toLowerCase());
    let start_week_day = start[0]?.key;
    let i = 1;
    while (date.getMonth() === month) {
      if (i < start_week_day) {
        days.push(null);
      } else {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      i++;
    }
    let pull = [];
    let line = [];
    days.map((day, index) => {
      if (index !== 0 && index % 7 === 0) {
        pull.push(line);
        line = [];
      }
      line.push(day);
    });
    if (line.length > 0) {
      pull.push(line);
    }
    this.setState({ pull });
  };

  isToday = (date) => {
    return date.getDate() === this.state.today.date && date.getMonth() === this.state.today.month && date.getFullYear() === this.state.today.year;
  };

  isSelected = (date) => {
    const selected = this.state.selected;
    if (this.state.selected) {
      const selectedDate = selected.getDate();
      const selectedMonth = selected.getMonth();
      const selectedYear = selected.getFullYear();
      return date.getDate() === selectedDate && date.getMonth() === selectedMonth && date.getFullYear() === selectedYear;
    } else return false;
  };

  handleMonth = (month_index) => {
    this.setState({ pull: [] });
    let month = month_index * 1;
    let year = this.state.currentYear * 1;
    if (month_index === 12) {
      month = 0;
      year = year + 1;
    } else if (month_index === -1) {
      month = 11;
      year = year - 1;
    }
    this.setState({ currentMonth: month, currentYear: year });
    this.pullDays(year, month);
  };

  handleSelectDate = (date) => {
    if (this.isSelected(date)) {
      this.setState({ selected: null });
    } else {
      this.setState({ selected: date });
    }
  };

  cancelDate = () => {
    if (this.props.onCancel) {
      this.props.onCancel(this.returnData());
    }
  };

  applyDate = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.returnData());
    }
  };

  timeCondition = () => {
    return this.props.time && !this.props.date;
  };

  returnData = () => {
    if (this.state.selected || this.timeCondition) {
      return {
        value: this.state.selected,
        isoDate: this.state.selected ? this.returnISOString(this.state.selected, false) : null,
        isoTime: this.returnISOString(null, true),
        isoDateTime: this.state.selected ? this.returnISOString(this.state.selected, true) : null,
      };
    } else return null;
  };

  returnISOString = (value, withTime) => {
    const hours = withTime ? removeLetters(this.state.hours) * 1 : 0;
    const minutes = withTime ? removeLetters(this.state.minutes) * 1 : 0;
    const today = this.state.today;
    let date = null;
    if (value) {
      date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), hours, minutes);
    } else {
      date = new Date(today.year, today.month, today.date, hours, minutes);
    }
    return dayjs.tz(date, "Europe/Moscow").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  };

  setHours = (value) => this.setState({ hours: value });
  setMinutes = (value) => this.setState({ minutes: value });

  handleChangeHours = (value) => {
    let num = removeLetters(value) * 1;
    if (num > 23) {
      this.setHours("23");
    } else this.setHours(num.toString());
  };

  handleChangeMinutes = (value) => {
    let num = removeLetters(value) * 1;
    if (num > 59) {
      this.setMinutes("59");
    } else this.setMinutes(num.toString());
  };

  render() {
    const { date, time } = this.props;

    return (
      <div {...{ className: "DateCalendar" }}>
        {date && (
          <div {...{ className: "DateCalendar_dates" }}>
            <div {...{ className: "DateCalendar_datesHeader" }}>
              <div
                {...{
                  onClick: () => this.handleMonth(this.state.currentMonth - 1),
                  className: "DateCalendar_datesHeaderHandleMonths",
                }}>
                <IconManager
                  {...{
                    icon: "chevron-left",
                    color: "var(--text-tenth)",
                    size: 12,
                  }}
                />
              </div>
              <div {...{ className: "DateCalendar_datesHeaderMonthYear" }}>
                {MONTHS_KEYS[this.state.currentMonth]}, {this.state.currentYear}
              </div>
              <div
                {...{
                  onClick: () => this.handleMonth(this.state.currentMonth + 1),
                  className: "DateCalendar_datesHeaderHandleMonths",
                }}>
                <IconManager
                  {...{
                    icon: "chevron-right",
                    color: "var(--text-tenth)",
                    size: 12,
                  }}
                />
              </div>
            </div>
            <div {...{ className: "DateCalendar_datesBody" }}>
              <table>
                <thead>
                  <tr>
                    {WEEK_KEYS.map((day, index) => {
                      return <th {...{ key: index }}>{day}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.pull &&
                    this.state.pull.map((line, line_index) => {
                      return (
                        <tr {...{ key: _.uniqueId(`__row__${line_index}`) }}>
                          {line.map((day, day_index) => {
                            return (
                              <td
                                {...{
                                  key: _.uniqueId(`__row__${line_index}__cell__${day_index}`),
                                  onClick: () => day && this.handleSelectDate(day),
                                  className: `${day && this.isToday(day) ? "Today" : ""} ${day && this.isSelected(day) ? "Selected" : ""}`,
                                }}>
                                {day ? day.getDate() : ""}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {time && (
          <div {...{ className: "DateCalendar_times" }}>
            <div {...{ className: "DateCalendar_timesElem" }}>
              <div {...{ className: "DateCalendar_timesElemLabel" }}>Часы</div>
              <input
                {...{
                  value: this.state.hours,
                  placeholder: "12",
                  onChange: (e) => this.handleChangeHours(e.target.value),
                  className: "DateCalendar_timesElemInput",
                }}
              />
            </div>
            <div {...{ className: "DateCalendar_timesElem" }}>
              <div {...{ className: "DateCalendar_timesElemLabel" }}>Минуты</div>
              <input
                {...{
                  value: this.state.minutes,
                  placeholder: "00",
                  onChange: (e) => this.handleChangeMinutes(e.target.value),
                  className: "DateCalendar_timesElemInput",
                }}
              />
            </div>
          </div>
        )}
        <div {...{ className: "DateCalendar_controls" }}>
          <div
            {...{
              className: "DateCalendar_controlsCancel",
              onClick: () => this.cancelDate(),
            }}>
            Отмена
          </div>
          <div
            {...{
              className: "DateCalendar_controlsApply",
              onClick: () => this.applyDate(),
            }}>
            Применить
          </div>
        </div>
      </div>
    );
  }
}

export default DateCalendar;

DateCalendar.defaultProps = {
  value: null,
  onSubmit: null,
  onCancel: null,
  date: true,
  time: true,
};

export const MONTHS_KEYS = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

export const WEEK_KEYS = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
