class DragAndDrop {
  constructor(cardStyle, onMouseUpCallBack) {
    this.onMouseUpCallBack = onMouseUpCallBack;
    this.cardStyle = cardStyle;
    this.currentSummaryMovement = 0;
    this.currentDroppable = null;
    this.movingElement = null;
    this.placeholder = null;
    this.isDragActive = false;
    this.sectionFrom = null;
    this.prevAcitionInfo = null;
    this.shifts = {
      shiftX: 0,
      shiftY: 0,
      set: (clientX, clientY, movingElement) => {
        this.shifts.shiftX = clientX - movingElement.getBoundingClientRect().left;
        this.shifts.shiftY = clientY - movingElement.getBoundingClientRect().top;
      },
    };
    this.initialMovingElementPageXY = {
      x: 0,
      y: 0,
      set: (movingElement) => {
        const rect = movingElement.getBoundingClientRect();
        this.initialMovingElementPageXY.x = rect.x;
        this.initialMovingElementPageXY.y = rect.y;
      },
    };
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.reverseDragAction = this.reverseDragAction.bind(this);
  }

  getInfo = () => {
    const to_section = this.placeholder ? this.placeholder.closest(".boardElementsWrapper").crm_drag_wrapper : null;
    return {
      drag_item_id: this.movingElement.crm_drag_item_id,
      to_section,
      from_section: this.sectionFrom.crm_drag_wrapper,
      moving_element: this.movingElement,
      from_section_prev_element: this.movingElement.previousElementSibling,
      from_section_next_element: this.movingElement.nextElementSibling,
    };
  };

  getElementCoordinates = (node) => {
    // Returns left and top coordinates of node
    const rect = node.getBoundingClientRect();
    return {
      top: rect.top + rect.height / 2,
      left: rect.left + rect.width / 2,
    };
  };

  setCurrentSummaryMovement = (reset, x, y) => {
    if (reset) {
      this.currentSummaryMovement = 0;
      return null;
    }
    const mX = x < 0 ? -1 * x : x;
    const mY = y < 0 ? -1 * y : y;
    this.currentSummaryMovement += mX + mY;
  };

  isAbove = (nodeA, nodeB) => {
    // movingElement, currentDroppable
    // if true - appendBefore else appendAfter
    if (nodeB.classList.contains("emptyElement")) {
      return true;
    } else {
      const rectA = nodeA.getBoundingClientRect();
      const rectB = nodeB.getBoundingClientRect();
      const rectA_computed = rectA.top + rectA.height / 2;
      const rectB_computed = rectB.top + rectB.height / 2;
      return rectA_computed < rectB_computed;
    }
  };

  getElementBelow = (movingElement) => {
    // Get element below movingElement now
    const movingElementCenter = this.getElementCoordinates(movingElement);
    movingElement.hidden = true;
    let elementBelow = document.elementFromPoint(movingElementCenter.left, movingElementCenter.top);
    movingElement.hidden = false;
    return elementBelow;
  };

  moveAt = (element, pageX, pageY) => {
    element.style.transform = `translate(
		${pageX - this.initialMovingElementPageXY.x - this.shifts.shiftX}px, 
		${pageY - this.initialMovingElementPageXY.y - this.shifts.shiftY}px
		)`;
  };

  setMovingElement = (event) => {
    const rect_width = event.target.getBoundingClientRect().width;
    event.target.style.width = rect_width + "px";
    this.movingElement = event.target;
    this.movingElement.classList.remove("droppable");
  };

  createPlaceholder = () => {
    this.placeholder = document.createElement("div");
    this.placeholder.classList.add("placeholder");

    let marginTop = "1px";
    if (this.cardStyle && this.cardStyle.marginTop) {
      marginTop = this.cardStyle.marginTop + "px";
    }

    Object.assign(this.placeholder.style, {
      marginTop,
      height: this.movingElement.getBoundingClientRect().height + "px",
    });
    this.movingElement.parentNode.insertBefore(this.placeholder, this.movingElement);
  };

  // LISTENERS

  onMouseDown = (event, callBack) => {
    this.setMovingElement(event);
    this.sectionFrom = event.target.closest(".boardElementsWrapper");
    this.shifts.set(event.clientX, event.clientY, this.movingElement);
    this.initialMovingElementPageXY.set(this.movingElement);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
    // this.movingElement.onmouseup = this.onMouseUp; // have a bug with zIndex
    callBack();
  };

  onMouseUp = () => {
    if (!this.isDragActive) {
      // callBack start
      this.onMouseUpCallBack({
        isDragActive: this.isDragActive,
        info: this.getInfo(),
      });
      // callBack end

      this.movingElement.classList.add("droppable");
      this.reset();
      return;
    }

    // callBack start
    this.onMouseUpCallBack({
      isDragActive: this.isDragActive,
      info: this.getInfo(),
    });

    this.prevAcitionInfo = this.getInfo();
    // callBack end

    this.movingElement.classList.add("droppable");
    this.placeholder.parentNode.insertBefore(this.movingElement, this.placeholder);
    this.resetMovingElement();
    this.reset();
  };

  onMouseMove = (event) => {
    this.setCurrentSummaryMovement(false, event.movementX, event.movementY);
    if (this.currentSummaryMovement < 5) {
      return null;
    }
    if (!this.isDragActive) {
      this.isDragActive = true;
      this.createPlaceholder();
      Object.assign(this.movingElement.style, {
        position: "absolute",
        zIndex: 1000,
        left: `${this.initialMovingElementPageXY.x}px`,
        top: `${this.initialMovingElementPageXY.y}px`,
      });
    }
    this.moveAt(this.movingElement, event.pageX, event.pageY);
    let elementBelow = this.getElementBelow(this.movingElement);
    if (!elementBelow) return;
    let droppableBelow = elementBelow.closest(".droppable");

    if (this.currentDroppable === null && droppableBelow) {
      this.currentDroppable = droppableBelow;
      if (this.currentDroppable) {
        if (this.isAbove(this.movingElement, this.currentDroppable)) {
          this.currentDroppable.parentNode.insertBefore(this.placeholder, this.currentDroppable);
        } else {
          this.currentDroppable.parentNode.insertBefore(this.placeholder, this.currentDroppable.nextElementSibling);
        }
      }
      this.currentDroppable = null;
      droppableBelow = null;
    }
  };

  reverseDragAction = () => {
    const info = this.prevAcitionInfo;
    if (info) {
      const sections = document.getElementsByClassName("boardElementsWrapper");
      let section = null;
      for (let i = 0; i < sections.length; i++) {
        const item = sections[i];
        if (info.from_section.choice_id === item.crm_drag_wrapper.choice_id) {
          section = item;
        }
      }
      if (section) {
        const hiddenBoardElement = section.getElementsByClassName("boardElementHidden")[0];
        if (info.from_section_next_element) {
          const element = info.from_section_next_element;
          element.parentNode.insertBefore(info.moving_element, element);
        } else {
          hiddenBoardElement.parentNode.insertBefore(info.moving_element, hiddenBoardElement.nextElementSibling);
        }
      }
      this.prevAcitionInfo = null;
    }
  };

  // RESET
  reset = () => {
    document.removeEventListener("mousemove", this.onMouseMove);
    this.isDragActive = false;
    this.placeholder && this.placeholder.parentNode.removeChild(this.placeholder);
    this.placeholder = null;
    // this.movingElement.onmouseup = null // have a bug with zIndex
    document.removeEventListener("mouseup", this.onMouseUp);
    this.movingElement = null;
    this.sectionFrom = null;
    this.setCurrentSummaryMovement(true);
  };

  resetMovingElement = () => {
    Object.assign(this.movingElement.style, {
      position: "static",
      left: "auto",
      top: "auto",
      zIndex: "auto",
      transform: "none",
      border: "none",
      width: "100%",
    });
  };
}

export default DragAndDrop;

export const DRAG_N_DROP_MOVE_TYPES = {
  MOVE: "MOVE",
  FINAL_STAGE: "FINAL_STAGE",
};

export const setDragNDropSectionData = (data, type) => ({
  crm_drag_wrapper: {
    type,
    ...data,
  },
});
