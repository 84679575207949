import React from 'react';
import { useLocation, useParams } from "react-router-dom";

const withRouter = WrappedScreen => (props) => {
	const params = useParams()
	const location = useLocation()

	return <WrappedScreen
		{...props}
		location={location}
		params={params}
	/>
}

export default withRouter
