import { IconFileDownload } from "@tabler/icons-react";
import AudioLog from "./Audio";
import LogAttachmentsImages from "./LogAttachmentsImages";
import * as _ from "lodash"

const Attachments = (props) => {
  const { data } = props;

  const validateIssetAttachment = () => {
    let isset = false;
    Object.values(data).forEach((data) => {
      if (data) {
        isset = true;
      }
    });
    return isset;
  };

  if (!validateIssetAttachment()) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
      {data.audio &&
        _.isArray(data.audio) &&
        data.audio.map((element, key) => {
          return <AudioLog {...{ key, url: element.url }} />;
        })}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {data?.document?.length
          ? data.document.map((element, key) => (
              <div
                key={key}
                style={{ height: "100%", marginTop: "12px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <a href={element.url}>
                  <IconFileDownload style={{ color: "#7875FE", height: "20px" }} />
                </a>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    color: "var(--text-tenth)",
                    fontSize: "13px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {element.name}
                </div>
              </div>
            ))
          : null}
        {!!data.img && <LogAttachmentsImages images={data.img}/>}
      </div>
    </div>
  );
};

export default Attachments;
