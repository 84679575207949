import React from "react";
import ConfigIcon from "../../../kit/Icons/ConfigIcon";
import { RiCloseFill } from "react-icons/ri";
import "./index.css";

const Tag = (props) => {
	const { onDelete = null, text = "Tag", color = "var(--text-tenth)", style = null } = props;

	return (
		<div>
			<div
				{...{
					className: "Tag",
					...(!!style && { style }),
				}}
			>
				{/*<div {...{*/}
				{/*    className: 'Tag_background',*/}
				{/*    style: {background: color}*/}
				{/*}}></div>*/}
				<span
					{...{
						className: "text-dotted-end",
						style: { color },
					}}
				>
					{text}
				</span>
				{!!onDelete && (
					<div
						{...{
							className: "Tag_deleteIcon",
							onClick: onDelete,
						}}
					>
						<div
							{...{
								className: "Tag_deleteIconBackground",
								style: { background: color },
							}}
						></div>
						<ConfigIcon {...{ color, className: "Tag_deleteIconSvg" }}>
							<RiCloseFill />
						</ConfigIcon>
					</div>
				)}
			</div>
		</div>
	);
};

export default Tag;
