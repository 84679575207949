import React from "react";
import CreateComment from "./Comment/CreateComment";
import CreateWhatsAppMessage from "./WhatsApp/CreateWhatsAppMessage";

const MessageExtensionsRouter = {
	accepted_extensions: ["comment", "whatsapp"],
	extensions: {
		comment: {
			key: 'comment',
			menu: {
				name: "Комментарий",
				style: {
					background: "black",
					color: "#fff",
				},
			},
			create_widget: (props) => <CreateComment {...{...props}} />,
		},
		whatsapp: {
			key: 'whatsapp',
			menu: {
				name: "WhatsApp",
				style: {
					background: "#25D366",
					color: "#fff",
				},
			},
			create_widget: (props) => <CreateWhatsAppMessage {...{...props}} />,
		},
		viber: {
			menu: {
				name: "Viber",
				style: {
					background: "#8f5db7",
					color: "#fff",
				},
			},
			create_widget: null,
		},
	},
};

export default MessageExtensionsRouter;
