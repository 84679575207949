export const hashCode = (str) => {
  if (!str) {
    return "";
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const colorGenerator = (str, fper = 100, sper = 100) => {
  return `hsl(${hashCode(str) % 360}, ${fper}%, ${sper}%)`;
};
