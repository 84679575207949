import styles from "./index.module.css";
import cn from "classnames";
import LogAttachmentsImagesItem from "./item";

type LogAttachmentsImage = {
  url: string;
};

type propTypes = {
  images: Array<LogAttachmentsImage>;
};

const LogAttachmentsImages = ({ images }: propTypes) => {
  return (
    <div className={cn(styles.HistoryLog_AttachmentsImagesWrapper)}>
      {images.map((image, index) => (
        <LogAttachmentsImagesItem
          key={index}
          src={image.url}
        />
      ))}
    </div>
  );
};

export default LogAttachmentsImages;
