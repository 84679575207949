import { api } from "../index.jsx";
import * as billing from "../entry-points.jsx";
import { ApiEndpoint } from "../api-service";

const WHERE = "/";
const schema = {};

const loginEndpoint = new ApiEndpoint(WHERE, api, schema);

const getCurrentTariff = () => loginEndpoint.request(billing.GET_CURRENT_TARIFF, {}, "get");

const getAllTariffs = () => loginEndpoint.request(billing.GET_ALL_TARIFFS, {}, "get");

export { getAllTariffs, getCurrentTariff };
