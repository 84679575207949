import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./index.css";
import { CONTENT_LAYOUT_ANIMATION, SHOW_ANIMATION_MOTIONS } from "../../../../constants/framer-motion-animations";
import ConfigIcon from "../../../kit/Icons/ConfigIcon";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ucFirst } from "../../../../helpers/uppercaseFirst";
import { COLORS } from "../../../../constants/types";
import { useSelector } from "react-redux";

const RowList = (props) => {
  const { children, grouped_by } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  // const count = useSelector((state) => state.multiplyObjects.__groupedObjects_counts[grouped_by.choice_id].count);

  return (
    <motion.div
      {...{
        ...CONTENT_LAYOUT_ANIMATION,
        className: "RowList_container flex-column",
      }}>
      <div {...{ className: "RowList_containerTitle flex-row align-center w-100" }}>
        <div
          {...{
            className: "RowList_containerTitle_color",
            style: {
              backgroundColor: COLORS[grouped_by.color],
            },
          }}></div>
        <div
          {...{
            className: `RowList_containerTitle_expandButton ${
              isExpanded ? "expandButton_expanded" : ""
            } flex align-center justify-center pointer transition-default`,
            onClick: () => setIsExpanded((prev) => !prev),
          }}>
          <ConfigIcon
            {...{
              color: "var(--text-eighth)",
              size: 18,
            }}>
            <MdKeyboardArrowDown />
          </ConfigIcon>
        </div>
        <span>{grouped_by.value}</span>
        {/*<span {...{ className: "RowList_containerTitle_count" }}>кол-во: {count}</span>*/}
      </div>
      <AnimatePresence>
        <motion.div
          {...{
            ...SHOW_ANIMATION_MOTIONS.default_height(isExpanded, "closed"),
          }}>
          {children}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default RowList;
