import React from "react";
import "./index.css";
import SingleLogSkeleton from "../ElementsSkeleton/SingleLogSkeleton";

const LogsSkeleton = () => {
	return (
		<div {...{ className: "LogsSkeleton flex-column" }}>
			<div {...{ className: "LogsSkeleton_verticalLine" }}></div>
			<SingleLogSkeleton />
			<SingleLogSkeleton />
			<SingleLogSkeleton {...{ left: true }} />
			<SingleLogSkeleton />
			<SingleLogSkeleton {...{ right: true }} />
			<SingleLogSkeleton {...{ right: true }} />
			<SingleLogSkeleton {...{ left: true }} />
			<SingleLogSkeleton />
		</div>
	);
};

export default LogsSkeleton;
