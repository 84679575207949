import {useEffect, useRef, useState} from "react";

export const useInteractionObserver = (options: any) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const interactionObserverCallBack = (entries: Array<any>) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(interactionObserverCallBack, {})
    if(containerRef.current) {
      observer.observe(containerRef.current)
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [containerRef, options]);

  return [containerRef, isVisible]
}
