import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../../templates/ClickOutsideBoundaryTemplate";
import IconManager from "../../../../kit/Icons/IconManager";
import Spinner from "../../../../kit/Loaders/Spinner";
import { LINE_HEIGHT } from "@/constants/ui-constants.js";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";
import { isValue } from "@/helpers/values-validator.js";
import Variant from "../../../../templates/DropDownVariantElementTemplate";
import cn from "classnames";
import styles from "./index.module.css";
import { Separator } from "../../../../kit/Separator/Separator";
import * as _ from "lodash"
import React from "react";

/**
 *
 * values
 * variants
 * search
 * error
 * loading
 * variantsIcon
 * icon
 * placeholder
 * color
 * fontSize
 * onSubmit
 * stroked
 * defaultValue
 */

class EditDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.ref = null;
    this.state = {
      isOpen: false,
      search: "",
    };
  }

  onVariantSelect = (value) => {
    const values = this.props.values;
    const amount = this.props.amount;
    const field_key = this.props.field_key;
    console.log({amount})
    switch (amount) {
      case 1:
        isValue(values) && values.includes(value) ? this.handleValues([null]) : this.handleValues([value[field_key]]);
        this.ref.handleContainer();
        break;
      default:
        if (isValue(values)) {
          if (values.includes(value[field_key])) {
            this.handleValues(values.filter((item) => item !== value[field_key]));
          } else if (values.length !== amount) {
            this.handleValues([...values, value[field_key]]);
          }
        } else this.handleValues([value[field_key]]);
    }
  };

  onParentContainerChange = (isOpen) => {
    !this.props.readOnly && this.setState({ isOpen });
  };

  handleValues = (values) => {
    this.props.onSubmit(values);
  };

  render() {
    const { error, width, values, variants, loading, handling, icon, color, placeholder, fontSize, variantsIcon, readOnly, label, field_key } =
      this.props;

    const HasNoValues = () => {
      return <div {...{ className: cn(styles.EditDropDown_variantsEmpty) }}>Нет совпадений</div>;
    };

    const header = () => {
      return (
        <div
          {...{
            className: cn(styles.EditDropDown_header, error && styles.EditDropDown_headerError),
            style: {
              opacity: readOnly ? 0.6 : 1,
            },
          }}>
          <div
            {...{
              className: cn(styles.EditDropDown_headerValue),
              style: {
                marginLeft: icon && !label ? (fontSize - 2) * LINE_HEIGHT + 4 : 0,
              },
            }}>
            <span
              {...{
                placeholder,
                className: cn("text-dotted-end"),
                style: { fontSize, color },
              }}>
              {values && _.isArray(values) && values[0] && values.join(", ")}
            </span>
          </div>
          {icon && !label && (
            <div
              {...{
                style: {
                  height: fontSize * LINE_HEIGHT,
                  width: fontSize * LINE_HEIGHT,
                },
                className: cn(styles.EditDropDown_headerIcon),
              }}>
              <IconManager {...{ icon, size: fontSize - 3, color }} />
            </div>
          )}
          {handling && (
            <div {...{ className: cn(styles.EditDropDown_headerHandleButton) }}>
              <div
                {...{
                  className: cn(styles.EditDropDown_headerHandleButtonInner),
                  style: {
                    transform: `rotateZ(${this.state.isOpen ? 180 : 0}deg)`,
                  },
                }}>
                <IconManager
                  {...{
                    icon: "chevron-down",
                    size: 10,
                    color: "var(--text-seventh)",
                  }}
                />
              </div>
            </div>
          )}
          {loading && (
            <div
              {...{
                className: cn(styles.EditDropDown_loading),
                style: { right: -(fontSize * LINE_HEIGHT) - 6 },
              }}>
              <Spinner {...{ loading: loading, size: fontSize }} />
            </div>
          )}
        </div>
      );
    };

    return (
      <EditWidgetIconLabelTemplate
        {...{
          fontSize,
          label,
          icon,
        }}>
        <ClickOutsideBoundaryTemplate
          {...{
            handleScrollableDivs: this.props.handleScrollableDivs,
            disabled: loading || readOnly,
            containerMargin: 8,
            style: { width: "100%" },
            headerStyle: {
              width: !!width && width ? width : "100%",
              cursor: readOnly ? "default" : "pointer",
              pointerEvents: readOnly ? "none" : "initial",
            },
            childrenStyle: { width: !!width && width ? width : "100%" },
            header,
            onChange: this.onParentContainerChange,
            ref: (elem) => (this.ref = elem),
          }}>
          <div {...{ className: cn(styles.EditDropDown_variants) }}>
            <div {...{ className: cn(styles.EditDropDown_variantsSearch) }}>
              <IconManager
                {...{
                  style: { position: "absolute", left: 10 },
                  icon: "search",
                  size: 11,
                  color: "var(--text-sixth)",
                }}
              />
              <input
                {...{
                  className: cn(styles.EditDropDown_searchInput),
                  placeholder: "Найти...",
                  value: this.state.search,
                  onChange: (e) => this.setState({ search: e.target.value }),
                }}
              />
            </div>
            {isValue(variants) &&
              variants.map((variant, key) => {
                if (variant.name.toLowerCase().includes(this.state.search.toLowerCase())) {
                  return (
                    <div key={key}>
                      <Variant
                        {...{
                          variant,
                          variantsIcon,
                          fontSize,
                          color,
                          field_key,
                          key,
                          onClick: () => this.onVariantSelect(variant),
                          isActive: isValue(values) && values.includes(variant[field_key]),
                        }}
                      />
                      {variants?.length !== key + 1 && <Separator />}
                    </div>
                  );
                }
              })}
            {!isValue(variants) || variants.filter((variant) => variant.name.toLowerCase().includes(this.state.search.toLowerCase())).length === 0 ? (
              <HasNoValues />
            ) : (
              ""
            )}
          </div>
        </ClickOutsideBoundaryTemplate>
      </EditWidgetIconLabelTemplate>
    );
  }
}

EditDropDown.defaultProps = {
  handleScrollableDivs: true,
  stroked: true,
  loading: false,
  error: false,
  variantsIcon: null,
  icon: false,
  fontSize: 15,
  search: false,
  color: "var(--text-tenth)",
  values: null,
  variants: null,
  onSubmit: null,
  placeholder: "Выбрать",
  handling: false,
  amount: 1,
  field_key: "name",
};

export default EditDropDown;
