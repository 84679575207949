import { IconLink} from "@tabler/icons-react";
import styles from './index.module.css'

const DealRedirect = ({url}: any) => {

  const createRedirect = () => {
    window.open(url, '_blank')
  }

  return (
    <div className={styles.Container} onClick={createRedirect}>
      <div>Перейти</div>
      <IconLink size={14}/>
    </div>
  )
}

export default DealRedirect
