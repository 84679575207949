import "./index.css";
import { VIEW_WIDGETS_PULL } from "@/constants/widgets.tsx";
import { isValue } from "@/helpers/values-validator.js";
import * as _ from "lodash"

const ViewIteration = (props) => {
  const { fields, isGrouped, isRow } = props;

  if (fields) {
    return (
      <div
        {...{
          className: `${isRow ? "flex-row" : "flex-column"}`,
        }}>
        {fields.map((row) => {
          return (
            <div
              {...{
                key: _.uniqueId("row"),
                className: `ViewIteration_row ${isGrouped ? "flex-row justify-between" : "flex-column"}`,
              }}>
              {isValue(Object.values(row)[0].values) && Object.keys(VIEW_WIDGETS_PULL).includes(Object.values(row)[0].view_widget) ? (
                <div
                  {...{
                    key: _.uniqueId("field") + Object.values(row)[0].id,
                    className: "ViewIteration_rowItem",
                  }}>
                  {VIEW_WIDGETS_PULL[Object.values(row)[0].view_widget].component(Object.values(row)[0])}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  } else return null;
};

export default ViewIteration;

ViewIteration.defaultProps = {
  fields: null,
  isGrouped: false,
  isRow: false,
};
