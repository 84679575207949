import React from "react";

const LogoIcon = () => {
	return (
		<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.0625 38C16.8001 38 13.7466 37.1268 11.1236 35.6018C5.8125 32.5259 1.0625 26.5826 1.0625 20.0137C1.0625 24.9404 6.25618 29.1372 11.1889 29.1372C15.3908 29.1372 18.9141 26.2437 19.8668 22.3467C19.9451 21.6429 19.9973 20.9391 19.9973 20.2092V20.1441H20.1277C20.8324 20.1441 21.5371 20.0919 22.2287 20.0137C27.8922 19.336 32.7335 15.9864 35.4739 11.2812C36.9746 13.8489 37.8358 16.8335 37.875 20.0137C37.875 20.0789 37.875 20.1441 37.875 20.2092C37.875 30.0365 29.9018 38 20.0625 38Z"
				fill="url(#paint0_linear_199_1653)"
			/>
			<path
				d="M36.0938 0V0.0641598C36.0938 3.27215 35.234 6.27483 33.7327 8.85405C31.038 13.4864 26.2773 16.7842 20.7082 17.4515C20.0281 17.5285 19.3352 17.5798 18.6423 17.5798H18.514V17.6439C18.514 18.3497 18.4755 19.0555 18.3856 19.7484C17.4489 23.5851 13.9843 26.4338 9.85239 26.4338C5.00191 26.4338 1.0625 22.4944 1.0625 17.6439C1.0625 17.6183 1.0625 17.6054 1.0625 17.5798V17.4515C1.101 14.3205 1.94791 11.3819 3.42358 8.85405C4.95058 6.2235 7.15768 4.0164 9.78823 2.4894C12.3546 1.00089 15.3316 0.141152 18.514 0.12832H18.6423"
				fill="url(#paint1_linear_199_1653)"
			/>
			<defs>
				<linearGradient id="paint0_linear_199_1653" x1="22.8029" y1="11.9329" x2="33.1574" y2="31.4937" gradientUnits="userSpaceOnUse">
					<stop stopColor="#413FC6" />
					<stop offset="1" stopColor="#9997FF" />
				</linearGradient>
				<linearGradient id="paint1_linear_199_1653" x1="45" y1="-4.75" x2="2.84375" y2="22.8594" gradientUnits="userSpaceOnUse">
					<stop stopColor="white" />
					<stop offset="1" stopColor="#7875FE" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default LogoIcon;
