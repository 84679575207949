import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  url: string | null;
}

const initialState: IInitialState = {
  url: "",
};

const imageViewerSlice = createSlice({
  name: "imageViewer",
  initialState,
  reducers: {
    openImageViewer: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    closeImageViewer: (state) => {
      state.url = null;
    },
  },
});

export const imageViewerActions = imageViewerSlice.actions;

export const imageViewerReducer = imageViewerSlice.reducer;
