import { useEffect, useMemo, useState } from "react";

export const useMaxHeight = (padding: number = 30) => {
  const [maxHeight, setMaxHeight] = useState(document.body.getBoundingClientRect().height);

  useEffect(() => {
    let observer: ResizeObserver | null = new ResizeObserver(() =>
      setMaxHeight(document.body.getBoundingClientRect().height)
    );
    observer.observe(document.body);
    return () => {
      observer?.disconnect();
      observer = null;
    };
  }, []);

  return maxHeight - padding;
};
