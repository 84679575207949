import React from "react";
import styles from "./index.module.css";
import IconManager from "../../../Icons/IconManager";
import UserCircle from "../UserCircle";
import cn from "classnames";

const HeaderOwnersCircles = (props) => {
  const { size, data, readOnly, color } = props;

  return (
    <div {...{ className: cn(styles.SingleObjectUsersContainer) }}>
      {data &&
        Object.values(data).map((user, key) => (
          <UserCircle
            {...{
              key: user.id,
              size,
              shadow: true,
              user,
              user_key: key,
            }}
          />
        ))}
      {!readOnly && (
        <div
          {...{
            className: cn(styles.SingleObjectUsers),
            style: {
              width: size,
              height: size,
              borderRadius: size / 2,
              borderColor: color,
              marginLeft: !!Object.keys(data).length > 0 ? -size / 2 : 0,
            },
          }}>
          <IconManager
            {...{
              color: color,
              size: size / 3,
              icon: "user-plus",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderOwnersCircles;
