import Ajv from "ajv";
import { INVALID, responseService, SUCCESS } from "../helpers/response-service";
import { errorService } from "../helpers/error-service";
import { errorResponseService } from "./error-response";

const ajv = new Ajv();

export const RESPONSE_TYPE_JSON = "json";
export const RESPONSE_TYPE_BLOB = "blob";

class Api {
  constructor(client) {
    this.client = client;
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        errorResponseService(error);
        return Promise.reject(error.response);
      }
    );
  }

  async request(url, data = null, method = "get", params = null, signal = null) {
    try {
      return await this.client({ url, data, method, params, signal });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

class ApiEndpoint {
  constructor(where, api, schema) {
    this.where = where;
    this.api = api;
    this.schema = schema;
    this.validate = ajv.compile(this.schema);
  }

  validateResult(response) {
    return this.validate(response);
  }

  transferResult(response) {
    if (this.validateResult(response)) {
      return responseService(SUCCESS, response);
    }
    console.log("%c%s", "color: yellow;font-size: 1.2rem", `Invalid data from ${this.where}`);
    return responseService(INVALID, null);
  }

  async request(url, data = {}, method = "get", params = {}, signal) {
    try {
      const response = await this.api.request(url, data, method, params, signal);
      return this.transferResult(response.data);
    } catch (e) {
      return errorService(e.status, e.data);
    }
  }
}

export { Api, ApiEndpoint };
