import React from "react";
import { useSelector } from "react-redux";
import UserCircle from "../../../kit/Selects/SingleObjectUsers/UserCircle";

const UserContainer = () => {
  const user = useSelector((state) => state.user?.user?.user);

  return (
    <div {...{className: "SideMenu_userContainer flex align-center justify-center"}}>
      <UserCircle {...{user, size: 44}}/>
    </div>
  );
};

export default UserContainer;
