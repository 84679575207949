import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./index.css";
import MainElementSkeleton from "../ElementsSkeleton/MainElementSkeleton";
import SingleObjectFieldGroups from "../ElementsSkeleton/SingleObjectFieldGroups";

const ObjectPageSkeleton = () => {
	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<div {...{ className: "ObjectPageSkeleton" }}>
				<div className={"ObjectPageSkeleton_column"}>
					<MainElementSkeleton />
					<SingleObjectFieldGroups />
					<SingleObjectFieldGroups />
				</div>
			</div>
		</SkeletonTheme>
	);
};

export default ObjectPageSkeleton;
