import cn from "classnames";
import { useRef, useState } from "react";
import { IconChevronDown } from "@tabler/icons-react";

import ModalSelectAction from "../../kit/Modals/ModalSelectAction";
import ModalChangeResponsiblePerson from "../../kit/Modals/ModalChangeResponsiblePerson";
import { ClickOutsideBoundaryTemplate } from "../../templates/ClickOutsideBoundaryTemplate";

import styles from "./index.module.css";

const SelectAction = (props) => {
  const { getObjects } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [showMassTransfer, setShowMassTransfer] = useState(false);
  const [showChangeResponsiblePersonModal, setShowChangeResponsiblePersonModal] = useState(false);

  const ref = useRef(null);

  const handleClickPopupItem = (modalName) => {
    ref.current.handleContainer();

    if (modalName === "mass-transfer") {
      setShowMassTransfer(true);
    }
    if (modalName === "change-responsible-person-modal") {
      setShowChangeResponsiblePersonModal(true);
    }
  };

  return (
    <>
      <ClickOutsideBoundaryTemplate
        ref={ref}
        header={
          <div className={styles.SelectActionButton}>
            <span className={styles.SelectActionButtonText}>Действия</span>
            <div className={cn(styles.SelectActionButtonSelector, isOpen && styles.SelectActionButtonOpen)}>
              <IconChevronDown
                size={19}
                color="var(--text-tenth)"
              />
            </div>
          </div>
        }
        onChange={(value) => setIsOpen(value)}>
        <div className={styles.SelectActionPopupWrapper}>
          <div
            onClick={() => handleClickPopupItem("mass-transfer")}
            className={styles.SelectActionPopupItem}>
            Массовый перенос
          </div>
          <div
            onClick={() => handleClickPopupItem("change-responsible-person-modal")}
            className={styles.SelectActionPopupItem}>
            Смена ответственного
          </div>
        </div>
      </ClickOutsideBoundaryTemplate>
      {showMassTransfer && (
        <ModalSelectAction
          isOpen={showMassTransfer}
          getObjects={getObjects}
          handleClose={() => setShowMassTransfer(false)}
        />
      )}
      {showChangeResponsiblePersonModal && (
        <ModalChangeResponsiblePerson
          isOpen={showChangeResponsiblePersonModal}
          getObjects={getObjects}
          handleClose={() => setShowChangeResponsiblePersonModal(false)}
        />
      )}
    </>
  );
};

export default SelectAction;
