import React from "react";
import "./index.css";
import tabler_sprite from "./../../../../assets/fonts/tabler-sprite.svg";

const NOT_VALID_ICONS_PULL = ["string", "gay-sex.icon"];
const IconManager = ({ icon, size, color, style = {} }) => {
  if (icon) {
    return (
      <div {...{ className: "IconManagerContainer", style: { color, ...style } }}>
        <svg
          style={{ color }}
          width={size * 1.4}
          height={size * 1.4}
          fill={color}
          stroke={color}
          className={"IconManager"}>
          <use href={`${tabler_sprite}#tabler-${NOT_VALID_ICONS_PULL.includes(icon.toLowerCase()) ? "letter-case" : icon.toLowerCase()}`} />
        </svg>
      </div>
    );
  }
  return null;
};

IconManager.defaultProps = {
  icon: "alphabet-latin",
  size: 15,
  // color: "var(--text-tenth)",
};

export default IconManager;
