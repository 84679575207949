import React from "react";
import styles from "./index.module.css";
import { useLocation } from "react-router-dom";
import ChildrenElementsItem from "../ChildrenElementsItem";

const changeToRu = {
  __lead__: "Сделка",
  __client__: "Клиент",
  __task__: "Задача",
};

const ChildrenElements = ({ children, title, navigate, getLocation }) => {
  const location = useLocation();

  return (
    <div {...{ className: styles.SideMenuChildrenContainer }}>
      <div {...{ className: styles.SideMenuChildrenContainer_Title }}>
        <span>{title ? changeToRu[title] : "Untitled"}</span>
        {/*<div {...{className: styles.SideMenuChildrenContainer_Title_Create}}>*/}
        {/*    <IconPlus {...{color: 'var(--text-seventh)', size: 19}}/>*/}
        {/*</div>*/}
      </div>
      <div {...{ className: styles.SideMenuChildrenContainer_Elements }}>
        {children.map((element, key) => {
          return (
            <ChildrenElementsItem
              {...{
                element,
                key,
                index: key,
                navigate,
                onClick: null,
                isActive: location && location.pathname && getLocation(location.pathname) === getLocation(element.condition_path),
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChildrenElements;
