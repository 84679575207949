import StageExtension_MultipleView from "./StageExtension_MultipleView";
import StageExtension_SingleView from "./StageExtension_SingleView";
import StageExtension_ElementView from "./StageExtension_ElementView";
import StageExtension_FieldView from "./StageExtension_FieldView";

export const STAGE_EXTENSION = {
  MULTIPLE_VIEW: {
    component: (props) => <StageExtension_MultipleView {...props} />,
  },
  SINGLE_VIEW: {
    component: (props) => null,
    // <StageExtension_SingleView {...props}/>
  },
  ELEMENT_VIEW: {
    component: () => null,
    // (props) =>  <StageExtension_ElementView {...props}/>
  },
  FIELD_VIEW: {
    component: (props) => <StageExtension_FieldView {...props} />,
    // <StageExtension_FieldView {...props} />
  },
};
