import cn from "classnames";
import React, { useState, useRef, CSSProperties, ReactNode } from "react";
import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { isValue } from "../../../../helpers/values-validator";
import Variant from "../../../templates/DropDownVariantElementTemplate";
import DefaultInputPlaceholder from "../../Inputs/DefaultInputPlaceholder";
import IconManager from "../../Icons/IconManager";

interface SelectDefaultPlaceholderProps<T, VKey extends string> {
  field_key: VKey;
  data: Array<any> | null;
  values: Array<any> | null;
  placeholder?: string;
  onChange?: (values: Array<any>) => void;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  amount?: number;
  notEmpty?: boolean;
  onSubmit?: (values: Array<any>) => void;
  style?: CSSProperties;
  search?: boolean;
}

const SelectDefaultPlaceholder = <T, K extends string, Key extends string>(props: SelectDefaultPlaceholderProps<T, K>) => {
  const {
    field_key,
    data,
    values: _values,
    placeholder,
    onChange,
    notEmpty = true,
    search,
    error,
    errorText,
    disabled,
    amount,
    onSubmit,
    style,
  } = props;
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState("");
  const ref = useRef<any>(null);

  const values = _values || [];

  const handleSelectValue = (value: any) => {
    switch (amount) {
      case 1:
        isValue(values) && values.find((oldValue) => value.id === oldValue.id) && !notEmpty ? handleValues([]) : handleValues([value]);
        ref.current?.handleContainer();
        break;
      default:
        if (isValue(values)) {
          if (values.find((oldValue) => oldValue?.id === value?.id)) {
            handleValues(values.filter((item) => item?.id !== value?.id));
          } else if (values.length !== amount) {
            handleValues([...values, value]);
          }
        } else {
          handleValues([value]);
        }
    }
  };

  const handleValues = (values: Array<any>) => {
    onSubmit && onSubmit(values);
    onChange && onChange(values);
  };

  const handleSelectAll = () => {
    if (amount === 1) return;
    if (data && data.length > 0) {
      if (data.length !== values.length) {
        handleValues(data);
      } else {
        handleValues([]);
      }
    }
  };

  const defaultHeader = (values: Array<any>) => {
    return (
      <div
        {...{
          className: cn(
            "SelectDefaultPlaceholderContainer flex-row pointer",
            (focused || isValue(values)) && `SelectDefaultPlaceholderContainerActive`,
            disabled && "cursor-default",
            error && "SelectDefaultPlaceholderError"
          ),
        }}>
        <label
          {...{
            className: `SelectDefaultPlaceholder_label ${error ? "SelectDefaultPlaceholderError_text" : ""}`,
          }}>
          {!!placeholder ? placeholder : "Выбрать"}
        </label>
        <span {...{ className: "text-dotted-end font-size-16" }}>{isValue(values) ? values.map((item) => item?.[field_key]).join(", ") : null}</span>
        {errorText && <div {...{ className: "SelectDefaultPlaceholderContainerErrorText" }}>{errorText}</div>}
      </div>
    );
  };

  const renderVariants = (variants: Array<any> | null) => {
    const result = variants?.reduce<ReactNode[]>((acc, variant) => {
      const shouldRender = search ? variant?.[field_key].toLowerCase().includes(query.toLowerCase()) : true;
      if (shouldRender) {
        acc.push(
          <Variant
            {...{
              fontSize: 15,
              variant,
              key: variant?.id,
              field_key,
              onClick: () => (!disabled ? handleSelectValue(variant) : null),
              isActive: values?.find((val) => val?.id === variant?.id),
            }}
          />
        );
      }
      return acc;
    }, []);

    return result && result.length > 0 ? (
      result
    ) : (
      <div {...{ className: "EditDropDown_variantsEmpty", style: { padding: "0 14px" } }}>Не найдено</div>
    );
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        style: { ...style, width: style && style.width ? style.width : "100%" },
        headerStyle: { width: "100%" },
        childrenStyle: { width: "100%" },
        ref,
        onChange: !disabled ? (value: boolean) => setFocused(value) : undefined,
        header: search ? (
          <DefaultInputPlaceholder
            focused={focused}
            onChange={setQuery}
            placeholder={placeholder}
            value={query}
          />
        ) : (
          () => defaultHeader(values)
        ),
        disabled,
      }}>
      <div {...{ className: "EditDropDown_variants" }}>
        {amount !== 1 && (
          <div
            onClick={handleSelectAll}
            className={cn("EditDropDown_SelectAll", values.length === data?.length && "EditDropDown_SelectAllActive")}>
            <div>Выбрать всё</div>
            {values.length === data?.length && (
              <div {...{ className: "EditDropDown_SelectAllCheck" }}>
                <IconManager {...{ icon: "check", size: 10, color: "var(--text-eight)" }} />
              </div>
            )}
          </div>
        )}
        {renderVariants(data)}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

SelectDefaultPlaceholder.defaultProps = {
  field_key: "name",
  scrollableParentContainerClass: null,
};

export default SelectDefaultPlaceholder;
