import { Fragment } from "react";
import FormLargeElements from "../../../../templates/FormLargeElements";
import AccordionBase from "../../../../elements/Accordion/AccordionBase/AccordionBase";

const FilterFieldsIteration = (props) => {
  const { data, onSubmit } = props;

  return (
    <div className="flex-column w-100">
      {data.map((schema, schema_key) => (
        <Fragment key={schema_key}>
          <AccordionBase
            label={schema.name}
            meta_name={schema.meta_name}>
            <FormLargeElements
              {...{
                fields: schema.field_filters,
                onSubmit: (values, field_index, field_subIndex) =>
                  onSubmit({
                    schema_key,
                    field_index,
                    field_subIndex,
                    values,
                  }),
              }}
            />
          </AccordionBase>
          {schema_key !== data.length - 1 && <div {...{ className: `SchemaObjectsFilterModal_bodyColumnBreakLine` }} />}
        </Fragment>
      ))}
    </div>
  );
};

export default FilterFieldsIteration;
