import React from "react";
import "./index.css";
import IconManager from "../../kit/Icons/IconManager";

const Variant = (props) => {
  const { variant, isActive, onClick, variantsIcon, fontSize, color, field_key } = props;

  return (
    <div
      {...{
        onClick,
        className: `EditDropDown_variantsItem ${isActive ? "EditDropDown_variantsItemActive" : ""}`,
      }}>
      {variantsIcon && (
        <div {...{ className: "EditDropDown_variantsItemIcon" }}>
          <IconManager {...{ icon: variantsIcon, size: fontSize - 4, color }} />
        </div>
      )}
      <span
        {...{
          className: "text-dotted-end",
          style: { fontSize: fontSize - 2, ...(isActive && { color: "#222" }) },
        }}>
        {variant[field_key]}
      </span>
      {isActive && (
        <div {...{ className: "EditDropDown_variantsItemCheck" }}>
          <IconManager {...{ icon: "check", size: 10, color: "var(--text-eight)" }} />
        </div>
      )}
    </div>
  );
};

export default Variant;
