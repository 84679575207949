import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import styles from "./index.module.css";
import cn from "classnames";
import { imageViewerActions } from "../../../store/reducers/imageViewerSlice";
import IconManager from "../../kit/Icons/IconManager";

const ImageViewer = () => {
  const url = useAppSelector((state) => state.imageViewer.url);
  const dispatch = useAppDispatch();

  const closeViewer = () => {
    dispatch(imageViewerActions.closeImageViewer());
  };

  async function download(url: string) {
    window.open(url, "_blank");
  }

  if (!url) {
    return null;
  }
  return (
    <div
      // onClick={closeViewer}
      className={cn(styles.ImageViewerWrapper)}>
      <img
        src={url}
        alt={"Image"}
        className={cn(styles.ImageViewerElement)}
      />
      <div className={cn(styles.ImageViewerControls)}>
        <div
          onClick={() => download(url)}
          className={cn(styles.ImageViewerDownload)}>
          <IconManager
            size={16}
            icon={"download"}
            color={"#fff"}
          />
        </div>
        <div
          onClick={closeViewer}
          className={cn(styles.ImageViewerClose)}>
          <IconManager
            size={16}
            icon={"x"}
            color={"#000"}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
