import React, { useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { motion } from "framer-motion";
import IconManager from "../../../kit/Icons/IconManager";

const SelectSip = (props) => {
  const { onSelect, data, close } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onSelectItem = (item) => {
    setIsOpen(false);
    onSelect(item);
  };

  return (
    <div {...{ className: cn(styles.SelectSip) }}>
      <div {...{ className: cn(styles.SelectSipTitle) }}>Выберите SIP для звонка</div>

      <div {...{ className: styles.SelectSip_ListSelect }}>
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className={styles.SelectSip_ListSelectHeader}>
          Выбрать SIP
        </div>
        {isOpen && (
          <div className={styles.SelectSip_ListSelectList}>
            {data &&
              data.map((sip) => {
                return <div {...{ className: styles.SelectSip_Item, key: sip.name, onClick: () => onSelectItem(sip) }}>{sip.name}</div>;
              })}
          </div>
        )}
      </div>

      <div
        {...{
          className: cn(styles.SelectSip_Close),
          onClick: close,
        }}>
        <IconManager {...{ icon: "x", color: "var(--text-seventh)", size: 13 }} />
      </div>
    </div>
  );
};

const variants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

export default SelectSip;
