import React from "react";
import "./index.css";
import LeftMenuItem from "./LeftMenuItem";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Separator } from "../../kit/Separator/Separator";

/**
 * @param {
 *     {
 *      values: array,
 *      valueActive: string,
 *      setValueActive: function
 *     }
 * } props
 * @returns {JSX.Element}
 */

const LeftMenu = (props) => {
  const { values, onClick, valueActive, loading } = props;

  const location = useLocation();

  return (
    <div {...{ className: `LeftMenu margin-right-14` }}>
      <div {...{ className: `LeftMenuContainer flex flex-column border-radius-6 padding-17` }}>
        {!loading ? (
          values?.map((value, key) => {
            return (
              <>
                <LeftMenuItem
                  {...{
                    value: value.name,
                    isActive: value.path === location.pathname || valueActive === value,
                    onClick: () => onClick(value.path),
                    key,
                  }}
                />
                {values?.length !== key + 1 && <Separator />}
              </>
            );
          })
        ) : (
          <SkeletonTheme>
            <Skeleton
              count={5}
              style={{ width: "100%", height: 30, marginTop: 14 }}
            />
          </SkeletonTheme>
        )}
      </div>
    </div>
  );
};

export default LeftMenu;
