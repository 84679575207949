import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import DefaultCheckbox from "../../../CheckBoxes/DefaultCheckbox";
import { setSelectedObjects } from "../../../../../store/reducers/selectedObjectsSlice";
import { IconX } from "@tabler/icons-react";

const ModalMultipleViewSelectObjects = (props) => {
  const { optionsOfSelectedObjects, view_id } = props;
  const [isInit, setIsInit] = useState(false);
  const pull = useSelector((state) => state.selectedObjectsSlice.pull);
  const dispatch = useDispatch();

  const containerRef = useRef();

  const openContainer = () => {
    containerRef.current.style.display = "flex";
  };

  const closeContainer = () => {
    containerRef.current.style.display = "none";
    if (Object.keys(pull).length > 0) {
      dispatch(setSelectedObjects({}));
    }
  };

  useEffect(() => {
    closeContainer();
  }, [view_id]);

  useEffect(() => {
    if (Object.keys(pull).length > 0 && isInit === true) {
      openContainer();
    } else {
      if (!isInit) {
        setIsInit(true);
      } else {
        closeContainer();
      }
    }
  }, [pull]);

  useEffect(() => {
    closeContainer();
  }, []);

  return (
    <motion.div
      {...{
        ref: containerRef,
        className: `ModalMultipleViewSelectObjects`,
        variants: CALL_CLIENT_OPEN_VARIANTS,
        animate: isInit ? "opened" : "closed",
        initial: "closed",
      }}>
      <div className="ModalMultipleViewSelectObjectsContainer-checkbox">
        <DefaultCheckbox
          checked={true}
          onChange={() => closeContainer()}
        />
      </div>
      <div className="ModalMultipleViewSelectObjectsContainer-title">
        <span>{Object.keys(pull).length}</span>
        <span style={{ color: "#AEAEC7", fontSize: 14 }}>Выбрано</span>
      </div>
      <div className={`ModalMultipleViewSelectObjectsContainer-options`}>
        {optionsOfSelectedObjects.map((option, key) => (
          <div
            className={"ModalMultipleViewSelectObjectsContainer-option pointer"}
            key={key}
            onClick={() => option.action()}>
            <div>{option.icon}</div>
            <div className={`ModalMultipleViewSelectObjectsContainer-option__name`}>{option.name}</div>
          </div>
        ))}
      </div>
      <div
        className="ModalMultipleViewSelectObjectsContainer-close pointer"
        onClick={() => closeContainer()}>
        <IconX color={"var(--main-text-dark)"} />
      </div>
    </motion.div>
  );
};

export default ModalMultipleViewSelectObjects;

const CALL_CLIENT_OPEN_VARIANTS = {
  opened: {
    zIndex: 99999,
    y: 0,
    opacity: 1,
    transition: {
      bounces: false,
    },
  },
  closed: {
    zIndex: 0,
    y: 250,
    opacity: 0,
    transition: {
      bounces: false,
    },
  },
};
