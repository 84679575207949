import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import ModalMultipleViewAddObjectOwner from "./ModalMultipleViewAddObjectOwner";
import { getRelationsSchema } from "../../../../api/routes/objects_core";
import ModalMultipleViewRemoveObjectOwner from "./ModalMultipleViewRemoveObjectOwner";
import { ModalMultipleViewChangeStatus } from "./ModalMultipleViewChangeStatus";

const ModalMultipleView = (props) => {
  const { schema_id, isAddOwner, isRemoveOwner, handleClose, isChangeStatus } = props;
  const owners = useSelector((state) => state.app.campaign_users);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [schemasForOwners, setSchemasForOwners] = useState([]);
  const [selectedSchemasForOwners, setSelectedSchemasForOwners] = useState([]);

  useEffect(() => {
    if (!isAddOwner && !isRemoveOwner) {
      return;
    }
    let payload = {
      query: {
        schema_id,
      },
    };
    getRelationsSchema(payload).then((res) => {
      if (res.kind !== "SUCCESS") {
      } else {
        setSchemasForOwners(res.data);
      }
    });
  }, [isAddOwner, isRemoveOwner]);

  function handleCloseAndResetModals() {
    setSelectedOwners([]);
    setSelectedSchemasForOwners([]);
    handleClose();
  }

  return (
    <>
      {isAddOwner && (
        <ModalMultipleViewAddObjectOwner
          owners={owners}
          isOpen={isAddOwner}
          selectedOwners={selectedOwners}
          setSelectedOwners={setSelectedOwners}
          schemasForOwners={schemasForOwners}
          selectedSchemasForOwners={selectedSchemasForOwners}
          setSelectedSchemasForOwners={setSelectedSchemasForOwners}
          setSchemasForOwners={setSchemasForOwners}
          handleClose={() => handleCloseAndResetModals()}
          schema_id={schema_id}
        />
      )}
      {isRemoveOwner && (
        <ModalMultipleViewRemoveObjectOwner
          owners={owners}
          isOpen={isRemoveOwner}
          selectedOwners={selectedOwners}
          setSelectedOwners={setSelectedOwners}
          schemasForOwners={schemasForOwners}
          selectedSchemasForOwners={selectedSchemasForOwners}
          setSelectedSchemasForOwners={setSelectedSchemasForOwners}
          setSchemasForOwners={setSchemasForOwners}
          handleClose={() => handleCloseAndResetModals()}
          schema_id={schema_id}
        />
      )}
      {isChangeStatus && (
        <ModalMultipleViewChangeStatus
          isOpen={isChangeStatus}
          handleClose={handleCloseAndResetModals}
        />
      )}
    </>
  );
};

export default ModalMultipleView;
