import axios from "axios";
import { Api, RESPONSE_TYPE_BLOB } from "./api-service";

let HOST = import.meta.env.VITE_API_HOST;

console.log({HOST})

const client = axios.create({
  baseURL: HOST,
});

client.interceptors.request.use(
  (config) => {
    let access_token = localStorage.getItem("access_token");
    if (access_token !== null) {
      config.headers.Authorization = "Bearer " + access_token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const logOut = async () => {
  // console.log("unauthorize");
};

const api = new Api(client, logOut);
export { api, RESPONSE_TYPE_BLOB };
