import React from 'react';
import './index.css'

/**
 * @param {
 *     {
 *      value: string,
 *      valueActive: string,
 *      setValueActive: function
 *     }
 * } props
 * @returns {JSX.Element}
 */

const LeftMenuItem = (props) => {

    const {
        value,
        isActive,
        onClick
    } = props

    return (
        <div {...{
            className: `LeftMenuItem pointer padding-vertical-17 `,
            style: {...{color: isActive? 'var(--purple)' : ''}},
            onClick
        }}>
            <div {...{className: `LeftMenuItemContainer font-size-15`}}>{value}</div>
        </div>
    );
};

export default LeftMenuItem;
