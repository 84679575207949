import React from "react";
import UserVariantsElement from "../UserVariantsElement";
import cn from "classnames";
import styles from "./index.module.css";
import IconManager from "../../../Icons/IconManager";

const UserVariants = (props) => {
  const { data, selected, disabled, onAdd, onDelete, currentUser } = props;
  // console.log(selected);
  const [search, setSearch] = React.useState("");

  const isMe = (user) => {
    return currentUser && user.id * 1 === currentUser.id * 1;
  };

  const SingleObjectUsersUserEmpty = ({ text }) => {
    return <div {...{ className: cn(styles.SingleObjectUsersUserEmpty) }}>{text}</div>;
  };

  return (
    <div {...{ className: cn(styles.SingleObjectUsers_container, "flex-column", "default-scrollbar") }}>
      <div {...{ className: cn(styles.searchUsersInputContainer) }}>
        <IconManager
          {...{
            style: { position: "absolute", left: 10 },
            icon: "search",
            size: 11,
            color: "var(--text-sixth)",
          }}
        />
        <input
          {...{
            className: cn(styles.searchUsersInput),
            placeholder: "Найти...",
            value: search,
            onChange: (e) => setSearch(e.target.value),
          }}
        />
      </div>
      <div {...{ className: cn(styles.SingleObjectUsers_containerGroup, "flex-column") }}>
        {data &&
          data.map((user) => {
            if (user?.name.toLowerCase().includes(search.toLowerCase())) {
              return (
                <UserVariantsElement
                  {...{
                    key: user.id,
                    selected: !!selected[user.id],
                    isMe: isMe(user),
                    user,
                    onAdd: () => onAdd(user),
                    onDelete: () => onDelete(user),
                  }}
                />
              );
            }
          })}
        {data && data.filter((user) => user?.name.toLowerCase().includes(search.toLowerCase())).length === 0 && (
          <SingleObjectUsersUserEmpty {...{ text: "Нет совпадений" }} />
        )}
      </div>
    </div>
  );
};

export default UserVariants;
