import { removeLetters } from "./phone-formatter";

export const validateValuesByType = (field_type, values) => {
  const type = field_type.toLowerCase();
  let array = [];
  switch (type) {
    case "str":
      array = [];
      values.map((value) => {
        array.push(value.toString());
      });
      return array;
    case "price":
      array = [];
      values.map((value) => {
        array.push(removeLetters(value));
      });
      return array;
    case "phone":
      array = [];
      values.map((value) => {
        array.push(removeLetters(value));
      });
      return array;
    case "time":
      array = [];
      if (!new Date(values[0]).getDate()) {
        return undefined;
      }
      values.map((value) => {
        array.push(new Date(value).toISOString());
      });
      return array;
    default:
      array = [];
      values.map((value) => {
        array.push(value.toString());
      });
      return array;
  }
};

export const formatFieldValues = (field_type, values) => {
  const type = field_type.toLowerCase();
  if (type === "boolean") {
    return values === undefined ? ["false"] : values;
  }
  const isContainsValues = isValue(values);
  let array = [];
  if (!isContainsValues) {
    return FIELD_EMPTY_VALUES;
  } else {
    if (type === "price") {
      array = [];
      values.map((value) => {
        array.push(removeLetters(value));
      });
      return array;
    }
    if (type === "phone") {
      array = [];
      values.map((value) => {
        array.push(removeLetters(value));
      });
      return array;
    }
    if (type === "number") {
      array = [];
      values.map((value) => {
        array.push(removeLetters(value));
      });
      return array;
    }
    return values;
  }
};

export const isFieldValueValid = (required, field_type, values) => {
  const type = field_type.toLowerCase();
  const isContainsValues = isValue(values);
  if (type === "boolean") {
    return true;
  }

  if (required && !isContainsValues) {
    return false;
  } else if (isContainsValues) {
    const value = values[0];

    if (type === "string") {
      if (typeof value !== "string") {
        return false;
      }
    }
    if (type === "number") {
      if (typeof value !== "string") {
        return false;
      } else if (!removeLetters(value)) {
        return false;
      }
    }
    if (type === "price") {
      if (typeof value !== "string") {
        return false;
      } else if (!removeLetters(value) || value[0] === "0") {
        return false;
      }
    }
    if (type === "phone") {
      if (typeof value !== "string") {
        return false;
      } else if (removeLetters(value).length !== 11) {
        return false;
      }
    }
    if (type === "time") {
      if (typeof value !== "string") {
        return false;
      } else if (!new Date(value).getDate) {
        return false;
      }
    }
  }
  return true;
};

export const FIELD_EMPTY_VALUES = [""];

export const isValue = (value) => {
  return !!(value && value[0] && value.toString().replace(" ", ""));
};

export const returnBoolean = (value) => {
  return value === "true";
};
