import React from "react";
import "./index.css";
import ConfigIcon from "../../../../kit/Icons/ConfigIcon";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { createObjectComment, sendFile, updateObjectComment } from "../../../../../api/routes/extensions";
import Spinner from "../../../../kit/Loaders/Spinner";
import { connect } from "react-redux";
import IconManager from "../../../../kit/Icons/IconManager";
import { setCommentExtension } from "@/store/reducers/extensionsReducer.jsx";
import EditInput from "../../../../elements/Widgets/Edit/EditInput";

class CreateComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      error: false,
      attachment: null,
      loading: false,
      files: [],
      isEditMode: false,
      fileLoading: false,
      filesUrl: [],
    };
    this.inputRef = null;
    this.isSendFile = this.props.isSendFile;
    this.onChange = this.props.onChange;
    this.object_id = this.props.object_id;
  }

  handleSendFile = (e) => {
    const { files } = e.target;
    this.setState({ files: files });
    if (!files) {
      return;
    }

    let formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const payload = {
      query: {
        object_id: this.object_id,
      },
      data: formData,
    };
    payload.query = new URLSearchParams(payload.query);
    sendFile(payload)
      .then((result) => {
        return result;
      })
      .then((result) => this.setState({ filesUrl: result.data }))
      .catch((e) => console.log(e))
      .finally(() => {
        this.setState({ fileLoading: false });
      });
    this.setState({ fileLoading: true });
    e.target.files = null;
  };

  createComment = (value) => {
    if (!this.fileLoading) {
      const text = value || this.state.text;
      if (!text) {
        return;
      }
      const payload = {
        query: {
          object_id: this.object_id,
        },
        data: {
          text,
          docs: this.state.filesUrl,
        },
      };
      payload.query = new URLSearchParams(payload.query);
      this.setState({ loading: true });
      createObjectComment(payload)
        .then((result) => {
          if (result.kind === "SUCCESS") {
            this.setState({ error: false, text: "", filesUrl: [] });
            this.inputRef.clearInput();
          } else {
            this.setState({ error: true });
          }
        })
        .catch((e) => {
          this.setState({ error: true });
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        });
    }
  };
  deleteFile = (currentIdx) => {
    this.setState({ filesUrl: this.state.filesUrl.filter((file, idx) => currentIdx !== idx) });
  };
  resetEditComment = () => {
    this.inputRef.clearInput();
    this.props.setCommentExtension(null);
  };

  saveEditComment = () => {
    const payload = {
      query: {
        id: this.props.editComment.id,
      },
      data: {
        text: this.state.text,
      },
    };
    payload.query = new URLSearchParams(payload.query);
    this.setState({ loading: true });
    updateObjectComment(payload)
      .then((result) => {
        this.props.setCommentExtension(null);
        this.props.onChange(this.props.editComment, this.state.text);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const comment = this.props.editComment;
    if (prevProps.editComment !== this.props.editComment) {
      comment?.data && this.inputRef.focusInput();
      this.setState({
        text: comment && comment.data ? comment.data.body.text : "",
        isEditMode: comment && comment.data,
      });
    }
  }

  render() {
    return (
      <div {...{ className: "CreateComment flex-row align-start justify-between" }}>
        {/*<div {...{ className: "CreateComment_attachments" }}>*/}
        {/*	<SelectAttachments />*/}
        {/*</div>*/}
        <div {...{ className: "CreateComment_textContainer" }}>
          <EditInput
            {...{
              ref: (el) => (this.inputRef = el),
              inputStyle: { minWidth: 300, width: "100%" },
              style: { width: "100%" },
              label: null,
              icon: null,
              filesUrl: this.state.filesUrl,
              deleteFile: this.deleteFile,
              sendFile: this.handleSendFile,
              fileLoading: this.state.fileLoading,
              fontSize: 15,
              isSendFile: this.isSendFile,
              loading: false,
              error: this.state.error,
              value: this.state.text,
              placeholder: "Оставить комментарий",
              createComment: this.createComment,
              readOnly: false,
              onSubmit: (value) => this.setState({ text: value }),
            }}
          />
        </div>
        <div {...{ className: "CreateComment_sendButton" }}>
          {this.state.loading ? (
            <Spinner
              {...{
                size: 20,
                loading: this.state.loading,
              }}
            />
          ) : !this.state.isEditMode ? (
            <ConfigIcon
              {...{
                onClick: this.state.text ? this.createComment : null,
                className: "CreateComment_sendButton_icon",
                size: 22,
              }}>
              <HiOutlinePaperAirplane style={{ opacity: this.state.fileLoading ? "0.3" : "1" }} />
            </ConfigIcon>
          ) : (
            <EditModeControls {...{ reset: this.resetEditComment, save: this.saveEditComment }} />
          )}
        </div>
      </div>
    );
  }
}

const EditModeControls = ({ reset, save }) => {
  return (
    <div {...{ className: "EditModeControls" }}>
      <div {...{ className: "DeclineEditComment", onClick: reset }}>
        <IconManager {...{ icon: "x", color: "var(--text-seventh)", size: 13 }} />
      </div>
      <div {...{ className: "AcceptEditComment", onClick: save }}>
        <IconManager {...{ icon: "check", color: "#fff", size: 13 }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  editComment: state.extensions.__commentExtension.data,
});

const mapDispatchToProps = (dispatch) => ({
  setCommentExtension: (payload) => dispatch(setCommentExtension(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateComment);
