import React, { useRef, useState, useMemo } from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import IconManager from "../../kit/Icons/IconManager";
import { COLORS } from "../../../constants/types";
import { ClickOutsideBoundaryTemplate } from "../../templates/ClickOutsideBoundaryTemplate";
import { IconChevronDown } from "@tabler/icons-react";
import cn from "classnames";
import { formatPrice } from "../../../helpers/price-formatter";
import Spinner from "../../kit/Loaders/Spinner";
import ChildrenElementsItem from "../SideMenu/ChildrenElementsItem";
import { useNavigate } from "react-router-dom";
import { SchemasMetaNames } from "../../../constants/MetaNamesEnum";

const MetaNameToName = {
  [SchemasMetaNames.LEAD]: "Сделка",
  [SchemasMetaNames.CLIENT]: "Клиент",
  [SchemasMetaNames.TASK]: "Задача",
};

const SelectSchemaFromGroup = ({ schema_id, isCount }) => {
  const schemas = useSelector((state) => state.app.schemas);
  const __groupedObjects_counts = useSelector((state) => state.multiplyObjects.__groupedObjects_counts);
  const ref = useRef(null);
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const currentCount = useMemo(() => {
    return __groupedObjects_counts ? Object.values(__groupedObjects_counts).reduce((acc, item) => (acc += item.count), -1) : 0;
  }, [__groupedObjects_counts]);

  const currentSchemaMetaName = useMemo(() => schemas?.filter((schema) => schema.id === schema_id)[0]?.meta_name, [schemas, schema_id]);

  const formattedByGroups = (data) => {
    const groups = {};
    data.forEach((schema) => {
      if (schema.id === schema_id) {
        setSelected(schema);
      }
      if (groups[schema.meta_name]) {
        groups[schema.meta_name].children.push(schema);
      } else {
        groups[schema.meta_name] = {
          children: [schema],
          group_meta_name: schema.meta_name,
          title: MetaNameToName[schema.meta_name],
        };
      }
    });
    return groups;
  };

  const groups = useMemo(() => {
    if (schemas) {
      return formattedByGroups(schemas);
    }
  }, [schemas, schema_id]);

  const handleSelect = (schema) => {
    let path_name = "/";
    if (schema.meta_name === "__client__") {
      path_name += schema.path;
    } else {
      path_name += "path/" + schema.path;
    }
    navigate(path_name);
  };

  // useEffect(() => {
  //     console.log(currentSchemaMetaName)
  // }, [currentSchemaMetaName])

  const header = () => {
    return (
      <div {...{ className: styles.SelectSchemaFromGroupHeader }}>
        <div {...{ className: styles.SelectSchemaFromGroupHeaderIconTitle }}>
          <div
            {...{
              className: styles.SelectSchemaFromGroupHeaderIcon,
              style: { backgroundColor: selected ? (!!COLORS[selected.color] ? COLORS[selected.color] : `#${selected.color}`) : 0 },
            }}>
            <IconManager
              color="#fff"
              size={16}
              icon={selected.icon}
            />
          </div>
          <div {...{ className: styles.SelectSchemaFromGroupHeaderTitle }}>
            <span>{selected ? MetaNameToName[selected.meta_name] : ""}</span>
            <span {...{ className: "text-dotted-end" }}>{selected ? selected.name : ""}</span>
          </div>
        </div>
        <div {...{ className: "flex-row align-center" }}>
          {isCount && (
            <div {...{ className: styles.SelectSchemaFromGroupHeaderCount }}>
              {currentCount === 0 && <Spinner {...{ size: 12, loading: true }} />}
              {currentCount === -1 && "0"}
              {currentCount > 0 && formatPrice(currentCount)}
            </div>
          )}
          <div {...{ className: cn(styles.SelectSchemaFromGroupHeaderHandleIcon, isOpen && styles.SelectSchemaFromGroupHeaderHandleIconActive) }}>
            <IconChevronDown {...{ size: 19, color: "var(--text-tenth)" }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        header,
        ref,
        onChange: (value) => {
          setIsOpen(value);
        },
      }}>
      <div {...{ className: styles.SelectSchemaFromGroupVariants }}>
        {groups &&
          currentSchemaMetaName &&
          Object.values(groups).map((group) => {
            if (group.group_meta_name === currentSchemaMetaName) {
              return (
                <div {...{ key: group.title, className: styles.SelectSchemaFromGroupVariants_Group }}>
                  <div {...{ className: styles.SelectSchemaFromGroupVariants_Title }}>{group.title}</div>
                  <div {...{ className: styles.SelectSchemaFromGroupVariants_List }}>
                    {group.children.map((element, key) => {
                      return (
                        <ChildrenElementsItem
                          {...{
                            element,
                            key,
                            index: key,
                            navigate: null,
                            onClick: () => handleSelect(element),
                            isActive: selected && selected.id === element.id,
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

SelectSchemaFromGroup.defaultProps = {
  isCount: true,
};

export default SelectSchemaFromGroup;
