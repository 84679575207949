import "./index.css";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import React from "react";

const HorizontalScrollBar = (props) => {
  const x = useSelector((state) => state.position.x);
  const { count } = props;

  const scrollElement = document.getElementsByClassName("MultiplyView_dataContainer")[0];
  const visibleLayout = document.getElementsByClassName("MultiplyView")[0];

  const constraintsRef = React.useRef(null);
  const [dragItemWidth, setWidth] = React.useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const onDrag = (event) => {
    if (constraintsRef.current) {
      const layoutX = constraintsRef.current.getBoundingClientRect().x;
      const layoutT = constraintsRef.current.scrollLeft;
      const itemX = event.target.getBoundingClientRect().x;
      const enabledScrollLeft = visibleLayout.getBoundingClientRect().width;
      if (itemX - layoutX >= 0) {
        scrollElement.scrollTo((itemX - layoutX) * (enabledScrollLeft / dragItemWidth), null);
      }
    }
  };

  React.useEffect(() => {
    let resizeObserver = null;
    if (visibleLayout) {
      resizeObserver = new ResizeObserver(() => {
        const rect = visibleLayout.getBoundingClientRect();
        computeDragItemWidth(rect);
      });
      resizeObserver.observe(visibleLayout);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(visibleLayout);
      }
    };
  }, []);

  const computeDragItemWidth = (rect) => {
    if (constraintsRef.current) {
      const fullWidth = 354 * count + 14;
      const visibleLayoutWidth = rect.width;
      const percent = (100 / fullWidth) * visibleLayoutWidth;
      const dragWrapperWidth = constraintsRef.current.getBoundingClientRect().width;
      const width = (dragWrapperWidth * percent) / 100;
      if (width > dragWrapperWidth) {
        setWidth(dragWrapperWidth);
      } else setWidth(width);
    }
  };

  return (
    <div {...{ className: "HorizontalScrollBar" }}>
      {Array.from(Array(count), (_, key) => (
        <div
          {...{
            key,
            className: "HorizontalScrollBar_column",
            style: { width: 20, marginRight: key + 1 === count ? 0 : 1 },
          }}></div>
      ))}
      <div
        {...{
          ref: constraintsRef,
          className: "HorizontalScrollBar_dragWrapper",
        }}>
        {dragItemWidth !== 0 && (
          <motion.div
            {...{
              style: {
                width: dragItemWidth,
              },
              animate: { x: x },
              transition: { delay: -1 },
              onDrag: (e) => onDrag(e),
              className: "HorizontalScrollBar_dragItem",
              drag: "x",
              dragElastic: 0,
              dragTransition: {},
              dragMomentum: false,
              dragConstraints: constraintsRef,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HorizontalScrollBar;
