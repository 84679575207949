import React from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";
import { EditWidgetsEnum } from "../../../../../constants/types";
import { IFilter } from "../../../../../types/stats";
import DefaultInputPlaceholder from "../../../Inputs/DefaultInputPlaceholder";
import { isValue } from "../../../../../helpers/values-validator";
import SelectDefault from "../../../Selects/SelectDefault";
import SelectDefaultPlaceholder from "../../../Selects/SelectDefaultPlaceholder";
import SelectDatePlaceholder from "../../../Selects/SelectDatePlaceholder";
import PhoneInputPlaceholder from "../../../Inputs/PhoneInputPlaceholder";
import uniqueId from "lodash/uniqueId";

interface FilterField<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  filterField: IFilter;
  name: TName;
  className?: string;
  style?: any;
}

const a = {
  a: [],
  b: [],
};

const FilterField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(props: FilterField<TFieldValues, TName>) => {
  const { filterField, name, className, style } = props;
  const { field } = useController({ name });

  switch (filterField.edit_widget) {
    case EditWidgetsEnum.DROPDOWN:
      return (
        <div className={className}>
          <SelectDefault
            field_key="name"
            search={true}
            placeholder={filterField.name ? filterField.name : "Выбрать"}
            data={isValue(filterField.choice) ? filterField.choice : null}
            onChange={(value: any[]) => field.onChange(value)}
            values={isValue(field.value) ? field.value : null}
            amount={0}
          />
        </div>
      );
    case EditWidgetsEnum.DATETIME:
      return (
        <div className={className}>
          <SelectDatePlaceholder
            style={style}
            placeholder={filterField?.name ? filterField?.name : "Дата и время"}
            date={true}
            time={true}
            onChange={(value: any) => field.onChange([value.isoDateTime])}
            value={isValue(field.value) ? field.value : null}
          />
        </div>
      );
    default:
      return null;
  }
};

export default FilterField;
