import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  pull: {};
}

const initialState: IInitialState = {
  pull: {},
};

const selectedObjectsSlice = createSlice({
  name: "selectedObjectsSlice",
  initialState,
  reducers: {
    setSelectedObjectId: (state, action: PayloadAction<object>) => {
      const id = Object.keys(action.payload)[0];
      if (id in state.pull) {
        // @ts-ignore
        delete state.pull[id];
      } else {
        state.pull = { ...state.pull, ...action.payload };
      }
    },
    setSelectedObjects: (state, action: PayloadAction<{}>) => {
      state.pull = action.payload;
    },
    deleteAllSelectedObjects: (state) => {
      state.pull = {};
    },
  },
});

// export reducers

export const { deleteAllSelectedObjects, setSelectedObjectId, setSelectedObjects } = selectedObjectsSlice.actions;

export const selectedObjectsReducer = selectedObjectsSlice.reducer;
