import React from "react";
import ObjectCardSkeleton from "../ElementsSkeleton/ObjectCardSkeleton";

const NestedObjectsSkeleton = ({ count, style }) => {
	return Array.from(Array(count), (_, key) => {
		return <ObjectCardSkeleton {...{ key, style }} />;
	});
};

export default NestedObjectsSkeleton;
