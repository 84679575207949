import { IconTrash } from "@tabler/icons-react";
import cn from "classnames";
import { isEqual } from "lodash";
import uniqueId from "lodash/uniqueId";
import { ReactNode, useState } from "react";
import { LINE_HEIGHT } from "../../../../../constants/ui-constants";
import { phoneFormatter, removeLetters } from "../../../../../helpers/phone-formatter";
import DefaultButton from "../../../../kit/Buttons/DefaultButton";
import styles from "./index.module.css";

interface EditPhoneProps {
  label: string;
  actionIcon?: ReactNode;
  onActionIconClick?: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean
  loading?: boolean;
  error?: boolean;
  fontSize: number;
  values: string[];
  color: string;
  onSubmit: (values: string[]) => void;
}

interface ValuesWithId {
  name: string;
  id: string;
}

const getValuesWithId = (values: string[]): ValuesWithId[] =>
  values.map((value) => ({
    name: phoneFormatter(value),
    id: uniqueId()
  }));
const isValuesValid = (valuesWithId: ValuesWithId[]) => valuesWithId.every((valueWithId) => removeLetters(valueWithId.name).length === 11);
const isValuesEqual = (valuesWithId: ValuesWithId[], values: (string | ValuesWithId)[]) =>
  isEqual(
    valuesWithId.map((value) => removeLetters(value.name)),
    values.map((value) => removeLetters(typeof value === "string" ? value : value.name))
  );
const EditPhone = ({
                     loading,
                     actionIcon,
                     readOnly,
                     required,
                     onActionIconClick,
                     placeholder,
                     onSubmit,
                     fontSize,
                     label,
                     values,
                     error
                   }: EditPhoneProps) => {
  const [valuesWithId, setValuesWithId] = useState(() => getValuesWithId(values));
  const [_error, setError] = useState(error);

  const handleChange = (value: string, id: string) => {
    let notEqual = false;
    const newValues = valuesWithId.map((oldValue) => {
      if (oldValue?.id === id) {
        notEqual = phoneFormatter(oldValue.name) !== phoneFormatter(value);
        return { id, name: phoneFormatter(value) };
      }
      return oldValue;
    });
    setValuesWithId(newValues);
    if (!error && isValuesValid(newValues) && notEqual) {
      setError(false);
      handleSubmit(newValues);
    }
  };

  const handleAdd = () => {
    if (!isValuesValid(valuesWithId)) {
      setError(true);
      return;
    }
    setValuesWithId((prev) => [...prev, { name: "", id: uniqueId() }]);
  };

  const handleDelete = (id: string) => {
    const newValues = valuesWithId.filter((value) => value?.id !== id);
    handleSubmit(newValues);
    setValuesWithId(newValues);
  };

  const handleSubmit = (valuesWithId: ValuesWithId[]) => {
    if (!onSubmit) {
      return;
    }
    onSubmit(valuesWithId.map((value) => removeLetters(value.name)));
  };

  return (
    <div className={cn(styles.editPhone)}>
      <span
        className={styles.label}
        style={{
          height: LINE_HEIGHT * fontSize,
          fontSize
        }}>
        {label}
      </span>
      <div className={styles.content}>
        <ul className={styles.list}>
          {valuesWithId.map(({ name, id }) => (
            <li
              key={id}
              className={styles.inputContainer}
              style={{
                height: LINE_HEIGHT * fontSize,
                fontSize
              }}>
              <div
                className={cn(styles.actionIconContainer, (!name || removeLetters(name).length !== 11) && styles.actionIconDisabled)}
                onClick={() => onActionIconClick && onActionIconClick(name)}>
                {actionIcon}
              </div>
              <input
                disabled={loading || readOnly}
                className={cn(styles.input, _error && styles.inputError, readOnly && styles.readOnly)}
                value={name}
                onChange={(e) => handleChange(e.target.value, id)}
                placeholder={placeholder}
              />
              {!readOnly && !required && valuesWithId.length && (
                <IconTrash
                  className={styles.deleteIcon}
                  onClick={() => handleDelete(id)}
                />
              )}
            </li>
          ))}
        </ul>
        {
          !readOnly && !valuesWithId.length && <DefaultButton
            disabled={_error}
            border={true}
            text={"добавить"}
            height={30}
            onClick={handleAdd}
          />
        }
      </div>
    </div>
  );
};

EditPhone.defaultProps = {
  placeholder: "+7 (000) 000 00 00",
  label: null,
  icon: "phone",
  fontSize: 15,
  error: false,
  loading: false,
  color: "var(--text-tenth)"
};

export default EditPhone;
