import React from "react";
import "./index.css";
import LogTemplate from "../../../../templates/LogTemplate";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SingleLogSkeleton = ({ left = false, right = false }) => {
	const CircleLoadingContainer = () => {
		return (
			<Skeleton
				{...{
					width: 40,
					height: 40,
					borderRadius: 20,
					marginTop: 0,
					style: { marginTop: 0, padding: 0 },
				}}
			/>
		);
	};

	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<LogTemplate
				{...{
					leftContainer: left ? <CircleLoadingContainer /> : null,
					rightContainer: right ? <CircleLoadingContainer /> : null,
				}}
			>
				<div {...{ className: "HeavyLogSkeleton" }}>
					<Skeleton
						{...{
							width: "100%",
							height: !left && !right ? 20 : 60,
							borderRadius: 6,
							style: { marginTop: !left && !right ? 9 : 0, padding: 0 },
						}}
					/>
				</div>
			</LogTemplate>
		</SkeletonTheme>
	);
};

export default SingleLogSkeleton;
