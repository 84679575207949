import { appTypes } from "../types/appTypes";

export const setNotification = (payload) => {
	return {
		type: appTypes.SET_NOTIFICATION,
		payload,
	};
};

export const app_SetCamapignUsers = (payload) => {
	return {
		type: appTypes.SET_CAMPAIGN_USERS,
		payload,
	};
};

export const deleteNotification = (payload) => {
	return {
		type: appTypes.DELETE_NOTIFICATION,
		payload
	}
}

export const setSchemasRoutes = (payload) => {
	return {
		type: appTypes.SET_SCHEMAS_PULL,
		payload
	}
}

export const setAudioPlayer = (payload) => {
	return {
		type: appTypes.SET_AUDIO_PLAYER,
		payload
	}
}
