import React from "react";
import "./index.css";
import EditPhone from "../../../elements/Widgets/Edit/EditPhone";
import { isValue } from "../../../../helpers/values-validator";
import { setCallExtension } from "../../../../store/reducers/extensionsReducer";
import { useDispatch } from "react-redux";
import IconManager from "../../../kit/Icons/IconManager";
import { phoneFormatter, removeLetters } from "../../../../helpers/phone-formatter";
import { updateObject } from "../../../../api/routes/objects_core";
import { setNotification } from "../../../../store/actions/appActions";
import { WHATSAPP_PHONE_EMPTY } from "../../../../constants/notification-texts";
import { IconPhone } from "@tabler/icons-react";

const CallExtension_FieldView = (props) => {
  const { data, object_id, schemaKey } = props;

  const [state, setState] = React.useState(data);

  const createStoreAction = useDispatch();

  const onChangeField = (value) => {
    let isError = false;
    const cleanPhone = value.map((value) => {
      const cleanPhone = removeLetters(value);
      if (cleanPhone.length !== 11) {
        isError = true;
      }
      return cleanPhone;
    });
    setState((prev) => ({
      ...prev,
      error: isError,
      values: cleanPhone,
    }));
  };

  const onSubmitField = (value) => {
    const payload = {
      query: object_id,
      data: [
        {
          field_id: data.id,
          values: value?.map((item) => removeLetters(item)),
        },
      ],
    };
    updateObject(payload).then((result) => {});
  };

  const makeCall = (value) => {
    const phone = isValue(value) ? removeLetters(value) : null;

    if (!phone || phone.length !== 11) {
      setState((prev) => ({
        ...prev,
        error: true,
      }));
      createStoreAction(setNotification(WHATSAPP_PHONE_EMPTY));
    } else {
      const payload = {
        object_id,
        phone: {
          value: phone,
        },
      };
      createStoreAction(setCallExtension(payload));
    }
  };

  return (
    <div {...{ className: "CallExtension_FieldView flex-row align-center justify-between" }}>
      <div {...{ className: "CallExtension_FieldViewField" }}>
        <EditPhone
          {...{
            label: "Телефон",
            fontSize: 15,
            loading: false,
            error: state.error,
            placeholder: "Номер для звонка",
            values: isValue(state.values) ? state.values : [""],
            readOnly: state.readOnly || +schemaKey !== state.schema_id,
            onSubmit: (value) => onSubmitField(value),
            onChange: (value) => onChangeField(value),
            actionIcon: <IconPhone className="CallExtension_call-btn" />,
            onActionIconClick: (value) => makeCall(value),
          }}
        />
      </div>
    </div>
  );
};

export default CallExtension_FieldView;
