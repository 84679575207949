import DefaultButton from "../../Buttons/DefaultButton";

interface BottomProps {
	submitLabel: string;
	cancelLabel: string;
	onCancel: () => void;
	loading: boolean;
	error?: boolean;
}

const Bottom = ({ submitLabel, cancelLabel, onCancel, loading, error }: BottomProps) => {
	return (
		<div className="flex justify-end">
			<div
				{...{
					className: "margin-right-30",
				}}>
				<DefaultButton
					{...{
						type: "button",
						disabled: loading,
						text: cancelLabel,
						onClick: onCancel,
						fitContent: true,
						style: { backgroundColor: "transparent" },
						color: "var(--main-text-dark)",
					}}
				/>
			</div>
			<DefaultButton
				{...{
					type: "submit",
					style: { backgroundColor: error ? "var(--red)" : undefined },
					disabled: loading,
					loading: loading,
					text: submitLabel,
					fitContent: true,
				}}
			/>
		</div>
	);
};

export default Bottom;
