import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./index.css";

const textItemWidth = 65
const iconSize = 20

const SelectViewTabsSkeleton = (props) => {

	const {
		icons,
		height
	} = props

	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<div className={"SelectViewTabsSkeleton flex-row"} style={{height}}>
				{Array.from(Array(3), (_, key) => {
					return (
						<div key={key} className={"SelectViewTabsSkeleton_element flex-row align-center"}>
							{icons && <Skeleton {...{ style: { width: iconSize, height: iconSize, borderRadius: 4, marginRight: 10 } }} />}
							<Skeleton {...{ style: { width: textItemWidth, height: iconSize, borderRadius: 4 } }} />
						</div>
					)
				})}
			</div>
		</SkeletonTheme>
	);
};

SelectViewTabsSkeleton.defaultProps = {
	height: 30,
	icons: false
}

export default SelectViewTabsSkeleton;
