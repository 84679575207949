import React from "react";
import "./index.css";
import IconManager from "../../Icons/IconManager";

const SelectOpenClosedStages = (props) => {
  const { data, value, onChange } = props;

  const onClick = (value) => {
    onChange(value);
  };

  return (
    <div {...{ className: "SelectOpenClosedStages flex-row align-center" }}>
      {data.map((item) => {
        return <Stage {...{ key: item.icon, item, onClick, isActive: value && value.icon === item.icon }} />;
      })}
    </div>
  );
};

const Stage = ({ item, onClick, isActive }) => {
  return (
    <div
      {...{
        onClick: () => onClick(item),
        className: `SelectOpenClosedStages_item ${isActive ? "SelectOpenClosedStages_itemActive" : ""} flex-row align-center`,
      }}>
      <IconManager {...{ icon: item.icon, color: "var(--text-tenth)", size: 13 }} />
      {/*<span>{item.name}</span>*/}
    </div>
  );
};

export default SelectOpenClosedStages;
