// @ts-nocheck
import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "./reducers/appReducer";
import extensionsReducer from "./reducers/extensionsReducer";
import { reportsReducer } from "./reducers/reportsSlice";
import { roleReducer } from "./reducers/roleSlice";
import { settingsReducer } from "./reducers/settingsSlice";
import { userReducer } from "./reducers/userReducer";
import { singleObjectReducer } from "./reducers/singleObjectReducer";
import multiplyObjectsReducer from "./reducers/multiplyObjectsReducer";
import { selectedObjectsReducer } from "./reducers/selectedObjectsSlice";
import { positionReducer } from "./reducers/positionSlice";
import { imageViewerReducer } from "./reducers/imageViewerSlice";
import {windowReducer} from "./reducers/windowSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    singleObject: singleObjectReducer,
    multiplyObjects: multiplyObjectsReducer,
    extensions: extensionsReducer,
    settings: settingsReducer,
    role: roleReducer,
    reports: reportsReducer,
    selectedObjectsSlice: selectedObjectsReducer,
    position: positionReducer,
    imageViewer: imageViewerReducer,
    window: windowReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
