import React, { CSSProperties } from "react";
import "./index.css";
import {motion} from "framer-motion";

interface SpinnerProps {
  size: number;
  style?: CSSProperties;
  loading?: boolean;
  className?: string;
  color?: string;
}

const Spinner = ({ size, style, loading, className, color }: SpinnerProps) => {
  if (loading) {
    return (

        <motion.div {...{ style, className, initial: {opacity: 0 }, animate: {opacity: 1}, exit: {opacity: 0}}}>
          <svg
            {...{
              style: { width: size, height: size },
              className: `loading-spinner`,
              viewBox: `0 0 ${size * 2} ${size * 2}`,
            }}>
            <circle
              {...{
                className: "loading-spinner__path",
                stroke: color ? color : "#d3d3d3",
                cx: size,
                cy: size,
                r: size - (1 / 5) * size,
                fill: "none",
                strokeWidth: 3,
              }}></circle>
          </svg>
        </motion.div>

    );
  } else return null;
};

export default Spinner;
