import cn from "classnames";
import "./Separator.css";
import type { HTMLAttributes } from "react";

interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
  width?: number;
  color?: keyof typeof colorMap;
  className?: string;
  orientation?: "horizontal" | "vertical";
}

const colorMap = {
  darkest: "Separator_dark",
  light: "Separator_light",
};

export const Separator = ({ color = "light", width = 1, className, orientation = "horizontal", ...props }: SeparatorProps) => {
  return (
    <div
      {...props}
      className={cn(colorMap[color], className)}
      style={{
        height: orientation === "vertical" ? "100%" : width,
        width: orientation === "horizontal" ? "100%" : width,
      }}
    />
  );
};
