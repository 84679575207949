import React from "react";
import "./index.css";

const LogTemplate = (props) => {

	const {
		children,
		leftContainer,
		rightContainer
	} = props;

	return (
		<div
			{...{ className: "LogTemplateContainer"}}
		>
			<div
				{...{
					className: "LogTemplateContainer_connectLine",
				}}
			></div>
			<div {...{ className: "LogTemplateContainer_leftArea" }}>{leftContainer ? <div {...{ className: "LogTemplateContainer_leftAreaContent" }}>{typeof leftContainer === "function" ? leftContainer() : leftContainer}</div> : null}</div>
			<div
				{...{
					className: "LogTemplateContainer_centerArea",
				}}
			>
				{children}
			</div>
			<div {...{ className: "LogTemplateContainer_rightArea" }}>{rightContainer ? <div {...{ className: "LogTemplateContainer_rightAreaContent" }}>{typeof rightContainer === "function" ? rightContainer() : rightContainer}</div> : null}</div>
		</div>
	);
};

export default LogTemplate;

LogTemplate.defaultProps = {
	children: null,
	leftContainer: null,
	rightContainer: null
}
