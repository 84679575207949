import React from 'react';
import styles from './index.module.css'
import cn from "classnames";
import SchemaObjectsFilterModal from "../../../kit/Modals/SchemaObjectsFilterModal";
import SelectIconAction from "../../../kit/Selects/SelectIconAction";
import { BiDotsVerticalRounded, BiPlus } from "react-icons/bi";
import IconButton from "../../../kit/Buttons/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { TbEdit, TbExternalLink } from "react-icons/tb";
import { getUrl } from "../../../../helpers/window-helpers";
import { setNotification } from "../../../../store/actions/appActions";
import { COPY_LINK } from "../../../../constants/notification-texts";
import { useDispatch } from "react-redux";
import SelectSchemaFromGroup from "../../SelectSchemaFromGroup";

const UngroupedViewHeader = (props) => {

	const {
		schema,
		getObjects
	} =props

	const navigate = useNavigate()
	const createStoreAction = useDispatch()
	const location = useLocation()

	const selectActionData = [
		{
			name: "Ссылка на доску",
			icon: <TbExternalLink />,
			action: () => getUrl().then(() => createStoreAction(setNotification(COPY_LINK))),
		},
		{
			name: "Редактировать",
			icon: <TbEdit />,
			action: () => navigate("/settings/structure"),
		},
	];

	return (
		<div {...{className: cn(styles.ungroupedViewHeader)}}>
			<div {...{className: 'flex-row'}}>
				<SelectSchemaFromGroup {...{schema_id: schema.id, isCount: false}}/>
				{/*<div {...{className: cn(styles.ungroupedViewHeaderSchemaName)}}>*/}
				{/*	{schema.name}*/}
				{/*</div>*/}
			</div>
			<div {...{className: cn(styles.ungroupedViewHeaderRightControls)}}>
				<SchemaObjectsFilterModal
					{...{
						ungrouped: true,
						style: { marginRight: 6 },
						getObjects,
						schema_id: schema.id,
					}}
				/>
				<SelectIconAction
					{...{
						style: { background: "var(--card-background)" },
						headerSize: 55,
						className: cn("margin-right-6"),
						data: selectActionData,
						icon: <BiDotsVerticalRounded />,
					}}
				/>
				<IconButton
					{...{
						onClick: () => navigate(location.pathname + "/new"),
						color: "#FFF",
						size: 24,
						width: 55,
						height: 55,
						icon: <BiPlus />,
						style: { background: "var(--primary-color)", height: 60 },
					}}
				/>
			</div>
		</div>
	)
}

export default UngroupedViewHeader
