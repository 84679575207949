import React from "react";
import { Navigate } from "react-router-dom";
import LoggedLayout from "../layouts/LoggedLayout";
import Reports from "../pages/logged/Reports";
import SingleObject from "../pages/logged/SingleObject";
import MultiplyView from "../pages/logged/MultiplyView";
import Redirect from "../pages/logged/Redirect";
import Extension_ApprovePage from "../pages/logged/Extensions/Approve";
import Extension_DeadLinePage from "../pages/logged/Extensions/DeadLine";
import { useSelector } from "react-redux";
import UngroupedView from "../pages/logged/UngroupedView";
import SettingsBilling from "../pages/logged/Settings/SettingsBilling";
import SettingsIntegrations from "../pages/logged/Settings/SettingsIntegrations";
import Settings from "../pages/logged/Settings/Settings";
import { SettingsStructureSchemas } from "../pages/logged/Settings/SettingsStructure/SettingsStructureSchemas";
import { SettingsStructure } from "../pages/logged/Settings/SettingsStructure";
import { SettingsUsers } from "../pages/logged/Settings/SettingsUsers";
import * as _ from "lodash"

const schemasPagesConstructor = (root, values) => {
  if (!values) {
    return { items: [], default_path: `${root}/reports` };
  }

  let array = [];

  values.forEach((schema) => {
    if (UNGROUPED_VIEW_PULL.includes(schema.meta_name)) {
      array = array.concat(createSchemaUngroupedView(schema, root));
    } else {
      array = array.concat(createSchemaMultiplyView(schema, root));
    }
  });

  return {
    items: array,
    default_path: `${root}/${values[0].path}`,
  };
};

const UNGROUPED_VIEW_PULL = ["__client__", "__client2__", "__clients__", "__clients2__"];

export const logged = (root) => {
  const schemas = useSelector((state) => state.app.schemas);

  const getRoutesPull = (schemas) => {
    return schemasPagesConstructor(root, schemas);
  };

  const getIterableByIsset = (schemas, key) => {
    const routes = getRoutesPull(schemas);
    if (schemas && _.isArray(schemas) && routes && routes.items) {
      return routes[key];
    } else return null;
  };

  const default_path = getIterableByIsset(schemas, "default_path");
  const redirect_isset_path = default_path ? default_path : "/reports";

  const devRoutes = [
    {
      path: `settings`,
      element: <Settings />,
      menu: true,
      icon: "settings",
      name: "Настройки",
      children: [
        {
          path: `users`,
          element: <SettingsUsers />,
          show: true,
          name: "Сотрудники и настройка доступа",
        },
        {
          path: `billing`,
          element: <SettingsBilling />,
          show: true,
          name: "Подписка",
        },
        {
          path: `structure`,
          show: true,
          name: "Объекты и поля",
          children: [
            {
              element: <SettingsStructureSchemas />,
              show: true,
              index: true,
              key: "structure",
              // name: "Объекты и поля",
            },
            {
              path: `:schema_id`,
              element: <SettingsStructure />,
              show: true,
              // name: "Объекты и поля",
            },
          ],
        },
        {
          path: `integrations`,
          element: <SettingsIntegrations />,
          show: true,
          name: "Интеграции",
        },
      ],
    },
  ];

  const prodRoutes = [
    ...(getIterableByIsset(schemas, "items") ? getIterableByIsset(schemas, "items") : []),
    // Route = Template for grouped view elements
    {
      path: `${root}/:schema_key`,
      element: <MultiplyView />,
      menu: false,
      icon: null,
      name: "",
    },
    {
      path: `${root}/reports`,
      element: <Reports />,
      menu: true,
      icon: "file-analytics",
      name: "Отчеты",
    },
    {
      path: `${root}/settings/`,
      element: <Navigate {...{ to: `${root}/settings/users` }} />,
    },
    // {
    //   path: `${root}/settings/*`,
    //   element: <Navigate {...{ to: `${root}/settings/users` }} />,
    // },
    {
      path: `${root}/`,
      element: <Navigate {...{ to: redirect_isset_path }} />,
    },
    {
      path: `${root}/*`,
      element: <Navigate {...{ to: redirect_isset_path }} />,
    },
    {
      path: `${root}/path_redirect`,
      element: <Navigate {...{ to: redirect_isset_path }} />,
    },
  ];

  return [
    {
      path: `${root}/`,
      element: <LoggedLayout />,
      children: process.env.NODE_ENV === "production" ? prodRoutes : [...prodRoutes, ...devRoutes],
    },
  ];
};

const createSchemaMultiplyView = (schema, root) => {
  // console.log('schema', schema)
  let array = [];
  array.push({
    path: `${root}/path/${schema.key}`,
    condition_path: `${root}/${schema.key}`,
    element: <Redirect {...{ redirect_path: `${root}/${schema.key}` }} />,
    menu: schema.menu,
    icon: schema.icon,
    color: schema.color,
    name: schema.name,
    meta_name: schema.meta_name,
    id: schema.id,
    // id:
  });
  array.push({
    path: `${root}/${schema.key}/new`,
    element: <SingleObject {...{ schema_id: schema.id, root_object_path: `/${schema.key}/` }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  array.push({
    path: `${root}/${schema.key}/:object_id`,
    element: <SingleObject {...{ root_object_path: `/${schema.key}/` }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  array.push({
    path: `${root}/${schema.key}/approve`,
    element: <Extension_ApprovePage {...{ schema }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  array.push({
    path: `${root}/${schema.key}/deadlines`,
    element: <Extension_DeadLinePage {...{ schema }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  array.push({
    path: `${root}/${schema.key}/deadlines/lead-manager`,
    element: <Extension_DeadLinePage {...{ schema }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  array.push({
    path: `${root}/${schema.key}/deadlines/lead-remote`,
    element: <Extension_DeadLinePage {...{ schema }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  return array;
};

const createSchemaUngroupedView = (schema, root) => {
  let array = [];
  array.push({
    path: `${root}/${schema.key}`,
    condition_path: `${root}/${schema.key}`,
    element: <UngroupedView {...{ schema }} />,
    menu: schema.menu,
    icon: schema.icon,
    color: schema.color,
    name: schema.name,
    meta_name: schema.meta_name,
    id: schema.id,
  });
  array.push({
    path: `${root}/${schema.key}/new`,
    element: <SingleObject {...{ schema_id: schema.id, root_object_path: `/${schema.key}/` }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  array.push({
    path: `${root}/${schema.key}/:object_id`,
    element: <SingleObject {...{ root_object_path: `/${schema.key}/` }} />,
    menu: false,
    icon: null,
    color: schema.color,
    name: null,
  });
  return array;
};
