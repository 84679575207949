import React from "react";
import "./index.css";
import ConfigIcon from "../../Icons/ConfigIcon";
import { FaRegCalendar } from "react-icons/fa";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import { getShortRuDate, getShortRuDateHoursMinutes } from "../../../../helpers/date-formatter";
import SelectDate from "../SelectDatePlaceholder";

const defaultColor = "#DBDBEB";

const SingleObjectComplete = (props) => {
	const { style, value, onSubmit, error, loading, disabled, onChange } = props;

	const header = () => {
		return (
			<div {...{ className: "SingleObjectCompleteActive" }}>
				<div
					{...{
						className: "SingleObjectComplete",
						style: { borderColor: defaultColor },
					}}
				>
					<ConfigIcon {...{ color: defaultColor }}>
						<FaRegCalendar />
					</ConfigIcon>
				</div>
				{value && value[0] && value[0].toISOString() && <div {...{ className: "SingleObjectCompleteActiveDate" }}>{getShortRuDate(value[0]) + " " + getShortRuDateHoursMinutes(value[0])}</div>}
			</div>
		);
	};

	const handleValues = (values) => {
		onChange(values);
		onSubmit(values);
	};

	return (
		<ClickOutsideBoundaryTemplate
			{...{
				style,
				header,
			}}
		>
			<SelectDate
				{...{
					time: true,
					range: false,
					date: value && value[0] ? value[0] : null,
					setDate: handleValues,
				}}
			/>
		</ClickOutsideBoundaryTemplate>
	);
};

export default SingleObjectComplete;
