import React from "react";
import IconManager from "../../../kit/Icons/IconManager";
import { COLORS } from "../../../../constants/types";
import styles from "./index.module.css";
import cn from "classnames";
import { IconPencil } from "@tabler/icons-react";
import { ElementColors } from "../../../../constants/types";
import { colorGenerator } from "../../../../helpers/color-generator";

const ChildrenElementsItem = ({ element, index, navigate, isActive, onClick }) => {
  return (
    <div
      {...{
        className: cn(styles.ChildrenElementsItem, isActive && styles.ChildrenElementsItemActive),
        onClick: onClick ? onClick : () => navigate(element.path, 1000),
      }}>
      <div
        {...{
          className: styles.ChildrenElementsItem_Icon,
          style: { backgroundColor: !!COLORS[element.color] ? COLORS[element.color] : `#${element.color}` },
        }}>
        <IconManager
          color="#fff"
          size={12}
          icon={element.icon}
        />
      </div>
      <div {...{ className: cn(styles.ChildrenElementsItem_Title, "text-dotted-end") }}>{element.name}</div>
      {/*<div {...{className: styles.ChildrenElementsItem_Count}}>*/}
      {/*    5*/}
      {/*</div>*/}
    </div>
  );
};

export default ChildrenElementsItem;
