import React from "react";
import { EditWidgetsEnum } from "../../../../../../constants/types";
import DefaultInputPlaceholder from "../../../../Inputs/DefaultInputPlaceholder";
import { isValue } from "../../../../../../helpers/values-validator";
import SelectDefaultPlaceholder from "../../../../Selects/SelectDefaultPlaceholder";
import SelectDatePlaceholder from "../../../../Selects/SelectDatePlaceholder";
import PhoneInputPlaceholder from "../../../../Inputs/PhoneInputPlaceholder";
import EditCheckbox from "../../../../../elements/Widgets/Edit/EditCheckbox";
import SelectDefault from "../../../../Selects/SelectDefault";

const FilterField = (props) => {
  const { field, onSubmit, className } = props;

  if (!field) {
    return null;
  }
  const handleSubmitField = (values) => {
    onSubmit(values);
  };

  switch (field.edit_widget) {
    case EditWidgetsEnum.INPUT:
      return (
        <div {...{ className }}>
          <DefaultInputPlaceholder
            {...{
              placeholder: field.name ? field.name : "Значение",
              type: field.type,
              onSubmit: (value) => handleSubmitField([value]),
              value: isValue(field.selected) ? field.selected[0] : "",
            }}
          />
        </div>
      );
    case EditWidgetsEnum.DROPDOWN:
      return (
        <div {...{ className }}>
          <SelectDefault
            {...{
              search: true,
              field_key: "name",
              placeholder: field.name ? field.name : "Выбрать",
              data: isValue(field.choice) ? field.choice : null,
              onChange: (values) => handleSubmitField(values),
              values: isValue(field.selected) ? field.selected : null,
              amount: field.values_amount === -1 ? field.choice.length : field.values_amount,
            }}
          />
        </div>
      );
    case EditWidgetsEnum.DATE:
      return (
        <div {...{ className }}>
          <SelectDatePlaceholder
            {...{
              placeholder: field.name ? field.name : "Дата",
              date: true,
              time: false,
              onSubmit: (value) => handleSubmitField([value.isoDate]),
              value: isValue(field.selected) ? field.selected[0] : null,
            }}
          />
        </div>
      );
    case EditWidgetsEnum.TIME:
      return (
        <div {...{ className }}>
          <SelectDatePlaceholder
            {...{
              placeholder: field.name ? field.name : "Время",
              date: false,
              time: true,
              onSubmit: (value) => handleSubmitField([value.isoTime]),
              value: isValue(field.selected) ? field.selected[0] : null,
            }}
          />
        </div>
      );
    case EditWidgetsEnum.DATETIME:
      return (
        <div {...{ className }}>
          <SelectDatePlaceholder
            {...{
              placeholder: field?.name ? field?.name : "Дата и время",
              date: true,
              time: true,
              onSubmit: (value) => handleSubmitField([value.isoDateTime]),
              value: isValue(field.selected) ? field.selected[0] : null,
            }}
          />
        </div>
      );
    case EditWidgetsEnum.LINK:
      return (
        <div {...{ className }}>
          <DefaultInputPlaceholder
            {...{
              placeholder: field.name ? field.name : "Текст",
              onSubmit: (value) => handleSubmitField([value]),
              value: isValue(field.selected) ? field.selected[0] : "",
            }}
          />
        </div>
      );
    case EditWidgetsEnum.PRICE:
      return (
        <div {...{ className }}>
          <DefaultInputPlaceholder
            {...{
              placeholder: field.name ? field.name : "Текст",
              type: field.type,
              onSubmit: (value) => handleSubmitField([value]),
              value: isValue(field.selected) ? field.selected[0] : "",
            }}
          />
        </div>
      );
    case EditWidgetsEnum.PHONE:
      return (
        <div {...{ className }}>
          <DefaultInputPlaceholder
            {...{
              placeholder: field.name ? field.name : "Телефон",
              type: "NUMBER",
              onSubmit: (value) => handleSubmitField([value]),
              value: isValue(field.selected) ? field.selected[0] : "",
            }}
          />
        </div>
        // <div {...{className, key: _.uniqueId()}}>
        //     <PhoneInputPlaceholder
        //         {...{
        //             placeholder: field.name ? field.name : "Телефон",
        //             onSubmit: (value) => handleSubmitField([value]),
        //             value: isValue(field.values) ? field.values[0] : "",
        //         }}
        //     />
        // </div>
      );
    case EditWidgetsEnum.EMAIL:
      return (
        <div {...{ className }}>
          <DefaultInputPlaceholder
            {...{
              placeholder: field.name ? field.name : "Текст",
              onSubmit: (value) => handleSubmitField([value]),
              value: isValue(field.selected) ? field.selected[0] : "",
            }}
          />
        </div>
      );
    case EditWidgetsEnum.CHECKBOX:
      return (
        <div className="flex align-center justify-between">
          <div
            className="margin-right-7"
            style={{ color: "var(--main-text-dark)" }}>
            {field.name}
          </div>
          <div {...{ className, style: { width: 20 } }}>
            <EditCheckbox
              {...{
                onChange: (value) => {
                  handleSubmitField([value]);
                },
                value: isValue(field.selected) ? field.selected[0] : "",
              }}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default FilterField;
