import { useState } from "react";
import { multiplyObjects_ChangeStatus } from "../../../../../store/reducers/multiplyObjectsReducer";
import { changeObjectStatuses } from "../../../../../api/routes/extensions";
import { KindEnum } from "../../../../../helpers/response-service";
import { useNotification } from "../../../../../hooks/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useRedux";
import { setSelectedObjects } from "../../../../../store/reducers/selectedObjectsSlice";
import { IStageExtension } from "../../../../../types/extension";
import { ClickOutsideBoundaryTemplate } from "../../../../templates/ClickOutsideBoundaryTemplate";
import SelectDefaultObjectStatus from "../../../Selects/SelectDefaultObjectStatus";
import Bottom from "../../Bottom";
import ModalPopup from "../../ModalPopup";

interface ModalMultipleViewChangeStatusProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ModalMultipleViewChangeStatus = (props: ModalMultipleViewChangeStatusProps) => {
  const { isOpen, handleClose } = props;

  const [showStatuses, setShowStatuses] = useState(true);
  // states
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<IStageExtension | null>(null);
  const [statusError, setStatusError] = useState(false);

  const objects = useAppSelector((state) => state.selectedObjectsSlice.pull);
  const dispatch = useAppDispatch();
  const extensions: IStageExtension[] = useAppSelector((state) => state.extensions.__stageExtensionConfig.data) as any;
  const { succeedNotification, errorNotification } = useNotification();

  const handleSubmit = async () => {
    const payload = {
      object_ids: Object.keys(objects),
      status: status?.name,
    };

    setLoading(true);

    const res = await changeObjectStatuses(payload);

    if (res.kind === KindEnum.SUCCESS) {
      setLoading(false);
      dispatch(setSelectedObjects({}));

      dispatch(
        multiplyObjects_ChangeStatus({
          object_ids: Object.keys(objects),
          status: status?.name,
        })
      );
      succeedNotification("Статус успешно изменён");
      setStatusError(false);
      handleClose();
    }
    if (res.kind === KindEnum.BAD) {
      errorNotification("Произошла ошибка");
      return setStatusError(true);
    }
  };

  return (
    <ClickOutsideBoundaryTemplate
      overlayVisible
      isOpen={isOpen}
      onClose={() => handleClose()}
      center>
      <ModalPopup
        {...{
          onClose: handleClose,
          titleText: "Сменить статус",
          bottom: () => (
            <Bottom
              buttonLabel="Сохранить"
              onSubmit={handleSubmit}
              onClose={() => handleClose()}
              loading={loading}
            />
          ),
          onClick: () => handleClose(),
        }}>
        <SelectDefaultObjectStatus
          disabled={loading}
          placeholder="Выберите статус"
          error={statusError}
          data={extensions}
          selected={status}
          showStatuses={showStatuses}
          onChange={setStatus}
        />
      </ModalPopup>
    </ClickOutsideBoundaryTemplate>
  );
};
