import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SchemasMetaNames } from "../../constants/MetaNamesEnum";
import { FilterChoice, IStatsMeta } from "../../types/stats";

export interface IFilterPayload {
  field_id: number;
  values: (number | string)[];
}

export type FiltersValues = Record<string, (string | { id: number; name: string })[]>;

export interface ISortPayload {
  field_index: number;
  direction: "asc" | "desc";
}

interface IInitialState {
  fetching: boolean;
  loading: boolean;
  selectedSchema: FilterChoice | null;
  filter_values?: FiltersValues;
  filter_fields?: IFilterPayload[];
  sort?: ISortPayload;
  shouldRefetch: boolean;
  activeTab: IStatsMeta | null;
  schemas: FilterChoice[] | null;
}

export const SCHEMA_FILTER_ID = -5;

const initialState: IInitialState = {
  fetching: false,
  loading: false,
  selectedSchema: null,
  schemas: null,
  shouldRefetch: false,
  activeTab: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    refetch: (state) => {
      state.shouldRefetch = !state.shouldRefetch;
    },
    setLoading: (state, action: PayloadAction<{ fetching?: boolean; loading?: boolean }>) => {
      state.loading = !!action.payload.loading;
      state.fetching = !!action.payload.fetching;
    },
    setActiveTab: (state, action: PayloadAction<{ activeTab: IStatsMeta }>) => {
      state.activeTab = action.payload.activeTab;
    },
    setSelectedSchema: (state, action: PayloadAction<{ schema: FilterChoice }>) => {
      state.selectedSchema = action.payload.schema;
    },
    setFiltersFields: (state, action: PayloadAction<{ filter_fields: IFilterPayload[] }>) => {
      state.filter_fields = action.payload.filter_fields;
    },
    setSort: (state, action: PayloadAction<{ filter_fields: IFilterPayload[] }>) => {
      state.filter_fields = action.payload.filter_fields;
    },
    setFiltersValues: (state, action: PayloadAction<{ filter_values: FiltersValues }>) => {
      state.filter_values = action.payload.filter_values;
    },
    setSchemas: (state, action: PayloadAction<FilterChoice[] | null>) => {
      state.schemas = action.payload;
    },
    resetFilters: (state) => {
      state.filter_values = undefined;
      state.filter_fields = undefined;
    },
  },
});

export const { reducer: reportsReducer, actions: reportsActions } = reportsSlice;
