export const RoleConfig = {
  ADMIN: "ADMIN",
  OWNER: "OWNER",
  REGULAR: "REGULAR",
} as const;

export type Roles = ValuesOf<typeof RoleConfig>;

export interface IRole {
  id: number;
  // campaign_id: number;
  name: string;
  parent_role_id: number;
  created_at: string;
  type: Roles;
}

export type RolePayload = Omit<IRole, "id">;

export type RoleResponse = Omit<IRole, "campaign_id" | "parent_role_id">;
