import React, { createContext, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { logOut, push, signIn, signUp, verify } from "../../api/routes/auth";
import { initUser } from "../../store/actions/userActions";
import { BAD, SUCCESS } from "../../helpers/response-service";
import { pushNotificationHelper } from "../../helpers/push-notification-helper";
import Cookies from "js-cookie";

const AuthContext = createContext();

/**
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
export const AuthProvider = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const [token, setToken] = useLocalStorage("access_token", null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  const loginAction = async (payload) => {
    return new Promise((resolve, reject) => {
      signIn(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              resolve(result);
              break;
            case BAD:
              reject(result);
          }
        })
        .catch((e) => reject(e));
    });
  };

  // Call this function to sign out logged user
  const logoutAction = () => {
    logOut().then((result) => {
      if (result.kind === SUCCESS) {
        setToken(null);
        Cookies.remove("access_token", { path: "/" });
        localStorage.removeItem("access_token");
        window.location.reload()
        // navigate("/auth/sign-in", { replace: true });
        // dispatch(initUser(null));
      }
    });
  };

  const verifyAction = () => {
    return new Promise((resolve, reject) => {
      verify()
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              dispatch(initUser(result.data));
              pushNotificationHelper(pushAction);
              resolve(result.data);
              break;
            case BAD:
              logoutAction();
              reject(result.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const registerAction = (payload) => {
    return new Promise((resolve, reject) => {
      signUp(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              resolve(result);
              break;
            case BAD:
              reject(result);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const pushAction = async (payload) => {
    return new Promise((resolve, reject) => {
      push(payload)
        .then((result) => {
          switch (result.kind) {
            case SUCCESS:
              resolve(result);
              break;
            case BAD:
              reject(result);
          }
        })
        .catch((e) => reject(e));
    });
  };

  const value = useMemo(
    () => ({
      token,
      user,
      loginAction,
      logoutAction,
      verifyAction,
      registerAction,
      pushAction,
    }),
    [user, token]
  );
  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
