import type { SliderProps as SliderPrimitiveProps } from "@radix-ui/react-slider";
import * as SliderPrimitive from "@radix-ui/react-slider";
import cn from "classnames";
import styles from "./Slider.module.css";

interface SliderOwnProps {
  big?: boolean;
}

type SliderProps = Omit<SliderPrimitiveProps, keyof SliderOwnProps | "asChild"> & SliderOwnProps;

export const Slider = ({ className, big = false, ...props }: SliderProps) => {
  return (
    <SliderPrimitive.Root
      {...props}
      className={cn(styles.root, big && styles.big, className)}>
      <SliderPrimitive.SliderTrack className={cn(styles.track, big && styles.big)}>
        <SliderPrimitive.SliderRange className={cn(styles.range, big && styles.big)} />
      </SliderPrimitive.SliderTrack>
      <SliderPrimitive.SliderThumb className={cn(styles.thumb, big && styles.big)} />
    </SliderPrimitive.Root>
  );
};
