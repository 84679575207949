import React, { ComponentProps, ComponentType, CSSProperties, useEffect, useState } from "react";
import ConfigIcon from "../../Icons/ConfigIcon";
import { FiSearch } from "react-icons/fi";
import cn from "classnames";
import styles from "./index.module.css";

interface SearchInputProps {
  value?: string;
  onChange?: (value: string) => void;
  type?: ComponentProps<"input">["type"];
  className?: string;
  style?: CSSProperties;
  small?: boolean;
}

const SearchInput = (props: SearchInputProps) => {
  const { value, onChange, type = "text", className, style, small } = props;
  const [_value, setValue] = useState(value || "");

  useEffect(() => {
    onChange?.(_value);
  }, [_value]);

  return (
    <div
      className={cn(styles.container, small && styles.small, className)}
      style={style}>
      <ConfigIcon>
        <FiSearch
          color="var(--input-placeholder-color)"
          size={15}
          className={cn(styles.icon, "align-center flex")}
        />
      </ConfigIcon>
      <input
        className={cn(styles.input, small && styles.small)}
        placeholder="Поиск"
        value={_value}
        type={type}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
