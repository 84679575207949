import React, { useState, useRef } from "react";
import "./style.css";
import DefaultModalClickTemplate from "../../../../templates/DefaultModalClickTemplate";
import ModalPopup from "../../ModalPopup";
import Bottom from "../../Bottom";
import SelectDefaultPlaceholder from "../../../Selects/SelectDefaultPlaceholder";
import { useNotification } from "../../../../../hooks/useNotification";
import { addOwnerToSchema } from "../../../../../api/routes/role";
import { useSelector } from "react-redux";
import { ClickOutsideBoundaryTemplate } from "../../../../templates/ClickOutsideBoundaryTemplate";

const ModalMultipleViewAddObjectOwner = (props) => {
  //props
  const { isOpen, owners, selectedOwners, setSelectedOwners, handleClose, schemasForOwners, selectedSchemasForOwners, setSelectedSchemasForOwners } =
    props;

  // states
  const [loading, setLoading] = useState(false);
  const [ownersError, setOwnersError] = useState(false);
  const [schemaError, setSchemaError] = useState(false);

  const objects = useSelector((state) => state.selectedObjectsSlice.pull);
  const containerRef = useRef(null);
  const { succeedNotification, errorNotification } = useNotification();

  function handleSubmit() {
    if (selectedOwners.length < 1) {
      setOwnersError(true);
    } else if (selectedSchemasForOwners.length < 1) {
      setSchemaError(true);
    } else {
      setLoading(true);
      setOwnersError(false);
      setSchemaError(false);
      let payload = {
        query: { action: "Create" },
        data: {
          schemas_ids: selectedSchemasForOwners.map(({ id }) => id),
          owners_ids: selectedOwners.map(({ id }) => id),
          objects_ids: Object.keys(objects).map(Number),
        },
      };
      addOwnerToSchema(payload).then((res) => {
        if (res.kind !== "SUCCESS") {
          // console.log(res);
          errorNotification(res.data.error.detail);
        } else {
          succeedNotification("Ответственные добавлены");
          handleClose();
        }
        setLoading(false);
      });
    }
  }

  return (
    <ClickOutsideBoundaryTemplate
      overlayVisible
      isOpen={isOpen}
      onClose={() => handleClose()}
      center>
      <ModalPopup
        {...{
          onClose: handleClose,
          titleText: "Добавить ответственного",
          bottom: () => (
            <Bottom
              buttonLabel={"Сохранить"}
              onSubmit={handleSubmit}
              onClose={handleClose}
              loading={loading}
            />
          ),
          onClick: () => {
            handleClose();
          },
        }}>
        <SelectDefaultPlaceholder
          {...{
            style: { marginBottom: 20, width: "100%" },
            placeholder: `Пользователь`,
            data: owners,
            values: selectedOwners.length > 0 ? selectedOwners : null,
            field_key: "name",
            onChange: setSelectedOwners,
            error: ownersError,
          }}
        />

        <SelectDefaultPlaceholder
          {...{
            style: { marginBottom: 20, width: "100%" },
            placeholder: `Где`,
            data: schemasForOwners,
            values: selectedSchemasForOwners.length > 0 ? selectedSchemasForOwners : null,
            field_key: "name",
            onChange: setSelectedSchemasForOwners,
            error: schemaError,
          }}
        />
      </ModalPopup>
    </ClickOutsideBoundaryTemplate>
  );
};

export default ModalMultipleViewAddObjectOwner;
