import React from "react";
import "./index.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { VIEW_TYPES } from "../../../../constants/types";
import ObjectCardSkeleton from "../ElementsSkeleton/ObjectCardSkeleton";

const SkeletonView = (props) => {
	const { view } = props;

	if (view === VIEW_TYPES.BILLBOARD) {
		return <BillboardLoading/>
	}
	if (view === VIEW_TYPES.LIST) {
		return (
			<RowLoadingSkeleton/>
		)
	}
	if (view === VIEW_TYPES.TABLE) {
		return (
			<RowLoadingSkeleton/>
		)
	}
	return <BillboardLoading/>
};

export default React.memo(SkeletonView);

const RowLoadingSkeleton = () => {

	function getRandomArbitrary(min, max) {
		return Math.random() * (max - min) + min;
	}

	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<div {...{ className: "skeletonTable" }}>
				{Array.from(Array(15), (_, row_key) => {
					return (
						<div {...{key: row_key, className: 'skeletonTableItem'}}>
							{Array.from(Array(5), (_, key) => {
								return (
									<div {...{key, className: 'skeletonTableItem_cell'}}>
										<Skeleton style={{width: getRandomArbitrary(20, 100) + '%', height: 20}} />
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</SkeletonTheme>
	)
}

const BillboardLoading = () => {
	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<div {...{ className: "skeletonKanban flex-row" }}>
				<div {...{ className: "skeletonKanbanInner flex-row" }}>
					<div {...{ className: "skeletonKanbanInnerColumn flex-column" }}>
						<div className={"KanbanTitleItem"}>
							<Skeleton width={120} height={20} borderRadius={6} />
							<Skeleton width={70} height={20} borderRadius={6} style={{ marginTop: 10 }} />
						</div>
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
					</div>
					<div {...{ className: "skeletonKanbanInnerColumn flex-column" }}>
						<div className={"KanbanTitleItem"}>
							<Skeleton width={120} height={20} borderRadius={6} />
							<Skeleton width={70} height={20} borderRadius={6} style={{ marginTop: 10 }} />
						</div>
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
					</div>
					<div {...{ className: "skeletonKanbanInnerColumn flex-column" }}>
						<div className={"KanbanTitleItem"}>
							<Skeleton width={120} height={20} borderRadius={6} />
							<Skeleton width={70} height={20} borderRadius={6} style={{ marginTop: 10 }} />
						</div>
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
					</div>
					<div {...{ className: "skeletonKanbanInnerColumn flex-column" }}>
						<div className={"KanbanTitleItem"}>
							<Skeleton width={120} height={20} borderRadius={6} />
							<Skeleton width={70} height={20} borderRadius={6} style={{ marginTop: 10 }} />
						</div>
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
						<ObjectCardSkeleton {...{ style: { padding: 17, borderRadius: 6, marginTop: 5 } }} />
					</div>
				</div>
			</div>
		</SkeletonTheme>
	)
}
