import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Page404 from "../pages/error/Page404";
import { logged } from "./logged";
import { unlogged } from "./unlogged";
import { ProtectedLayout } from "../layouts/ProtectedLayout";

const Router = () => {

	const router = [
		{
			path: "/",
			element: <ProtectedLayout />,
			children: [...logged(""), ...unlogged("/auth")],
		},
		{
			path: "/*",
			element: <Page404 />,
		},
		{
			path: "*",
			element: <Navigate {...{ to: "/" }} />,
		},
	];

	return useRoutes(router);
};

export default Router;
