import ViewDefault from "../components/elements/Widgets/View/ViewDefault";
import { isValue, returnBoolean } from "../helpers/values-validator";
import SelectTag from "../components/kit/Selects/SelectTag";
import { IObjectField } from "../types/fields";
import { DATE_WIDGETS, EditWidgetsEnum, ViewWidgetsEnum } from "./types";
import EditDropDown from "../components/elements/Widgets/Edit/EditDropDown";
import EditInput from "../components/elements/Widgets/Edit/EditInput";
import EditDateTime from "../components/elements/Widgets/Edit/EditDateTime";
import EditPhone from "../components/elements/Widgets/Edit/EditPhone";
import EditPrice from "../components/elements/Widgets/Edit/EditPrice";
import { VIEW_FONT_SIZE_DEFAULT } from "./ui-constants";
import EditTags from "../components/elements/Widgets/Edit/EditTags";
import EditIcon from "../components/elements/Widgets/Edit/EditIcon";
import EditCheckbox from "../components/elements/Widgets/Edit/EditCheckbox";
import * as _ from "lodash"

interface EditObjectField extends IObjectField {
  loading: boolean;
  error: boolean;
}

interface EditDateObjectField extends Omit<EditObjectField, "edit_widget"> {
  edit_widget: keyof Pick<typeof EditWidgetsEnum, "DATE" | "TIME" | "DATETIME">;
}

export const VIEW_WIDGETS_PULL = {
  DEFAULT: {
    key: "DEFAULT",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          // ...(field.meta_name === "__title__" && {
          //   iconRight: {
          //     name: "point",
          //     color: "orange",
          //   },
          // }),
          color: field.meta_name === "__title__" ? "var(--text-nineth)" : "#6E7689",
          fontSize: field.meta_name === "__title__" ? VIEW_FONT_SIZE_DEFAULT + 2 : VIEW_FONT_SIZE_DEFAULT,
          mask: null,
          text: isValue(field.values) && field.values.join(", "),
        }}
      />
    ),
  },
  PRICE: {
    key: "PRICE",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          iconRight: {
            name: "currency-rubel",
            color: "#6E7689",
          },
          fontSize: VIEW_FONT_SIZE_DEFAULT,
          mask: ViewWidgetsEnum.PRICE,
          text: isValue(field.values) && field.values[0],
        }}
      />
    ),
  },
  DATE: {
    key: "DATE",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          fontSize: VIEW_FONT_SIZE_DEFAULT - 2,
          color: "var(--text-fifth)",
          mask: field.view_widget,
          text: isValue(field.values) && field.values[0],
        }}
      />
    ),
  },
  TIME: {
    key: "TIME",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          fontSize: VIEW_FONT_SIZE_DEFAULT - 2,
          color: "var(--text-fifth)",
          mask: field.view_widget,
          text: isValue(field.values) && field.values[0],
        }}
      />
    ),
  },
  DATETIME: {
    key: "DATETIME",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          fontSize: VIEW_FONT_SIZE_DEFAULT - 2,
          color: "var(--text-fifth)",
          mask: field.view_widget,
          text: isValue(field.values) && field.values[0],
        }}
      />
    ),
  },
  PHONE: {
    key: "PHONE",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          fontSize: VIEW_FONT_SIZE_DEFAULT,
          mask: ViewWidgetsEnum.PHONE,
          text: isValue(field.values) && field.values[0],
        }}
      />
    ),
  },
  LINK: {
    key: "LINK",
    component: (field: EditObjectField) => (
      <ViewDefault
        {...{
          key: _.uniqueId("__view__row__field"),
          iconLeft: {
            name: field.icon,
            color: "#6E7689",
          },
          color: "#1584d3",
          fontSize: VIEW_FONT_SIZE_DEFAULT,
          mask: null,
          text: isValue(field.values) && field.values.join(", "),
        }}
      />
    ),
  },
  TAG: {
    key: "TAG",
    component: (field: EditObjectField) => (
      <SelectTag
        {...{
          key: _.uniqueId("__view__row__field"),
          values: isValue(field.values) ? field.values : null,
          readOnly: true,
        }}
      />
    ),
  },
};

export const EDIT_WIDGETS_PULL = (
  isShowLabel: boolean,
  schemaKey: number,
  onChange: (field: EditObjectField | EditDateObjectField, values: (string | number)[]) => void,
  onSubmit: (field: EditObjectField | EditDateObjectField, values: (string | number)[]) => void,
  handleScrollableDivs: boolean,
  fontSize: number = 15
) => ({
  INPUT: {
    key: "INPUT",
    component: (field: EditObjectField) => (
      <EditInput
        {...{
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? field.values[0] : "",
          placeholder: field.placeholder ? field.placeholder : "Текст",
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, [value]);
            onSubmit(field, [value]);
          },
        }}
      />
    )
  },
  DROPDOWN: {
    key: "DROPDOWN",
    component: (field: EditObjectField) => (
      <EditDropDown
        {...{
          handleScrollableDivs,
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          defaultValue: field.default,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          values: isValue(field.values) ? field.values : [null],
          variants: field.choice && field.choice[0] ? field.choice : null,
          amount: field.values_amount,
          placeholder: field.placeholder ? field.placeholder : "Выбрать",
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, value);
            onSubmit(field, value);
          },
        }}
      />
    ),
  },
  TEXTAREA: {
    key: "TEXTAREA",
    component: (field: EditObjectField) => (
      <EditInput
        {...{
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          isSendFile: false,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? field.values[0] : "",
          placeholder: field.placeholder,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, [value]);
            onSubmit(field, [value]);
          },
        }}
      />
    ),
  },
  PRICE: {
    key: "PRICE",
    component: (field: EditObjectField) => (
      <EditPrice
        {...{
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? field.values[0] : "",
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            const formattedValue = value.replace(/\D/g, "")
            onChange(field, [formattedValue]);
            onSubmit(field, [formattedValue]);
          },
        }}
      />
    ),
  },
  DATE: {
    key: "DATE",
    component: (field: EditDateObjectField) => (
      <EditDateTime
        {...{
          handleScrollableDivs,
          ...DATE_WIDGETS[field.edit_widget],
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          placeholder: field.placeholder ? field.placeholder : "Дата",
          value: isValue(field.values) ? field.values[0] : null,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, value ? [value.isoDateTime] : [""]);
            onSubmit(field, value ? [value.isoDateTime] : [""]);
          },
        }}
      />
    ),
  },
  TIME: {
    key: "TIME",
    component: (field: EditDateObjectField) => (
      <EditDateTime
        {...{
          handleScrollableDivs,
          ...DATE_WIDGETS[field.edit_widget],
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          placeholder: field.placeholder ? field.placeholder : "Время",
          value: isValue(field.values) ? field.values[0] : null,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, value ? [value.isoDateTime] : [""]);
            onSubmit(field, value ? [value.isoDateTime] : [""]);
          },
        }}
      />
    ),
  },
  DATETIME: {
    key: "DATETIME",
    component: (field: EditDateObjectField) => (
      <EditDateTime
        {...{
          handleScrollableDivs,
          ...DATE_WIDGETS[field.edit_widget],
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          placeholder: field.placeholder ? field.placeholder : "Дата и время",
          value: isValue(field.values) ? field.values[0] : null,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, value ? [value.isoDateTime] : [""]);
            onSubmit(field, value ? [value.isoDateTime] : [""]);
          },
        }}
      />
    ),
  },
  PHONE: {
    key: "PHONE",
    component: (field: EditObjectField) => (
      <EditPhone
        {...{
          label: isShowLabel ? field.name : "",
          // icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          values: isValue(field.values) ? field.values : [""],
          required: field.required,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any[]) => {
            onChange(field, value);
            onSubmit(field, value);
          },
        }}
      />
    )
  },
  LINK: {
    key: "LINK",
    component: (field: EditObjectField) => (
      <EditInput
        {...{
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          color: "blue",
          fontSize,
          isLink: true,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? field.values[0] : "",
          placeholder: field.placeholder,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, [value]);
            onSubmit(field, [value]);
          },
        }}
      />
    ),
  },
  EMAIL: {
    key: "EMAIL",
    component: (field: EditObjectField) => (
      <EditInput
        {...{
          label: isShowLabel ? field.name : null,
          icon: field.icon,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? field.values[0] : "",
          placeholder: field.placeholder ? field.placeholder : "example@email.ru",
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: any) => {
            onChange(field, [value]);
            onSubmit(field, [value]);
          },
        }}
      />
    ),
  },
  TAG: {
    key: "TAG",
    component: (field: EditObjectField) => (
      <EditTags
        {...{
          label: isShowLabel ? field.name : null,
          fontSize,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          values: isValue(field.values) ? field.values : null,
          icon: field.icon,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (values: any) => {
            onChange(field, values);
            onSubmit(field, values);
          },
        }}
      />
    ),
  },
  ICON: {
    key: "ICON",
    component: (field: EditObjectField) => (
      <EditIcon
        {...{
          label: isShowLabel ? field.name : null,
          fontSize,
          data: field.choice,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? field.values[0] : null,
          icon: field.icon,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (values: any) => {
            onChange(field, values);
            onSubmit(field, values);
          },
        }}
      />
    ),
  },
  CHECKBOX: {
    key: "CHECKBOX",
    component: (field: EditObjectField) => (
      <EditCheckbox
        {...{
          defaultValue: field.default,
          fontSize,
          label: isShowLabel ? field.name : null,
          loading: field.loading ? field.loading : false,
          error: field.error ? field.error : false,
          value: isValue(field.values) ? returnBoolean(field.values[0]) : false,
          icon: field.icon,
          readOnly: field.read_only || +schemaKey !== field.schema_id,
          onSubmit: (value: boolean) => {
            onChange(field, [value.toString()]);
            onSubmit(field, [value.toString()]);
          },
        }}
      />
    )
  },
});
