import { singleObjectTypes } from "../types/singleObjectTypes";
import * as _ from "lodash"
import { SINGLE_OBJECT_COLUMNS_TEMPLATE, SINGLE_OBJECT_KEYS } from "@/constants/types.tsx";
import { structuredCloneByStringify } from "@/helpers/functions.js";

/**
 * @param {object} payload - singleObject all data
 * @param {string} key_name - singleObject any property key
 * @returns {*|null}
 */
const returnObjectPropertyByKey = (payload, key_name) => {
  return payload && payload[key_name] ? payload[key_name] : null;
};

const INITIAL_STATE = {
  // loading
  loading: true,
  // in create mode
  objectOwnersIds: null,

  // single object data
  __singleObject: null,
  __singleObjectRelationObjectsSchemaData: null,
  __singleObjectRelationObjects: [],
  __singleObjectUsers: null,
  __singleObjectMainElement: null,
  __singleObjectGroups: null,
  __singleObjectHistory: [],
  // __singleObjectExtensions: null,
  __singleObjectExtensions: null,
  // layout
  singleObjectPositionedLayout: null,
};

const initialState = structuredCloneByStringify(INITIAL_STATE);
// structuredClone()

/**
 * @param {object} state - before field state level
 * @param {array} values
 * @param {boolean} loading
 * @param {boolean} error
 * @returns {object} - updated field level state
 */
const fieldLevelUpdateReducer = (state, { values = undefined, loading = undefined, error = undefined }) => {
  return {
    ...state,
    ...(values !== undefined && { values }),
    ...(loading !== undefined && { loading }),
    ...(error !== undefined && { error }),
  };
};

/**
 * @param {array} groups
 * @returns {null|array}
 */
const formatGroupsFields = (groups) => {
  let data = null;
  if (groups) {
    data = [...groups];
    data.map((group, index) => {
      data[index].groupsMap = formatFields(group.fields);
    });
  }
  return data;
};

/**
 * @param {array} fields
 * @returns {null|object}
 */
const formatFields = (fields) => {
  if (fields && _.isArray(fields)) {
    return Object.assign({}, fields);
  }
  return null;
};

class SingleObjectLayout {
  constructor() {
    this.layout = structuredCloneByStringify(SINGLE_OBJECT_COLUMNS_TEMPLATE);
    // structuredClone()
    this.init = this.init.bind(this);
    this.getLayout = this.getLayout.bind(this);
  }

  getLayout = () => {
    return this.layout;
  };

  init = (data) => {
    Object.keys(SINGLE_OBJECT_KEYS).forEach((property) => {
      const key_name = SINGLE_OBJECT_KEYS[property];
      const PULL = data[key_name];
      if (key_name === SINGLE_OBJECT_KEYS.__objectAppExtensions) {
        Object.keys(PULL).forEach((extension) => {
          const prevPropertyState = this.layout.LEFT.children[property];
          this.layout.LEFT.children[property] = {
            ...prevPropertyState,
            [extension]: PULL[extension],
          };
        });
      } else if (PULL && _.isArray(PULL)) {
        PULL.forEach((element, key) => {
          const prevPropertyState = this.layout[element.position].children[property];
          this.layout[element.position].children[property] = {
            ...prevPropertyState,
            [key]: key,
          };
        });
      }
    });
  };
}

export const singleObjectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case singleObjectTypes.SET_SINGLE_OBJECT: {
      const layout = new SingleObjectLayout();
      layout.init(payload);

      return {
        ...state,

        singleObjectPositionedLayout: layout.getLayout(), // Need to create positioning

        __singleObject: payload,

        __singleObjectExtensions: returnObjectPropertyByKey(payload, "extensions"),

        __singleObjectUsers: returnObjectPropertyByKey(payload, "users"),

        __singleObjectGroups: Object.assign({}, formatGroupsFields(payload[SINGLE_OBJECT_KEYS.__objectGroups])),

        __singleObjectMainElement: returnObjectPropertyByKey(payload, SINGLE_OBJECT_KEYS.__objectMainElement),

        __singleObjectRelationObjectsSchemaData: returnObjectPropertyByKey(payload, SINGLE_OBJECT_KEYS.__objectRelationObjects),

        // __singleObjectExtensions: returnObjectPropertyByKey(payload, SINGLE_OBJECT_KEYS.__objectExtensions)
      };
    }

    case singleObjectTypes.UPDATE_GROUP_FIELD:
      return {
        ...state,
        __singleObjectGroups: {
          ...state.__singleObjectGroups,
          [payload.store_key]: {
            ...state.__singleObjectGroups[payload.store_key],
            fields: {
              ...state.__singleObjectGroups[payload.store_key].fields,
              [payload.field_key]: fieldLevelUpdateReducer(state.__singleObjectGroups[payload.store_key].fields[payload.field_key], payload.data),
            },
          },
        },
      };

    case singleObjectTypes.UPDATE_MAIN_ELEMENT_FIELD:
      return {
        ...state,
        __singleObjectMainElement: {
          ...state.__singleObjectMainElement,
          [payload.field_key]: fieldLevelUpdateReducer(state.__singleObjectMainElement[payload.field_key], payload.data),
        },
      };

    case singleObjectTypes.SET_LOADING:
      return { ...state, loading: payload };

    case singleObjectTypes.RESET_SINGLE_OBJECT:
      return {
        ...state,
        ...structuredCloneByStringify(INITIAL_STATE),
        // structuredClone()
      };

    case singleObjectTypes.SET_SINGLE_OBJECT_HISTORY:
      return {
        ...state,
        __singleObjectHistory: [...state.__singleObjectHistory, ...payload],
      };
    case singleObjectTypes.SET_SINGLE_OBJECT_RELATION_OBJECTS:
      return {
        ...state,
        __singleObjectRelationObjects: [...state.__singleObjectRelationObjects, ...payload],
      };
    case singleObjectTypes.HANDLE_OBJECT_OWNERS_IDS_CREATE_MODE:
      return {
        ...state,
        objectOwnersIds: payload
      }
    default:
      return state;
  }
};
