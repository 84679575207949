import { IconDotsVertical, IconDownload, IconPlayerPause, IconPlayerPlay, IconPlayerStop, IconVolume } from "@tabler/icons-react";
import cn from "classnames";
import dayjs from "dayjs";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useAudioPlayer } from "../../../hooks/useAudioPlayer";
import Spinner from "../../kit/Loaders/Spinner";
import SelectIconAction from "../../kit/Selects/SelectIconAction";
import { ClickOutsideBoundaryTemplate } from "../../templates/ClickOutsideBoundaryTemplate";
import { Slider } from "../Slider/Slider";
import styles from "./DefaultPlayer.module.css";

interface ISpeedValue {
  name: string;
  value: number;
}

interface DefaultPlayerProps {
  audioSrc: string;
  step?: number;
  speedValues?: ISpeedValue[];
  big?: boolean;
  preload?: boolean;
}

const defaultSpeedValues: ISpeedValue[] = [
  { name: "x1", value: 1 },
  { name: "x1.3", value: 1.3 },
  { name: "x1.5", value: 1.5 },
  { name: "x1.7", value: 1.7 },
  { name: "x2", value: 2 },
];

const DEFAULT_VOLUME_STEP = 0.05;

export const DefaultPlayer = ({ audioSrc, step, speedValues, big = false, preload = true }: DefaultPlayerProps) => {
  const [src, setSrc] = useState<string | null>(null);
  const [state, actions] = useAudioPlayer(preload ? audioSrc : src, { volumeStep: DEFAULT_VOLUME_STEP });
  const speed = speedValues ?? defaultSpeedValues;

  const playStopBtn = () => {
    return !state.isPlaying ? (
      <IconPlayerPlay
        className={styles.actionBtn}
        onClick={state.canPlay ? actions.play : undefined}
      />
    ) : (
      <IconPlayerPause
        className={styles.actionBtn}
        onClick={state.canPlay ? actions.pause : undefined}
      />
    );
  };

  const downloadButton = (loading: boolean) => {
    return !loading ? (
      <IconDownload
        className={cn(styles.actionBtn, styles.clickable)}
        onClick={() => setSrc(audioSrc)}
      />
    ) : (
      <Spinner
        size={big ? 20 : 16}
        loading={true}
      />
    );
  };

  return (
    <div
      className={cn(styles.container, big && styles.big)}
      data-disabled={!state.canPlay}>
      {!preload && !state.canPlay ? downloadButton(state.loading) : playStopBtn()}
      <span className={cn(styles.timer, "margin-left-14")}>
        <span>{dayjs(state.currentTime * 1000).format("mm:ss")}</span> / <span>{dayjs(state.duration * 1000).format("mm:ss")}</span>
      </span>
      <div className={cn("margin-right-14 margin-left-14 w-100")}>
        <Slider
          big={big}
          disabled={!state.canPlay}
          onValueChange={(value: any) => actions.setCurrentTime(value[0])}
          value={[state.currentTime]}
          defaultValue={[0]}
          min={0}
          max={state.duration}
          step={0.5}
        />
      </div>
      <ClickOutsideBoundaryTemplate
        disabled={!state.canPlay}
        header={<IconVolume className={styles.actionBtn} />}
        headerStyle={{ marginRight: 10 }}>
        <div className={styles.volumeSlider}>
          <Slider
            big={big}
            value={[state.volume]}
            onValueChange={(value: any) => actions.setVolume(value[0])}
            min={0}
            max={1}
            step={DEFAULT_VOLUME_STEP}
            orientation="vertical"
          />
        </div>
      </ClickOutsideBoundaryTemplate>
      <SelectIconAction
        headerSize={20}
        disabled={!state.canPlay}
        data={speed.map((speed) => ({ name: speed.name, action: () => actions.setSpeed(speed.value) }))}
        icon={<IconDotsVertical className={cn(styles.actionBtn, "margin-left-14")} />}
      />
      {/*  </>*/}
      {/*) : (*/}
      {/*  <Skeleton*/}
      {/*    containerClassName={styles.skeleton}*/}
      {/*    borderRadius={6}*/}
      {/*    baseColor="var(--skeleton-loader-second)"*/}
      {/*    highlightColor="var(--skeleton-loader-first)"*/}
      {/*    height="100%"*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};
