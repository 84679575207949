import React from "react";
import SignIn from "../pages/unlogged/SignIn";
import { Navigate } from "react-router-dom";
import UnloggedLayout from "../layouts/UnloggedLayout";
import SignUp from "../pages/unlogged/SignUp";
import Restore from "../pages/unlogged/Restore";

export const unlogged = (root) => [
	{
		path: `${root}`,
		element: <UnloggedLayout />,
		children: [
			{
				path: `${root}/sign-in`,
				element: <SignIn />,
			},
			{
				path: `${root}/sign-up`,
				element: <SignUp />,
			},
			{
				path: `${root}/restore`,
				element: <Restore/>
			},
			{
				path: `${root}/`,
				element: <Navigate {...{ to: `${root}/sign-in` }} />,
			},
			{
				path: `${root}/*`,
				element: <Navigate {...{ to: `${root}/sign-in` }} />,
			},
		],
	},
];
