import React from "react";
import "./index.css";
import SingleObjectEditFieldsIteration from "../SingleObjectEditFieldsIteration";
import { useDispatch } from "react-redux";
import { singleObject_UpdateObjectGroupField } from "../../../../store/actions/singleObjectActions";
import { isFieldValueValid } from "../../../../helpers/values-validator";

const SingleObjectFieldsGroup = (props) => {
  const { style, data, object_id, objectSchemaId, store_key, isCreateMode, handleUpdateSingleObjectField } = props;

  const createStoreAction = useDispatch();

  const onSubmitField = (field_key, field, values) => {
    const fieldStoreKeys = {
      store_key,
      field_key,
    };

    const isError = !isFieldValueValid(field.required, field.type, values);

    const payload = {
      ...fieldStoreKeys,
      data: {
        values,
        // loading: !isError && !isCreateMode ? true : undefined,
        error: isError,
      },
    };

    createStoreAction(singleObject_UpdateObjectGroupField(payload));

    if (!payload.data.error && !isCreateMode) {
      handleUpdateSingleObjectField(field, values)
        .then(() => {
          createStoreAction(
            singleObject_UpdateObjectGroupField({
              ...fieldStoreKeys,
              data: {
                error: false,
                // loading: false,
              },
            })
          );
        })
        .catch((e) => {
          createStoreAction(
            singleObject_UpdateObjectGroupField({
              ...fieldStoreKeys,
              data: {
                error: true,
                // loading: false,
              },
            })
          );
        });
    }
  };

  return (
    <div {...{ className: "SingleObjectFieldsGroup flex-column", style }}>
      <div
        {...{
          className: "SingleObjectFieldsGroup_title w-100 flex-row align-center justify-between",
        }}>
        <div {...{ className: "SingleObjectFieldsGroup_titleName" }}>
          {data ? (data.name === "__default_group__" ? "Информация" : data.name) : "Group Name"}
        </div>
        <div {...{ className: "SingleObjectFieldsGroup_titleAction" }}></div>
      </div>
      <SingleObjectEditFieldsIteration
        {...{
          object_id,
          isCreateMode,
          includeExtensions: !isCreateMode,
          link: true,
          schemaKey: objectSchemaId,
          fields: data && data.fields ? data.fields : null,
          onSubmit: (field_key, field, values) => onSubmitField(field_key, field, values),
        }}
      />
    </div>
  );
};

export default SingleObjectFieldsGroup;
