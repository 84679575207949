import React, { useMemo } from "react";
import styles from "./index.module.css";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import IconManager from "../../../kit/Icons/IconManager";
import cn from "classnames";
import { ROLE_TYPES_ENUM } from "../../../../constants/types";
import { useSelector } from "react-redux";

const filters = [
  { id: 0, type: "COMMENT", name: "Комментарии", icon: "quote" },
  { id: 1, type: "CREATE", name: "Действия", icon: "repeat" },
  { id: 2, type: "UPDATE", name: "Изменение полей", icon: "edit" },
  { id: 3, type: "TELEPHONY", name: "Звонки", icon: "phone-outgoing" },
  { id: 4, type: "MESSAGE", name: "Сообщения", icon: "message" },
  { id: 5, type: "NO_TYPE", name: "Без типа", icon: "note" },
];

const adminFilters = [{ id: 6, type: "ONLY_FOR_ADMIN", name: "Для админа", icon: "star" }];

const LogsFilterContainer = (props) => {
  const { selected, onSelected } = props;

  const isAdmin = useSelector((state) => state.user.user.role.type) === ROLE_TYPES_ENUM.ADMIN;

  const containerRef = React.useRef(null);

  const memoFilters = useMemo(() => (isAdmin ? filters.concat(adminFilters) : filters));

  const header = () => (
    <div className={styles["header-filter"]}>
      <IconManager
        {...{
          icon: "filter",
          size: 16,
        }}
      />
      <span>Фильтры</span>
        {selected.length > 0 && <div className={styles["header-filter__count"]}>{selected.length}</div>}
    </div>
  );

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        header,
        ref: containerRef,
      }}>
      <div {...{ className: cn(styles.LogsFilterContainer) }}>
        {memoFilters.map((item, index) => {
          const isSelected = !!selected.find((obj) => obj.id === item.id);
          return (
            <div
              key={index}
              className={styles["LogsFilter-item"]}
              onClick={() => onSelected(item)}>
              {item.icon && (
                <IconManager
                  {...{
                    icon: item.icon,
                    size: 14,
                    ...(isSelected && { color: "var(--medium-third)" }),
                  }}
                />
              )}
              <span style={{ ...(isSelected && { color: "var(--medium-third)" }) }}>{item.name}</span>
            </div>
          );
        })}
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};

export default LogsFilterContainer;
