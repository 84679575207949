import React, { CSSProperties, ReactNode } from "react";
import "./index.css";
import { ucFirst } from "../../../../helpers/uppercaseFirst";
import ConfigIcon from "../../Icons/ConfigIcon";
import IconButton from "../../Buttons/IconButton";
import { AiOutlinePlus } from "react-icons/ai";
import { VIEW_TYPES, VIEW_TYPES_ICONS } from "../../../../constants/types";
import SelectViewTabsSkeleton from "../../Loaders/ElementsSkeleton/SelectViewTabsSkeleton";

export interface ITab {
  id: number;
  name: string;
  template?: VIEW_TYPES;
}

interface ViewTabsProps {
  value?: ITab;
  data: ITab[];
  onChange?: (tab: ITab) => void;
  style?: CSSProperties;
  disabled?: boolean;
  controls?: ReactNode;
  loading?: boolean;
  loaderConfig?: any;
}

const ViewTabs = (props: ViewTabsProps) => {
  const { value, data, onChange, style, disabled, controls, loading, loaderConfig } = props;

  const handleSelectValue = (item: ITab) => {
    if (!disabled && onChange) {
      if (!value) {
        onChange(item);
      } else if (value.name && value.name !== item.name) {
        onChange(item);
      }
    }
  };

  const ViewTabs = () => {
    if (!loading && !!data && Array.isArray(data)) {
      return <Tabs />;
    } else if (!loading && !data) {
      return null;
    }
    return <SelectViewTabsSkeleton {...{ ...loaderConfig }} />;
  };

  const Tabs = () => {
    return (
      <>
        {data.map((item, key) => (
          <div
            {...{
              style,
              key,
              className: `ViewTabs_values_item flex align-center padding-17 ${
                !!value && value.name === item.name ? "ViewTabs_values_item" : "ViewTabs_values_item_disabled"
              } ${disabled ? "" : "pointer"}`,
              onClick: () => handleSelectValue(item),
            }}>
            {item.template && (
              <div className="ViewTabs_values_item_icon">
                <ConfigIcon
                  {...{
                    color: "var(--main-text-dark)",
                    size: 22,
                  }}>
                  {VIEW_TYPES_ICONS[item.template]}
                </ConfigIcon>
              </div>
            )}
            <span>{ucFirst(item.name)}</span>
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      {...{
        className: "ViewTabs flex-row",
        style: { opacity: disabled ? 0.5 : 1 },
      }}>
      <ViewTabs />
      {controls && (
        <IconButton
          {...{
            color: "var(--primary-color)",
            size: 20,
            icon: <AiOutlinePlus />,
            style: {
              background: "#F9F9FA",
              height: 60,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            width: 45,
            height: 60,
          }}
        />
      )}
    </div>
  );
};

ViewTabs.defaultProps = {
  loaderConfig: {
    icons: false,
    height: 42,
  },
};

export default ViewTabs;
