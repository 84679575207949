// *************** API ROUTES ***************
// export const PREFIXES_WITH_CAMPAIGN = `v2/${CAMPAIGN_ID}`

const VERSION_V2_PREFIX = "/v2";
const CAMPAIGN_ID_PREFIX = `/campaign_id=1`;

const PREFIXES_WITH_CAMPAIGN = VERSION_V2_PREFIX + CAMPAIGN_ID_PREFIX + "/";

const PREFIXES_WITHOUT_CAMPAIGN = VERSION_V2_PREFIX + "/";

// export const PREFIXES_WITH_CAMPAIGN = 'v2'

// AUTH ================================================================================================================

export const SIGN_IN = `${PREFIXES_WITHOUT_CAMPAIGN}auth/login`; // POST + { "email": "string", "password": "string" }
export const SIGN_UP = `${PREFIXES_WITHOUT_CAMPAIGN}auth/register`; // POST + {"name": "string", "email": "string", "password": "string"}
export const LOGOUT = `${PREFIXES_WITHOUT_CAMPAIGN}auth/logout`; // POST
export const VERIFY = `${PREFIXES_WITHOUT_CAMPAIGN}auth/verify${CAMPAIGN_ID_PREFIX}`; // GET

// OBJECTS CORE ========================================================================================================

// - views
export const GET_MULTIPLE_VIEW = (id) => `${PREFIXES_WITH_CAMPAIGN}view/multiple/${id}`;
export const GET_VIEWS = (query) => `${PREFIXES_WITH_CAMPAIGN}views${query ? "?" + query : ""}`;
export const CREATE_VIEW = `${PREFIXES_WITH_CAMPAIGN}view/create`;
export const UPDATE_VIEW = `${PREFIXES_WITH_CAMPAIGN}view/element`;
export const GET_VIEWS_ELEMENTS = (query) => `${PREFIXES_WITH_CAMPAIGN}view/elements${query ? "?" + query : ""}`;
export const GET_SINGLE_VIEW = (schema_id) => `${PREFIXES_WITH_CAMPAIGN}view/${schema_id}/single-view`;
export const GET_SCHEMA_ELEMENT_VIEW = (query) => `${PREFIXES_WITH_CAMPAIGN}view/element/${query.path}${query.params ? "?" + query.params : ""}`;
export const MOVE_FIELD = `${PREFIXES_WITH_CAMPAIGN}view/move-field`;
export const EDIT_GROUP = `${PREFIXES_WITH_CAMPAIGN}view/group`;
export const REMOVE_GROUP = (group_id) => `${PREFIXES_WITH_CAMPAIGN}view/group/${group_id ? group_id : ""}`;
export const CREATE_GROUP = `${PREFIXES_WITH_CAMPAIGN}view/add-fields-group`;

// - objects
export const GET_GROUPED_OBJECTS = (query) => `${PREFIXES_WITH_CAMPAIGN}objects/grouped-view${query ? "?" + query : ""}`;
export const GET_GROUPED_OBJECTS_TEST = (query) => `${PREFIXES_WITH_CAMPAIGN}objects/grouped-view${query ? "?" + query : ""}`;
export const GET_UNGROUPED_OBJECTS = (query) => `${PREFIXES_WITH_CAMPAIGN}objects/ungrouped-view${query ? "?" + query : ""}`;
export const GET_OBJECT_FILTERS = (query) => `${PREFIXES_WITH_CAMPAIGN}object/filters${query ? "?" + query : ""}`;
export const GET_OBJECT_SORTS = `${PREFIXES_WITH_CAMPAIGN}object/sorts`;
export const GET_OBJECT = (query) => `${PREFIXES_WITH_CAMPAIGN}object${query ? "?" + query : ""}`;
export const CREATE_OBJECT = (query) => `${PREFIXES_WITH_CAMPAIGN}object${query ? "?" + query : ""}`;
export const UPDATE_OBJECT = (id) => `${PREFIXES_WITH_CAMPAIGN}object/${id}`;
export const DELETE_OBJECT = (id) => `${PREFIXES_WITH_CAMPAIGN}object/${id}`;
export const ADD_NESTED_OBJECT = (id, query) => `${PREFIXES_WITH_CAMPAIGN}object/nested/${id}${query ? "?" + query : ""}`;
export const REMOVE_NESTED_OBJECT = (id) => `${PREFIXES_WITH_CAMPAIGN}object/nested/${id}`;
export const ADD_OBJECT_OWNER = `${PREFIXES_WITH_CAMPAIGN}object/owners`;
export const DELETE_OBJECT_OWNER = `${PREFIXES_WITH_CAMPAIGN}object/owners`;
export const TEMP_OBJECT_CREATE = (query) => `${PREFIXES_WITH_CAMPAIGN}temp/create-template-object${query ? `?${query}` : ""}`;
export const MOVE_OBJECTS_BETWEEN_SCHEMAS = (query) => `${PREFIXES_WITH_CAMPAIGN}object/move-objects${query ? "?" + query : ""}`;

// - schemas
export const GET_SCHEMAS = `${PREFIXES_WITH_CAMPAIGN}schemas`;
export const GET_SCHEMA_FIELDS = (query) => `${PREFIXES_WITH_CAMPAIGN}schema${query ? "?" + query : ""}`;
export const GET_RELATIONS_SCHEMA = (schema_id) => `${PREFIXES_WITH_CAMPAIGN}schema/relations/${schema_id}`;
export const CREATE_SCHEMA = `${PREFIXES_WITH_CAMPAIGN}schema`;
export const CREATE_SCHEMA_TEMP = `${PREFIXES_WITH_CAMPAIGN}temp/new-schema`;
export const UPDATE_SCHEMA = (schema_id) => `${PREFIXES_WITH_CAMPAIGN}schema/${schema_id}`;
export const ADD_SCHEMA_FIELD = (schema_id) => `${PREFIXES_WITH_CAMPAIGN}schema/field?schema_id=${schema_id}`;
export const REMOVE_SCHEMA_FIELD = (id) => `${PREFIXES_WITH_CAMPAIGN}schema/field/${id}`;
export const DELETE_SCHEMA_FIELD = (field_id) => `${PREFIXES_WITH_CAMPAIGN}schema/field/${field_id}`;
export const UPDATE_SCHEMA_FIELD = (id) => `${PREFIXES_WITH_CAMPAIGN}schema/field/${id}`;
export const UPDATE_CHOICE = (choice_id) => `${PREFIXES_WITH_CAMPAIGN}schema/choice/${choice_id}`;
export const CREATE_CHOICE = (field_id) => `${PREFIXES_WITH_CAMPAIGN}schema/choice?field_id=${field_id}`;
export const DELETE_CHOICE = (choice_id) => `${PREFIXES_WITH_CAMPAIGN}schema/choice/${choice_id}`;

// ROLE ================================================================================================================

// - role
export const GET_ALL_USERS = `${PREFIXES_WITH_CAMPAIGN}users`;
export const GET_ALL_ROLES = `${PREFIXES_WITH_CAMPAIGN}roles`;
export const GET_ROLE = (query) => `${PREFIXES_WITH_CAMPAIGN}role${query ? "?" + query : ""}`;
export const CREATE_ROLE = `${PREFIXES_WITH_CAMPAIGN}role`;
export const UPDATE_ROLE = (role_id) => `${PREFIXES_WITH_CAMPAIGN}role/${role_id}`;
export const DELETE_ROLE = (role_id, new_role_id) => `${PREFIXES_WITH_CAMPAIGN}role/${role_id}${new_role_id ? "?new_role_id=" + new_role_id : ""}`;

// - user with a role
export const SET_ROLE = (query) => `${PREFIXES_WITH_CAMPAIGN}user/set-role?${query}`;
export const CREATE_USER = `${PREFIXES_WITH_CAMPAIGN}user`;
export const REMOVE_USER = (user_id, new_user_id) => `${PREFIXES_WITH_CAMPAIGN}user/${user_id}${new_user_id ? "?new_user_id=" + new_user_id : ""}`;

// - owner
export const ADD_OWNER_TO_SCHEMA = (action) => `${PREFIXES_WITH_CAMPAIGN}object/owners/update_owners?action=${action}`;
export const UPDATE_OBJECTS_OWNERS_FULLY = (query) => `${PREFIXES_WITH_CAMPAIGN}object/owners/update_owners_fully${query ? "?" + query : ""}`;

// PERMISSION ==========================================================================================================
export const BASE_PERMISSION = (role_id) => `${PREFIXES_WITH_CAMPAIGN}access/${role_id}`;
export const GET_PERMISSIONS = (role_id) => `${PREFIXES_WITH_CAMPAIGN}accesses${role_id ? "?role_id=" + role_id : ""}`;

// EXTENSIONS ================================================================================================================
export const GET_OBJECT_LOGS = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/logs${query ? "?" + query : ""}`;
export const GET_OBJECT_LOGS_TEMP = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/logs/temp${query ? "?" + query : ""}`;
export const GET_EXTENSIONS_CAMPAIGN = `${PREFIXES_WITH_CAMPAIGN}extensions/core/campaign/extensions`;
export const GET_PACT_CAMPAIGNS = `${PREFIXES_WITH_CAMPAIGN}integrations/message/pact-campaigns`;
export const ADD_EXTENSIONS_CAMPAIGN = (extension_id) => `${PREFIXES_WITH_CAMPAIGN}extensions/core/connect/campaign?extension_id=${extension_id}`;
export const UPDATE_EXTENSIONS_CAMPAIGN = (extension_id) => `${PREFIXES_WITH_CAMPAIGN}extensions/core/disconnect/${extension_id}`;
export const GET_TELEPHONY_CONFIG = `${PREFIXES_WITH_CAMPAIGN}integrations/telephony/configurate/telephony`;
export const ADD_TELEPHONY_CONFIG = `${PREFIXES_WITH_CAMPAIGN}integrations/telephony/configurate/telephony`;
export const UPDATE_TELEPHONY_CONFIG = `${PREFIXES_WITH_CAMPAIGN}integrations/telephony/configurate/telephony`;
export const GET_PACT_TOKEN = `${PREFIXES_WITH_CAMPAIGN}integrations/message/pact-token-campaign`;
export const ADD_PACT_TOKEN = (pact_token) => `${PREFIXES_WITH_CAMPAIGN}integrations/message/set-pact-token?pact_token=${pact_token}`;
export const SET_PACT_CAMPAIGNS = `${PREFIXES_WITH_CAMPAIGN}integrations/message/set-pact-campaigns`;
export const DELETE_PACT_CAMPAIGN = (campaign_id) => `${PREFIXES_WITH_CAMPAIGN}integrations/message/remove?company_id=${campaign_id}`;
export const UPLOAD_FILES = (object_id) => `${PREFIXES_WITH_CAMPAIGN}integrations/minio/upload-files?${object_id}`;
export const GET_PUSH = (token) => `${PREFIXES_WITH_CAMPAIGN}extensions/notifications/set-token?${token}`;

// stages
export const GET_MULTIPLY_VIEW_EXTENSIONS_CONFIGS = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/stages${query ? "?" + query : ""}`;
export const FINISH_STAGE = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/stage/finish/${query.path + (query.params ? "?" + query.params : "")}`;
export const UPDATE_STAGE = `${PREFIXES_WITH_CAMPAIGN}extensions/stages`;

export const APPROVE_OBJECT = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/stage/approve${query ? "?" + query : ""}`;
export const GET_APPROVE_OBJECTS = (query) =>
  `${PREFIXES_WITH_CAMPAIGN}extensions/stage/approve/${query.path + (query.params ? "?" + query.params : "")}`;
export const CHANGE_OBJECT_STATUSES = `${PREFIXES_WITH_CAMPAIGN}extensions/stage/objects`;
// deadlines
export const GET_DEADLINE_GROUPED_OBJECTS = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/deadline${query ? "?" + query : ""}`;
export const GET_DEADLINE_GROUPED_OBJECTS_BY_LEAD_MANAGER = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/deadline/lead-manager${query ? "?" + query : ""}`;
export const GET_DEADLINE_GROUPED_OBJECTS_BY_LEAD_REMOTE = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/deadline/lead-remote${query ? "?" + query : ""}`;

// messages
export const CREATE_WA_MESSAGE = `${PREFIXES_WITH_CAMPAIGN}integrations/message/send`;

export const GET_ALL_CAMPAIGNS = `${PREFIXES_WITH_CAMPAIGN}integrations/message/pact-campaigns`;

const transformPayload = (payload) => {
  const { pact_company_id, to } = payload;
  if (!pact_company_id || !to) {
    return "";
  }
  const company_id_query = pact_company_id ? `pact_company_id=${pact_company_id}` : "";
  const to_query = to ? `to=${to}` : "";
  return `?${company_id_query}&${to_query}`;
};
export const CREATE_PACT = (payload) => `${PREFIXES_WITH_CAMPAIGN}integrations/message/set-pact-account${transformPayload(payload)}`;
export const GET_PACT_USERS = `${PREFIXES_WITH_CAMPAIGN}integrations/message/get-pact-user`;

// comment
export const CREATE_OBJECT_COMMENT = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/log/create-comment${query ? "?" + query : ""}`;
export const UPDATE_OBJECT_COMMENT = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/log/update-comment${query ? "?" + query : ""}`;
export const REMOVE_OBJECT_COMMENT = (query) => `${PREFIXES_WITH_CAMPAIGN}extensions/log/remove-comment${query ? "?id=" + query : ""}`;

// statistics
export const GET_ALL_STATS = `${PREFIXES_WITH_CAMPAIGN}extensions/statistic/all-stats`;
export const GET_STATISTICS = (payload) =>
  `${PREFIXES_WITH_CAMPAIGN}extensions/statistic${payload.prefix}${payload.query ? "?" + payload.query : ""}`;
export const GET_STATISTIC_FILTERS = `${PREFIXES_WITH_CAMPAIGN}extensions/statistic/all-filters`;

// telephony
export const PRE_OUTGOING_CALL = (query) => `${PREFIXES_WITH_CAMPAIGN}integrations/telephony/pre-outgoing-call${query ? "?" + query : ""}`;
export const REGISTER_OUTGOING_CALL = `${PREFIXES_WITH_CAMPAIGN}integrations/telephony/register-outgoing-call`;

// tariffs
export const GET_CURRENT_TARIFF = `${PREFIXES_WITH_CAMPAIGN}user/tariff`;
export const GET_ALL_TARIFFS = `${PREFIXES_WITH_CAMPAIGN}user/tariffs`;
