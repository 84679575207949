import React from "react";
import "./index.css";
import { ClickOutsideBoundaryTemplate } from "../../../../templates/ClickOutsideBoundaryTemplate";
import EditWidgetIconLabelTemplate from "../../../../templates/EditWidgetIconLabelTemplate";
import DateCalendar from "../../../../kit/DateTimes/DateCalendar";
import { getShortRuDate, getShortRuDateHoursMinutes } from "@/helpers/date-formatter.ts";
import { LINE_HEIGHT } from "@/constants/ui-constants.js";
import Spinner from "../../../../kit/Loaders/Spinner";
import IconManager from "../../../../kit/Icons/IconManager";

class EditDateTime extends React.Component {
  constructor(props) {
    super(props);
    this.ref = null;
  }

  render() {
    const {
      time,
      date,
      fontSize,
      label,
      icon,
      loading,
      color,
      style,
      width,
      tooltip,
      error,
      value,
      onSubmit,
      readOnly,
      placeholder,
      handleScrollableDivs,
    } = this.props;

    const getValueView = (value) => {
      let str = "";
      if (value && new Date(value).getHours) {
        str = (date ? getShortRuDate(value) : "") + (time ? " - " + getShortRuDateHoursMinutes(new Date(value)) : "");
      }
      return str;
    };

    const header = () => {
      return (
        <div
          {...{
            className: `EditDateTimeHeader flex-row ${error ? "EditDateTimeHeader_error" : ""}`,
            style: {
              opacity: readOnly ? 0.6 : 1,
            },
          }}>
          {icon && !label && (
            <div
              {...{
                style: {
                  height: fontSize * LINE_HEIGHT,
                  width: fontSize * LINE_HEIGHT,
                },
                className: "EditDateTimeHeader_icon flex-row align-center justify-start",
              }}>
              <IconManager {...{ icon, size: fontSize - 3, color }} />
            </div>
          )}
          <div {...{ className: "EditDateTimeHeader_value" }}>
            <span
              {...{
                className: "text-dotted",
                style: { fontSize, color },
                placeholder,
              }}>
              {value ? getValueView(value) : null}
            </span>
          </div>
          {loading && (
            <div
              {...{
                className: "EditDateTimeHeader_loading",
                style: { right: -(fontSize * LINE_HEIGHT) - 6 },
              }}>
              <Spinner {...{ loading: loading, size: fontSize }} />
            </div>
          )}
        </div>
      );
    };

    return (
      <EditWidgetIconLabelTemplate
        {...{
          label,
          fontSize,
          icon,
        }}>
        <ClickOutsideBoundaryTemplate
          {...{
            handleScrollableDivs,
            header,
            style: { width: "100%" },
            headerStyle: {
              width: !!width && width ? width : "100%",
              cursor: readOnly ? "default" : "pointer",
              pointerEvents: readOnly ? "none" : "initial",
            },
            ref: (elem) => (this.ref = elem),
            containerMargin: 8,
          }}>
          <DateCalendar
            {...{
              value: value,
              time,
              date,
              onCancel: () => this.ref.handleContainer(),
              onSubmit: (value) => {
                onSubmit(value);
                this.ref.handleContainer();
              },
            }}
          />
        </ClickOutsideBoundaryTemplate>
      </EditWidgetIconLabelTemplate>
    );
  }
}

export default EditDateTime;

EditDateTime.defaultProps = {
  handleScrollableDivs: true,
  fontSize: 15,
  date: true,
  time: true,
  placeholder: "Выбрать",
  loading: false,
  error: false,
  readOnly: false,
};
