import { useRef } from "react";
import { COLORS } from "../../../../constants/types";
import ConfigIcon from "../../Icons/ConfigIcon";
import { IoMdColorFilter, IoMdClose } from "react-icons/io";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import "./index.css";

export interface IColor {
  name: string;
  color: string;
}

interface SelectColorProps {
  isOpen: boolean;
  onChange: (color: IColor) => void;
  onClose: () => void;
  color: IColor;
}

const SelectColor = ({ isOpen, color, onChange, onClose }: SelectColorProps) => {
  const ref = useRef<any>(null);

  // const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
  // 	setChangeColor(event.target.value);
  // 	console.log(reversColors);
  // };

  const closeModal = () => {
    onClose();
    ref.current.handleContainer();
  };

  const handleColorClick = (color: IColor) => {
    onChange(color);
    closeModal();
  };

  return (
    <ClickOutsideBoundaryTemplate
      {...{
        isOpen,
        containerMargin: 2,
        header: <div className="SelectColor_trigger" style={{ backgroundColor: color.color }} />,
        ref: (el: any) => (ref.current = el),
      }}>
      <div
        {...{
          className: "SelectColor padding-17 border-radius-6",
        }}>
        <div {...{ className: "SelectColor_wrapper flex flex-column" }}>
          <div
            {...{
              className: "SelectColor_close w-100 flex justify-end margin-bottom-14",
              onClick: closeModal,
            }}>
            <ConfigIcon
              {...{
                size: 20,
                className: "pointer",
              }}>
              <IoMdClose />
            </ConfigIcon>
          </div>
          <div {...{ className: "SelectColor_items" }}>
            {colors.map((item, key) => {
              return (
                <div
                  {...{
                    className: "margin-right-7 margin-bottom-7 SelectColor_item pointer border-radius-6 ",
                    style: { backgroundColor: `${item.color}` },
                    key: key,
                    onClick: () => handleColorClick(item),
                  }}
                />
              );
            })}
            <div
              {...{
                className: "SelectColor_item pointer border-radius-6 margin-right-7 margin-bottom-7",
                style: { backgroundColor: `${color.color}` },
                onClick: () => handleColorClick(color),
              }}
            />
            <div
              {...{
                className: "SelectColor_change margin-right-7 margin-bottom-7",
              }}>
              {/*<input type="color" id="color" onChange={handleColorChange} value={changeColor} />*/}
              <label htmlFor="color">
                <ConfigIcon
                  {...{
                    size: 20,
                    className: "pointer",
                  }}>
                  <IoMdColorFilter />
                </ConfigIcon>
              </label>
            </div>
          </div>
        </div>
      </div>
    </ClickOutsideBoundaryTemplate>
  );
};
export default SelectColor;

const colors: IColor[] = Object.entries(COLORS).map(([name, color]) => ({ color, name } as IColor));

export const reversColors = Object.fromEntries(colors.map(({ color, name }) => [color, name]));

// export const colors = [
// 	{
// 		color: "#B03060",
// 	},
// 	{
// 		color: "#eb3d25",
// 	},
// 	{
// 		color: "#541611",
// 	},
// 	{
// 		color: "#f38fa9",
// 	},
// 	{
// 		color: "#ffe449",
// 	},
// 	{
// 		color: "#2d9bff",
// 	},
// 	{
// 		color: "#ff6900",
// 	},
// 	{
// 		color: "#896cb9",
// 	},
// 	{
// 		color: "#ff0096",
// 	},
// 	{
// 		color: "#a2ace6",
// 	},
// 	{
// 		color: "#3b48d7",
// 	},
// 	{
// 		color: "#03abb3",
// 	},
// ];
