import React from "react";
import "./index.css";
import ConfigIcon from "../../Icons/ConfigIcon";

const EditableDiv = (props) => {
	const { fontSize, icon, color, onSubmit, placeholder, value, tooltip, style, onBlur, onEnterPress, readOnly = false, disabled = false, divRef, error = false, loading = false } = props;

	const ref = divRef ? divRef : React.useRef(null);

	React.useEffect(() => {
		if (ref.current && !disabled && !readOnly) {
			ref.current.addEventListener("paste", (e) => {
				e.preventDefault();
				if (e.clipboardData) {
					const content = (e.originalEvent || e).clipboardData.getData("text/plain");
					document.execCommand("insertText", false, content);
				} else if (window.clipboardData) {
					const content = window.clipboardData.getData("Text");
					document.selection.createRange().pasteHTML(content);
				}
				// const date = '2022-11-09T12:22:46.927Z'
			});
			ref.current.addEventListener("blur", (e) => {
				e.preventDefault();
				blurDivEditable();
			});
		}
	}, [ref, disabled, readOnly]);

	const focusDivEditable = () => {
		if (!disabled && !readOnly) {
			ref.current.setAttribute("contenteditable", true);
			Object.assign(ref.current.style, {
				"-webkit-user-modify": "read-write-plaintext-only",
			});
			ref.current.focus();
			ref.current.addEventListener("keypress", keyPressEnterListener);
		}
	};

	let state = value;

	const blurDivEditable = () => {
		ref.current.setAttribute("contenteditable", false);
		Object.assign(ref.current.style, {
			"-webkit-user-modify": "read-only",
		});
		const text = ref.current.innerText;
		if (onBlur) {
			onBlur(text);
		}
		if (state !== text && !disabled && !readOnly && onSubmit) {
			onSubmit(text);
			state = text;
		}
	};

	const keyPressEnterListener = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.target.blur();
			if (onEnterPress) {
				onEnterPress(ref.current.innerText);
			}
			ref.current.removeEventListener("keypress", keyPressEnterListener);
		}
	};

	return (
		<div {...{ className: "flex-row align-start", style }}>
			{icon && (
				<ConfigIcon
					{...{
						color,
						size: fontSize - 2,
						style: { marginRight: 4, height: fontSize + fontSize / 2 },
					}}
				>
					{icon}
				</ConfigIcon>
			)}
			<div
				{...{
					...(tooltip && { title: placeholder ? placeholder : "Текст" }),
					ref,
					style: { color, fontSize },
					spellCheck: false,
					onClick: () => (!readOnly ? focusDivEditable() : false),
					className: `EditableDiv ${error ? "EditableDiv_error" : ""}`,
					placeholder: placeholder ? placeholder : "Текст",
				}}
			>
				{value}
			</div>
		</div>
	);
};

export default EditableDiv;
