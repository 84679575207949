import { formatFieldValues } from "./values-validator";

export const formatFieldForCreate = (field) => ({
	field_id: field.id,
	values: formatFieldValues(field.type, field.values),
});

class Field {
	constructor() {
		this.id = null
		this.choice = null
		this.schema_id = null
		this.name = null
		this.type = null
		this.default = null
		this.values_amount = null
		this.required = null
		this.read_only = null
		this.show = null
		this.info = null
		this.placeholder = null
		this.icon = null
		this.edit_widget = null
		this.view_widget = null
		this.extensions_type = null
		this.meta_name = null
	}
}
