import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./index.css";

const ObjectCardSkeleton = ({ style, user }) => {
	return (
		<SkeletonTheme baseColor="var(--skeleton-loader-second)" highlightColor="var(--skeleton-loader-first)">
			<div className={"ObjectCardSkeleton"} style={{ ...style }}>
				<Skeleton {...{ style: { width: "80%", height: 20, borderRadius: 4 } }} />
				<Skeleton
					{...{
						style: { width: 140, height: 20, marginTop: 7, borderRadius: 4 },
					}}
				/>
				<div className={"flex-row justify-between"}>
					<Skeleton
						{...{
							style: { width: 80, height: 20, marginTop: 7, borderRadius: 4 },
						}}
					/>
					<Skeleton
						{...{
							style: { width: 80, height: 20, marginTop: 7, borderRadius: 4 },
						}}
					/>
				</div>
				<Skeleton
					{...{
						style: { width: 60, height: 10, marginTop: 7, borderRadius: 4 },
					}}
				/>
				<div className={"w-100 flex-row justify-end align-center"} style={{ marginTop: 0, height: 30 }}>
					<Skeleton {...{ style: { width: 30, height: 30, borderRadius: 15 } }} />
				</div>
			</div>
		</SkeletonTheme>
	);
};

export default ObjectCardSkeleton;
