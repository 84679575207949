import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatFieldValues, isFieldValueValid, isValue } from "../../../../helpers/values-validator";
import { singleObject_UpdateObjectGroupField, singleObject_UpdateObjectMainElementField } from "../../../../store/actions/singleObjectActions";
import DefaultButton from "../../../kit/Buttons/DefaultButton";
import { formatFieldForCreate } from "../../../../helpers/field-helper";

const SingleObjectCreateObjectButton = (props) => {
  const { createObjectRequest } = props;

  const [loading, setLoading] = React.useState(false);
  const __singleObjectMainElement = useSelector((state) => state.singleObject.__singleObjectMainElement);
  const __singleObjectGroups = useSelector((state) => state.singleObject.__singleObjectGroups);
  const objectOwnersIds = useSelector((state) => state.singleObject.objectOwnersIds);

  const navigate = useNavigate();
  const createStoreAction = useDispatch();

  // Returns just filled and required fields
  // If field not filled & not required - returns undefined type
  const validateFieldBeforeCreate = (field, field_key, store_key) => {
    const isError = !isFieldValueValid(field.required, field.type, field.values);
    if (isError) {
      const fieldStoreKeys = {
        field_key,
        ...(store_key && { store_key }),
      };
      // console.log(fieldStoreKeys);
      const payload = {
        ...fieldStoreKeys,
        data: { error: isError },
      };
      // console.log(payload);
      if (store_key) {
        createStoreAction(singleObject_UpdateObjectGroupField(payload));
      } else {
        createStoreAction(singleObject_UpdateObjectMainElementField(payload));
      }
      return false;
    } else if (!isError && isValue(field.values)) {
      return formatFieldForCreate(field);
    }
    return undefined;
  };

  const handleCreateObject = () => {
    const __fieldsFilledValues = [];
    let valid = true;

    const setValidByValidatedField = (validatedField) => {
      if (validatedField) {
        __fieldsFilledValues.push(validatedField);
      } else if (typeof validatedField === "boolean" && !validatedField) {
        valid = validatedField;
      }
    };
    // Collecting fields from __singleObjectMainElement
    Object.keys(__singleObjectMainElement).map((field_key) => {
      const field = __singleObjectMainElement[field_key];
      const validatedField = validateFieldBeforeCreate(field, field_key, null);
      setValidByValidatedField(validatedField);
    });

    // Collecting fields from __singleObjectGroups
    Object.keys(__singleObjectGroups).map((store_key) => {
      const fields = __singleObjectGroups[store_key].fields;
      Object.keys(fields).map((field_key) => {
        const field = fields[field_key];
        const validatedField = validateFieldBeforeCreate(field, field_key, store_key);
        setValidByValidatedField(validatedField);
      });
    });
    if (valid) {
      setLoading(true);
      createObjectRequest(__fieldsFilledValues, objectOwnersIds)
        .then((result) => {
          if (result.resultPath) {
            navigate(result.resultPath);
          }
        })
        .catch((e) => {
          // some error action
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      // some error action
    }
  };

  return (
    <div
      {...{
        style: {
          height: "50px !important",
          marginBottom: 14,
        },
      }}>
      <DefaultButton
        {...{
          text: "Создать",
          loading,
          height: 50,
          disabled: typeof loading === "boolean" ? loading : false,
          onClick: () => handleCreateObject(),
        }}
      />
    </div>
  );
};

export default SingleObjectCreateObjectButton;
