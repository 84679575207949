import DefaultButton from "../Buttons/DefaultButton";

interface BottomProps {
  buttonLabel: string;
  onClose?: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
}

const Bottom = ({ onClose, onSubmit, buttonLabel, loading, error, disabled }: BottomProps) => {
  return (
    <div className="flex justify-end">
      <div className="margin-right-30">
        <DefaultButton
          disabled={loading}
          text="Отменить"
          fitContent={true}
          style={{ backgroundColor: "transparent" }}
          color="var(--text-seventh)"
          onClick={onClose}
        />
      </div>
      <DefaultButton
        disabled={loading || disabled}
        loading={loading}
        text={buttonLabel}
        fitContent={true}
        onClick={onSubmit}
      />
    </div>
  );
};

export default Bottom;
