import React from 'react';
import { useNavigate } from "react-router-dom";

const Redirect = ({redirect_path}) => {

	const navigate = useNavigate()

	React.useEffect(() => {
		navigate(redirect_path)
	}, [])
}

export default Redirect
