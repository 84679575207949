import React from "react";
import "./index.css";
import { useSelector } from "react-redux";
import FinalStage from "./FinalStage";

const StageExtension_MultipleView = (props) => {
  const { dragNDrop } = props;

  const isActive = useSelector((state) => state.extensions.__stageExtensionConfig.isActive);

  if (isActive) {
    return (
      <div
        {...{
          className: "StageExtension_MultipleView",
        }}>
        {Object.keys(FINAL_STAGES).map((key) => {
          return (
            <FinalStage
              {...{
                key,
                stageKey: key,
                dragNDrop,
              }}
            />
          );
        })}
      </div>
    );
  } else return null;
};

export default StageExtension_MultipleView;

const FINAL_STAGES = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};
