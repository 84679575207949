import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useRedux";
import { FiltersValues, IFilterPayload, reportsActions } from "../../../../store/reducers/reportsSlice";
import { IFilter, IStatsMeta } from "../../../../types/stats";
import ModalPopup from "../ModalPopup";
import Bottom from "./Bottom";
import FilterField from "./FilterField";

interface ReportsFiltersModalProps {
  filters: IFilter[];
  handleClose?: () => void;
}

export const ReportsFiltersModal = ({ handleClose, filters }: ReportsFiltersModalProps) => {
  const ref = useRef(null);
  const filterValues = useAppSelector((state) => state.reports.filter_values);
  const methods = useForm({ values: filterValues });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = (data: FiltersValues) => {
    dispatch(reportsActions.setFiltersValues({ filter_values: data }));
    const filter_fields = Object.keys(data).reduce((acc, field_id) => {
      const values = data[field_id]?.map((field) => {
        if (typeof field === "object" && "id" in field) {
          return field.id;
        }
        return field;
      });
      if (values && values.length > 0) {
        acc.push({ field_id: parseInt(field_id), values });
      }
      return acc;
    }, [] as IFilterPayload[]);
    dispatch(reportsActions.setLoading({ loading: true }));
    dispatch(reportsActions.setFiltersFields({ filter_fields }));
    dispatch(reportsActions.refetch());
    if (handleClose) {
      handleClose();
    }
  };

  const handleCancel = () => {
    Object.keys(methods.getValues()).forEach((field_name) => methods.setValue(field_name, []));
    dispatch(reportsActions.resetFilters());
  };
  const renderFilters = () => {
    const date = filters.filter((filterField) => filterField.edit_widget === "DATETIME");
    const filtersOther = filters.filter((filterField) => filterField.edit_widget !== "DATETIME");
    return (
      <div>
        <div style={{ display: "flex", gap: "3px" }}>
          {date.map((v) => (
            <FilterField
              key={v.field_id.toString()}
              filterField={v}
              name={v.field_id.toString()}
              className="w-100 margin-bottom-10"
            />
          ))}
        </div>
        {filtersOther.map((v) => (
          <div>
            <FilterField
              key={v.field_id.toString()}
              filterField={v}
              name={v.field_id.toString()}
              className="margin-bottom-10"
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={ref}
        onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalPopup
          width={500}
          titleText="Фильтры"
          bottom={() => (
            <Bottom
              submitLabel={"Применить"}
              loading={isLoading}
              cancelLabel={"сбросить фильтры"}
              onCancel={handleCancel}
            />
          )}
          onClose={() => handleClose && handleClose()}>
          <div>
            {filters.length > 0 ? (
              renderFilters()
            ) : (
              <SkeletonTheme
                baseColor="var(--skeleton-loader-second)"
                highlightColor="var(--skeleton-loader-first)">
                <Skeleton
                  count={6}
                  height={60}
                />
              </SkeletonTheme>
            )}
          </div>
        </ModalPopup>
      </form>
    </FormProvider>
  );
};
