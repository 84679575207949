import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import * as JsSIP from "jssip";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { CALL_STATUSES } from "../call-statuses";
import { removeLetters } from "@/helpers/phone-formatter.js";
import { setCallExtension } from "@/store/reducers/extensionsReducer.jsx";
import { registerOutgoingCall, preOutgoingCall } from "@/api/routes/extensions.tsx";
import { BAD, SUCCESS } from "@/helpers/response-service.ts";
import ControlsButtons from "../ControlsButtons";
import AudioElements from "../AudioElements";
import CallStatusInformation from "../CallStatusInformation";
import SelectSip from "../SelectSip";
import cn from "classnames";
import { COLORS } from "@/constants/types.tsx";
import ModalCreateObject from "../../../kit/Modals/ModalCreateObject/ModalCreateObject";
import * as _ from "lodash"

const callSound = "https://cdn.ugravity.org/hh-resumes/request-call-in-process-s.mp3";

const mediaConstraints = {
  audio: true,
  video: false,
};

const OutgoingCallContainer = () => {
  const [isInit, setIsInit] = useState(false);
  const [isSelectSipActive, setIsSelectSipActive] = useState(true);
  const [currentStatus, setCurrentStatus] = useState(CALL_STATUSES.CONNECTING);
  const [currentCallConfig, setCurrentCallConfig] = useState(null);
  const [tones, setTones] = useState(null)

  const audioRef = useRef(null);
  const timerRef = useRef(null);

  const [createObjectModal, setCreateObjectModal] = useState(false);
  const shouldCreateObject = useRef(false);
  const [phone, setPhone] = useState("");

  const callContainerRef = useRef(null);

  const current_call = useSelector((state) => state.extensions.__callExtension);
  const dispatch = useDispatch();

  const initUA = (config, registered_call_id) => {
    let id = currentCallConfig?.id;
    if (!id && !registered_call_id) {
      endCall();
      return null;
    }
    if (!id && registered_call_id) {
      id = registered_call_id;
    }
    const { sip_socket_url, sip_password, sip } = config;
    const phone = removeLetters(current_call.phone.value);

    if (!sip || !sip_socket_url || !sip_password || !phone) {
      return null;
    }
    window.ua = new JsSIP.UA({
      uri: sip,
      password: sip_password,
      sockets: [new JsSIP.WebSocketInterface(sip_socket_url)],
    });
    window.ua.start();
    window.ua.on("connected", function (e) {
      makeCall(phone, id);
    });
    window.ua.on("disconnected", function (e) {
      window.ua.stop();
      setIsInit(false);
      closeCallContainer();
    });
  };

  const onSelectSip = (selected_sip_config, registered_call_id) => {
    setIsSelectSipActive(false);
    setTones(selected_sip_config.tones)
    initUA(selected_sip_config, registered_call_id);
  };

  const validateSipCallId = (config) => {
    if (!_.isArray(config.sips) || config.sips.length === 0) {
      closeCallContainer();
      return null;
    }
    if (config.sips.length > 1) {
      setCurrentCallConfig(config);
      setIsSelectSipActive(true);
    } else if (config.sips.length === 1) {
      onSelectSip(config.sips[0], config.id);
    }
  };

  const createPreOutgoingCall = (phone, object_id) => {
    if (!object_id) {
      shouldCreateObject.current = true;
    }
    const clearPhone = removeLetters(phone);
    const payload = {
      query: new URLSearchParams({
        ...(object_id && { object_id }),
        phone: clearPhone,
      }).toString(),
    };
    preOutgoingCall(payload).then((result) => {
      switch (result.kind) {
        case SUCCESS:
          validateSipCallId(result.data);
          break;
      }
    });
  };

  const handleProgressCall = (payload) => {
    registerOutgoingCall({ data: payload }).then((result) => {
      switch (result.kind) {
        case SUCCESS:
          return null;
        case BAD:
          endCall();
      }
    });
  };

  // HANDLERS
  const progress = (e, pre_call_id) => {
    audioRef.current.playSound();
    audioRef.current.muteCall();
    setCurrentStatus(CALL_STATUSES.WAITING);
    if (!e.response.call_id || !pre_call_id) {
      endCall();
      return null;
    }
    const payload = {
      call_id: e.response.call_id,
      pre_call_id,
      call_status: "PROGRESS",
    };
    handleProgressCall(payload);
  };

  const failed = () => {
    timerRef.current.stopTimer();
    audioRef.current.pauseSound();
    setCurrentStatus(CALL_STATUSES.ERROR);
    setTimeout(() => {
      setIsInit(false);
      setTimeout(() => {
        closeCallContainer();
      }, 1000);
    }, 1000);
  };

  const confirmed = () => {
    timerRef.current.startTimer();
    audioRef.current.pauseSound();
    audioRef.current.unMuteCall();
    setCurrentStatus(CALL_STATUSES.CALL);
  };

  const handlers = (pre_call_id) => ({
    progress: (e) => progress(e, pre_call_id),
    failed: (e) => failed(e),
    ended: (e) => failed(e),
    confirmed: (e) => confirmed(e),
  });

  const openCreateModal = () => {
    if (shouldCreateObject.current && removeLetters(current_call?.phone?.value)) {
      setPhone(removeLetters(current_call?.phone?.value));
      setCreateObjectModal(true);
    }
    shouldCreateObject.current = false;
  };

  const endCall = () => {
    audioRef.current.pauseSound();

    if (currentStatus.status_key === CALL_STATUSES.CONNECTING.status_key) {
      if (window.ua) {
        openCreateModal();
        window.ua.stop();
      } else {
        openCreateModal();
        setIsInit(false);
        setTimeout(() => {
          closeCallContainer();
        }, 1000);
      }
    } else {
      openCreateModal();
      window.ua.stop();
    }
  };

  /**
   * @param {string} phone - f.e: 79998887766
   * @param {int} pre_call_id
   */
  const makeCall = (phone, pre_call_id) => {
    window.oSipAudio = audioRef.current.audio;
    window.oCall = window.ua.call(phone, {
      eventHandlers: handlers(pre_call_id),
      mediaConstraints,
    });
    window.oCall.connection.onaddstream = (e) => {
      window.oSipAudio.srcObject = e.stream;
      window.oSipAudio.play();
    };
  };

  const sendTone = (tone) => {
    if (!!window.oCall.sendDTMF) {
      window.oCall.sendDTMF(tone.toString());
    }
  };

  const onCloseSip = () => {
    setIsInit(false);
    setTimeout(() => {
      closeCallContainer();
      setIsSelectSipActive(false);
    }, 1000);
  };

  const openCallContainer = () => {
    callContainerRef.current.style.display = "flex";
  };

  const closeCallContainer = () => {
    callContainerRef.current.style.display = "none";
    setCurrentStatus(CALL_STATUSES.CONNECTING);
    dispatch(setCallExtension(null));
  };

  useEffect(() => {
    if (current_call && current_call.phone) {
      openCallContainer();
      setIsInit(true);
      setIsSelectSipActive(true);
      createPreOutgoingCall(current_call.phone.value, current_call.object_id);
    }
  }, [current_call]);

  return (
    <>
      <motion.div
        {...{
          ref: callContainerRef,
          ...containerConfig(isInit),
        }}>
        {!!tones && <div className={cn(styles.CallClient__DTMF_Tones)}>{
          tones.map((toneElement, index) => {
            return <div style={{
              backgroundColor: COLORS[toneElement.color] ? COLORS[toneElement.color] : undefined
            }} className={cn(styles.CallClient__DTMF_Tones__Element)} onClick={() => sendTone(toneElement.tone)} key={index}>{toneElement.title}</div>
          })
        }</div>}
        <div className={cn(styles.CallClient__DTMF_Numbers)}>
          {Array.from(Array(10).keys()).map((num) => {
            return (
              <div
                key={num}
                onClick={() => sendTone(num)}
                className={cn(styles.CallClient__DTMF_Numbers_item)}>
                {num}
              </div>
            );
          })}
          <div
            onClick={() => sendTone("#")}
            className={cn(styles.CallClient__DTMF_Numbers_item)}>
            #
          </div>
          <div
            onClick={() => sendTone("*")}
            className={cn(styles.CallClient__DTMF_Numbers_item)}>
            *
          </div>
        </div>
        <div className={cn(styles.CallClient__wrapper)}>
          <ModalCreateObject
            onSuccess={() => setPhone("")}
            phone={phone}
            open={createObjectModal}
            onOpenChange={setCreateObjectModal}
          />
          <CallStatusInformation
            {...{
              title: currentStatus.status_timer_name,
              subtitle: currentStatus.status_text,
              loading: currentStatus.loading,
              color: currentStatus.status_color,
            }}
          />
          <ControlsButtons
            {...{
              isShowTimer: currentStatus.timer,
              ref: timerRef,
              isShowConfirm: false,
              isShowDecline: currentStatus.declineButtonActive,
              onConfirm: null,
              onDecline: endCall,
            }}
          />
          {isSelectSipActive && (
            <SelectSip
              {...{
                onSelect: onSelectSip,
                data: currentCallConfig?.sips,
                close: onCloseSip,
              }}
            />
          )}
        </div>
      </motion.div>
      <AudioElements
        {...{
          ref: audioRef,
          ringAudioSrc: callSound,
        }}
      />
    </>
  );
};

export default OutgoingCallContainer;

const containerConfig = (isInit) => ({
  className: styles.CallClient__container,
  variants: CALL_CLIENT_OPEN_VARIANTS,
  animate: isInit ? "opened" : "closed",
  initial: "closed",
});

const CALL_CLIENT_OPEN_VARIANTS = {
  opened: {
    zIndex: 99999,
    y: 0,
    opacity: 1,
    transition: {
      bounces: false,
    },
  },
  closed: {
    zIndex: 0,
    y: 250,
    opacity: 0,
    transition: {
      bounces: false,
    },
  },
};
