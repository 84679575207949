import React, { useState } from "react";
import { tempObjectCreate } from "../../../../api/routes/objects_core";
import { SchemasMetaNames } from "../../../../constants/MetaNamesEnum";
import { KindEnum } from "../../../../helpers/response-service";
import { useNotification } from "../../../../hooks/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useRedux";
import { ISchema } from "../../../../types/schema";
import { ClickOutsideBoundaryTemplate } from "../../../templates/ClickOutsideBoundaryTemplate";
import DefaultInputPlaceholder from "../../Inputs/DefaultInputPlaceholder";
import SelectDefaultPlaceholder from "../../Selects/SelectDefaultPlaceholder";
import Bottom from "../Bottom";
import ModalPopup from "../ModalPopup";
import "./ModalDelete.css";

interface ModalSettingDelete {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  phone: string;
  loading?: boolean;
  disabled?: boolean;
  onSuccess: () => void;
}

const ModalCreateObject = ({ loading, disabled, open, onOpenChange, phone, onSuccess }: ModalSettingDelete) => {
  const schemas = useAppSelector((state) => state.app.schemas);
  const [selectedSchema, setSelectedSchema] = useState<ISchema[]>([]);
  const [name, setName] = useState("");

  const [selectedSchemaError, setSelectedSchemaError] = useState(false);

  const { errorNotification, succeedNotification } = useNotification();

  const createObject = async () => {
    if (!selectedSchema[0] || !phone) {
      setSelectedSchemaError(true);
      return;
    }

    setSelectedSchemaError(false);

    const payload = {
      query: new URLSearchParams({ phone, schema_id: selectedSchema[0].id.toString(), name }).toString(),
    };

    const res = await tempObjectCreate(payload);
    onOpenChange(false);

    if (res.kind === KindEnum.BAD) {
      errorNotification("Произошла ошибка, объект не создан");
      return;
    }

    onSuccess();
    setSelectedSchema([]);
    succeedNotification("Объект успешно создан");
  };

  return (
    <ClickOutsideBoundaryTemplate
      isOpen={open}
      onChange={onOpenChange}
      overlayVisible
      center>
      <ModalPopup
        onClose={() => onOpenChange(false)}
        titleText="Создать объект?"
        // titleDescription={description}
        bottom={() => (
          <Bottom
            error={true}
            disabled={disabled}
            loading={loading}
            buttonLabel="Создать"
            onSubmit={createObject}
            onClose={() => onOpenChange(false)}
          />
        )}>
        <DefaultInputPlaceholder
          style={{ marginBottom: 10 }}
          value={name}
          onChange={setName}
          placeholder="Введите имя клиента"
        />
        <SelectDefaultPlaceholder
          amount={1}
          error={selectedSchemaError}
          errorText="Обязательное поле"
          placeholder="Выберите схему"
          data={schemas.filter((schema: ISchema) => schema.meta_name === SchemasMetaNames.LEAD)}
          values={selectedSchema}
          field_key="name"
          onChange={setSelectedSchema}
        />
      </ModalPopup>
    </ClickOutsideBoundaryTemplate>
  );
};

export default ModalCreateObject;
