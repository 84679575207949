export const SHOW_ANIMATION_MOTIONS = {
	default_height: (value, initial) => ({
		animate: value ? "opened" : "closed",
		initial: initial ? initial : "opened",
		exit: "closed",
		variants: {
			closed: {
				transition: {
					duration: 0.3,
					bounce: false,
				},
				width: '100%',
				opacity: 0,
				height: 0,
				overflow: "hidden",
			},
			opened: {
				transition: {
					duration: 0.3,
					bounce: false,
				},
				width: '100%',
				opacity: 1,
				height: "auto",
				overflow: "visible",
			},
		},
	}),
	default_width: (value, initial) => ({
		animate: value ? "opened" : "closed",
		initial: initial ? initial : "opened",
		variants: {
			closed: {
				opacity: 0,
				width: 0,
				overflow: "hidden",
			},
			opened: {
				opacity: 1,
				width: "auto",
				overflow: "visible",
			},
		},
	}),
};

export const NEW_DRAGGED_ELEMENT_ANIMATION = (value) => ({
	animate: value ? "open" : "closed",
	initial: "closed",
	transition: { ease: "easeOut", duration: 0.15 },
	variants: {
		open: {
			height: window.crm_drag_n_drop_data?.target.clientHeight - 6 || 50,
			opacity: 1,
			border: "1px dashed #d1d1d1",
			marginTop: 4,
			marginBottom: 2,
		},
		closed: {
			height: 0,
			opacity: 0,
			border: "none",
			margin: 0,
		},
	},
});

export const MAIN_LAYOUT_ANIMATION = {
	initial: { opacity: 0, y: -10 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: -10 },
};

export const CONTENT_LAYOUT_ANIMATION = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};

export const SWITCHER_SWITCH_ANIMATION = {
	type: "spring",
	stiffness: 700,
	damping: 30,
};

export const HANDLE_SIDE_MENU_ANIMATION = (isOpen) => ({
	initial: "closed",
	animate: isOpen ? "opened" : "closed",
	variants: {
		closed: {
			width: 70,
			...LINEAR_TRANSITION
		},
		opened: {
			width: 240,
			...LINEAR_TRANSITION
		},
	},
});

export const EXTENSION_STAGE_ANIMATION = (isOpen) => ({
	initial: "closed",
	animate: isOpen ? "opened" : "closed",
	variants: {
		closed: {
			y: 100,
			transition: {
				duration: 0.1,
				bounce: false,
			},
		},
		opened: {
			y: 0,
			transition: {
				duration: 0.1,
				bounce: false,
			},
		},
	},
});

const LINEAR_TRANSITION = {
	transition: {
		duration: 0.3,
		bounce: false
	}
}

export const SHOW_DROPDOWN_ANIMATION = (focused) => ({
	initial: "blurred",
	animate: focused ? "focused" : "blurred",
	variants: {
		blurred: {
			transition: {
				duration: 0.1,
				bounce: false,
			},
			opacity: 0,
			zIndex: 99999,
			transform: "translateY(10px)",
		},
		focused: {
			transition: {
				duration: 0.2,
				bounce: true,
			},
			opacity: 1,
			zIndex: 99999,
			transform: "translateY(0px)",
		},
	},
});

export const SHOW_SPINNER_ANIMATION = (loading) => ({
	initial: "closed",
	animate: loading ? "opened" : "closed",
	variants: {
		opened: {
			width: 20,
		},
		closed: {
			width: 0,
		},
	},
});

export const SHOW_SPINNER_INPUT_ANIMATION = (loading) => ({
	initial: "closed",
	animate: loading ? "opened" : "closed",
	variants: {
		opened: {
			width: 80,
		},
		closed: {
			width: 0,
		},
	},
});
