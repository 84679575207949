import React from "react";
import DefaultCheckbox from "../../../kit/CheckBoxes/DefaultCheckbox";
import { deleteAllSelectedObjects, setSelectedObjects } from "../../../../store/reducers/selectedObjectsSlice";
import DraggableSection from "../DraggableSection";
import { DRAG_N_DROP_MOVE_TYPES, setDragNDropSectionData } from "../drag-n-drop";
import DraggableElement from "../DraggableElement";
import { useAppDispatch } from "../../../../hooks/useRedux";
import { useSelector } from "react-redux";
import "./index.css";

const DraggableSectionWrapper = (props) => {
  const {
    navigateToObject,
    multiSelect,
    drag,
    onMouseDown,
    cardHeight,
    cardWidth,
    list,
    cardStyle,
    elementContent,
    sectionWrapper,
    section,
    onListEnd,
    loading,
  } = props;

  const sectionRef = React.useRef(null);

  const pull = useSelector((state) => state.selectedObjectsSlice.pull);
  const dispatch = useAppDispatch();

  const [selectedAll, setSelectedAll] = React.useState(false);
  const handleToggleSelected = () => {
    if (!selectedAll) {
      const toggleData = Object.assign(
        {},
        ...section.objects.map((v) => ({
          [v.id]: !selectedAll,
        }))
      );

      dispatch(setSelectedObjects(toggleData));
      setSelectedAll(true);
    } else {
      dispatch(deleteAllSelectedObjects());
      setSelectedAll(false);
    }
  };
  React.useEffect(() => {
    if (selectedAll && Object.keys(pull).length === 0) {
      setSelectedAll(false);
    }
  }, [pull, selectedAll]);

  React.useEffect(() => {
    Object.assign(sectionRef.current, setDragNDropSectionData(section.grouped_by, DRAG_N_DROP_MOVE_TYPES.MOVE));
  }, []);

  return sectionWrapper({
    children: (
      <DraggableSection
        {...{
          cardHeight,
          objects: section.objects,
          drag,
          navigateToObject,
          loading,
          onMouseDown,
          section,
          sectionRef,
          cardWidth,
          list,
          cardStyle,
          elementContent,
          onListEnd,
          isLastPage: section.grouped_by.isLastPage,
          objectsLength: section.objects.length,
        }}>
        {section?.objects?.length && list ? (
          <div {...{ className: "containerSelected" }}>
            <div
              onClick={() => handleToggleSelected()}
              {...{ className: "SelectElementCheckbox" }}>
              <DefaultCheckbox
                onChange={() => {
                  setSelectedAll(!selectedAll);
                }}
                checked={selectedAll}
              />
              <div {...{ className: "text" }}>Выбрать все</div>
            </div>
          </div>
        ) : null}
        {section.objects.map((item, index) => {
          return (
            <DraggableElement
              {...{
                drag,
                multiSelect,
                navigateToObject,
                elementContent,
                cardStyle,
                cardWidth,
                cardHeight,
                onMouseDown,
                item,
                key: index,
              }}
            />
          );
        })}
      </DraggableSection>
    ),
    count: section.objects.length,
    grouped_by: section.grouped_by,
  });
};

DraggableSectionWrapper.defaultProps = {
  multiSelect: false,
};

export default DraggableSectionWrapper;
