import React from "react";
import "./index.css";
import RowList from "../../elements/Rows/RowList";
import DragAndDropTemplate from "../DragAndDropNativeTemplate";
import CardRow from "../../elements/Rows/CardRow";

const RowsTemplate = (props) => {
  const { schema_key, multiSelect, data, schema_id, action, view_id, onListEnd, loading, extensions, drag } = props;

  const MAX_FIELDS_COUNT = 9;

  return (
    <DragAndDropTemplate
      {...{
        multiSelect,
        schema_key,
        cardWidth: "100%",
        cardHeight: 48,
        cardStyle: {
          marginTop: 3,
        },
        drag,
        schema_id,
        extensions,
        data,
        action,
        loading,
        list: true,
        onListEnd,
        sectionWrapper: (props) => <RowList {...{ ...props, schema_id, view_id }} />,
        elementContent: (props) => <CardRow {...{ ...props, max: MAX_FIELDS_COUNT }} />,
      }}
    />
  );
};

export default RowsTemplate;
