import React, {useEffect, useRef, useState} from "react";
import { useIntersectionObserver } from "usehooks-ts";
import Spinner from "../../../kit/Loaders/Spinner";
import {AnimatePresence} from "framer-motion";
import {useInteractionObserver} from "../../../../hooks/useInteractionObserver";

export const OBJECTS_LIMIT = 12;
export const LIST_ADMIN_OBJECTS_LIMIT = 250

const DraggableSection = (props) => {
  const {
    children,
    cardHeight,
    objectsLength,
    isLastPage,
    list,
    sectionRef,
    section,
    onListEnd,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [intersectionRef, isVisible] = useInteractionObserver({})

  useEffect(() => {
    if (isVisible && onListEnd && objectsLength >= OBJECTS_LIMIT) {
      setIsLoading(true);
      const payload = {
        page,
        limit: OBJECTS_LIMIT,
        choice_id: section.grouped_by.choice_id,
      };
      onListEnd(payload).finally(() => setIsLoading(false));
      setPage((page) => page + 1);
    }
  }, [isVisible]);

  return (
    <div
      {...{
        ref: sectionRef,
        className: `boardElementsWrapper ${list ? "boardListElementsWrapper" : ""} flex-column`,
      }}>
      <div {...{ className: `boardElement boardElementHidden` }}></div>
      {children}
      <div
        {...{
          ref: intersectionRef,
          style: { minHeight: cardHeight ? cardHeight : 48 },
          className: `emptyElement droppable`,
        }}>
        <AnimatePresence>
          <Spinner
            size={30}
            loading={isLoading}
            color={"var(--gray1)"}
          />
        </AnimatePresence>
        {list && section?.objects?.length === 0 && <div className={!list ? "listDroppable" : ""}>Добавьте объект, перетащив его сюда</div>}
      </div>
    </div>
  );
};

export default DraggableSection;
