import React from "react";
import "./index.css";
import { removeLetters } from "../../../../../helpers/phone-formatter";
import { updateObject } from "../../../../../api/routes/objects_core";
import EditPhone from "../../../../elements/Widgets/Edit/EditPhone";
import { isValue } from "../../../../../helpers/values-validator";
import { useDispatch } from "react-redux";
import { setCreateMessageActiveExtension, setWhatsAppExtensionPhone } from "../../../../../store/reducers/extensionsReducer";
import { setNotification } from "../../../../../store/actions/appActions";
import { WHATSAPP_PHONE_EMPTY } from "../../../../../constants/notification-texts";
import { IconBrandWhatsapp } from "@tabler/icons-react";

const WhatsAppExtension_FieldView = (props) => {
  const { data, object_id, schemaKey } = props;

  if (data.type !== "PHONE") {
    return null;
  }

  const [state, setState] = React.useState(data);
  const createStoreAction = useDispatch();

  const onChangeField = (value) => {
    let isError = false;
    const cleanPhone = value.map((value) => {
      const cleanPhone = removeLetters(value);
      if (cleanPhone.length !== 11) {
        isError = true;
      }
      return cleanPhone;
    });
    setState((prev) => ({
      ...prev,
      error: isError,
      values: cleanPhone,
    }));
  };

  const onSubmitField = (value) => {
    let isError = false;
    const payload = {
      query: object_id,
      data: [
        {
          field_id: data.id,
          values: value?.map((value) => {
            const cleanPhone = removeLetters(value);
            if (cleanPhone.length !== 11) {
              isError = true;
            }
            return cleanPhone;
          }),
        },
      ],
    };
    if (!isError) {
      updateObject(payload).then((result) => {});
    }
  };

  const focusSender = (value) => {
    if (!value || removeLetters(value).length !== 11) {
      setState((prev) => ({
        ...prev,
        error: true,
      }));
      createStoreAction(setNotification(WHATSAPP_PHONE_EMPTY));
    } else {
      createStoreAction(setWhatsAppExtensionPhone(removeLetters(value)));
      createStoreAction(setCreateMessageActiveExtension("whatsapp"));
    }
  };

  return (
    <div {...{ className: "WhatsAppExtension_FieldView flex-row align-center justify-between" }}>
      <div {...{ className: "WhatsAppExtension_FieldViewField" }}>
        <EditPhone
          {...{
            label: "WhatsApp",
            fontSize: 15,
            loading: false,
            error: state.error,
            placeholder: "WhatsApp",
            values: state && state.values && isValue(state.values) ? state.values : [""],
            readOnly: state.readOnly || +schemaKey !== state.schema_id,
            onSubmit: (value) => onSubmitField(value),
            onChange: (value) => onChangeField(value),
            actionIcon: <IconBrandWhatsapp className="WhatsAppExtension_call-btn" />,
            onActionIconClick: (value) => focusSender(value),
          }}
        />
      </div>
    </div>
  );
};

export default WhatsAppExtension_FieldView;
