import { createSlice } from "@reduxjs/toolkit";

interface IInitialState {
  isFocused: boolean;
}

const initialState: IInitialState = {
  isFocused: false,
};

const windowSlice = createSlice({
  name: "windowSlice",
  initialState,
  reducers: {
    setIsFocused: (state: IInitialState, data: {payload: boolean}) => {
      state.isFocused = data.payload
    }
  },
});

export const { setIsFocused} = windowSlice.actions;

export const windowReducer = windowSlice.reducer;
