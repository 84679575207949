export enum FieldsMetaNames {
  TITLE = "__title__",
  TAGS = "__tags__",
  DESCRIPTION = "__description__",
  PRIORITY = "__priority__",
  CREATED_AT = "__created_at__",
  DEADLINE_TO = "__deadline_to__",
  DEADLINE_FROM = "__deadline_from__",
  DEADLINE_STATUS = "__deadline_status__",
  IS_COMPLETE = "__is_complete__",
  COMPLETE_TEXT = "__complete_text__",
  APPROVE = "__approve__",
  STAGE = "__stage__",
  PACT_PHONE_WA = "__pact_phone_wa__",
  PACT_CONVERSATION_ID = "__pact_conversation_id__",
  PACT_MESSAGE_COUNT_WA = "__pact_message_count_wa__",
}

export enum SchemasMetaNames {
  TASK = "__task__",
  LEAD = "__lead__",
  CLIENT = "__client__",
}

export const MetaNameToName = {
  [SchemasMetaNames.TASK]: "Задача",
  [SchemasMetaNames.LEAD]: "Сделка",
  [SchemasMetaNames.CLIENT]: "Клиент",
};
