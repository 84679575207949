import React from "react";
import { MULTIPLY_VIEWS } from "../../../../constants/multiply-views";
import { useSelector } from "react-redux";
import SkeletonView from "../../../kit/Loaders/SkeletonView";
import Page404 from "../../../../pages/error/Page404";

const GroupedObjects = (props) => {
  const __groupedObjects = useSelector((state) => state.multiplyObjects.__groupedObjects);
  const acceptedViewsActive = useSelector((state) => state.multiplyObjects.acceptedViewsActive);
  const groupedByFieldsActive = useSelector((state) => state.multiplyObjects.groupedByFieldsActive);
  const currentSchemaFields = useSelector((state) => state.multiplyObjects.currentSchemaFields);

  const { schema_id, loading, action, onListEnd, refetching, schema_key, isDragActive } = props;

  if (
    !loading &&
    __groupedObjects &&
    acceptedViewsActive &&
    groupedByFieldsActive &&
    Object.keys(MULTIPLY_VIEWS).includes(acceptedViewsActive.template)
  ) {
    return MULTIPLY_VIEWS[acceptedViewsActive.template].component({
      drag: isDragActive,
      data: __groupedObjects.grouped_objects || [],
      action,
      schema_key,
      multiply_view_id: groupedByFieldsActive.view_id,
      schemaFields: currentSchemaFields,
      schema_id,
      onListEnd,
    });
  } else if (!loading && !acceptedViewsActive) {
    return <Page404 />;
  } else return <SkeletonView {...{ view: acceptedViewsActive?.template }} />;
};

export default GroupedObjects;

GroupedObjects.defaultProps = {
  schemaFields: null,
  schema_id: null,
  isDragActive: true,
};
